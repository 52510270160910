<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <div class="card-title pt-0">
            <v-row v-if="loading">
              <v-col cols="12" md="3" v-for="item in 10" :key="item">
                <div class="pa-5 duplicate--card">
                  <v-skeleton-loader class="mx-auto" max-width="300" type="image"></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="!loading">
              <template v-for="value in contacts">
                <v-col cols="12" md="3">
                  <div class="pa-5 duplicate--card" :key="item.id" v-for="item in limitBy(value, 1)">
                    <span class="duplicate__count">{{value.length}}</span>
                    <div class="d-flex align-center justify-space-between">
                      <div class="d-flex flex-column align-start">
                        <p class="font-15 line-h-18 font-weight--regular user-name">{{item.name}}</p>
                        <p class="font-12 line-h-16 font-weight--regular user-location">{{item.branch_name}}</p>
                      </div>
                      <div class="d-flex align-center justify-space-between">
                        <template v-for="stat in SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('lead')}))" v-if="stat.id === item.status">
                          <v-chip small class="este-chip mb-2" :class="stat.outline ? 'tag-chip' : 'status-chip'" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color">
                            {{ stat.name | capitalize }}
                          </v-chip>
                        </template>
                        <v-chip small class="este-chip mb-2 status-chip">
                          {{item.status_name}}
                        </v-chip>
                        <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                              <v-icon medium>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <div class="logout-block">
                            <ul>
                              <li><a>View Lead</a></li>
                              <li><a>Mark Duplicate</a></li>
                            </ul>
                          </div>
                        </v-menu>
                      </div>
                    </div>
                    <p class="mt-4" v-if="item.contact_no">
                      <span class="contact">
                        <a>
                          <img src="/images/phone.svg" height="15" class="mr-1">{{item.contact_no}}
                        </a>
                      </span>
                    </p>
                    <p class="mt-4" v-if="item.email">
                      <span class="contact">
                        <a>
                          <img src="/images/email.svg" height="15" class="mr-1">{{item.email}}
                        </a>
                      </span>
                    </p>
                  </div>
                </v-col>
              </template>
            </v-row>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import status from '../../../mixins/status';
import Vue2Filters from 'vue2-filters'
export default {
  name: "Duplicates",
  mixins: [Vue2Filters.mixin, status],
  data() {
    return {
      contacts: [],
      loading: false
    }
  },
  created() {
    this.getDuplicates()
  },
  methods: {
    async getDuplicates() {
      this.loading = true
      await this.$store.dispatch('reports/GET_DUPLICATE_CONTACTS', `?page=1&per_page=10`).then(res => {
        if (res.result === 'success') {
          this.contacts = res.data
          this.loading = false
        }
      })
    }
  }
};

</script>
<style lang="scss">
.duplicate--card {
  position: relative;
  background: rgba(217, 217, 217, 0.23) !important;
  box-shadow: 0 1px 2px 1px #0000000f !important;
  margin: 6px 0 6px 6px !important;
  border-radius: 3px !important;

  .duplicate__count {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -12px;
    left: -7px;
    border-radius: 100px;
    background-color: #fff;
    border: 1px solid #FF0000;
    border-top-left-radius: 100px !important;
    border-top-right-radius: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contact {
    background: rgba(34, 31, 32, 0.06);
    border-radius: 100px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2px;
    color: rgba(34, 31, 32, 0.9);
    position: relative;

  }
}

</style>
