import http from '../http/common'

const resource = '/users'

export default {

	get: (payload) => http.get(`${resource}/get-all-details${payload}`),

	globalSearch: (payload) => http.post(`/global/search/all`, payload),


}
