<template>
  <v-col cols="12" xl="6" sm="12" md="6" lg="6" class="no-border">
    <label class="login-label">Add Payment</label>
    <v-form class="mt-5" ref="form">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" xl="3" lg="3" md="3" class="e-col">
          <label>
            <span class="label-txt">
              Amount
            </span>
          </label>
          <v-text-field autocomplete="off" v-model="form.amount" :rules="rules.amount" solo outlined flat hide-details="auto" class="solo-cust" placeholder="£1999"></v-text-field>
        </v-col>
        <v-col cols="12" xl="4" lg="3" md="3" class="e-col">
          <label>
            <span class="label-txt">
              Method
            </span>
          </label>
          <v-select autocomplete="off" v-model="form.method" :rules="rules.method" :items="paymentMethods" item-value="id" item-text="name" solo outlined flat class="solo-cust" hide-details="auto">
            <template slot="append">
              <v-icon>mdi-chevron-down</v-icon>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" class="e-col">
          <label>
            <span class="label-txt">
              Date
            </span>
          </label>
          <v-menu v-model="paymentDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field autocomplete="off" :value="dateUKformat(form.date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
            </template>
            <v-date-picker v-model="form.date" no-title @input="paymentDateMenu = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" xl="2" lg="3" md="3" class="e-col mt-6 d-flex">
          <v-btn class="b-comment" width="90px" depressed rounded x-large :loading="loading" @click="create">
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-col>
</template>
<script>
import globalFn from "../../../mixins/globalFn.js";
import { mapActions } from "vuex";
export default {
  name: "Payments",
  mixins: [globalFn],
  props: {
    invoiceId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      paymentDateMenu: false,
      headers: [{
          text: 'Amount',
          value: 'amount',
          sortable: false,
        },
        { text: 'Method', value: 'payment_method', sortable: false, },
        { text: 'Date', value: 'date', sortable: false, },
        { text: '', value: 'status', sortable: false, },
      ],
      showSkeleton: false,
      loading: false,
      sortBy: 'id',
      sortDesc: false,
      items: [],
      pageSize: 10,
      page: 1,
      form: {
        amount: '',
        method: '',
        date: ''
      },
      rules: {
        amount: [v => !!v || 'Please enter the amount'],
        method: [v => !!v || 'Please select the method of payment']
      }
    }
  },
  methods: {
    async create() {
      if (this.$refs.form.validate()) {
        this.loading = true
        let formData = {}
        formData.invoice_id = this.invoiceId
        formData.amount = this.form.amount
        formData.method = this.form.method
        formData.date = this.form.date
        await this.$store.dispatch('invoicePayments/CREATE_INVOICE_PAYMENT', formData).then(res => {
          if (res.result === 'success') {
            this.loading = false
            this.$root.$emit('UpdatePaymentHistory')
            this.$refs.form.reset()
          } else if (res.result === 'fail') {
            this.loading = false
            res.type = 'error'
            this.$store.dispatch('snackbar/SHOW', res)
          }
        }).catch(err => {
          this.loading = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        })
      }
    },
  }
};

</script>
