<template>
	<v-app>
		<router-view></router-view>
		<Notification></Notification>
		<RightSlider></RightSlider>
	</v-app>
</template>
<script>
import Notification from './Notification'
import RightSlider from './RightSlider'
export default {
	name: 'App',
	components: {
		Notification,
		RightSlider
	}
};

</script>
<style>
</style>
