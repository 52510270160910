import collection from '../../api/collections/Customers'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    count: {},
    list: [],
    activity: [],
    phone: [],
    appointments: []
  },
  mutations: {
    SET_CUSTOMERS(state, data) {
      state.list = data
    },
    SET_WIDGET_COUNT(state, data) {
      state.count = data
    },
    SET_LEAD_ACTIVITY_REPORT(state, data) {
      state.activity = data
    },

    SET_PHONE_ACTIVITY_REPORT(state, data) {
      state.phone = data
    },

    SET_PABAU_APPOINTMENTS(state, data) {
      state.appointments = data
    }
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_CUSTOMERS', data.data)
        return data
      }, commit, dispatch, 'GET', true)
    },
    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.widgetCount(payload)
        commit('SET_WIDGET_COUNT', data.data)
        return data
      }, commit, dispatch, 'WIDGET_COUNT', true)
    },

    async LEAD_ACTIVITY_REPORT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.leadActivityReport(payload)
        commit('SET_LEAD_ACTIVITY_REPORT', data.data)
        return data
      }, commit, dispatch, 'LEAD_ACTIVITY_REPORT', true)
    },

    async PHONE_ACTIVITY_REPORT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.phoneActivityReport(payload)
        commit('SET_PHONE_ACTIVITY_REPORT', data.data)
        return data
      }, commit, dispatch, 'PHONE_ACTIVITY_REPORT', true)
    },

    async MARK_AS_CALL({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.markCommentAsCall(payload)
        return data
      }, commit, dispatch, 'MARK_AS_CALL', true)
    },


    async PIN_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.markCommentAsPinNote(payload)
        return data
      }, commit, dispatch, 'PIN_COMMENT', true)
    },

    async GET_PABAU_APPOINTMENTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.pabauAppointments(payload)
        commit('SET_PABAU_APPOINTMENTS', data.data)
        return data
      }, commit, dispatch, 'GET_PABAU_APPOINTMENTS', true)
    },

    async GET_CONTACTS_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.getContacts(payload)
        return data
      }, commit, dispatch, 'GET_CONTACTS_LIST', true)
    },

    

    async ALL_CONTACTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.allContacts(payload)
        return data
      }, commit, dispatch, 'ALL_CONTACTS', true)
    },
    async CONTACT_PABAU_APPOINTMENTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.getContactPabauAppointments(payload)
        return data
      }, commit, dispatch, 'CONTACT_PABAU_APPOINTMENTS', true)
    },
  }
}
