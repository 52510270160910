var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"user-name"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(item.name))+"\n\t\t\t\t\t\t\t")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")])]),_vm._v(" "),_c('li',[_c('a',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("Delete")])])])])])]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalDialog),callback:function ($$v) {_vm.modalDialog=$$v},expression:"modalDialog"}},[_c('v-card',{staticClass:"delete-alert"},[_c('v-card-title',[_vm._v("\n\t\t\t\tConfirm\n\t\t\t\t"),_c('a',{staticClass:"float-right pa-6",on:{"click":function($event){_vm.modalDialog = false}}},[_c('img',{attrs:{"src":"/images/close.svg","alt":"Close"}})])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n\t\t\t\t"+_vm._s(_vm.message)+"\n\t\t\t")]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"delete-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap",attrs:{"depressed":"","rounded":"","outlined":"","color":"dark","x-large":""},on:{"click":function($event){_vm.modalDialog = false}}},[_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]),_vm._v(" "),_c('v-btn',{staticClass:"delete-btn",attrs:{"color":(_vm.actionText === 'Delete') ? 'red' : 'secondary',"depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.handleFunction(_vm.action)}}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(_vm.actionText)+"\n\t\t\t\t\t")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }