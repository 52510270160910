<template>
	<div>
		<div class="tab-content-wrapper">
			<v-overlay :value="loading">
				<v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
			</v-overlay>
			<v-form ref="form" v-if="showDealForm">
				<v-row class="e-row">
					<v-col cols="12" md="12" class="e-col">
						<div>
							<label>
								<span class="label-txt">
									Deal Info
								</span>
							</label>
							<v-text-field autocomplete="off" solo outlined :rules="rules.deal_info" v-model="dealForm.deal_info" flat hide-details="auto" class="solo-cust" @keyup='remaincharCount()'></v-text-field>
						</div>
					</v-col>
				</v-row>
				<v-row class="e-row">
					<v-col cols="12" md="12" class="e-col">
						<div>
							<label>
								<span class="label-txt">
									Deal Notes
								</span>
							</label>
							<v-textarea autocomplete="off" solo outlined v-model="dealForm.notes" flat hide-details="auto" class="solo-cust"></v-textarea>
						</div>
					</v-col>
				</v-row>
				<v-row class="e-row" v-for="item in treatmentInputs" :key="item.id">
					<v-col cols="12" md="6" class="e-col">
						<label>
							<span class="label-txt">
								Treatment
							</span>
						</label>
						<v-autocomplete :rules="rules.treatment_id" v-model="item.treatment_id" :items="treatments" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
							<template slot="append">
								<v-icon>mdi-chevron-down</v-icon>
							</template>
							<template v-slot:selection="data">
								<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
									<span class="pr-2">
										{{ data.item.name }}
									</span>
								</v-chip>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" md="2" class="e-col">
						<div>
							<label>
								<span class="label-txt">
									Total (£)
								</span>
							</label>
							<v-text-field placeholder="£1999" :rules="rules.cost" autocomplete="off" solo outlined v-model="item.cost" flat hide-details="auto" class="solo-cust" @change="getDealTotal"></v-text-field>
						</div>
					</v-col>
					<v-col cols="12" md="3" class="e-col">
						<div>
							<label>
								<span class="label-txt">
									No of Sessions
								</span>
							</label>
							<v-text-field :rules="numberRule" autocomplete="off" solo outlined v-model="item.no_of_sessions" flat hide-details="auto" class="solo-cust"></v-text-field>
						</div>
					</v-col>
					<v-col cols="12" md="1" class="d-flex align-center justify-center">
						<v-btn icon color="green" @click="addTreatment" v-if="item.plus">
							<v-icon size="50">mdil-plus-circle</v-icon>
						</v-btn>
						<v-btn icon color="red" @click="deleteTreatment(item.id)" v-if="!item.plus">
							<v-icon size="50">mdil-minus-circle</v-icon>
						</v-btn>
					</v-col>
				</v-row>
				<v-row class="e-row">
					<v-col cols="12" md="6" class="e-col">
					</v-col>
					<v-col cols="12" md="2" class="e-col">
						<div class="text-center">
							<label>
								<span class="label-txt">
									{{dealTotal | currency('£')}}
								</span>
							</label>
						</div>
					</v-col>
					<v-col cols="12" md="3" class="e-col">
					</v-col>
					<v-col cols="12" md="1">
					</v-col>
				</v-row>
				<v-row dense class="e-row mt-5">
					<v-col cols="6" md="6" class="e-col">
						<div>
							<label>
								<span class="label-txt">
									Sold by:
								</span>
							</label>
							<v-autocomplete :readonly="user.role_type_name === 'Consultant' ? true : false" :rules="rules.soldby_id" v-model="dealForm.soldby_id" :items="staff" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
								<template slot="append">
									<v-icon>mdi-chevron-down</v-icon>
								</template>
							</v-autocomplete>
						</div>
					</v-col>
					<v-col cols="6" md="6" class="d-flex align-center justify-center">
						<v-btn class="b-act-upload mr-2" depressed rounded outlined x-large @click="showDealForm = false">
							Cancel
						</v-btn>
						<v-btn class="b-act-comment" depressed rounded x-large :loading="dealLoading" @click="createDeal">
							Add Deal
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
			<v-row class="e-row">
				<v-col cols="12" md="4" class="e-col">
					<label>
						<span class="tab-title">
							Deals ({{deals.length}})
						</span>
					</label>
				</v-col>
				<v-col cols="12" md="4" class="e-col">
				</v-col>
				<v-col cols="12" md="4" class="e-col">
					<v-btn class="b-comment" depressed rounded x-large v-if="!showDealForm" @click="showDealForm = true">
						Add New Deal
					</v-btn>
				</v-col>
			</v-row>
			<template v-for="deal in deals">
				<v-row class="e-row deal-block" :key="deal.id">
					<v-col cols="12" md="12" class="e-col">
						<div class="deal-wrap" :class="(deal.id === itemData.deal_id) ? 'belongs-to' :''">
							<v-row class="e-row">
								<v-col cols="12" md="12">
									<div class="d-flex justify-space-between mb-1">
										<p class="name">{{deal.deal_info}}</p>
										<div>
											<div class="d-flex justify-space-between mb-1">
												<p class="total" v-if="deal.latest_payment !== null">{{deal.latest_payment.amount | currency('£')}}</p>
												<p class="total" v-else>£0.00</p>
												<v-icon class="delete-deal" color="rgba(0, 0, 0, 0.2)" @click="deleteDeal(deal.id)">mdi-trash-can-outline</v-icon>
											</div>
											<p class="payment-date">
												Deal Created at : {{ deal.created_at | moment("DD/MM/YY") }}
											</p>
										</div>
									</div>
									<p>
										<span class="total mr-2">{{deal.total | currency('£')}}</span>
										<span>
											<template v-for="treat in deal.deal_treatments">
												<v-chip small class="este-chip status-chip" text-color="#221F20" color="rgba(34, 31, 32, 0.08)">
													{{treat.treatment_name}}
												</v-chip>
											</template>
										</span>
									</p>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<p class="sold-by">Sold by: {{ deal.sold_by.name }}</p>
								</v-col>
							</v-row>
							<v-row class="e-row" v-if="deal.notes">
								<v-col cols="12" md="12" class="e-col">
									<p class="sold-by font-weight-bold mb-2">Deal Notes: </p>
									<div class="sold-by line-height-20" v-if="!readMore">
										{{ deal.notes.slice(0, 100) }}
										<a class="read-text text-decoration-underline" v-if="!readMore && deal.notes.length > 100" @click="readMore = !readMore"> Read more</a>
									</div>
									<div class="sold-by line-height-20" v-if="readMore">
										{{ deal.notes }}
										<a class="read-text text-decoration-underline" v-if="readMore" @click="readMore = !readMore"> Read less</a>
									</div>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<tabs :data="deal" :dealId="deal.id" :userId="userId"></tabs>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-row>
			</template>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../store/action-types.js';
import globalFn from '../../mixins/globalFn.js';
import sliderFn from '../../mixins/sliderFn.js';
import status from '../../mixins/status.js';
import Tabs from '../DealMolecules/Tabs.vue';
export default {
	mixins: [globalFn, status, sliderFn],
	props: {
		userId: {
			required: true
		},
		keyId: {
			required: true
		},
		itemData: {
			required: true
		}
	},
	components: {
		Tabs
	},
	data: () => ({
		dealTotal: 0,
		numberRule: [
			v => !!v || "This field is required",
			v => (v && v <= 50) || "No of sessions has to be between 0 to 50",
			v => (v && v > 0) || "Please enter a positive number which is greate than 0",
		],
		appointmentDateMenu: [],
		appointmentFromTimeMenu: [],
		loading: false,
		dealLoading: false,
		readMore: false,
		showDealForm: false,
		user: JSON.parse(localStorage.getItem('user')),
		tab: null,
		dealForm: {
			user_id: 0,
			deal_info: '',
			notes: '',
			soldby_id: '',
			appointment_id: null
		},
		treatmentInputs: [{
			id: 'index0',
			treatment_id: null,
			no_of_sessions: null,
			cost: null,
			plus: true
		}],
		counter: 0,
		rules: {
			deal_info: [
				v => !!v || 'Please enter deal information'
			],
			cost: [
				v => !!v || 'Please enter cost value'
			],
			no_of_sessions: [
				v => !!v || 'Please select no of sessions'
			],
			treatment_id: [
				v => !!v || 'Please select treatment'
			],
			soldby_id: [
				v => !!v || 'Please select Sold by'
			],
			sessions: [
				v => !!v || 'Please select sessions'
			],
		},
		staffParams: {
			branch: '',
			type: ''
		},
		deals: [],
		branchId: null,
		maxcharacter: 150,
		remaincharactersText: "Remaining 150 characters."
	}),
	computed: {
		treatments() {
			if (this.$store.state.treatments.allList) {
				return this.$store.state.treatments.allList
			}
		},
		staff() {
			if (this.$store.state.staff.all) {
				return this.$store.state.staff.all
			}
		},
	},
	mounted() {
		this.$root.$on('RefreshDeals', this.loadDeals)
	},
	created() {
		this.loadDeals()
		this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
		this.$store.dispatch('staff/GET_STAFF_LIST', { data: this.staffParams, params: '' })
	},
	methods: {
		remaincharCount() {

			if (this.dealForm.deal_info.length > this.maxcharacter) {
				this.rules.deal_info = [
					v => `Exceeded  ${ this.maxcharacter} characters limit.`
				];
			} else {
				const remainCharacters = this.maxcharacter - this.dealForm.deal_info.length;
				this.rules.deal_info = [];
			}

		},
		getDealTotal() {
			this.dealTotal = this.treatmentInputs.reduce(function(accumulator, item) {
				let val = (item.cost !== null) ? parseInt(item.cost) : 0
				return accumulator + val;
			}, 0)
		},
		addTreatment() {
			this.treatmentInputs.push({
				id: `index${++this.counter}`,
				treatment_id: null,
				cost: null,
				no_of_sessions: null,
				plus: false
			});
		},
		deleteTreatment(id) {
			if (Object.keys(this.treatmentInputs).length > 1) {
				const index = this.treatmentInputs.findIndex(x => x.id === id)
				if (index >= 0) {
					this.treatmentInputs.splice(index, 1)
				}
			}
			this.getDealTotal()
		},
		async loadDeals() {
			this.loading = true
			let dealParams = {
				client_id: this.userId,
				attributes: "client,soldBy,dealTreatments,payments"
			}
			await this.$store.dispatch('deals/GET_DEAL_LIST', { data: dealParams, params: '' }).then(res => {
				this.deals = res.data
			})
			this.dealForm.soldby_id = this.user.id
			this.loading = false
		},
		async updateDealUserId(data) {
			this.dealForm.appointment_id = data.id
			this.dealForm.user_id = data.user_id
			this.branchId = data.user.branch_id
			this.dealForm.soldby_id = this.user.id
			this.loadDeals(data.user_id)
		},
		close() {
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		loadData() {
			this.$root.$emit("appointmentsTableUpdate", "");
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		async validate() {
			if (this.$refs.form.validate()) {
				this.createDeal(deal)
			}
		},
		async createDeal() {
			this.dealForm.user_id = this.userId
			this.dealForm.appointment_id = (this.$router.currentRoute.meta.name === 'Appointments' || this.$router.currentRoute.meta.name === 'Treatments') ? this.keyId : null
			this.dealForm.treatments = this.treatmentInputs
			if (this.$refs.form.validate()) {
				this.dealLoading = true
				await this.$store.dispatch('appointments/CREATE_DEAL', this.dealForm).then(response => {
					if (response.result === 'success') {
						this.dealLoading = false
						this.loadDeals()
						this.showDealForm = false
						this.$refs.form.reset()
					} else if (response.result === 'fail') {
						response.type = 'error'
						this.dealLoading = false
						this.$store.dispatch('snackbar/SHOW', response)
					}
				}).catch(err => {
					this.dealLoading = false
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
			}
		},
		async deleteDeal(id) {
			await this.$store.dispatch('deals/DELETE_DEAL', id).then(response => {
				if (response.result === 'success') {
					this.loading = false
					this.loadDeals()
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.loading = false
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				this.loading = false
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		}
	}
};

</script>
