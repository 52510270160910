<template>
	<div class="d-flex flex-row">
		<v-btn class="b-btn b-small mr-4" color="secondary" depressed @click="editContact">
			Edit Contact
		</v-btn>
		<v-btn class="b-btn b-small mr-4" color="secondary" depressed @click="addOpp">
			Add Opportunity
		</v-btn>
	</div>
</template>
<script>
import sliderFn from '../../mixins/sliderFn'
export default {

	name: 'ViewContact',
	mixins: [sliderFn],
	data() {
		return {

		}
	},
	methods: {
		async editContact() {
			const id = this.$router.currentRoute.params.id
			await this.$store.dispatch('contacts/CONTACT_BY_ID', { user_id: id }).then(res => {
				this.openDrawer("add", "1100", "customers/edit");
			})
		},

		async addOpp() {
			const id = this.$router.currentRoute.params.id
			await this.$store.dispatch('contacts/CONTACT_BY_ID', { user_id: id }).then(res => {
				this.openDrawer("add", "1100", "customers/addOpp");
			})
		}
	}
}

</script>
<style lang="css" scoped>
</style>
