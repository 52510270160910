var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0 pt-3"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-14 line-h-17 font-weight--semibold text-black-100",domProps:{"innerHTML":_vm._s(item.details)}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-14 line-h-17 font-weight--semibold text-black-100"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',[(item.campaign)?_c('span',{staticClass:"badge--chip font-weight--semibold white--text  font-11 line-h-13",style:(("background: " + (item.campaign.color)))},[_vm._v(" "+_vm._s(_vm._f("capitalize")(item.campaign.chip)))]):(item.code)?_c('span',{staticClass:"badge--chip font-weight--semibold white--text font-11 line-h-13 bg-black-100"},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(item.code)+"\n\t\t\t\t\t\t\t\t")]):_vm._e()])]}},{key:"item.for_branch",fn:function(ref){
var item = ref.item;
return [(item.offer_branch && item.offer_branch.length > 0)?[_vm._l((item.offer_branch.slice(0, 1)),function(val,index){return [(val.branch)?_c('span',{key:index,staticClass:"badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(val.branch.branch)+"\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e()]}),_vm._v(" "),(item.offer_branch.length > 1)?_c('span',{staticClass:"badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"},[_vm._v("+"+_vm._s(item.offer_branch.length - 1))]):_vm._e()]:_vm._e()]}},{key:"item.starts_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-13 line-h-16 fw-semibold text-black-300 mb-1"},[_vm._v(_vm._s(_vm._f("moment")(item.starts_at,"DD/MM/YY")))])])]}},{key:"item.expired_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-13 line-h-16 fw-semibold text-black-300 mb-1"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("moment")(item.expired_at,"DD/MM/YY"))+"\n\t\t\t\t\t\t\t\t\t")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.statusTags),function(val,index){return [(item.status === val.id)?_c('span',{key:index,staticClass:"badge--chip font-weight--semibold text-black-300 font-11 line-h-13",style:(val.outline
                  ? ("border: 1px solid " + (val.color))
                  : ("background: " + (val.color)))},[_vm._v(_vm._s(val.name))]):_vm._e()]})]}},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"bg-gray-100 btn-action",attrs:{"elevation":0},on:{"click":function($event){return _vm.editItem(item)}}},[_c('img',{attrs:{"height":"16","src":"/images/icons/duotune/general/gen055.svg"}})])],1)]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalDialog),callback:function ($$v) {_vm.modalDialog=$$v},expression:"modalDialog"}},[_c('v-card',{staticClass:"delete-alert"},[_c('v-card-title',[_vm._v("\n\t\t\t\t\tConfirm\n\t\t\t\t\t"),_c('a',{staticClass:"float-right pa-6",on:{"click":function($event){_vm.modalDialog = false}}},[_c('img',{attrs:{"src":"/images/close.svg","alt":"Close"}})])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.message)+"\n\t\t\t\t")]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"delete-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap",attrs:{"depressed":"","rounded":"","outlined":"","color":"dark","x-large":""},on:{"click":function($event){_vm.modalDialog = false}}},[_vm._v("\n\t\t\t\t\t\t\tCancel\n\t\t\t\t\t\t")]),_vm._v(" "),_c('v-btn',{staticClass:"delete-btn",attrs:{"color":(_vm.actionText === 'Delete') ? 'red' : 'secondary',"depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.handleFunction(_vm.action)}}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.actionText)+"\n\t\t\t\t\t\t")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }