<template>
	<div>
		<div class="fill-height slider-cont">
			<div class="modal-inside pb-0 tab-brd">
				<div class="form-title mb-24">Create Deal</div>
				<v-tabs v-model="tab" class="cust-tabs">
					<v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
					<v-tab href="#deal">Deal</v-tab>
				</v-tabs>
			</div>
			<div class="modal-inside pt-0 right-pane">
				<v-tabs-items v-model="tab">
					<div class="client-info pt-4">
						<div class="name" v-if="appointment.user.name !== null">
							{{ appointment.user.name }}
						</div>
						<div class="d-flex name" v-if="appointment.user.name === null && appointment.user.social_handle !== null">
							<img :src="`/images/${appointment.user.source_name.toLowerCase()}.svg`" class="mr-2" v-if="appointment.user.source_name !== null">
							<span>
								@{{ appointment.user.social_handle }}
							</span>
						</div>
						<p class="mt-4">
							<span class="contact" v-if="appointment.user.contact_no !== null">
								<a :href="`tel:${appointment.user.contact_no}`">
									<img src="/images/phone.svg" height="15"> {{ appointment.user.contact_no }}</a>
							</span>
							<span class="contact" v-if="appointment.user.email !== null">
								<a :href="`tel:${appointment.user.email}`">
									<img src="/images/email.svg" height="15"> {{ appointment.user.email }}</a>
							</span>
						</p>
					</div>
					<v-tab-item value="deal" eager>
						<tab-deal :userId="appointment.user_id" :keyId="appointment.id" :itemData="appointment"></tab-deal>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="loadData">
					Close
				</v-btn>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn.js';
import sliderFn from '../../../mixins/sliderFn.js';
import TabDeal from '../../../components/TabItems/TabDeal';
export default {
	mixins: [globalFn, status, sliderFn],
	components: {
		TabDeal
	},
	data: () => ({
		user: JSON.parse(localStorage.getItem('user')),
		tab: null
	}),
	computed: {
		appointment() {
			if (this.$store.state.appointments.item) {
				return this.$store.state.appointments.item
			}
		},
	},
	methods: {
		loadData() {
			this.$root.$emit("appointmentsTableUpdate", "");
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
	}
};

</script>
