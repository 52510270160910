<template>
	<v-card flat>
		<v-toolbar flat height="90px" class="tab__toolbar" :class="responsiveClass">
			<v-toolbar-title>
				<div class="form-title">Add Contact</div>
			</v-toolbar-title>
		</v-toolbar>
		<v-tabs v-model="navigationTab" :vertical="vertical" class="este__tabs" :class="responsiveClass">
			<v-tabs-slider class="cust-tabs-slider" v-if="!vertical"></v-tabs-slider>
			<template v-for="(item, index) in navigation">
				<v-tab :href="`#${item.slug}`">
					<v-icon left>
						{{item.icon}}
					</v-icon>
					{{item.name}}
				</v-tab>
			</template>
		</v-tabs>
		<div class="tab__content__wrapper">
			<v-tabs-items v-model="navigationTab">
				<v-tab-item value="personal" :eager="true">
					<div class="content__text">
						<v-form ref="personalForm">
							<v-row dense class="e-row">
								<v-col cols="6" md="2" class="e-col">
									<div>
										<label>
											<span class="label-txt">Title</span>
											<v-select autocomplete="off" :items="titles" v-model="form.title" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</label>
									</div>
								</v-col>
								<v-col cols="6" md="10" class="e-col">
									<div>
										<label>
											<span class="label-txt">
												Name
											</span>
											<v-text-field autocomplete="off" solo outlined v-model="form.name" flat hide-details="auto" class="solo-cust"></v-text-field>
										</label>
									</div>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Social Handle
										</span>
									</label>
									<v-text-field autocomplete="off" solo outlined v-model="form.social_handle" flat hide-details="auto" class="solo-cust social-handle-input" :class="socialError ? 'v-input--has-state error--text' : '' ">
										<template slot="append-outer">
											<v-select autocomplete="off" v-model="form.source_id" :items="socialMedia" item-text="name" item-value="id" solo outlined flat class="este-autocomplete-input" hide-details="auto" :class="socialError ? 'v-input--has-state error--text' : '' ">
												<template v-slot:selection="data">
													<img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
												</template>
												<template v-slot:item="data">
													<img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
												</template>
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</template>
									</v-text-field>
									<span class="quick-validation" v-if="socialError">
										<a @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
											<span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
												<span v-if="existUser.name !== null && existUser.social_handle === null">{{ existUser.name }} </span>
												<span v-if="existUser.name === null && existUser.social_handle !== null">{{ existUser.social_handle }} </span>
												<span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
												<span v-if="existUser.email">/ {{ existUser.email }} </span>
											</span>
											<span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
												{{ existUser.user_type_name === "Lead" ? "L" : "C" }}
											</span>
										</a>
									</span>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Phone No.
										</span>
									</label>
									<v-text-field autocomplete="off" solo outlined v-model="form.contact_no" flat hide-details="auto" class="solo-cust" append-icon="mdil-phone" :class="contactError ? 'v-input--has-state error--text' : '' "></v-text-field>
									<span class="quick-validation" v-if="contactError">
										<a @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
											<span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
												<span v-if="existUser.name !== null && existUser.social_handle === null">{{ existUser.name }} </span>
												<span v-if="existUser.name === null && existUser.social_handle !== null">{{ existUser.social_handle }} </span>
												<span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
												<span v-if="existUser.email">/ {{ existUser.email }} </span>
											</span>
											<span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
												{{ existUser.user_type_name === "Lead" ? "L" : "C" }}
											</span>
										</a>
									</span>
								</v-col>
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Email Address
										</span>
									</label>
									<v-text-field autocomplete="off" v-model="form.email" solo outlined flat hide-details="auto" class="solo-cust" append-icon="mdil-email" :class="emailError ? 'v-input--has-state error--text' : '' "></v-text-field>
									<span class="quick-validation" v-if="emailError">
										<a @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
											<span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
												<span v-if="existUser.name !== null && existUser.social_handle === null">{{ existUser.name }} </span>
												<span v-if="existUser.name === null && existUser.social_handle !== null">{{ existUser.social_handle }} </span>
												<span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
												<span v-if="existUser.email">/ {{ existUser.email }} </span>
											</span>
											<span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
												{{ existUser.user_type_name === "Lead" ? "L" : "C" }}
											</span>
										</a>
									</span>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Address
										</span>
										<v-text-field autocomplete="off" solo outlined flat v-model="form.address_one" hide-details="auto" class="solo-cust mb-2"></v-text-field>
										<v-text-field autocomplete="off" solo outlined flat v-model="form.address_two" hide-details="auto" class="solo-cust mar-8"></v-text-field>
									</label>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Town / City
										</span>
									</label>
									<v-text-field autocomplete="off" solo outlined flat v-model="form.city" hide-details="auto" class="solo-cust"></v-text-field>
								</v-col>
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Post Code
										</span>
									</label>
									<v-text-field autocomplete="off" solo outlined flat v-model="form.postcode" hide-details="auto" class="solo-cust"></v-text-field>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Preferred Branch:
										</span>
										<v-select autocomplete="off" :items="branches" :rules="rules.branch_id" item-value="id" item-text="branch" v-model="form.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="getStaffList">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</label>
								</v-col>
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Responsible
										</span>
										<v-select autocomplete="off" :items="staff" v-model="form.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto" @change="form.opp_responsible_id = form.responsible_id">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</label>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Status
										</span>
										<v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('customer')}))" item-value="id" item-text="name" :rules="rules.status" v-model="form.status" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</label>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Contact Type
										</span>
									</label>
									<v-radio-group class="mt-2" hide-details="auto" mandatory v-model="contact_type" row @change="contact_type === 4 ? (isContactTypeClient = true) : (isContactTypeClient = false)">
										<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Lead" :value="3"></v-radio>
										<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Client" :value="4"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<template v-if="isContactTypeClient">
								<v-row class="e-row">
									<v-col cols="12" md="12" class="">
										<label>
											<span class="label-txt">
												Client Satisfaction:
											</span>
										</label>
									</v-col>
									<v-col cols="12" md="12" class="e-col">
										<template v-for="(item,index) in satisfaction">
											<div :class="['emoji-wrap', `${lowerCase(item.name)}-emoji` , { 'active': activeIndex === index  }]" @click="setActive(item, index)" :key="item.name">
												<div class="icon">
													<img :src="`${item.image}`">
												</div>
												<div class="text">{{item.name}}</div>
											</div>
										</template>
									</v-col>
								</v-row>
							</template>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<p class="sub-title mb-4">
										Contact Preferences
									</p>
									<label>
										<span class="label-txt">
											Receive SMS
										</span>
									</label>
									<v-radio-group class="mt-2" hide-details="auto" mandatory v-model="form.is_receive_sms" row>
										<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
										<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</v-tab-item>
				<v-tab-item value="lead" :eager="true">
					<div class="content__text">
						<v-form ref="form">
							<v-row class="e-row" v-if="!isContactTypeClient">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Reason for Treatment:
										</span>
									</label>
									<v-combobox v-model="form.reasons" :items="reasons" item-text="name" item-value="id" multiple :search-input.sync="reasonSearch" solo outlined flat chips hide-details="auto" class="este-autocomplete-input">
										<template v-slot:no-data>
											<v-list-item>
												<v-list-item-content>
													<v-list-item-title>
														No results matching "<strong>{{ reasonSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
													</v-list-item-title>
												</v-list-item-content>
											</v-list-item>
										</template>
										<template v-slot:selection="data">
											<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
												<span class="pr-2" v-if="data.item.name">
													{{ data.item.name }}
												</span>
												<span class="pr-2" v-else>
													{{ data.item }}
												</span>
												<v-icon color="primary" small @click.stop="removeReason(data.item)">
													mdi-close
												</v-icon>
											</v-chip>
										</template>
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-combobox>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Symptoms / Condition:
										</span>
									</label>
									<v-autocomplete v-model="form.symptoms" :items="symptoms" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="symptomChange">
										<template v-slot:selection="data">
											<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
												<span class="pr-2">
													{{ data.item.name }}
												</span>
												<v-icon color="primary" small @click.stop="removeSymptoms(data.item)">
													mdi-close
												</v-icon>
											</v-chip>
										</template>
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Suggested Treatments:
										</span>
									</label>
								</v-col>
								<v-col cols="12" md="12" class="e-col">
									<template v-for="treat in suggestedTreatments">
										<v-chip small class="este-chip status-chip mb-2" text-color="black" @click="suggTreat(treat)">
											{{ treat.name | capitalize }}
										</v-chip>
									</template>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<label>
										<span class="label-txt">
											Service Recommended:
										</span>
									</label>
									<v-autocomplete v-model="form.service_recommended" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
										<template v-slot:selection="data">
											<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
												<span class="pr-2">
													{{ data.item.name }}
												</span>
												<v-icon color="primary" small @click.stop="removeService(data.item)">
													mdi-close
												</v-icon>
											</v-chip>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="4" class="e-col" v-if="!isContactTypeClient">
									<label>
										<span class="label-txt">
											Enquiry Date
										</span>
										<v-menu v-model="enquiryMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field autocomplete="off" prepend-inner-icon="mdil-calendar" clearable clear-icon="mdi-close-circle-outline" :value="dateUkFormat(form.enquiry_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
											</template>
											<v-date-picker v-model="form.enquiry_date" no-title @input="enquiryMenu = false"></v-date-picker>
										</v-menu>
									</label>
								</v-col>
								<v-col cols="12" md="4" class="e-col">
									<label>
										<span class="label-txt">
											Follow Up Date
										</span>
										<v-menu v-model="followUpMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field autocomplete="off" prepend-inner-icon="mdil-calendar" clearable clear-icon="mdi-close-circle-outline" :value="dateUkFormat(form.follow_up_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
											</template>
											<v-date-picker v-model="form.follow_up_date" no-title @input="followUpMenu = false"></v-date-picker>
										</v-menu>
									</label>
								</v-col>
								<v-col cols="12" md="4" class="e-col">
									<label>
										<span class="label-txt">Follow Up Time:</span>
										<v-menu ref="followUpTimeMenu" v-model="followUpTimeMenu" :close-on-content-click="false" :return-value.sync="form.follow_up_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field outlined flat hide-details="auto" solo class="solo-cust" v-model="form.follow_up_time" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
											</template>
											<v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="followUpTimeMenu" v-model="form.follow_up_time" full-width @click:minute="$refs.followUpTimeMenu.save(form.follow_up_time)"></v-time-picker>
										</v-menu>
									</label>
								</v-col>
							</v-row>
							<v-row class="e-row">
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Campaigns:
										</span>
									</label>
									<v-autocomplete v-model="form.campaign_ids" :items="campaigns" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
										<template v-slot:selection="data">
											<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
												<span class="pr-2">
													{{ data.item.name }}
												</span>
												<v-icon color="primary" small @click.stop="removeCampaign(data.item)">
													mdi-close
												</v-icon>
											</v-chip>
										</template>
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Source:
										</span>
									</label>
									<v-select autocomplete="off" :items="source" v-model="form.source_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Responsible
										</span>
										<v-select autocomplete="off" :items="staff" v-model="form.opp_responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</label>
								</v-col>
								<v-col cols="12" md="6" class="e-col">
									<label>
										<span class="label-txt">
											Preferred Branch:
										</span>
									</label>
									<v-select autocomplete="off" :items="branches" :rules="rules.opp_branch_id" item-value="id" item-text="branch" v-model="form.opp_branch_id" solo outlined flat class="solo-cust" hide-details="auto">
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-select>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</v-tab-item>
			</v-tabs-items>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
					Cancel
				</v-btn>
				<v-btn color="secondary" class="b-blue" depressed :disabled="disabled" rounded x-large @click="addContact">
					Add
				</v-btn>
			</div>
		</div>
	</v-card>
</template>
<script>
import globalFn from '../../../mixins/globalFn';
import status from '../../../mixins/status.js';
export default {
	mixins: [globalFn, status],
	data: () => ({
		navigationTab: null,
		navigation: [{
			name: 'Contact Details',
			icon: 'mdil-account',
			slug: 'personal',
			module: 'personal',
			form: true
		}, {
			name: 'Opportunity Details',
			icon: 'mdil-star',
			slug: 'lead',
			module: 'lead',
			form: true
		}],
		activeIndex: null,
		markAsChase: false,
		isHovering: false,
		markAsChaseAndCallback: false,
		contact_type: null,
		disabled: false,
		isContactTypeClient: false,
		IsDuplicate: false,
		ExistUserId: 0,
		existUser: null,
		callbackDisabled: false,
		comment: '',
		enquiryMenu: false,
		followUpTimeMenu: false,
		followUpMenu: false,
		user: JSON.parse(localStorage.getItem('user')),
		titles: ["Mr", "Mrs", "Dr", "Miss"],
		reasonSearch: null,
		sourceSearch: null,
		form: {
			title: '',
			name: '',
			address_one: '',
			address_two: '',
			city: '',
			postcode: '',
			contact_no: '',
			branch_id: '',
			email: '',
			reasons: [],
			symptoms: [],
			service_recommended: [],
			status: 1,
			enquiry_date: new Date().toISOString().slice(0, 10),
			follow_up_date: new Date().toISOString().slice(0, 10),
			follow_up_time: '',
			responsible_id: '',
			opp_responsible_id: '',
			opp_branch_id: '',
			source_id: '',
			social_handle: '',
			is_receive_sms: 0,
			client_satisfaction: '',
			campaign_ids: []
		},
		suggestedTreatments: [],
		rules: {
			name: [
				v => !!v || 'Please enter name'
			],
			email: [
				v => !!v || "Please enter email address",
				v => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com"
			],
			branch_id: [
				v => !!v || 'Please select branch'
			],
			status: [],
			comment: [
				v => !!v || 'Please enter comment'
			],
			source: [
				v => !!v || 'Please select source'
			],
		},
		staff: [],
		emailError: '',
		contactError: '',
		socialError: '',
		emailErrorMessage: '',
		contactErrorMessage: '',
		socialErrorMessage: '',
	}),
	watch: {
		'form.status'(val) {
			this.rules.status = []
			this.loading = false;
		},
		'form.email': _.debounce(function() {
			this.isTyping = false;
			if (this.form.email) {
				this.checkForDuplicate();
			} else {
				this.emailError = ''
			}
		}, 3000),
		'form.contact_no': _.debounce(function() {
			this.isTyping = false;
			if (this.form.contact_no) {
				this.checkForDuplicate();
			} else {
				this.contactError = ''
			}
		}, 3000),
		'form.social_handle': _.debounce(function() {
			this.isTyping = false;
			if (this.form.social_handle) {
				this.checkForDuplicate();
			} else {
				this.socialError = ''
			}
		}, 3000),
		'form.source_id': _.debounce(function() {
			this.isTyping = false;
			if (this.form.social_handle) {
				this.checkForDuplicate();
			} else {
				this.socialError = ''
			}
		}, 3000)
	},
	computed: {
		responsiveClass() {
			return (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') ? 'lg' : 'md'
		},
		vertical() {
			return (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') ? true : false
		},
		treatments() {
			if (this.$store.state.treatments.allList) {
				return this.$store.state.treatments.allList
			}
		},
		branches() {
			if (this.$store.state.branches.allList) {
				return this.$store.state.branches.allList
			}
		},
		reasons() {
			if (this.$store.state.reasons.allList) {
				return this.$store.state.reasons.allList
			}
		},
		symptoms() {
			if (this.$store.state.symptoms.allList) {
				return this.$store.state.symptoms.allList
			}
		},
		source() {
			if (this.$store.state.source.list) {
				return this.$store.state.source.list
			}
		},
		socialMedia() {
			if (this.$store.state.source.list) {
				return this.$store.state.source.list.filter(item => ['Facebook', 'Instagram', 'TikTok', 'Snapchat'].includes(item.name));
			}
		},
		campaigns() {
			if (this.$store.state.campaign.all) {
				return this.$store.state.campaign.all
			}
		},
	},
	created() {
		this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
		this.$store.dispatch('branches/GET_ALL_BRANCH', '')
		this.$store.dispatch('symptoms/GET_ALL_SYMPTOM', '')
		this.$store.dispatch('reasons/GET_ALL_REASON', '')
		this.$store.dispatch('source/GET_SOURCE', '')
		this.$store.dispatch('campaign/LIST', '')
	},
	mounted() {
		this.staffParams.branch = this.user.branch_id
		this.getStaffList()
		this.$root.$on('numberFillIn', this.numberFillIn)
		this.$root.$on('emailFillIn', this.emailFillIn)
	},
	methods: {
		emailFillIn(data) {
			if (data) {
				this.form.email = data
			}
		},
		numberFillIn(data) {
			if (data) {
				this.form.contact_no = data
			}
		},
		close() {
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		checkForDuplicate() {
			if (!this.form.social_handle && !this.form.email && !this.form.contact_no) {
				this.form.emailError = ''
				this.form.contactError = ''
				this.form.socialError = ''
				this.markAsChase = false
				this.markAsChaseAndCallback = false
				this.IsDuplicate = false
			} else {
				this.CheckEmailDuplicate()
				this.CheckContactDuplicate()
				this.CheckSocialDuplicate()
			}
		},
		async CheckEmailDuplicate() {
			if (this.form.email) {
				let formData = {}
				formData.email = this.form.email
				await this.validate(formData, 'email')
				this.emailError = this.IsDuplicate ? this.emailErrorMessage : ''
			}
		},
		async CheckContactDuplicate() {
			if (this.form.contact_no) {
				let formData = {}
				formData.contact_no = this.form.contact_no
				await this.validate(formData, 'contact')
				this.contactError = this.IsDuplicate ? this.contactErrorMessage : ''
			}
		},
		async CheckSocialDuplicate() {
			if (this.form.social_handle) {
				let formData = {}
				let isValid = this.isValidUrl(this.form.social_handle)
				if (isValid) {
					let url = new URL(this.form.social_handle);
					this.form.source_id = this.getSourceId(url.origin)
				}
				formData.source_id = this.form.source_id
				formData.social_handle = this.form.social_handle
				await this.validate(formData, 'social')
				this.socialError = this.IsDuplicate ? this.socialErrorMessage : ''
			}
		},
		async validate(formData, type) {
			this.IsDuplicate = false
			this.markAsChase = false
			this.markAsChaseAndCallback = false
			this.emailErrorMessage = ''
			this.contactErrorMessage = ''
			this.socialErrorMessage = ''
			this.ExistUserId = 0
			this.existUser = 0
			await this.$store.dispatch('leads/VALIDATE_LEAD', formData).then(response => {
				if (response.result === 'success' && response.data.status) {
					this.disabled = false
					this.IsDuplicate = response.data.status
					this.markAsChaseAndCallback = response.data.user_type_name === 'Client' ? true : false
					this.markAsChase = response.data.user_type_name === 'Lead' ? true : false
					this.ExistUserId = response.data.user_id
					this.existUser = response.data
					if (type === 'email') {
						this.emailErrorMessage = response.message
					}
					if (type === 'contact') {
						this.contactErrorMessage = response.message
					}
					if (type === 'social') {
						this.socialErrorMessage = response.message
					}
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.disabled = false
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				this.disabled = false
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
		async autoSelectSocialIcon() {
			let isValid = this.isValidUrl(this.form.social_handle)
			if (isValid) {
				let url = new URL(this.form.social_handle);
				this.form.source_id = this.getSourceId(url.origin)
			}
		},
		isValidUrl(_string) {
			const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
			return matchPattern.test(_string);
		},
		getSourceId(url) {
			let id = 0
			let self = this
			this.socialMedia.some(function(el) {
				if (url.indexOf(el.name.toLowerCase()) != -1) {
					id = el.id
				}
			});
			return id
		},
		loadData() {
			this.$root.$emit("customerTableUpdate", "");
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		async removeService(item) {
			const index = this.form.service_recommended.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.form.service_recommended.splice(index, 1)
			} else {
				const i = this.form.service_recommended.findIndex(x => x === item.id)
				if (i >= 0) this.form.service_recommended.splice(i, 1)
			}
		},
		async removeSymptoms(item) {
			const index = this.form.symptoms.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.form.symptoms.splice(index, 1)
			} else {
				const i = this.form.symptoms.findIndex(x => x === item.id)
				if (i >= 0) this.form.symptoms.splice(i, 1)
			}
			await this.symptomChange()
		},
		async removeCampaign(item) {
			const index = this.form.campaign_ids.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.form.campaign_ids.splice(index, 1)
			} else {
				const i = this.form.campaign_ids.findIndex(x => x === item.id)
				if (i >= 0) this.form.campaign_ids.splice(i, 1)
			}
		},
		async removeReason(item) {
			if (item.id) {
				const index = this.form.reasons.findIndex(x => x.id === item.id)
				if (index >= 0) {
					this.form.reasons.splice(index, 1)
				} else {
					const i = this.form.reasons.findIndex(x => x === item.id)
					if (i >= 0) this.form.reasons.splice(i, 1)
				}
			} else {
				const i = this.form.reasons.findIndex(x => x === item)
				if (i >= 0) this.form.reasons.splice(i, 1)
			}
		},
		async symptomChange() {
			const ids = this.form.symptoms
			await this.$store.dispatch('symptoms/GET_SUGGESTED_TREATMENTS', { 'symptoms': ids }).then(response => {
				if (response.result === 'success') {
					this.suggestedTreatments = response.data
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
		async addContact() {
			if (this.form.contact_no === '' && this.form.email === '') {
				let response = []
				response.type = 'error'
				response.message = 'Please enter either email address/contact number'
				this.$store.dispatch('snackbar/SHOW', response)
				this.navigationTab = 'personal'
				this.$refs.personalForm.validate()
			} else {
				let formData = {}
				formData.title = this.form.title
				formData.user_type = this.contact_type
				formData.name = this.form.name
				formData.address_one = this.form.address_one
				formData.address_two = this.form.address_two
				formData.city = this.form.city
				formData.postcode = this.form.postcode
				formData.branch_id = this.form.branch_id
				formData.social_handle = this.form.social_handle
				formData.responsible_id = this.form.responsible_id
				if (this.form.email) {
					formData.email = this.form.email
				}
				if (this.form.contact_no) {
					formData.contact_no = this.form.contact_no
				}
				formData.status = this.form.status

				if (this.form.source_id) {
					formData.source_id = this.form.source_id
				}

				formData.responsible_id = this.form.opp_responsible_id
				formData.opp_branch_id = this.form.opp_branch_id



				formData.service_recommended = []
				this.form.service_recommended.forEach(value => {
					if (value.id) {
						formData.service_recommended.push(value.id);
					} else {
						formData.service_recommended.push(value);
					}
				});

				formData.symptoms = []
				this.form.symptoms.forEach(value => {
					if (value.id) {
						formData.symptoms.push(value.id);
					} else {
						formData.symptoms.push(value);
					}
				});


				formData.campaign_ids = []
				this.form.campaign_ids.forEach(value => {
					if (value.id) {
						formData.campaign_ids.push(value.id);
					} else {
						formData.campaign_ids.push(value);
					}
				});


				formData.reasons = []
				this.form.reasons.forEach(value => {
					if (value.id) {
						formData.reasons.push({ id: value.id });
					} else {
						formData.reasons.push(value);
					}
				});

				formData.enquiry_date = this.form.enquiry_date
				formData.follow_up_time = this.form.follow_up_time
				formData.follow_up_date = this.form.follow_up_date

				if (this.form.status === '') {
					this.rules.status = [v => !!v || 'Please select status'];
				}
				this.navigationTab = 'personal'
				if (this.$refs.personalForm.validate()) {
					this.navigationTab = 'lead'
					if (this.$refs.form.validate()) {
						this.disabled = true
						await this.$store.dispatch(`contacts/CREATE`, formData).then(response => {
							if (response.result === 'success') {
								this.disabled = false
								this.loadData()
							} else if (response.result === 'fail') {
								response.type = 'error'
								this.disabled = false
								this.$store.dispatch('snackbar/SHOW', response)
							}
						}).catch(err => {
							this.disabled = false
							err.type = 'error'
							this.$store.dispatch('snackbar/SHOW', err)
						});
					}
				}
			}

		},
		setActive(item, index) {
			this.activeIndex = index;
			this.form.client_satisfaction = item.name.toLowerCase()
		},
		async suggTreat(item) {
			let exists = this.form.service_recommended.some(el => el.id === item.id) ? true : this.form.service_recommended.some(el => el === item.id) ? true : false;
			if (!exists) {
				this.form.service_recommended.push(item.id)
			} else {
				const index = this.form.service_recommended.findIndex(x => x.id === item.id)
				if (index >= 0) {
					this.form.service_recommended.splice(index, 1)
				} else {
					const i = this.form.service_recommended.findIndex(x => x === item.id)
					if (i >= 0) this.form.service_recommended.splice(i, 1)
				}
			}
		},
		async getStaffList() {
			this.form.opp_branch_id = this.form.branch_id
			this.staff = []
			await this.$store.dispatch('staff/GET_STAFF_LIST', { data: this.staffParams, params: '' }).then(response => {
				if (response.result === 'success') {
					this.staff = response.data
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
		async addComment() {
			if (this.$refs.commentForm.validate()) {
				await this.$store.dispatch('leads/ADD_COMMENT', { lead_id: this.lead.id, comment: this.comment }).then(res => {
					if (res.result === 'success') {
						this.loading = false
						this.loadAudit()
						this.$refs.commentForm.reset()
					} else if (res.result === 'fail') {
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
			}
		},
		async markToChase(callback) {
			console.log(callback)
			let formData = {}
			formData.contact_no = this.form.contact_no
			formData.comment = this.form.comment
			if (this.form.email) {
				formData.email = this.form.email
			}
			formData.source_id = this.form.source_id
			formData.social_handle = this.form.social_handle
			formData.branch_id = this.form.branch_id
			formData.treatment_ids = this.form.treatment_ids
			formData.user_exists = this.IsDuplicate

			this.loading = true
			await this.$store.dispatch('leads/QUICK_LEAD', formData).then(response => {
				if (response.result === 'success') {
					this.loading = false
					if (callback) {
						this.createCallback()
					} else {
						this.$refs.form.reset()
						this.loadData()
					}
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.loading = false
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				this.loading = false
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},

		async createCallback() {
			this.callbackDisabled = true
			let formData = {}
			formData.user_id = this.ExistUserId
			formData.treatment_ids = this.form.treatment_ids

			await this.$store.dispatch('callback/CREATE_CALLBACK_USER', formData).then(response => {
				if (response.result === 'success') {
					this.callbackDisabled = false
					this.loadData()
					this.$refs.form.reset()
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.callbackDisabled = false
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				this.callbackDisabled = false
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		}
	}
}

</script>
