<template>
  <div class="body-wrapper">
    <v-container fluid class="pa-0">
      <v-row class="ma-0 postiton--relative">
        <v-col cols="12" md="8" class="pa-0 bg-surface-100">
          <div class="d-flex flex-column align-center justify-center surface--wrap">
            <v-img contain lazy-src="/images/login-logo.png" src="/images/login-logo.png" max-width="170"></v-img>
            <v-form ref="form" @submit.prevent="validate" v-model="valid" lazy-validation>
              <div class="mb-4">
                <v-text-field v-model="form.email" required :rules="rules.email" hide-details="auto" autofocus filled class="mt-1 b-input" placeholder="Email"></v-text-field>
              </div>
              <div class="mb-2">
                <v-text-field v-model="form.password" :rules="rules.password" hide-details="auto" required filled placeholder="Password" class="mt-1 b-input" :type="show ? 'text' : 'password'">
                  <template slot="append">
                    <a class="mr-2 mt-1 mb-0 grey-text" @click.stop="show = !show">Show</a>
                    <v-icon v-if="!show" @click.stop="show = !show">mdil-eye</v-icon>
                    <v-icon v-if="show" @click.stop="show = !show">mdil-eye-off</v-icon>
                  </template>
                </v-text-field>
              </div>
              <div class="d-flex align-center justify-end mb-4">
                <a class="forgot-psw" href="javascript:void(0)" @click="goto('/password/reset')" :loading="loading">Forgot Password?</a>
              </div>
              <div class="mb-4 message-block error-block" v-if="loginError">
                <img src="/images/alert.svg">
                <p>Please check your email & password are correct <br> or <a class="black--text" href="javascript:void(0)" @click="passwordReset">Reset Password</a></p>
              </div>
              <div class="btn-wrapper">
                <v-btn type="submit" block class="height-45 font-14 line-h-17 font-weight--bold b-login-btn b-btn b-primary" depressed :loading="loading">Sign In</v-btn>
              </div>
            </v-form>
          </div>
        </v-col>
        <v-col cols="12" md="4" class="pa-0 bg-author">
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER_LOGIN, USER, USER_VERIFY } from "../../../store/action-types";
import TheLogo from '../../../components/TheLogo.vue'
export default {
  name: "Login",
  components: {
    TheLogo
  },
  data: () => ({
    loading: false,
    valid: true,
    show: false,
    loginError: false,
    form: {
      email: "",
      password: ""
    },
    rules: {
      email: [],
      password: []
    },
    staffParams: '?attributes=permissions',
    logo: process.env.MIX_BRAND_LOGO
  }),
  watch: {
    'form.email'(val) {
      this.rules.email = []
      this.loading = false;
    },
    'form.password'(val) {
      this.rules.password = []
      this.loading = false;
    },
  },
  methods: {
    ...mapActions({
      USER_LOGIN,
      USER,
      USER_VERIFY
    }),
    goto(path) {
      this.$router.push(path);
    },
    validate() {
      this.rules.email = [
        v => !!v || "Please enter email address",
        v => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com"
      ];
      this.rules.password = [v => !!v || "Please enter a password"];
      if (this.$refs.form.validate()) {
        this.submitForm();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    async submitForm() {
      this.loading = true;
      try {
        await this.USER_LOGIN(this.form).then(res => {
          if (res.result == 'success') {
            this.loading = false;
            if (res.data.user_type_name === 'Admin' || res.data.permission_modules.length) {
              window.location.href = '/home'
            } else {
              let res = []
              res.type = 'error'
              res.message = `You don't have a permission for any screen yet. Please contact admin`
              this.$store.dispatch('snackbar/SHOW', res)
            }
          } else if (res.result === 'fail') {
            this.loading = false
            res.type = 'error'
            this.$store.dispatch('snackbar/SHOW', res)
          }
        }).catch(err => {
          console.log(err)
        });
      } catch (e) {
        console.log("e: ", e);
        this.loginError = true
        this.loading = false;
      }
    },
  }
};

</script>
