export default {
	data: () => ({
		widgets: [{
			name: 'Deal Count',
			active: false,
			slug: 'deal_counts'
		}, {
			name: 'Deal Total',
			active: false,
			slug: 'deal_total'
		},{
			name: 'Amount Received',
			active: false,
			slug: 'amount_received'
		},{
			name: 'Total Balance',
			active: false,
			slug: 'total_balance'
		}]
	}),
	mounted() {
		this.loadDealsCount()
	},
	methods: {
		async loadDealsCount() {
			let counts = {}
			await this.$store.dispatch('deals/WIDGET_COUNT', { branch: this.payload.filter.branch}).then(res => {
				counts = res.data
			})
			this.widgets = this.widgets.map(
				function(el) {
					let item = Object.assign({}, el);
					item.count = (counts.hasOwnProperty(el.slug)) ? counts[el.slug] : 0
					return item;
				}
			);
		},
	}

}
