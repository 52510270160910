import http from '../http/common'

const resource = '/configurations'

export default {

	uploadLogo: (payload) => http.post(`${resource}/upload-logo`, payload),

	get: (payload) => http.get(`${resource}/get${payload}`)
}
