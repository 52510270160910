import collection from '../../../api/collections/Appointments'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    assignedTo: [],
    pabauAppointment: {},
    item: {},
    widget: {},
    pagination: {}
  },
  mutations: {
    SET_APPOINTMENT(state, data) {
      state.list = data
    },
    SET_PABAU_APPOINTMENT_SHOW(state, data) {
      state.pabauAppointment = data
    },
    SET_ASSIGNED_TO(state, data) {
      state.assignedTo = data
    },
    SET_APPOINTMENT_ITEM(state, data) {
      state.item = data
    },
    RESET_APPOINTMENT(state, data) {
      state.item = {}
    },
    SET_WIDGET_COUNT(state, data) {
      state.widget = {}
    },
    SET_APPOINTMENT_TABLE_PAGINATION(state, data) {
      state.pagination = data
    },

    SET_PABAU_APPOINTMENTS(state, data) {
      state.appointments = data
    }
  },
  actions: {
    async GET_APPOINTMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_APPOINTMENT', data.data.data)
        return data
      }, commit, dispatch, 'GET_APPOINTMENT', true)
    },
    async GET_CALENDAR({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.calendar(payload.data, payload.params)
        return data
      }, commit, dispatch, 'GET_CALENDAR', true)
    },
    async CALENDAR_DASHBOARD({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.calendarDashboard(payload.data, payload.params)
        return data
      }, commit, dispatch, 'CALENDAR_DASHBOARD', true)
    },
    async GET_PABAU({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.pabau(payload.data, payload.params)
        return data
      }, commit, dispatch, 'GET_PABAU', true)
    },
    async CREATE_APPOINTMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_APPOINTMENT', true)
      return data
    },
    async SHOW_APPOINTMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_APPOINTMENT_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW_APPOINTMENT', false)
      return data
    },
    async UPDATE_APPOINTMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_APPOINTMENT', true)

      return data
    },
    async UPDATE_STATUS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.archive(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_STATUS', true)

      return data
    },
    async CONVERT_TO_CLIENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.convertToClient(payload)
        return data
      }, commit, dispatch, 'CONVERT_TO_CLIENT', true)

      return data
    },

    async ADD_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.comment(payload)
        return data
      }, commit, dispatch, 'ADD_COMMENT', true)
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.upload(payload)
        return data
      }, commit, dispatch, 'UPLOAD_DOCS', true)
      return data
    },

    async CREATE_DEAL({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.deal(payload)
        return data
      }, commit, dispatch, 'CREATE_DEAL', true)
      return data
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.appointmentCount(payload)
        commit('SET_WIDGET_COUNT', data.data)
        return data
      }, commit, dispatch, 'WIDGET_COUNT', true)
    },

    async ASSIGNED_TO({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.assignedTo(payload)
        commit('SET_ASSIGNED_TO', data.data)
        return data
      }, commit, dispatch, 'ASSIGNED_TO', true)
    },

    async APPOINTMENTS_BY_USERID({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.appointmentsByUserId(payload)
        return data
      }, commit, dispatch, 'APPOINTMENTS_BY_USERID', true)

      return data
    },


    async GET_PABAU_APPOINTMENTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.pabauAppointments(payload)
        commit('SET_PABAU_APPOINTMENTS', data.data)
        return data
      }, commit, dispatch, 'GET_PABAU_APPOINTMENTS', true)
    },

    async PABAU_APPOINTMENT_SHOW({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.pabauAppointmentShow(payload)
        commit('SET_PABAU_APPOINTMENT_SHOW', data.data)
        return data
      }, commit, dispatch, 'PABAU_APPOINTMENT_SHOW', true)
    },

    async PABAU_UPDATE_USER({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.pabauAppointmentUpdateUser(payload)
        return data
      }, commit, dispatch, 'PABAU_UPDATE_USER', true)
    },
  }
}
