import collection from '../../../api/collections/Docs'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    pagination: {}
  },
  mutations: {
    SET_DOCS(state, data) {
      state.list = data
    },
    SET_STATUS_ITEM(state, data) {
      state.item = data
    },
    RESET_STATUS(state, data) {
      state.item = {}
    },
    SET_STATUS_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_DOCS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload)
        commit('SET_DOCS', data.data)
        return data
      }, commit, dispatch, 'GET_DOCS', true)
    },

    async DELETE_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.delete(payload)
        return data
      }, commit, dispatch, 'DELETE_DOCS', true)
      return data
    },
  }
}
