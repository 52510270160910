<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-form ref="cashPaymentForm">
              <v-data-table class="results-table" item-key="id" :headers="headers" :items="items" :loading="loading" :options.sync="options" sort-by="updated_at" :items-per-page="itemsPerPage" :footer-props="footerProps" :server-items-length="totalCount">
                <template v-slot:item.description="{ item }">
                  <v-text-field autocomplete="off" solo outlined v-model="editedItem.description" flat hide-details="auto" class="solo-cust inline-input" v-if="item.id === editedItem.id">
                  </v-text-field>
                  <div class="name-column" v-else>
                    <p class="user-name">
                      {{ item.description }}
                    </p>
                  </div>
                </template>
                <template v-slot:item.net_amount="{ item }">
                  <v-text-field autocomplete="off" solo outlined v-model="editedItem.net_amount" :rules="rules.net_amount" flat hide-details="auto" class="solo-cust inline-input" v-if="item.id === editedItem.id">
                  </v-text-field>
                  <template v-else>
                    {{ item.net_amount | currency('£')}}
                  </template>
                </template>
                <template v-slot:item.tax_type="{ item }">
                  <v-select autocomplete="off" v-if="item.id === editedItem.id" :rules="rules.tax_type" :items="tax" item-value="id" item-text="name" v-model="editedItem.tax_type" solo outlined flat class="solo-cust" hide-details="auto" style="width: 150px;">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                  <template v-else>
                    {{ item.tax_type}}%
                  </template>
                </template>
                <template v-slot:item.branch_id="{ item }">
                  <v-select autocomplete="off" v-if="item.id === editedItem.id" :rules="rules.branch_id" :items="branches" item-value="id" item-text="branch" v-model="editedItem.branch_id" solo outlined flat class="solo-cust" hide-details="auto" style="width: 150px;">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                  <div class="name-column" v-else>
                    <p class="user-name">
                      {{ item.branch_name }}
                    </p>
                  </div>
                </template>
                <template v-slot:item.payment_date="{ item }">
                  <v-menu v-if="item.id === editedItem.id" v-model="invoiceMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" :value="dateUKformat(editedItem.payment_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust inline-input" append-icon="mdil-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.payment_date" no-title @input="invoiceMenu = false"></v-date-picker>
                  </v-menu>
                  <template v-else>
                    <p class="date-sec date-mute" v-if="item.payment_date !== null && item.payment_date !== ''">
                      <v-btn class="badge badge-grey" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                      </v-btn> {{ item.payment_date | moment("DD/MM/YY") }}
                    </p>
                  </template>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip v-if="item.id !== editedItem.id" small class="este-chip status-chip" :class="item.status === 6 ? 'text-uppercase' : ''" :outlined="(item.status === 11 || item.status === 12) ? true: false" :text-color="item.status === 12 ? getColor(item.status) : '#221F20'" :color="getColor(item.status)">
                    {{ getStatus(item.status) | capitalize }}
                  </v-chip>
                  <template v-if="item.id === editedItem.id">
                    <v-btn class="px-3 btn-inline" min-width="32" height="32" icon color="#4ACE28" :loading="saveLoading" @click="update">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn class="px-3 btn-inline" min-width="32" height="32" icon color="#D34141" :loading="cancelLoading" @click="cancel">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                          <v-icon large>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <div class="logout-block">
                        <ul>
                          <li><a @click="editItem(item)">Edit</a></li>
                          <li><a @click="deleteItem(item)" v-if="item.status !== 7">Delete</a></li>
                        </ul>
                      </div>
                    </v-menu>
                  </template>
                </template>
              </v-data-table>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"><img src="/images/close.svg" alt="Close" /></a>
        </v-card-title>
        <v-card-text>
          {{message}}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn class="cancel-btn btn-gap" depressed rounded outlined @click="modalDialog = false" color="dark" x-large>
              Cancel
            </v-btn>
            <v-btn class="delete-btn" :color="(actionText === 'Delete') ? 'red' : 'secondary'" depressed @click="handleFunction(action)" rounded x-large>
              {{actionText}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn';
import sliderFn from '../../../mixins/sliderFn';
import status from '../../../mixins/status';
import eventBus from '../../../eventBus.js';
import moment from 'moment';
export default {
  name: "CashPayments",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      saveLoading: false,
      cancelLoading: false,
      modalDialog: false,
      invoiceMenu: false,
      dueMenu: false,
      headers: [{ text: 'Description', align: 'start', value: 'description', align: 'left', sortable: false },
        { text: 'Net Amount', value: 'net_amount', align: 'left', sortable: false },
        { text: 'Tax Type', value: 'tax_type', align: 'left', sortable: false },
        { text: 'Payment Date', value: 'payment_date', align: 'left', sortable: false },
        { text: 'Branch', value: 'branch_id', align: 'left', sortable: false },
        { text: 'Status', value: 'status', sortable: false, align: 'end' }
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: 'desc',
      sortBy: 'updated_at',
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: '',
      params: {
        attributes: 'branch',
        search: '',
        filter: {
          payment_from: '',
          payment_to: '',
          status: [],
          branch: []
        }
      },
      message: '',
      action: '',
      actionText: '',
      editedIndex: -1,
      editedItem: {
        id: 0,
        description: '',
        net_amount: '',
        tax_type: '',
        payment_date: '',
        branch_id: '',
        type: ''
      },
      defaultItem: {
        id: 0,
        description: '',
        net_amount: '',
        tax_type: '',
        payment_date: '',
        branch_id: '',
        type: 'new'
      },
      rules: {
        net_amount: [v => !!v || 'Please enter net amount'],
        tax_type: [v => !!v || 'Please enter tax type'],
        branch_id: [v => !!v || 'Please select branch'],
      }
    }
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true
    },
  },
  computed: {
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList
      }
    },
  },
  mounted() {
    this.$root.$on('searchCashPayments', this.getSearchCashPayments)
    this.$root.$on('AddNewCashPayment', this.AddNewCashPayment)
  },
  created() {
    this.$store.dispatch('branches/GET_ALL_BRANCH', '')
  },
  methods: {
    AddNewCashPayment() {
      const index = this.items.findIndex(x => x.type === 'new');
      if (index >= 0) {
        this.items.splice(index, 1);
      } else {
        const addObj = Object.assign({}, this.defaultItem);
        if (this.items.length) {
          addObj.id = Math.max.apply(Math, this.items.map(function(o) { return o.id; })) + 1
        } else {
          addObj.id = 0
        }
        this.items.unshift(addObj);
        this.totalCount = this.items.length
        this.editItem(addObj)
      }
    },
    dateUKformat(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    async getSearchCashPayments(data) {
      this.options.page = 1;
      this.items = []
      if (data) {
        this.params.search = data.search
        this.params.filter.payment_from = data.payment_from
        this.params.filter.payment_from = data.payment_from
        this.params.filter.status = data.status
        this.params.filter.branch = data.branch
        await this.readTheDataFromApi()
      } else {
        this.params.search = ''
        this.params.filter.payment_from = ''
        this.params.filter.payment_from = ''
        this.params.filter.status = []
        this.params.filter.branch = []
        await this.readTheDataFromApi()
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi()
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch('cashPayments/GET_CASH_PAYMENT', { data: this.params, params: `?page=${this.page}&per_page=${this.itemsPerPage}` }).then(res => {
        res.data.data = res.data.data.map(
          function(el) {
            let element = Object.assign({}, el);
            element.type = 'old'
            return element;
          }
        );
        this.items = res.data.data
        this.totalCount = res.data.total
      })
      this.loading = false;
    },
    async loadUpdatedApiData() {
      this.items = []
      this.loading = true;
      await this.$store.dispatch('cashPayments/GET_CASH_PAYMENT', { data: this.params, params: `?page=${this.page}&per_page=${this.itemsPerPage}` }).then(res => {
        res.data.data = res.data.data.map(
          function(el) {
            let element = Object.assign({}, el);
            element.type = 'old'
            return element;
          }
        );
        this.items = res.data.data
        this.totalCount = res.data.total
      })
      this.loading = false;
    },
    editItem(item) {
      item.tax_type = (item.tax_type === 0) ? 1 : item.tax_type
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    cancel() {
      this.cancelLoading = true
      if (this.editedItem.type === 'new') {
        const index = this.items.findIndex(x => x.id === this.editedItem.id);
        if (index >= 0) {
          this.items.splice(index, 1);
        }
      } else {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.loadUpdatedApiData()
      }
      this.cancelLoading = false
    },
    async update() {
      if (this.$refs.cashPaymentForm.validate()) {
        if (this.editedItem.type === 'new') {
          this.updateOrCreate('cashPayments/CREATE_CASH_PAYMENT')
        } else {
          this.updateOrCreate('cashPayments/UPDATE_CASH_PAYMENT')
        }
      }
    },
    async updateOrCreate(action) {
      let formData = Object.assign({}, this.editedItem);
      formData.tax_type = (this.editedItem.tax_type === 1) ? 0 : this.editedItem.tax_type;
      await this.$store.dispatch(action, formData).then(response => {
        if (response.result === 'success') {
          this.saveLoading = false
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.loadUpdatedApiData()
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.saveLoading = false
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        this.saveLoading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },
    handleFunction(funName) {
      this[funName]()
    },
    async deleteItem(item) {
      this.selectedItem = item
      this.modalDialog = true
      this.message = "Please confirm you want to delete this Cash Payment?"
      this.actionText = "Delete"
      this.action = "delete"
    },
    async delete() {
      await this.$store.dispatch('cashPayments/DELETE_CASH_PAYMENT', this.selectedItem.id).then(res => {
        if (res.result === 'success') {
          this.modalDialog = false
          this.loadUpdatedApiData()
        } else if (res.result === 'fail') {
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    }
  }
};

</script>
