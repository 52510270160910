var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"support-wrap"},[_c('v-expansion-panels',{attrs:{"multiple":""}},[_vm._l((_vm.treatments),function(treat,index){return [_c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_c('span',{staticClass:"total mr-2",staticStyle:{"width":"250px"}},[_vm._v(_vm._s(treat.treatment_name))]),_vm._v(" "),_c('span',{staticClass:"total mr-2",staticStyle:{"width":"150px"}},[_c('span',{staticClass:"sold-by"},[_vm._v("No of Sessions: ")]),_vm._v("\n\t\t\t\t\t\t"+_vm._s(treat.appointments_completed)+"/"+_vm._s(treat.no_of_sessions))]),_vm._v(" "),_c('span',{staticClass:"total mr-2",staticStyle:{"width":"150px"}},[_c('span',{staticClass:"sold-by"},[_vm._v("Cost: ")]),_vm._v("£"+_vm._s(treat.cost))])]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"group-panel-content"},[_c('v-data-table',{staticClass:"history-table form-wrap",attrs:{"headers":_vm.headers,"items":treat.deal_appointments},scopedSlots:_vm._u([{key:"item.appointment_date",fn:function(ref){
var item = ref.item;
return [(item.status === 16)?[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("moment")(item.appointment_date,"DD/MM/YY"))+"\n\t\t\t\t\t\t\t")]:[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"solo-cust deal-slot",attrs:{"autocomplete":"off","solo":"","outlined":"","value":_vm.dateUkFormat(item.appointment_date),"flat":"","hide-details":"auto"}},on))]}}],null,true),model:{value:(_vm.appointmentDateMenu[item.id]),callback:function ($$v) {_vm.$set(_vm.appointmentDateMenu, item.id, $$v)},expression:"appointmentDateMenu[item.id]"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.appointmentDateMenu[item.id] = false;}},model:{value:(item.appointment_date),callback:function ($$v) {_vm.$set(item, "appointment_date", $$v)},expression:"item.appointment_date"}})],1)]]}},{key:"item.appointment_from_time",fn:function(ref){
var item = ref.item;
return [(item.status === 16)?[_c('span',[_vm._v(_vm._s(item.appointment_from_time))])]:[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"solo-cust deal-slot",attrs:{"flat":"","hide-details":"auto","solo":"","outlined":"","readonly":""},model:{value:(item.appointment_from_time),callback:function ($$v) {_vm.$set(item, "appointment_from_time", $$v)},expression:"item.appointment_from_time"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.appointmentFromTimeMenu[item.id]),callback:function ($$v) {_vm.$set(_vm.appointmentFromTimeMenu, item.id, $$v)},expression:"appointmentFromTimeMenu[item.id]"}},[_vm._v(" "),_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedStep,"no-title":"","ampm-in-title":"","format":"24hr","full-width":""},model:{value:(item.appointment_from_time),callback:function ($$v) {_vm.$set(item, "appointment_from_time", $$v)},expression:"item.appointment_from_time"}})],1)]]}},{key:"item.appointment_to_time",fn:function(ref){
var item = ref.item;
return [(item.status === 16)?[_c('span',[_vm._v(_vm._s(item.appointment_to_time))])]:[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"solo-cust deal-slot",attrs:{"flat":"","hide-details":"auto","solo":"","outlined":"","readonly":""},model:{value:(item.appointment_to_time),callback:function ($$v) {_vm.$set(item, "appointment_to_time", $$v)},expression:"item.appointment_to_time"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.appointmentToTimeMenu[item.id]),callback:function ($$v) {_vm.$set(_vm.appointmentToTimeMenu, item.id, $$v)},expression:"appointmentToTimeMenu[item.id]"}},[_vm._v(" "),_c('v-time-picker',{attrs:{"allowed-minutes":_vm.allowedStep,"no-title":"","ampm-in-title":"","format":"24hr","full-width":""},model:{value:(item.appointment_to_time),callback:function ($$v) {_vm.$set(item, "appointment_to_time", $$v)},expression:"item.appointment_to_time"}})],1)]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 16)?[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"icon":"","color":"green"},on:{"click":function($event){return _vm.update(item, treat)}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.cancel(item, treat)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","color":"secondary","loading":_vm.reminderLoading},on:{"click":function($event){return _vm.SendReminder(item)}}},[_c('v-icon',[_vm._v("mdi-message-text-clock-outline")])],1)],1)]:[_c('v-chip',{staticClass:"este-chip status-chip",class:item.status === 6 ? 'text-uppercase' : '',attrs:{"small":"","outlined":(item.status === 11 || item.status === 12) ? true: false,"text-color":item.status === 12 ? _vm.getColor(item.status) : '#221F20',"color":_vm.getColor(item.status)}},[_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(_vm.getStatus(item.status)))+"\n\t\t\t\t\t\t\t\t")])]]}}],null,true)})],1)],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }