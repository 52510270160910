<template>
	<div>
		<template v-if="statSkeleton">
			<v-row>
				<v-col cols="12" sm="12" md="12">
					<v-skeleton-loader :loading="loading" type="image"></v-skeleton-loader>
				</v-col>
			</v-row>
		</template>
		<template v-if="!statSkeleton">
			<v-row>
				<v-col cols="12" sm="12" md="12">
					<h4 class="graph-title mt-2">Opportunities
						<v-tooltip content-class="doc-tooltip" color="#fff" text-color="#141715" top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on" size="20" color="rgba(34, 31, 32, 0.9)">
									mdil-information
								</v-icon>
							</template>
							<span class="content">Opportunities generated between {{dateUkFormat(filter.created_at_from)}} and {{dateUkFormat(filter.created_at_to)}} showing their status as of today</span>
						</v-tooltip>
					</h4>
					<div class="graph-wrap" v-if="Object.keys(opportunitiesByToday).length > 0">
						<Doughnut class="chart" :options="options" :height="290" :width="290" :chartData="opportunitiesByToday"></Doughnut>
						<div class="total">
							<div class="number" v-if="opportunitiesByTodaySum">
								{{opportunitiesByTodaySum}}
							</div>
						</div>
					</div>
					<template v-if="Object.keys(opportunitiesByToday).length === 0">
						No data available
					</template>
				</v-col>
			</v-row>
		</template>
	</div>
</template>
<script>
import Doughnut from "../../plugins/Doughnut.js";
import status from "../../mixins/status.js";
import globalFn from '../../mixins/globalFn';
export default {
	components: {
		Doughnut
	},
	mixins: [status, globalFn],
	data: () => ({
		filter: {
			branches: [],
			created_at_from: '',
			created_at_to: '',
			status: [],
		},
		statSkeleton: false,
		loading: false,
		moduleStats: {},
		opportunitiesByToday: {},
		opportunitiesByTodaySum: 0,
		options: {
			plugins: {
				datalabels: {
					color: "#000",
					textAlign: "center",
					font: {
						weight: "bold",
						size: 14
					}
				}
			},
			tooltips: {
				titleFontSize: 14,
				bodyFontSize: 14,
				fontColor: '#221F20',
				fontFamily: "'Inter', sans-serif",
				padding: 10,
				lineHeight: 2.4
			},
			borderWidth: "0",
			legend: {
				position: 'bottom',
				display: true,
				labels: {
					usePointStyle: true,
					boxWidth: 6,
					fontColor: '#221F20',
					fontFamily: "'Inter', sans-serif",
					titleFontSize: 14,
					bodyFontSize: 14,
				}
			},
			responsive: true,
			maintainAspectRatio: false,
		}
	}),
	mounted() {
		this.$root.$on('SearchDashboard', this.SearchDashboard)
	},
	created() {
		window.Echo.channel("dashboard-update").listen(".refresh-dashboard", e => {
			let branchExists = this.filter.branches.includes(e.data.branch_id)
			if (e.data.refresh && this.filter.branches.length === 0) {
				this.loadModuleStats()
			} else if (e.data.refresh && branchExists) {
				this.loadModuleStats()
			}
		});
	},
	methods: {
		async SearchDashboard(data) {
			this.filter.branches = data.branch
			this.filter.created_at_from = data.created_at_from
			this.filter.created_at_to = data.created_at_to
			await this.loadModuleStats()
		},
		async loadModuleStats() {
			this.loading = true
			this.statSkeleton = true
			await this.$store.dispatch('dashboard/MODULE_STATS', this.filter).then(res => {
				let _this = this
				if (res.data.opps_by_today) {
					res.data.opps_by_today = res.data.opps_by_today.map(
						function(el) {
							let item = Object.assign({}, el);
							item.color = _this.getColor(el.status)
							item.status_name = `${_this.getStatusName(el.status)} (${el.status_count})`
							return item;
						}
					);
					_this.opportunitiesByTodaySum = res.data.opps_by_today.map(item => item.status_count).reduce((prev, next) => prev + next, 0);
				}
				this.moduleStats = res.data

			})
			await this.oppsByToday()
			this.loading = false
			this.statSkeleton = false
		},
		async oppsByToday() {
			if (this.moduleStats.opps_by_today) {
				this.opportunitiesByToday = {
					labels: this.moduleStats.opps_by_today.map(function(el) { return el.status_name; }),
					datasets: [{
						borderWidth: 0,
						label: "Data One",
						backgroundColor: this.moduleStats.opps_by_today.map(function(el) { return el.color; }),
						data: this.moduleStats.opps_by_today.map(function(el) { return el.status_count; }),
					}]
				}
			}
		}
	}
}

</script>
