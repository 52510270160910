<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Edit Branch</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Branch Name
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined :rules="rules.branch" v-model="branch.branch" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="6" md="6" class="e-col">
                <label>
                  <span class="label-txt">
                    Contact No
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined v-model="branch.contact_no" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
              <v-col cols="6" md="6" class="e-col">
                <label>
                  <span class="label-txt">
                    Email
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined v-model="branch.email" flat hide-details="auto" class="solo-cust">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Machines
                  </span>
                </label>
                <v-autocomplete v-model="branch.machines" :items="machines" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Visibility for Consultants
                  </span>
                </label>
                <v-radio-group hide-details="auto" mandatory v-model="branch.visibility_all" row>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="See all rows" :value="0"></v-radio>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="See rows assigned to them only" :value="1"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    SMS Credits
                  </span>
                </label>
                <v-text-field :rules="[positiveNumber]" autocomplete="off" solo outlined v-model="branch.sms_credits" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    SMS Enabled
                  </span>
                </label>
                <v-radio-group hide-details="auto" mandatory v-model="branch.send_sms" row>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Automatic SMS Enabled
                  </span>
                </label>
                <v-radio-group hide-details="auto" mandatory v-model="branch.is_auto_send" row>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Can see points in the App?
                  </span>
                </label>
                <v-radio-group hide-details="auto" mandatory v-model="branch.points_visible" row>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
                  <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="create">
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../../store/action-types.js';
import status from '../../../../mixins/status.js';
import globalFn from '../../../../mixins/globalFn.js';
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem('user')),
    tab: null,
    rules: {
      branch: [
        v => !!v || 'Please enter branch'
      ]
    }
  }),
  computed: {
    branch() {
      if (this.$store.state.branches.item) {
        return this.$store.state.branches.item
      }
    },
    machines() {
      if (this.$store.state.machines.all) {
        return this.$store.state.machines.all
      }
    }
  },
  created() {
    this.$store.dispatch('machines/GET_MACHINE_LIST', '')
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("branchTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async create() {
      let formData = {}
      formData.id = this.branch.id
      formData.branch = this.branch.branch
      formData.contact_no = this.branch.contact_no
      formData.email = this.branch.email
      formData.visibility_all = this.branch.visibility_all
      formData.send_sms = this.branch.send_sms
      formData.is_auto_send = this.branch.is_auto_send
      formData.sms_credits = this.branch.sms_credits
      formData.points_visible = this.branch.points_visible
      formData.machines = []
      this.branch.machines.forEach(value => {
        if (value.id) {
          formData.machines.push({ id: value.id });
        } else {
          formData.machines.push(value);
        }
      });
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.$store.dispatch('branches/UPDATE_BRANCH', formData).then(response => {
          if (response.result === 'success') {
            this.loading = false
            this.loadData()
          } else if (response.result === 'fail') {
            response.type = 'error'
            this.loading = false
            this.$store.dispatch('snackbar/SHOW', response)
          }
        }).catch(err => {
          this.loading = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        });
      }
    }
  }
};

</script>
