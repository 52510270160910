export default {
	props: {},
	data: () => ({
		titles: ["Mr", "Mrs", "Dr", "Miss"],
		ticketTypes: [{
			label: 'Advice',
			id: 'advice',
			active: false
		}, {
			label: 'Information',
			id: 'information',
			active: false
		}, {
			label: 'Complaint',
			id: 'complaint',
			active: false
		}],
		paymentMethods: [{
			id: 1,
			name: 'Cash'
		}, {
			id: 2,
			name: 'Credit/Debit Card'
		}, {
			id: 3,
			name: 'Voucher'
		}, {
			id: 4,
			name: 'Bank'
		}, {
			id: 5,
			name: 'Points'
		}, {
			id: 6,
			name: 'Imported'
		}],
		appointmentType: ['Treatment', 'Consultation'],
		numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		satisfaction: [{
			name: 'Sad',
			slug: 'sad',
			image: 'images/sad.svg',
			active: false,
		}, {
			name: 'Neutral',
			slug: 'neutral',
			image: 'images/neutral.svg',
			active: false,
		}, {
			name: 'Happy',
			slug: 'happy',
			image: 'images/happy.svg',
			active: false,
		}, {
			name: 'VIP',
			slug: 'vip',
			image: 'images/vip.svg',
			active: false,
		}],
		modules: [
			{ header: 'Screens' },
			{
				id: 'dashboard',
				name: 'Dashboard',
				group: 'Screens'
			}, {
				id: 'pulse_dashboard',
				name: 'Pulse Dashboard',
				group: 'Screens'
			}, {
				id: 'appointment_calendar_dashboard',
				name: 'Appointment Calendar Dashboard',
				group: 'Screens'
			}, {
				id: 'opportunities',
				name: 'Opportunities',
				group: 'Screens'
			}, {
				id: 'customer',
				name: 'Contacts',
				group: 'Screens'
			}, {
				id: 'support-tickets',
				name: 'Support Tickets',
				group: 'Screens'
			}, {
				id: 'lead',
				name: 'Lead',
				group: 'Screens'
			}, {
				id: 'client',
				name: 'Client',
				group: 'Screens'
			}, {
				id: 'appointment',
				name: 'Appointment',
				group: 'Screens'
			}, {
				id: 'callback',
				name: 'Call Back',
				group: 'Screens'
			}, {
				id: 'complaint',
				name: 'Complaint',
				group: 'Screens'
			}, {
				id: 'insight',
				name: 'Insights',
				group: 'Screens'
			}, {
				id: 'deal',
				name: 'Deals',
				group: 'Screens'
			},
			{
				id: 'purchase_invoice',
				name: 'Purchase Invoices',
				group: 'Screens'
			}, {
				id: 'cash_payment',
				name: 'Cash Payments',
				group: 'Screens'
			}, {
				id: 'pabau_sales',
				name: 'Pabau Sales',
				group: 'Screens'
			},
			{ header: 'Reports' },
			{
				id: 'commission_report',
				name: 'Commission Report',
				group: 'Reports'
			}, {
				id: 'invoice_report',
				name: 'Purchase Invoices Report',
				group: 'Reports'
			}, {
				id: 'cash_payment_report',
				name: 'Cash Payments Report',
				group: 'Reports'
			}, {
				id: 'social_media_report',
				name: 'Social Media Report',
				group: 'Reports'
			}, {
				id: 'lead_source_report',
				name: 'Lead Source Report',
				group: 'Reports'
			},
			{
				id: 'email_report',
				name: 'Email Report',
				group: 'Reports'
			}, {
				id: 'social_media_chased_report',
				name: 'Social & Chase Report',
				group: 'Reports'
			}, {
				id: 'lead_activity_report',
				name: 'Lead Activity Report',
				group: 'Reports'
			}, {
				id: 'phone_activity_report',
				name: 'Phone Activity Report',
				group: 'Reports'
			},
			{ header: 'Actions' },
			{
				id: 'payment_delete',
				name: 'Payment Delete',
				group: 'Actions'
			},
			{ header: 'Settings' },
			{
				id: 'branches',
				name: 'Branches',
				group: 'Settings'
			}, {
				id: 'campaigns',
				name: 'Campaigns',
				group: 'Settings'
			}, {
				id: 'staff',
				name: 'Staff',
				group: 'Settings'
			}, {
				id: 'categories',
				name: 'Categories',
				group: 'Settings'
			}, {
				id: 'symptoms',
				name: 'Symptoms',
				group: 'Settings'
			}, {
				id: 'treatments',
				name: 'Treatments',
				group: 'Settings'
			}, {
				id: 'machines',
				name: 'Machines',
				group: 'Settings'
			},{
				id: 'warehouse',
				name: 'Warehouse',
				group: 'Settings'
			}, {
				id: 'products',
				name: 'Products',
				group: 'Settings'
			}, {
				id: 'points',
				name: 'Points',
				group: 'Settings'
			}, {
				id: 'configurations',
				name: 'Configurations',
				group: 'Settings'
			},
		],
		roles: [{
			id: 1,
			name: 'Manager'
		}, {
			id: 2,
			name: 'Branch Manager'
		}, {
			id: 3,
			name: 'Team Member'
		}, {
			id: 4,
			name: 'Consultant'
		}],
		period: [{
			id: 1,
			name: 'Yearly'
		}, {
			id: 2,
			name: 'Half-yearly'
		}, {
			id: 3,
			name: 'Monthly'
		}, {
			id: 4,
			name: 'Weekly'
		}, {
			id: 5,
			name: 'Daily'
		}],
		tax: [{
			id: 1,
			name: '0%'
		}, {
			id: 20,
			name: '20%'
		}],
		StatusExpenses: [{
			id: 1,
			name: 'Active',
			active: false
		}, {
			id: 3,
			name: 'Deleted',
			active: false
		}],
		StatusSales: [{
			id: 34,
			name: 'Confirmed',
			active: false
		}, {
			id: 35,
			name: 'Unconfirmed',
			active: false
		}],


		medical_conditions: [{
			id: 1,
			name: 'Yes'
		}, {
			id: 0,
			name: 'No'
		}]

	}),
	computed: {
		dealInfoById() {
			if (this.$store.state.deals.item) {
				return this.$store.state.deals.item
			}
		},
	},
	methods: {
		copyContent(copyText) {
			navigator.clipboard.writeText(copyText).then(() => {
				// alert("Copied to clipboard");
			});
		},
		dateUkFormat(val) {
			if (!val) return null
			const [year, month, day] = val.split('-')
			return `${day}/${month}/${year}`
		},
		positiveNumber: (v) => {
			if (!isNaN(parseFloat(v)) && v >= 0 && v <= 999) return true;
			return 'Please enter a positive number between 0 and 999';
		},
		dateUKformat(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		allowedStep: m => m % 15 === 0,
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		getInitials(nameString) {
			if (nameString && nameString.trim().indexOf(' ') != -1) {
				const fullName = nameString.split(' ');
				const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
				return initials.toUpperCase();
			} else {
				return nameString.substring(0, 2).toUpperCase()
			}
		},
		lowerCase(string) {
			if(string){
				return string.toLowerCase();
			}
			
		},
		trimLowerCase(string) {
			return string.replace(/\s/g, "").toLowerCase();
		},
		changeDateFormat(date) {
			if (!date) return null
			const [day, month, year] = date.split('/')
			return `${year}-${month}-${day}`
		},
		capitalize(string) {
			if (typeof string !== 'string') return ''
			return string.charAt(0).toUpperCase() + string.slice(1)
		},

		async loadDealInformation(event) {
			console.log(event)
			await this.$store.dispatch('deals/SHOW_DEAL', { id: event, params: `?attributes=dealTreatments,soldBy,payments` })
		}
	}
}
