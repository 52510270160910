export default {
	data: () => ({
		widgets: [{
			name: 'Overdue',
			active: false,
			slug: 'overdue'
		}, {
			name: 'Reschedule Required',
			active: false,
			slug: 'reschedule'
		}, {
			name: 'Today',
			active: false,
			slug: 'today'
		}]
	}),
	mounted() {
		this.loadAppointmentCount()
	},
	methods: {
		async loadAppointmentCount() {
			let counts = {}
			let params = {}
			params.type = this.$router.currentRoute.meta.slug === 'app-treatments' ? 'treatment' : 'consultation'
			params.enquiry_date_from = this.AppointmentParams.filter.enquiry_date_from
			params.enquiry_date_to = this.AppointmentParams.filter.enquiry_date_to
			params.appointment_date_from = this.AppointmentParams.filter.appointment_date_from
			params.appointment_date_to = this.AppointmentParams.filter.appointment_date_to
			params.status = this.AppointmentParams.filter.status
			params.branch = this.AppointmentParams.filter.branch
			params.source = this.AppointmentParams.filter.source
			params.source_blank = this.AppointmentParams.filter.source_blank
			params.assigned = this.AppointmentParams.filter.assigned
			params.assigned_to = this.AppointmentParams.filter.assigned_to

			await this.$store.dispatch('appointments/WIDGET_COUNT', params).then(res => {
				counts = res.data
			})
			this.widgets = this.widgets.map(
				function(el) {
					let item = Object.assign({}, el);
					item.count = (counts.hasOwnProperty(el.slug)) ? counts[el.slug] : 0
					return item;
				}
			);
		},
	}

}
