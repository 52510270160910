import http from '../../http/common'

const resource = '/report'

export default {

	commission: (payload) => http.get(`${resource}/export${payload}`),

	duplicateContacts: (params) => http.get(`${resource}/duplicate-contacts${params}`)
}
