import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from '../utils/AuthRequired'
import AdminAccess from '../utils/AdminAccess'
import UserAccess from '../utils/UserAccess'
Vue.use(VueRouter)

function page(path) {
  return () => import( /* webpackChunkName: '' */ `${path}`).then(m => m.default || m)
}
const routes = [{
    path: '*',
    component: require('../views/404').default
  },
  {
    path: '/',
    name: 'Login',
    meta: { name: "Login" },
    redirect: '/auth/login',
  },
  {
    path: '/login',
    name: 'Auth-login',
    meta: { name: "Login" },
    redirect: '/auth/login',
  },
  {
    name: 'login',
    path: '/auth/login',
    meta: { name: "Login" },
    component: require('../views/Auth/Login').default,
  },
  {
    name: 'home',
    path: '/home',
    component: require('../views/Home').default,
    beforeEnter: AuthRequired
  },
  {
    path: '/password',
    component: require('../views/Auth/Password').default,
    name: 'password',
    children: [{
        path: 'reset',
        meta: { name: "Login" },
        component: require('../views/Auth/Password/Email').default
      },
      {
        path: 'reset/:token',
        meta: { name: "Login" },
        component: require('../views/Auth/Password/Reset').default
      }
    ]
  },
  {
    path: "/admin",
    component: require("../views/Admin").default,
    meta: { name: "Leads" },
    redirect: "/leads",
    name: "Admin",
    children: [{
      name: "admin-dashboard",
      path: "/dashboard",
      meta: { name: "BI Dashboard", slug: "dashboard" },
      component: require("../views/Admin/Dashboard").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-pulse-dashboard",
      path: "/pulse-dashboard",
      meta: { name: "Pulse Dashboard", slug: "pulse-dashboard" },
      component: require("../views/Admin/PulseDashboard").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-customers",
      path: "/customers",
      meta: { name: "Contacts", slug: "customer" },
      component: require("../views/Admin/Customers").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-customers-view",
      path: "/view-contact/:id",
      meta: { name: "View Contact", slug: "view-customer" },
      component: require("../views/Admin/Customers/ViewContact.vue").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-opportunities",
      path: "/opportunities",
      meta: { name: "Opportunities", slug: "opportunities" },
      component: require("../views/Admin/Opportunities").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-support-tickets",
      path: "/support-tickets",
      meta: { name: "Support Tickets", slug: "support-tickets" },
      component: require("../views/Admin/SupportTickets").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-duplicates",
      path: "/duplicate-contacts",
      meta: { name: "Duplicate Contacts", slug: "duplicate-contacts" },
      component: require("../views/Admin/Duplicates").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-leads",
      path: "/leads",
      meta: { name: "Leads", slug: "leads" },
      component: require("../views/Admin/Leads").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-complaints",
      path: "/complaints",
      meta: { name: "Complaints", slug: "complaints" },
      component: require("../views/Admin/Complaints").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-deals",
      path: "/deals",
      meta: { name: "Deals", slug: "deals" },
      component: require("../views/Admin/Deals").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-offers",
      path: "/offers",
      meta: { name: "Offers", slug: "offers" },
      component: require("../views/Admin/Offers").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-notifications",
      path: "/notifications",
      meta: { name: "Notifications", slug: "notifications" },
      component: require("../views/Admin/Notifications").default,
      beforeEnter: AdminAccess,
    },{
      name: "pabau-sales",
      path: "/pabau-sales",
      meta: { name: "Pabau Sales", slug: "pabau" },
      component: require("../views/Admin/PabauSales").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-clients",
      path: "/clients",
      meta: { name: "Clients", slug: "clients" },
      component: require("../views/Admin/Clients").default,
      beforeEnter: AdminAccess,
    }, {
      name: "lead-activity-report",
      path: "/lead-activity-report",
      meta: { name: "Opportunity Activity Report", slug: "lead-activity-report" },
      component: require("../views/Admin/LeadActivityReport").default,
      beforeEnter: AdminAccess,
    },{
      name: "phone-activity-report",
      path: "/phone-activity-report",
      meta: { name: "Phone Activity Report", slug: "phone-activity-report" },
      component: require("../views/Admin/PhoneActivityReport").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-appointments",
      path: "/appointments",
      meta: { name: "Appointments", slug: "appointments" },
      component: require("../views/Admin/Appointments").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-pabau-appointments",
      path: "/pabau-appointments",
      meta: { name: "Pabau Appointments", slug: "pabau-appointments" },
      component: require("../views/Admin/Pabau").default,
      beforeEnter: AdminAccess,
    }, {
      name: "treatments",
      path: "/treatments",
      meta: { name: "Treatments", slug: "app-treatments" },
      component: require("../views/Admin/Treatments").default,
      beforeEnter: AdminAccess,
    }, {
      name: "appointment-calendar-dashboard",
      path: "/appointment-calendar-dashboard",
      meta: { name: "Appointment Calendar Dashboard", slug: "appointment-calendar-dashboard" },
      component: require("../views/Admin/AppointmentCalendarDashboard").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-callbacks",
      path: "/call-back",
      meta: { name: "Call Back", slug: "call-back" },
      component: require("../views/Admin/CallBack").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-calendar",
      path: "/calendar/:screen",
      meta: { name: "Calendar", slug: "calendar" },
      component: require("../views/Admin/Calendar").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-reports",
      path: "/reports",
      meta: { name: "Reports", slug: "reports" },
      component: require("../views/Admin/Reports").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-insights",
      path: "/insights",
      meta: { name: "Insights", slug: "insights" },
      component: require("../views/Admin/Insights").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-purchase-invoices",
      path: "/purchase-invoices",
      meta: { name: "Purchase Invoices", slug: "purchase-invoices" },
      component: require("../views/Admin/Invoices").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-cash-payments",
      path: "/cash-payments",
      meta: { name: "Cash Payments", slug: "cash-payments" },
      component: require("../views/Admin/CashPayments").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-staff",
      path: "/settings/staff",
      meta: { name: "Staff", slug: "settings" },
      component: require("../views/Admin/Settings/Staff").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-branches",
      path: "/settings/branches",
      meta: { name: "Branches", slug: "settings" },
      component: require("../views/Admin/Settings/Branches").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-categories",
      path: "/settings/categories",
      meta: { name: "Categories", slug: "settings" },
      component: require("../views/Admin/Settings/Categories").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-symptoms",
      path: "/settings/symptoms",
      meta: { name: "Symptoms", slug: "settings" },
      component: require("../views/Admin/Settings/Symptoms").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-treatments",
      path: "/settings/treatments",
      meta: { name: "Treatments", slug: "settings" },
      component: require("../views/Admin/Settings/Treatments").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-machines",
      path: "/settings/machines",
      meta: { name: "Machines", slug: "settings" },
      component: require("../views/Admin/Settings/Machines").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-products",
      path: "/settings/products",
      meta: { name: "Products", slug: "settings" },
      component: require("../views/Admin/Settings/Products").default,
      beforeEnter: AdminAccess,
    },{
      name: "admin-warehouse",
      path: "/settings/warehouse",
      meta: { name: "Warehouse", slug: "settings" },
      component: require("../views/Admin/Settings/Warehouse").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-configurations",
      path: "/settings/configurations",
      meta: { name: "Configurations", slug: "settings" },
      component: require("../views/Admin/Settings/Configurations").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-points",
      path: "/settings/points",
      meta: { name: "Points", slug: "settings" },
      component: require("../views/Admin/Settings/Points").default,
      beforeEnter: AdminAccess,
    }, {
      name: "admin-campaigns",
      path: "/settings/campaigns",
      meta: { name: "Campaigns", slug: "settings" },
      component: require("../views/Admin/Settings/Campaigns").default,
      beforeEnter: AdminAccess,
    }, ],
    beforeEnter: AdminAccess,
  },
]
const router = new VueRouter({ routes, mode: 'history' })

router.beforeEach((to, from, next) => {
  window.document.title = to.meta && to.meta.name ? `${process.env.MIX_BRAND_NAME} | ${to.meta.name}` : `${process.env.MIX_BRAND_NAME}`;
  next();
});


export default router
