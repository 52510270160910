<template>
	<div>
		<div class="tab-content-wrapper">
			<v-form ref="commentForm">
				<v-row class="e-row">
					<v-col cols="12" md="12" class="">
						<label>
							<span class="label-txt">
								Leave Comment
							</span>
						</label>
						<v-text-field :rules="rules.comment" v-model="comment" autocomplete="off" solo outlined flat hide-details="auto" class="solo-cust" placeholder="Add a comment"></v-text-field>
					</v-col>
				</v-row>
			</v-form>
			<v-row dense class="e-row mt-5">
				<v-col cols="12" md="6" class="e-col">
				</v-col>
				<v-col cols="12" md="6" class="e-col">
					<v-btn class="b-act-comment letter-s-0" depressed rounded x-large @click="addComment">
						Add Comment
					</v-btn>
					<v-btn class="b-act-upload letter-s-0 mr-2" depressed rounded outlined x-large @click="openFilePicker">
						Upload File
					</v-btn>
					<input type="file" multiple id="filePicker" @change="onFileChange($event.target.name, $event.target.files)" style="display:none">
				</v-col>
			</v-row>
			<v-row dense class="e-row">
				<v-col cols="12" md="12" class="e-col" v-if="audit.length > 0">
					<label>
						<span class="label-txt pb-5">
							Latest Updates {{itemData.user_type_name}}
						</span>
					</label>
				</v-col>
				<v-col cols="12" md="12" class="e-col">
					<template v-if="auditLoading">
						<v-skeleton-loader class="px-10" type="article"></v-skeleton-loader>
					</template>
					<template v-else>
						<v-timeline align-top dense class="pt-0 ml-n10">
							<v-timeline-item class="doc-timeline" v-for="(doc, index) in docs" :key="index" color="secondary" x-small fill-dot>
								<v-row dense class="e-row">
									<v-col cols="12" md="12" class="e-col doc-item">
										<div class="date">{{ doc.created_at | moment("MMMM Do YYYY") }} at {{ doc.created_at | moment("h:mm:ss a") }}</div>
										<div class="file-section">
											<div class="caption mb-0">
												<span class="float-right download-text">Download files ({{doc.filesize}} in total)</span>
											</div>
											<v-avatar class="doc-img">
												<v-img src="/images/pdf.png" class="img" v-if="doc.filetype === 'pdf'" @click="previewDoc(doc)">
													<div class="content-overlay">
														<img src="/images/zoom-in.svg">
													</div>
												</v-img>
												<v-img :src="`${doc.url}`" class="img" v-else @click="previewDoc(doc)">
													<div class="content-overlay">
														<img src="/images/zoom-in.svg">
													</div>
												</v-img>
											</v-avatar>
										</div>
										<div class="added-user">
											<span class="float-left user"> By {{ doc.uploaded_by_name }}</span>
										</div>
									</v-col>
								</v-row>
							</v-timeline-item>
						</v-timeline>
						<v-timeline align-top dense class="pt-0 ml-n10">
							<template v-for="(item, index) in audit">
								<template v-if="item.auditable_id === keyId && (item.model === 'Opportunity' || item.model === 'Complaint')">
									<template v-if="item.event === 'created' && Object.keys(item.old_values).length === 0">
										<v-timeline-item class="doc-timeline mb-6" color="secondary" x-small fill-dot>
											<div class="date">
												{{ item.created_at }}
											</div>
											<v-expansion-panels class="timeline-expansion" multiple>
												<v-expansion-panel>
													<v-expansion-panel-header>
														<template v-if="item.model === 'Complaint'">Support Ticket</template>
														<template v-else>{{item.model}}</template>
														is {{item.event}}
													</v-expansion-panel-header>
													<v-expansion-panel-content class="group-panel-content">
														<v-simple-table fixed-header>
															<template v-slot:default>
																<thead>
																	<tr>
																		<th class="text-left">Field</th>
																		<th class="text-left">Value</th>
																	</tr>
																</thead>
																<tbody>
																	<tr v-for="(value,key) in item.new_values">
																		<template v-if="value && value.length > 0 && value !== '01/01/1970' && value !== '00:00'">
																			<td>{{capitalize(key.replace(/_/g, " "))}}</td>
																			<td>{{value}}</td>
																		</template>
																	</tr>
																</tbody>
															</template>
														</v-simple-table>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
											<div class="added-user">
												<span class="float-left user"> By {{ item.owner_name }}</span>
											</div>
										</v-timeline-item>
									</template>
									<template v-else>
										<template v-for="(value,key) in item.new_values">
											<v-timeline-item class="doc-timeline" v-if="value !== null && key !== 'category'" :key="index.key" color="secondary" x-small fill-dot>
												<v-row dense class="e-row">
													<v-col cols="12" md="12" class="e-col doc-item">
														<div class="date">{{ item.created_at }}</div>
														<template v-if="key === 'is_urgent'">
															<div class="file-section">
																<div class="caption">
																	<div class="read-less">
																		<div class="d-flex flex-column">
																			<span>
																				<template v-if="item.model === 'Complaint'">Support Ticket</template>
																				<template v-else>{{item.model}}</template>
																				<span v-if="value">is flagged</span>
																				<span v-if="!value">is unflagged</span>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</template>
														<template v-else>
															<div class="file-section" :class="key === 'status' ? 'status' : ''">
																<ReadMoreText @refreshAudit="loadAudit" :data="item" :event="item.event" :moduleName="item.model" :category="key" :comment="value"></ReadMoreText>
															</div>
														</template>
														<div class="added-user">
															<span class="float-left user"> By {{ item.owner_name }}</span>
														</div>
													</v-col>
												</v-row>
											</v-timeline-item>
										</template>
									</template>
								</template>
								<template v-if="item.model === 'Comment' && (item.comments && item.comments.commentable_id === keyId)">
									<template v-for="(value,key) in item.new_values" v-if="key !== 'category'">
										<v-timeline-item class="doc-timeline" color="secondary" x-small fill-dot>
											<v-row dense class="e-row">
												<v-col cols="12" md="12" class="e-col doc-item">
													<div class="date">{{ item.created_at }}</div>
													<div class="file-section">
														<ReadMoreText @refreshAudit="loadAudit" :data="item" :event="item.event" :moduleName="item.model" :category="key" :comment="value"></ReadMoreText>
													</div>
													<div class="added-user">
														<span class="float-left user"> By {{ item.owner_name }}</span>
													</div>
												</v-col>
											</v-row>
										</v-timeline-item>
									</template>
								</template>
							</template>
						</v-timeline>
					</template>
				</v-col>
			</v-row>
		</div>
		<v-dialog v-model="showPreviewDialog" persistent max-width="900">
			<v-card class="preview-image">
				<v-card-text class="pa-0">
					<ul class="action">
						<li>
							<a @click.stop="deleteDocs(selectedPreview)">
								<img src="/images/delete.svg" alt="Delete" />
								<div class="text">Delete</div>
							</a>
						</li>
						<li>
							<a @click="showPreviewDialog = false">
								<img src="/images/prev-close.svg" alt="Close" />
								<div class="text">Close</div>
							</a>
						</li>
					</ul>
					<template v-if="selectedPreview.filetype === 'pdf'">
						<embed :src="`${selectedPreview.url}#toolbar=0&navpanes=0`" type="application/pdf" width="100%" height="600px">
					</template>
					<template v-else>
						<div class="outer">
							<div class="inner">
								<img class="preview" :src="`${selectedPreview.url}`" alt="" />
							</div>
						</div>
					</template>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import ReadMoreText from "./ReadMoreText.vue"
import eventBus from "../../eventBus.js"
import globalFn from "../../mixins/globalFn.js"
export default {
	name: 'ActivityTab',
	mixins: [globalFn],
	components: {
		ReadMoreText
	},
	props: {
		userId: {
			required: true
		},
		keyId: {
			required: true
		},
		itemData: {
			required: true
		},
		screenType: {
			required: true
		}
	},
	data: () => ({
		title: "",
		slug: "",
		auditLoading: false,
		selectedPreview: '',
		showPreviewDialog: false,
		comment: '',
		user: JSON.parse(localStorage.getItem('user')),
		rules: {
			comment: [
				v => !!v || 'Please enter comment'
			],
		},
		audit: [],
		docs: [],
		action: '',
		actionType: '',
		actionId: '',
		documentType: '',

	}),
	watch: {
		$route: function(val) {
			this.title = val.meta.name;
			this.slug = val.meta.slug;
		}
	},
	mounted() {
		this.loadAudit()
		this.loadTitle();
	},
	methods: {
		async loadTitle() {
			this.title = this.$router.currentRoute.meta.name;
			this.slug = this.$router.currentRoute.meta.slug;
			switch (this.$router.currentRoute.meta.name) {

				case 'Opportunities':
					this.action = 'opportunities'
					this.actionType = 'Opportunities'
					this.documentType = 'opportunity'
					this.actionId = 'id'
					break;

				case 'View Contact':
					this.action = (this.screenType === 'opportunity') ? 'opportunities' : 'complaints'
					this.actionType = (this.screenType === 'opportunity') ? 'Opportunities' : 'complaint'
					this.documentType = (this.screenType === 'opportunity') ? 'opportunity' : 'complaint'
					this.actionId = (this.screenType === 'opportunity') ? 'id' : 'complaint_id'
					break;

				case 'Support Tickets':
					this.action = 'complaints'
					this.actionType = 'complaint'
					this.documentType = 'complaint'
					this.actionId = 'complaint_id'
					break;
			}
		},
		async loadAudit() {
			this.auditLoading = true
			await this.$store.dispatch('audit/GET_AUDIT', `?filters=[{"user_id":[{"equ":${this.userId}}]}]&attributes=comments,commentCalls`).then(res => {
				res.data = res.data.map(
					function(el) {
						let item = Object.assign({}, el);
						item.isMarked = false
						item.isPin = 0

						if (el.comments) {
							item.isPin = el.comments.is_pin_note
						}
						if (el.comment_calls.length > 0) {

							const first = el.comment_calls[0]
							item.isMarked = first.is_marked
						}
						return item;
					}
				);
				this.audit = res.data
				this.auditLoading = false
			})
			await this.loadDocs()
			eventBus.$emit(`loadCommentsCount${this.userId}`, true)
		},
		async addComment() {
			if (this.$refs.commentForm.validate()) {

				let formData = new FormData()
				formData.append(`${this.actionId}`, this.keyId);
				formData.append(`comment`, this.comment);
				formData.append(`category`, 'comment');

				await this.$store.dispatch(`${this.action}/ADD_COMMENT`, formData).then(res => {
					if (res.result === 'success') {
						this.loading = false
						this.loadAudit()
						this.$refs.commentForm.reset()
					} else if (res.result === 'fail') {
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
			}
		},
		openFilePicker() {
			document.getElementById('filePicker').click()
		},
		async onFileChange(fieldName, files) {
			let formData = new FormData()
			formData.append(`${this.actionId}`, this.keyId);

			for (let file of files) {
				formData.append('docs[]', file, file.name)
			}
			try {
				await this.$store.dispatch(`${this.action}/UPLOAD_DOCS`, formData).then(res => {
					if (res.result === 'success') {
						this.loading = false
						this.loadDocs()
					} else if (res.result === 'fail') {
						this.loading = false
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					this.loading = false
					console.log(err)
				});
			} catch (e) {
				console.log("error: ", e);
				this.loading = false
				this.cancel();
			}

		},
		async loadDocs() {
			this.auditLoading = true
			try {
				await this.$store.dispatch('docs/GET_DOCS', `?id=${this.keyId}&type=${this.documentType}&sort_by=id&sort_order=desc`).then(res => {
					if (res.result === 'success') {
						this.auditLoading = false
						this.docs = res.data
					} else if (res.result === 'fail') {
						this.auditLoading = false
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					this.auditLoading = false
					console.log(err)
				});
			} catch (e) {
				console.log("error: ", e);
				this.auditLoading = false
				this.cancel();
			}
		},
		async previewDoc(doc) {
			this.selectedPreview = doc
			this.showPreviewDialog = true
		},
		async deleteDocs(item) {
			this.loading = true
			let ids = [];
			ids.push(item.id);
			await this.$store.dispatch('docs/DELETE_DOCS', { 'documents': ids }).then(res => {
				if (res.result === 'success') {
					this.loading = false
					this.showPreviewDialog = false
					this.loadDocs()
				} else if (res.result === 'fail') {
					this.loading = false
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				this.loading = false
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
	}
}

</script>
