<template>
	<div>
		<div class="date-range">
			<v-btn fab text small class="prev" color="prev grey darken-2" @click="prev">
				<v-icon large>
					mdi-chevron-left
				</v-icon>
			</v-btn>
			<div class="filter-wrap">
				<v-menu ref="menu" v-model="menu" :return-value.sync="date" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
					<template v-slot:activator="{ on, attrs }">
						<v-text-field :value="dateUkFormat(date)" v-bind="attrs" v-on="on" flat prepend-inner-icon="mdil-calendar" autocomplete="off" solo hide-details class="search-input">
							<template v-slot:append>
							</template>
						</v-text-field>
					</template>
					<v-date-picker v-model="date" no-title scrollable>
						<v-spacer></v-spacer>
						<v-btn text color="primary" @click="menu = false">
							Cancel
						</v-btn>
						<v-btn text color="primary" @click="$refs.menu.save(date); loadCalendarData();">
							OK
						</v-btn>
					</v-date-picker>
				</v-menu>
				<v-menu max-width="420" transition="slide-x-transition" :close-on-content-click="false" :nudge-width="420" offset-y left content-class="user-action">
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="b-btn b-advance" depressed v-on="on">
							Advanced <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<div class="advance-sec">
						<div class="head">
							Advanced Search
							<v-chip small class="este-chip yellow-chip float-right" text-color="black" @click="searchKey">
								Filter
							</v-chip>
							<v-chip small color="#221F20" class="este-chip mb-2 float-right" text-color="#FFFFFF" @click="reset">
								Reset
							</v-chip>
						</div>
						<v-divider></v-divider>
						<v-row class="filter-row">
							<v-col cols="12" sm="12" md="12" class="pa-5">
								<span class="heading">Branch:</span>
								<div class="mt-2">
									<template v-for="(branch, index) in branches" v-if="user.user_type_name === 'Admin'">
										<v-chip small class="este-chip tag-chip mb-2" :class="{'selected': branchIndex === index}" text-color="black" @click="branchToggle(branch, index)">
											{{ branch.branch | capitalize }}
										</v-chip>
									</template>
									<template v-for="(branch, index)  in user.permission_branches" v-if="user.user_type_name === 'Staff'">
										<v-chip small class="este-chip tag-chip mb-2" :class="{'selected': branchIndex === index}" text-color="black" @click="branchToggle(branch, index)">
											{{ branch.branch | capitalize }}
										</v-chip>
									</template>
								</div>
								<span class="heading">Treatments:</span>
								<div class="mt-2">
									<v-autocomplete autocomplete="off" multiple placeholder="Filter Treatments" :items="treatments" item-value="id" item-text="name" v-model="filter.treatments" solo outlined flat class="advance-input" hide-details="auto">
										<template v-slot:selection="{ item, index }">
											<v-chip class="este-chip select-chip" v-if="index === 0">
												<span>{{ item.name }}</span>
											</v-chip>
											<span v-if="index === 1" class="grey--text text-caption">
												(+{{ filter.treatments.length - 1 }} others)
											</span>
										</template>
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-autocomplete>
								</div>
								<span class="heading">Consultants:</span>
								<div class="mt-2">
									<v-autocomplete autocomplete="off" multiple placeholder="Filter Consultants" :items="consultants" item-value="id" item-text="name" v-model="filter.consultants" solo outlined flat class="advance-input" hide-details="auto">
										<template v-slot:selection="{ item, index }">
											<v-chip class="este-chip select-chip" v-if="index === 0">
												<span>{{ item.name }}</span>
											</v-chip>
											<span v-if="index === 1" class="grey--text text-caption">
												(+{{ filter.consultants.length - 1 }} others)
											</span>
										</template>
										<template slot="append">
											<v-icon>mdi-chevron-down</v-icon>
										</template>
									</v-autocomplete>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-menu>
			</div>
			<v-btn fab text small class="next" color=" grey darken-2 mt-1 ml-1" @click="next">
				<v-icon large>
					mdi-chevron-right
				</v-icon>
			</v-btn>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import globalFn from '../../mixins/globalFn';
import status from '../../mixins/status';
import eventBus from '../../eventBus.js';
import _ from 'lodash';
export default {
	name: "CalendarSearch",
	mixins: [globalFn, status],
	props: {
		screen: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		}
	},
	data: () => ({
		routeName: '',
		user: JSON.parse(localStorage.getItem('user')),
		date: new Date().toISOString().slice(0, 10),
		menu: false,
		type: 'week',
		labels: {
			month: 'Month',
			week: 'Week',
			category: 'Day'
		},
		filter: {
			branches: [],
			consultants: [],
			treatments: []
		},
		branchIndex: ''
	}),
	computed: {
		branches() {
			if (this.$store.state.branches.allList) {
				return this.$store.state.branches.allList
			}
		},
		treatments() {
			if (this.$store.state.treatments.allList) {
				return this.$store.state.treatments.allList
			}
		},
		consultants() {
			if (this.$store.state.staff.all) {
				this.$store.state.staff.all = this.$store.state.staff.all.filter(function(el) {
					return el.role_type_name === 'Consultant'
				});
				return this.$store.state.staff.all
			}
		},
	},
	created() {
		this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
		this.$store.dispatch('staff/GET_STAFF_LIST', { data: '', params: '' })
		this.$store.dispatch('branches/GET_ALL_BRANCH', '')
		this.routeName = this.$router.currentRoute.params.screen
	},
	mounted() {
		let _this = this
		setTimeout(function() {
			_this.branchIndex = (_this.user.user_type_name === 'Admin') ? _this.branches.findIndex(x => x.id === _this.user.branch_id) : _this.user.permission_branches.findIndex(x => x.id === _this.user.branch_id)
		}, 500);
	},
	methods: {
		formatDate(date) {
			if (!date) return null
			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		async loadCalendarData() {
			eventBus.$emit("loadDataForDateRange", this.dateRange);
		},
		prev() {
			eventBus.$emit("PrevAction", true);
		},
		next() {
			eventBus.$emit("NextAction", true);
		},
		typeViewUpdate(type) {
			this.type = type
			eventBus.$emit("updateCalendarView", type);
		},
		async branchToggle(item, index) {
			this.filter.branches = []
			this.branchIndex = index
			this.filter.branches.push(parseInt(item.id));
		},
		async searchKey() {
			eventBus.$emit('searchCalendarAppointments', this.filter)
		},
		async reset() {
			this.filter.branches = []
			this.filter.consultants = []
			this.filter.treatments = []
			this.branches.forEach(value => {
				value.active = false
			});
			await this.searchKey()
		}
	}
};

</script>
