<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Configurations</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col d-flex align-center justify-center">
                <label style="width: 200px;">
                  <span class="label-txt">
                    Brand Logo
                  </span>
                </label>
                <div class="logo--image ml-4" v-if="configuration.brand_logo">
                  <img class="client--logo" :src="configuration.brand_logo_url" />
                  <div @mouseover="brandOnHover = true" @mouseout="brandOnHover = false" class="icons--wrap d-flex justify-center align-center cursor" :class="brandOnHover ? 'opacity-1' : 'opacity-0'">
                    <CameraIcon class="white--text cursor" @click="$refs.brand.$refs.input.click()" />
                  </div>
                </div>
                <v-btn class="b-small" color="secondary" depressed v-else @click="$refs.brand.$refs.input.click()">
                  <v-icon color="white" size="20" class="mr-2">mdi-plus</v-icon>
                  Add
                </v-btn>
                <v-file-input ref="brand" v-model="brand_logo" prepend-icon="" @change="previewImage('brand_logo')" hide-input></v-file-input>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col d-flex align-center justify-center">
                <label style="width: 200px;">
                  <span class="label-txt">
                    Brand Mini Logo
                  </span>
                </label>
                <div class="logo--image ml-4" v-if="configuration.brand_mini_logo">
                  <img class="client--logo" :src="configuration.brand_mini_logo_url" />
                  <div @mouseover="miniOnHover = true" @mouseout="miniOnHover = false" class="icons--wrap d-flex justify-center align-center cursor" :class="miniOnHover ? 'opacity-1' : 'opacity-0'">
                    <CameraIcon class="white--text cursor" @click="$refs.mini.$refs.input.click()" />
                  </div>
                </div>
                <v-btn class="b-small" color="secondary" depressed v-else @click="$refs.mini.$refs.input.click()">
                  <v-icon color="white" size="20" class="mr-2">mdi-plus</v-icon>
                  Add
                </v-btn>
                <v-file-input ref="mini" v-model="brand_mini_logo" prepend-icon="" @change="previewImage('brand_mini_logo')" hide-input></v-file-input>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col d-flex align-center justify-center">
                <label style="width: 200px;">
                  <span class="label-txt">
                    Email Logo
                  </span>
                </label>
                <div class="logo--image ml-4" v-if="configuration.email_logo">
                  <img class="client--logo" :src="configuration.email_logo_url" />
                  <div @mouseover="emailOnHover = true" @mouseout="emailOnHover = false" class="icons--wrap d-flex justify-center align-center cursor" :class="emailOnHover ? 'opacity-1' : 'opacity-0'">
                    <CameraIcon class="white--text cursor" @click="$refs.email.$refs.input.click()" />
                  </div>
                </div>
                <v-btn class="b-small" color="secondary" depressed v-else @click="$refs.email.$refs.input.click()">
                  <v-icon color="white" size="20" class="mr-2">mdi-plus</v-icon>
                  Add
                </v-btn>
                <v-file-input ref="email" v-model="email_logo" prepend-icon="" @change="previewImage('email_logo')" hide-input></v-file-input>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../../store/action-types.js';
import status from '../../../../mixins/status.js';
import globalFn from '../../../../mixins/globalFn.js';
import { CameraIcon } from "@vue-hero-icons/outline"
export default {
  mixins: [status, globalFn],
  components: {
    CameraIcon
  },
  data: () => ({
    loading: false,
    brandOnHover: false,
    miniOnHover: false,
    emailOnHover: false,
    user: JSON.parse(localStorage.getItem('user')),
    tab: null,
    category: null,
    brand_logo: null,
    brand_mini_logo: null,
    email_logo: null,
    configurationData: {
      brand_logo_url: null,
      brand_mini_logo_url: null,
      email_logo_url: null,
    }
  }),
  computed: {
    configuration() {
      if (this.$store.state.configurations.item) {
        return this.$store.state.configurations.item
      } else {
        return this.configurationData
      }
    }
  },
  methods: {
    previewImage(type) {
      if (type === 'brand_logo') {
        this.configuration.brand_logo_url = URL.createObjectURL(this.brand_logo)
      } else if (type === 'brand_mini_logo') {
        this.configuration.brand_mini_logo_url = URL.createObjectURL(this.brand_mini_logo)
      } else if (type === 'email_logo') {
        this.configuration.email_logo_url = URL.createObjectURL(this.email_logo)
      }
      this.create(type)
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
      this.$store.dispatch('configurations/GET_LOGO', '')
    },
    async create(category) {
      let formData = new FormData()
      formData.append('type', category);
      if (category === 'brand_logo') {
        formData.append('image', this.brand_logo);
      } else if (category === 'brand_mini_logo') {
        formData.append('image', this.brand_mini_logo);
      } else if (category === 'email_logo') {
        formData.append('image', this.email_logo);
      }
      await this.$store.dispatch('configurations/UPLOAD_LOGO', formData).then(response => {
        if (response.result === 'success') {
          this.loading = false
          this.close()
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.loading = false
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        this.loading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    }
  }
};

</script>
