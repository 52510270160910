import { render, staticRenderFns } from "./TheGlobalSearch.vue?vue&type=template&id=3f87596f&scoped=true&"
import script from "./TheGlobalSearch.vue?vue&type=script&lang=js&"
export * from "./TheGlobalSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f87596f",
  null
  
)

export default component.exports