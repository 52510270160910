export default {
  data: () => ({
    editedItem: {}
  }),
  methods: {
    async openDrawer(type, width, path) {
      switch (type) {
        case 'add':
          await this.$store.dispatch('rightSlider/CREATE', {
            componentName: `${path}`,
            props: this.editedItem,
            title: `Add`,
            width: width
          })
          break
        case 'edit':
          await this.$store.dispatch('rightSlider/UPDATE', {
            componentName: `${path}`,
            props: this.editedItem,
            title: `Edit`,
            width: width
          })
          break
        default:
          return false
      }
    }
  }
}
