import collection from '../../../api/collections/EmailScheduler'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    pagination: {}
  },
  mutations: {
    GET_SCHEDULER(state, data) {
      state.list = data
    },
    SET_SCHEDULER_ITEM(state, data) {
      state.item = data
    },
    RESET_SCHEDULER(state, data) {
      state.item = {}
    },
    SET_SCHEDULER_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_SCHEDULER({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload)
        commit('GET_SCHEDULER', data.data)
        return data
      }, commit, dispatch, 'GET_SCHEDULER', true)
    },
    async CREATE_SCHEDULER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_SCHEDULER', true)
      return data
    },
    async DELETE_SCHEDULER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.delete(payload)
        return data
      }, commit, dispatch, 'DELETE_SCHEDULER', true)
      return data
    },
  }
}
