import collection from '../../../api/collections/Leads'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    assignedTo: [],
    all: [],
    item: {},
    widget: {},
    pagination: {}
  },
  mutations: {
    SET_LEAD(state, data) {
      state.list = data
    },
    SET_ASSIGNED_TO(state, data) {
      state.assignedTo = data
    },
    SET_LEADS_LIST(state, data) {
      state.all = data
    },
    SET_LEAD_ITEM(state, data) {
      state.item = data
    },
    RESET_LEAD(state, data) {
      state.item = {}
    },
    SET_WIDGET_COUNT(state, data) {
      state.widget = {}
    },
    SET_LEAD_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_LEAD({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_LEAD', data.data.data)
        return data
      }, commit, dispatch, 'GET_LEAD', true)
    },
    async GET_CALENDAR({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.calendar(payload.data, payload.params)
        // commit('SET_CALENDAR', data.data)
        return data
      }, commit, dispatch, 'GET_CALENDAR', true)
    },
    async GET_LEADS_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.list(payload)
        commit('SET_LEADS_LIST', data.data)
        return data
      }, commit, dispatch, 'GET_LEADS_LIST', true)
    },
    async CREATE_LEAD({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_LEAD', true)
      return data
    },
    async SHOW_LEAD({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_LEAD_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW_LEAD', false)
      return data
    },
    async UPDATE_LEAD({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_LEAD', true)

      return data
    },
    async QUICK_LEAD({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.quickLead(payload)
        return data
      }, commit, dispatch, 'QUICK_LEAD', true)

      return data
    },
    async VALIDATE_LEAD({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.validateLead(payload)
        return data
      }, commit, dispatch, 'VALIDATE_LEAD', true)

      return data
    },
    async ARCHIVE_LEAD({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.archive(payload.id, payload)
        return data
      }, commit, dispatch, 'ARCHIVE_LEAD', true)

      return data
    },
    async CONEVRT_LEAD_TO_CLIENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.convertToClient(payload)
        return data
      }, commit, dispatch, 'CONEVRT_LEAD_TO_CLIENT', true)
    },
    async ADD_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.comment(payload)
        return data
      }, commit, dispatch, 'ADD_COMMENT', true)
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.upload(payload)
        return data
      }, commit, dispatch, 'UPLOAD_DOCS', true)
      return data
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.leadCount(payload)
        commit('SET_WIDGET_COUNT', data.data)
        return data
      }, commit, dispatch, 'WIDGET_COUNT', true)
    },

    async ASSIGNED_TO({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.assignedTo(payload)
        commit('SET_ASSIGNED_TO', data.data)
        return data
      }, commit, dispatch, 'ASSIGNED_TO', true)
    },
  }
}
