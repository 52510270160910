var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('div',{staticClass:"user-name"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(item.name))+"\n\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(!item.name && item.social_handle)?_c('div',{staticClass:"d-flex user-name"},[(item.source_name !== null)?_c('img',{staticClass:"mr-2",attrs:{"src":("/images/" + (item.source_name.toLowerCase()) + ".svg")}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t@"+_vm._s(item.social_handle)+"\n\t\t\t\t\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"user-location"},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(item.branch_name)+"\n\t\t\t\t\t\t\t")])]}},{key:"item.consultant",fn:function(ref){
var item = ref.item;
return [_vm._l((item.phone),function(item){return [_vm._l((item.calls),function(value,index){return [_c('div',{staticClass:"my-6 height-60"},[_c('div',{staticClass:"user-name"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(value.owner_name))+"\n\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),(value.branch_name)?_c('p',{staticClass:"user-location"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(value.branch_name)+"\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e()])]})]})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._l((item.phone),function(item){return [_vm._l((item.calls),function(value,index){return [_c('div',{staticClass:"d-flex my-6 height-60"},[_c('ReadMore',{attrs:{"comment":item.comment}}),_vm._v(" "),_c('v-btn',{staticClass:"badge badge-grey ml-2",attrs:{"icon":""}},[(value.is_marked)?_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdi-phone-check-outline")]):_vm._e(),_vm._v(" "),(!value.is_marked)?_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdi-phone-off-outline")]):_vm._e()],1)],1)]})]})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._l((item.phone),function(item){return [_vm._l((item.calls),function(value,index){return [(value.created_at !== null)?_c('p',{staticClass:"date-sec date-mute my-6 height-60"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdil-calendar")])],1),_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("moment")(value.created_at,"MMMM Do YYYY"))+" @ "+_vm._s(_vm._f("moment")(value.created_at,"h:mm"))+"\n\t\t\t\t\t\t\t\t\t")],1):_vm._e()]})]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")])])])])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }