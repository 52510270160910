export default {
	data: () => ({
		widgets: [{
				name: 'Urgent',
				active: false,
				slug: 'urgent',
				module: ['opportunities', 'client'],
				svg: '/images/icons/duotune/general/mail-open.svg'
			}, {
				name: 'New Opportunities',
				active: false,
				slug: 'new',
				module: ['opportunities'],
				svg: '/images/icons/duotune/general/user.svg'
			},
			{
				name: 'Unassigned Opportunities',
				active: false,
				slug: 'unassigned',
				module: ['opportunities'],
				svg: '/images/icons/duotune/general/gen016.svg'
			},
			{
				name: 'Follow-Ups Today',
				active: false,
				slug: 'followup_today',
				module: ['opportunities'],
				svg: '/images/icons/duotune/general/chat.svg'
			}, {
				name: 'Follow-Ups Overdue',
				active: false,
				slug: 'followup_overdue',
				module: ['opportunities'],
				svg: '/images/icons/duotune/general/chat.svg'
			}
		]
	}),
	methods: {
		async loadCount() {
			let params = {}
			params.enquiry_from = this.payload.filter.enquiry_from
			params.enquiry_to = this.payload.filter.enquiry_to
			params.created_at_from = this.payload.filter.created_at_from
			params.created_at_to = this.payload.filter.created_at_to
			params.followup_from = this.payload.filter.followup_from
			params.followup_from_time = this.payload.filter.followup_from_time
			params.followup_to = this.payload.filter.followup_to
			params.followup_to_time = this.payload.filter.followup_to_time
			params.appointment_from = this.payload.filter.appointment_from
			params.appointment_to = this.payload.filter.appointment_to
			params.satisfaction = this.payload.filter.satisfaction
			if(this.payload.widget_filter === 'urgent'){
				params.status = []
			}else{
				params.status = this.payload.filter.status
			}
			params.branch = this.payload.filter.branch
			params.source = this.payload.filter.source
			params.assigned = this.payload.filter.assigned
			params.assigned_to = this.payload.filter.assigned_to
			params.source_blank = this.payload.filter.source_blank
			let counts = {}
			await this.$store.dispatch('opportunities/WIDGET_COUNT', params).then(res => {
				counts = res.data
			})
			this.widgets = this.widgets.map(
				function(el) {
					let item = Object.assign({}, el);
					item.count = (counts.hasOwnProperty(el.slug)) ? counts[el.slug] : 0
					return item;
				}
			);
		},
	}

}
