<template>
	<div>
		<div class="cell-item" v-for="item in appointments">
			<v-row dense class="e-row">
				<v-col cols="6" md="6">
					<template v-for="tag in item.treatments">
						<v-chip small class="este-chip tag-chip" text-color="black">
							{{ tag.name | capitalize }}
						</v-chip>
					</template>
				</v-col>
				<v-col cols="6" md="6">
					<span class="cell-date">Appointment Date:
						<span>{{ item.appointment_date | moment("DD/MM/YY") }}</span>
						<span v-if="item.appointment_from_time !== null">:{{ item.appointment_from_time }}</span>
						<span v-if="item.appointment_to_time !== null"> - {{ item.appointment_to_time}}</span>
					</span>
				</v-col>
			</v-row>
			<v-row dense class="e-row">
				<v-col cols="6" md="9">
					<div class="d-flex align-center justify-space-between">
						<div>Appointment Type :
							<v-chip small class="este-chip status-chip" :text-color="item.appointment_type === 'Consultation' ? 'white' : 'black'" :color="item.appointment_type === 'Consultation' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(223, 180, 71, 0.4)'">
								{{ item.appointment_type | capitalize }}
							</v-chip>
						</div>
						<div>Status:
							<template v-for="stat in SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('appointment')}))" v-if="stat.id === item.status">
								<v-chip small class="este-chip" :class="stat.outline ? 'tag-chip' : 'status-chip'" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color">
									{{ stat.name | capitalize }}
								</v-chip>
							</template>
						</div>
						<div class="d-flex align-center">Responsible:
							<div class="ml-2 profile-icon" alt="avatar" v-if="item.consultant_name !== null">
								<p>{{ getInitials(item.consultant_name)}}</p>
							</div>
						</div>
					</div>
				</v-col>
				<v-col cols="6" md="3">
					<v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="float-right" icon color="rgba(0, 0, 0, 0.6)" v-on="on">
								<v-icon large>mdi-dots-vertical</v-icon>
							</v-btn>
						</template>
						<div class="logout-block">
							<ul>
								<li><a @click="editItem(item)">Edit</a></li>
							</ul>
						</div>
					</v-menu>
				</v-col>
			</v-row>
		</div>
		<v-navigation-drawer class="este-drawer" v-model="drawer" stateless app right fixed temporary width="750">
			<edit-appointment></edit-appointment>
		</v-navigation-drawer>
	</div>
</template>
<script>
import globalFn from '../../mixins/globalFn.js';
import sliderFn from '../../mixins/sliderFn.js';
import status from '../../mixins/status.js';
import EditAppointment from '../../templates/forms/leads/EditAppointment'
export default {
	mixins: [globalFn, status, sliderFn],
	props: {
		userId: {
			required: true
		},
		keyId: {
			required: true
		},
		itemData: {
			required: true
		}
	},
	components: {
		EditAppointment
	},
	data() {
		return {
			drawer: false,
			appointments: []
		}
	},
	mounted() {
		this.$root.$on('toggleApppointmentDrawer', this.toggleApppointmentDrawer)
		this.loadAppointments()
	},
	methods: {
		async editItem(item) {
			await this.$store.dispatch('appointments/SHOW_APPOINTMENT', { id: item.id, param: '?attributes=user,treatments,consultations,consultant,branch' }).then(res => {
				this.drawer = true
			})
		},
		async loadAppointments() {
			this.appointments = []
			await this.$store.dispatch('appointments/APPOINTMENTS_BY_USERID', this.userId).then(res => {
				this.appointments = res.data
			})
		},
		async toggleApppointmentDrawer() {
			this.drawer = false
			await this.loadAppointments()
		}
	}
}

</script>
