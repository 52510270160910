<template>
	<v-card flat>
		<v-toolbar flat height="90px" class="tab__toolbar" :class="responsiveClass">
			<v-toolbar-title>
				<div class="form-title">Add Support Ticket</div>
			</v-toolbar-title>
		</v-toolbar>
		<v-tabs v-model="navigationTab" :vertical="vertical" class="este__tabs" :class="responsiveClass">
			<v-tabs-slider class="cust-tabs-slider" v-if="!vertical"></v-tabs-slider>
			<template v-for="(item, index) in navigation">
				<v-tab :href="`#${item.slug}`">
					<v-icon left>
						{{item.icon}}
					</v-icon>
					{{item.name}}
				</v-tab>
			</template>
		</v-tabs>
		<div class="tab__content__wrapper">
			<v-tabs-items v-model="navigationTab">
				<v-tab-item value="ticket" :eager="true">
					<div class="content__text">
						<v-form ref="complaintForm">
							<v-row dense class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<div>
										<label>
											<span class="label-txt">Support Ticket Type:</span>
										</label>
									</div>
									<div class="d-flex align-center justify-space-between">
										<template v-for="(item,index) in ticketTypes">
											<div :class="['ticket--chip', { 'active': ticketIndex === index  }]" @click="updateTicketType(item, index)">
												{{item.label}}
											</div>
										</template>
									</div>
								</v-col>
							</v-row>
							<v-row dense class="e-row">
								<v-col cols="12" md="12" class="e-col">
									<div>
										<label>
											<span class="label-txt">Select Contact:</span>
										</label>
									</div>
									<template v-if="!isContactSelected">
										<v-text-field flat rounded prepend-inner-icon="mdi-magnify" :rules="rules.user_id" autocomplete="off" label="Search for a contact…" class="solo-cust" clearable clear-icon="mdi-close-circle-outline" solo hide-details="auto" @input="conatctTyping = true" :loading="loading" v-model="contactSearch">
										</v-text-field>
										<ul class="contact--list" :class="(results.length > 20) ? 'height-300' : ''" v-if="results.length > 0">
											<li v-for="(item,index) in results" :key="index">
												<a @click="findContact(item)" class="d-flex align-center flex-row cursor">
													<span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="item">
														<span v-if="item.name">{{ item.name }} </span>
														<span v-if="item.name === null">{{ item.social_handle }} </span>
														<span v-if="item.contact_no">/ {{ item.contact_no }} </span>
														<span v-if="item.email">/ {{ item.email }} </span>
													</span>
													<span class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
														{{ item.user_type_name === "Lead" ? "L" : "C" }}
													</span>
												</a>
											</li>
										</ul>
									</template>
									<template v-if="isContactSelected">
										<v-text-field flat :readonly="true" rounded @click:clear="isContactSelected = false;" autocomplete="off" class="solo-cust" clearable clear-icon="mdi-close-circle-outline" solo hide-details :value="formatContact">
										</v-text-field>
									</template>
								</v-col>
							</v-row>
							<template v-if="ticketType === 'complaint'">
								<div class="complaint--wrap">
									<v-row class="e-row">
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Date Raised
												</span>
											</label>
											<v-menu v-model="dateRaisedMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
												<template v-slot:activator="{ on }">
													<v-text-field autocomplete="off" :value="dateUkFormat(complaintForm.date_raised)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
												</template>
												<v-date-picker v-model="complaintForm.date_raised" no-title @input="dateRaisedMenu = false"></v-date-picker>
											</v-menu>
										</v-col>
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Complaint Type
												</span>
											</label>
											<v-select autocomplete="off" :items="category" v-model="complaintForm.category_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Escalation Level
												</span>
											</label>
											<v-select autocomplete="off" :items="escalation" v-model="complaintForm.escalation_level" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Responsible
												</span>
											</label>
											<v-select autocomplete="off" :items="staff" :rules="rules.responsible_id" v-model="complaintForm.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Complaint Overview
												</span>
											</label>
											<v-textarea class="text-area" solo outlined v-model="complaintForm.description" flat hide-details="auto" rows="5" row-height="25" placeholder="Base of complaint, who it was raised with, what it relates to, actions take so far"></v-textarea>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Deals List
												</span>
											</label>
											<v-select autocomplete="off" :items="dealsbyclient" v-model="complaintForm.deal_id" item-text="deal_info" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Pabau ID / Link
												</span>
											</label>
											<v-text-field autocomplete="off" solo outlined flat v-model="complaintForm.pabau_id" hide-details="auto" class="solo-cust mb-2"></v-text-field>
										</v-col>
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Any medical conditions?
												</span>
											</label>
											<v-select autocomplete="off" :items="medical_conditions" v-model="complaintForm.medical_condition" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Machine Used
												</span>
											</label>
											<v-textarea class="text-area" solo outlined v-model="complaintForm.machine_used" flat hide-details="auto" rows="5" row-height="25" placeholder="Machine, temperature, any client comments/concerns on higher/lower temperature from client, side effects mentioned."></v-textarea>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Products Sold
												</span>
											</label>
											<v-textarea class="text-area" solo outlined v-model="complaintForm.products_sold" flat hide-details="auto" rows="5" row-height="25" placeholder="Items that were sold, why where they sold, were they part of the package or additional."></v-textarea>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Were complimentary sessions offered?
												</span>
											</label>
											<v-textarea class="text-area" solo outlined v-model="complaintForm.complimentary_sessions" flat hide-details="auto" rows="5" row-height="25" placeholder="Details of what was offered, why they were offered, what was the outcome."></v-textarea>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Next Action
												</span>
											</label>
											<v-select autocomplete="off" :items="nextActions" v-model="complaintForm.next_action" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Next Action Date
												</span>
											</label>
											<v-menu v-model="nextActionDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
												<template v-slot:activator="{ on }">
													<v-text-field autocomplete="off" :value="dateUkFormat(complaintForm.next_action_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
												</template>
												<v-date-picker v-model="complaintForm.next_action_date" no-title @input="nextActionDateMenu = false"></v-date-picker>
											</v-menu>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Preferred Branch:
												</span>
												<v-select autocomplete="off" :items="branches" item-value="id" item-text="branch" :rules="rules.branch_id" v-model="complaintForm.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
													<template slot="append">
														<v-icon>mdi-chevron-down</v-icon>
													</template>
												</v-select>
											</label>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Urgency
												</span>
											</label>
											<v-select autocomplete="off" :items="priority" v-model="complaintForm.urgency" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Status
												</span>
											</label>
											<v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('complaint')}))" v-model="complaintForm.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
								</div>
							</template>
							<template v-if="ticketType === 'advice' || ticketType === 'information'">
								<div class="callback--wrap">
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Treatment / Service:
												</span>
											</label>
											<v-autocomplete v-model="complaintForm.treatment_id" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-autocomplete>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Call Back Date:
												</span>
												<v-menu v-model="callbackDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
													<template v-slot:activator="{ on }">
														<v-text-field autocomplete="off" :value="dateUkFormat(complaintForm.callback_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
													</template>
													<v-date-picker v-model="complaintForm.callback_date" no-title @input="callbackDateMenu = false;"></v-date-picker>
												</v-menu>
											</label>
										</v-col>
										<v-col cols="12" md="6" class="e-col">
											<label>
												<span class="label-txt"> Call Back Time:</span>
												<v-menu ref="callbackTimeMenu" v-model="callbackTimeMenu" :close-on-content-click="false" :return-value.sync="complaintForm.callback_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
													<template v-slot:activator="{ on, attrs }">
														<v-text-field outlined flat hide-details="auto" solo class="solo-cust" v-model="complaintForm.callback_time" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
													</template>
													<v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="callbackTimeMenu" v-model="complaintForm.callback_time" full-width @click:minute="$refs.callbackTimeMenu.save(complaintForm.callback_time)"></v-time-picker>
												</v-menu>
											</label>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Preferred Branch:
												</span>
												<v-select autocomplete="off" :items="branches" item-value="id" item-text="branch" :rules="rules.branch_id" v-model="complaintForm.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
													<template slot="append">
														<v-icon>mdi-chevron-down</v-icon>
													</template>
												</v-select>
											</label>
										</v-col>
										<v-col cols="6" md="6" class="e-col">
											<label>
												<span class="label-txt">
													Urgency
												</span>
											</label>
											<v-select autocomplete="off" :items="priority" v-model="complaintForm.urgency" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Select Contact
												</span>
												<v-select autocomplete="off" :items="staff" v-model="complaintForm.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
													<template slot="append">
														<v-icon>mdi-chevron-down</v-icon>
													</template>
												</v-select>
											</label>
										</v-col>
									</v-row>
									<v-row class="e-row">
										<v-col cols="12" md="12" class="e-col">
											<label>
												<span class="label-txt">
													Status
												</span>
											</label>
											<v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('callback')}))" v-model="complaintForm.status" :rules="rules.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</v-col>
									</v-row>
								</div>
							</template>
						</v-form>
					</div>
				</v-tab-item>
			</v-tabs-items>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
					Cancel
				</v-btn>
				<v-btn color="secondary" class="b-blue" depressed rounded x-large @click="create">
					Add
				</v-btn>
			</div>
		</div>
	</v-card>
</template>
<script>
import globalFn from '../../../mixins/globalFn';
import status from '../../../mixins/status.js';
export default {
	mixins: [globalFn, status],
	data: () => ({
		loading: false,
		staff: [],
		navigationTab: null,
		ticketIndex: null,
		actionName: null,
		dateRaisedMenu: false,
		nextActionDateMenu: false,
		callbackDateMenu: false,
		callbackTimeMenu: false,
		navigation: [{
			name: 'Ticket Details',
			icon: 'mdil-account',
			slug: 'ticket',
			module: 'ticket',
			form: true
		}],
		ticketType: null,
		opportunity_id: 0,
		complaintForm: {
			user_id: 0,
			deal_id: null,
			support_ticket_type: null,
			date_raised: null,
			category_id: 0,
			description: null,
			next_action: 0,
			next_action_date: null,
			responsible_id: 0,
			urgency: 0,
			status: 9,
			complimentary_sessions: null,
			escalation_level: null,
			products_sold: null,
			machine_used: null,
			pabau_id: null,
			medical_condition: null,
			treatment_id: [],
			callback_date: null,
			callback_time: null,
			branch_id: null,
		},
		rules: {
			user_id: [
				v => !!v || 'Please select contact'
			],
			branch_id: [
				v => !!v || 'Please select branch'
			],
			status: [
				v => !!v || 'Please select status'
			],
			responsible_id: [
				v => !!v || 'Please select responsible person'
			]
		},
		staffParams: {
			branch: '',
			type: ''
		},
		contacts: [],
		isContactSelected: false,
		conatctTyping: false,
		contactSearch: null,
		results: [],
		selectedContact: null
	}),
	watch: {
		contactSearch: _.debounce(function() {
			this.conatctTyping = false;
		}, 1000),
		conatctTyping: function(value) {
			if (!value) {
				this.globalSearchAPI()
			}
		}
	},
	computed: {
		responsiveClass() {
			return (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') ? 'lg' : 'md'
		},
		vertical() {
			return (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') ? true : false
		},
		treatments() {
			if (this.$store.state.treatments.allList) {
				return this.$store.state.treatments.allList
			}
		},
		branches() {
			if (this.$store.state.branches.allList) {
				return this.$store.state.branches.allList
			}
		},
		source() {
			if (this.$store.state.source.list) {
				return this.$store.state.source.list
			}
		},
		nextActions() {
			if (this.$store.state.complaints.action) {
				return this.$store.state.complaints.action
			}
		},
		priority() {
			if (this.$store.state.complaints.priority) {
				return this.$store.state.complaints.priority
			}
		},
		category() {
			if (this.$store.state.complaints.category) {
				return this.$store.state.complaints.category
			}
		},
		escalation() {
			if (this.$store.state.complaints.escalation) {
				return this.$store.state.complaints.escalation
			}
		},
		dealsbyclient() {
			if (this.$store.state.deals.all) {
				return this.$store.state.deals.all
			}
		},
		formatContact() {

			if (this.selectedContact) {

				const fullname = [this.selectedContact.name, this.selectedContact.email, this.selectedContact.contact_no];
				const trimFullName = fullname.filter(function(el) {
					return el != null;
				});
				return trimFullName.join('/')
			} else {
				return ''

			}
		}
	},
	created() {
		this.$store.dispatch('source/GET_SOURCE', '')
		this.$store.dispatch('complaints/GET_PRIORITY')
	},
	methods: {
		async globalSearchAPI() {
			let data = {}
			data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22]
			data.page_size = 1000
			data.search = this.contactSearch
			this.loading = true
			await this.$store.dispatch('user/GLOBAL_SEARCH', data).then(res => {
				this.results = res.data.data
				this.loading = false
			})

			await this.setUserId()
			if (this.ticketType === 'complaint') {
				await this.loadDealsByClient()
			}
		},
		async findContact(item) {
			this.isContactSelected = true
			this.selectedContact = item
			this.complaintForm.user_id = item.id
		},
		async setUserId() {
			await this.$store.dispatch('user/GET_USER', `?user_id=${this.complaintForm.user_id}`).then(res => {
				this.complaintForm.branch_id = res.data.branch_id
				this.staffParams.branch = res.data.branch_id
				this.complaintForm.treatment_id = []
				if (res.data.treatments) {
					res.data.treatments.forEach(value => {
						this.complaintForm.treatment_id.push(value.id);
					});
				}
			})

			await this.getStaffList()
		},
		async staffByBranch() {
			this.staffParams.branch = this.complaintForm.branch_id
			await this.getStaffList()
		},
		async getStaffList() {
			this.staff = []
			await this.$store.dispatch('staff/GET_STAFF_LIST', { data: this.staffParams, params: '' }).then(response => {
				if (response.result === 'success') {
					this.staff = response.data
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
		handleFunction(funName) {
			this[funName]()
		},
		allowedStep: m => m % 15 === 0,
		updateTicketType(item, index) {
			this.ticketIndex = index;
			this.ticketType = item.id

			if (this.ticketType === 'complaint') {
				this.$store.dispatch('complaints/GET_NEXT_ACTION')
				this.$store.dispatch('complaints/GET_CATEGORY')
				this.$store.dispatch('complaints/GET_ESCALATION')
				this.getStaffList()
				this.actionName = 'addComplaint'

			} else {
				this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
				this.$store.dispatch('branches/GET_ALL_BRANCH', '')
				this.actionName = 'addCallback'
				this.complaintForm.callback_date = new Date().toISOString().slice(0, 10)
			}
		},
		close() {
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		async loadDealsByClient() {
			await this.$store.dispatch('deals/GET_DEAL_LIST', { data: { client_id: this.complaintForm.user_id }, params: '' })
		},

		loadData() {
			this.$root.$emit("SupportTicketsTableUpdate", "");
			this.$store.commit("rightSlider/RESET_SLIDER");
		},

		async create() {
			this.complaintForm.support_ticket_type = this.ticketType
			if (this.$refs.complaintForm.validate()) {
				this.loading = true
				await this.$store.dispatch('complaints/CREATE_COMPLAINT', this.complaintForm).then(response => {
					if (response.result === 'success') {
						this.loading = false
						this.loadData()
					} else if (response.result === 'fail') {
						response.type = 'error'
						this.loading = false
						this.$store.dispatch('snackbar/SHOW', response)
					}
				}).catch(err => {
					this.loading = false
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
			}
		}

	}
}

</script>
