var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticClass:"action mr-3",on:{"click":function($event){_vm.ShowAddPaymentForm = !_vm.ShowAddPaymentForm}}},[_c('v-icon',{staticClass:"mt-n1 mr-2",attrs:{"size":"19"}},[_vm._v("mdil-plus-circle")]),_vm._v("\n\t\tAdd Payment\n\t")],1),_vm._v(" "),(_vm.ShowAddPaymentForm)?_c('add-payment',{attrs:{"dealId":_vm.dealId,"dealTreatments":_vm.treatments}}):_vm._e(),_vm._v(" "),_c('v-data-table',{staticClass:"inner-table",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"item-class":_vm.rowClass,"hide-default-footer":true,"dense":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v("\n\t\t\t"+_vm._s(_vm._f("moment")(item.date,"DD/MM/YY"))+"\n\t\t")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [(item.method === 7)?_c('span',{staticClass:"red--text"},[_vm._v("- "+_vm._s(_vm._f("currency")(item.total,'£')))]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.total,'£')))])]}},{key:"item.taken_by",fn:function(props){return [(props.item.status !== 2 && props.item.method !== 7)?_c('v-edit-dialog',{attrs:{"content-class":"edit-payment","large":"","persistent":""},on:{"save":function($event){return _vm.save(props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"my-4 body-2"},[_vm._v("\n\t\t\t\t\t\tUpdate Taken By\n\t\t\t\t\t")]),_vm._v(" "),_c('v-select',{staticClass:"solo-cust",attrs:{"autocomplete":"off","items":_vm.staff,"item-text":"name","item-value":"id","solo":"","outlined":"","flat":"","hide-details":"auto"},model:{value:(props.item.take_by),callback:function ($$v) {_vm.$set(props.item, "take_by", $$v)},expression:"props.item.take_by"}},[_c('template',{slot:"append"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],2)]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(props.item.taken_by.name)+"\n\t\t\t\t\t"),_c('PencilIcon',{attrs:{"size":"12"}})],1)]):[(props.item.taken_by && props.item.taken_by.name)?_c('span',[_vm._v(_vm._s(props.item.taken_by.name))]):_vm._e()]]}},{key:"item.points_earned",fn:function(ref){
var item = ref.item;
return [(item.method !== 5 && item.method !== 7)?_c('span',{staticClass:"green--text"},[_vm._v("+\n\t\t\t\t"+_vm._s(item.points_earned))]):_vm._e(),_vm._v(" "),(item.method === 5 || item.method === 7)?_c('span',{staticClass:"red--text"},[_vm._v("-\n\t\t\t\t"+_vm._s(item.points_earned))]):_vm._e()]}},{key:"item.balance",fn:function(ref){
var item = ref.item;
return [(item.method === 7)?_c('span',{staticClass:"red--text"},[_vm._v("-"+_vm._s(_vm._f("currency")(item.balance,'£')))]):_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.balance,'£')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status !== 2 && item.method !== 7 && _vm.hasPermission({ module: 'payment_delete'}))?_c('v-btn',{attrs:{"icon":"","loading":item.loading},on:{"click":function($event){return _vm.deletePayment(item)}}},[_c('v-icon',{staticClass:"delete-deal",attrs:{"color":"rgba(0, 0, 0, 0.2)"}},[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }