import http from '../../http/common'

const resource = '/statistics'

export default {

	count: (payload) => http.get(`${resource}/count`, payload),

	commentCount: (params) => http.get(`${resource}/comment-count${params}`),
}
