import http from '../../http/common'

const resource = '/email-scheduler'

export default {

	create: (payload) => http.post(resource, payload),

	get: (payload) => http.get(`${resource}${payload}`),

	delete: (id) => http.delete(`${resource}/${id}`),
}
