<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Assign Row Payment</div>
        <div class="tab-content-wrapper">
          <v-form ref="form">
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <div>
                  <label>
                    <span class="label-txt">Find Deal</span>
                  </label>
                  <v-autocomplete :rules="rules.deal_id" v-model="form.deal_id" :items="deals" item-text="deal_info" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="getPayments">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <v-data-table :headers="headers" :items="payments" :loading="loading" :hide-default-footer="true" dense class="inner-table">
                  <template v-slot:item.date="{ item }">
                    {{item.date | moment("DD/MM/YY")}}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{item.total | currency('£')}}
                  </template>
                  <template v-slot:item.taken_by="props">
                    <v-edit-dialog content-class="edit-payment" large persistent @save="save(props.item)" v-if="props.item.status !== 2 && props.item.method !== 7">
                      <div>{{ props.item.taken_by.name }}
                        <PencilIcon size="12" />
                      </div>
                      <template v-slot:input>
                        <div class="my-4 body-2">
                          Update Taken By
                        </div>
                        <v-select autocomplete="off" v-model="props.item.take_by" :items="staff" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </template>
                    </v-edit-dialog>
                    <template v-else>
                      <span v-if="props.item.taken_by && props.item.taken_by.name">{{props.item.taken_by.name}}</span>
                    </template>
                  </template>
                  <template v-slot:item.balance="{ item }">
                    {{item.balance | currency('£')}}
                  </template>
                  <template v-slot:item.points_earned="{ item }">
                    <span class="green--text" v-if="item.method !== 5">+
                      {{item.points_earned}}</span>
                    <span class="red--text" v-if="item.method === 5">-
                      {{item.points_earned}}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn icon @click="deletePayment(item)" :loading="item.loading">
                      <v-icon class="delete-deal" color="rgba(0, 0, 0, 0.2)">mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-col cols="12" md="3" class="e-col">
                <label>
                  <span class="label-txt">
                    Total
                  </span>
                </label>
                <v-text-field v-model="form.total" autocomplete="off" solo outlined flat hide-details="auto" class="solo-cust" placeholder="£1999"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="e-col">
                <label>
                  <span class="label-txt">
                    Taken By
                  </span>
                </label>
                <v-select autocomplete="off" v-model="form.take_by" :items="staff" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="3" class="e-col">
                <label>
                  <span class="label-txt">
                    Method
                  </span>
                </label>
                <v-select autocomplete="off" v-model="form.method" :items="paymentMethods" item-value="id" item-text="name" solo outlined flat class="solo-cust" hide-details="auto">
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" md="3" class="e-col">
                <label>
                  <span class="label-txt">
                    Date
                  </span>
                </label>
                <v-menu v-model="paymentDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field autocomplete="off" :value="dateUkFormat(form.date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
                  </template>
                  <v-date-picker v-model="form.date" no-title @input="paymentDateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <div class="d-flex align-center justify-end">
              <v-btn class="cancel-btn btn-gap" depressed rounded outlined x-large @click="updateStatus">
                Mark as Synced
              </v-btn>
              <v-btn color="secondary" class="b-blue text-capitalize" depressed rounded x-large @click="connect">
                Connect Payment
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Close
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import globalFn from '../../../mixins/globalFn.js';
import { PencilIcon } from "@vue-hero-icons/outline"
export default {
  components: {
    PencilIcon
  },
  data: () => ({
    loading: false,
    deleteLoading: false,
    user: JSON.parse(localStorage.getItem('user')),
    paymentDateMenu: false,
    form: {
      deal_id: '',
      method: 6,
      total: '',
      date: new Date().toISOString().substr(0, 10),
      take_by: null
    },
    rules: {
      deal_id: [
        v => !!v || 'Please select deal'
      ],
      method: [
        v => !!v || 'Please select method'
      ],
      total: [
        v => !!v || 'Please enter total value'
      ],
    },
    payments: [],
    staffParams: {
      branch: '',
      type: ''
    },
    headers: [{
        text: 'Payment Date',
        value: 'date',
        sortable: false,
      },
      { text: 'Method', value: 'payment_method', sortable: false, },
      { text: '£', value: 'total', sortable: false, },
      { text: 'Taken By', value: 'taken_by', align: 'right', sortable: false, },
      { text: 'Balance', value: 'balance', align: 'right', sortable: false, },
      { text: 'Points', value: 'points_earned', align: 'right', sortable: false, },
      { text: '', value: 'actions', align: 'end', sortable: false, },
    ],
  }),
  mixins: [globalFn],
  computed: {
    sale() {
      if (this.$store.state.sales.item) {
        return this.$store.state.sales.item
      }
    },
    deals() {
      if (this.$store.state.deals.all) {
        return this.$store.state.deals.all
      }
    },
    staff() {
      if (this.$store.state.staff.all) {
        return this.$store.state.staff.all
      }
    },
  },
  created() {
    this.$store.dispatch('staff/GET_STAFF_LIST', { data: this.staffParams, params: '' })
  },
  mounted() {
    this.loadAllDeals()
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("salesTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async loadAllDeals() {
      await this.$store.dispatch('deals/GET_DEAL_LIST', { data: { client_id: this.sale.user_id, }, params: '' })
      this.form.total = this.sale.amount_paid
      this.form.date = this.sale.payment_date
    },
    async connect() {
      let formData = {}
      formData.deal_id = this.form.deal_id
      formData.method = this.form.method
      formData.total = this.form.total
      formData.date = this.form.date
      formData.take_by = this.form.take_by
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.$store.dispatch('payments/CREATE_PAYMENT', formData).then(response => {
          if (response.result === 'success') {
            this.loading = false
            this.markAsSynced()
          } else if (response.result === 'fail') {
            response.type = 'error'
            this.loading = false
            this.$store.dispatch('snackbar/SHOW', response)
          }
        }).catch(err => {
          this.loading = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        });
      }
    },

    async updateStatus() {
      if (this.$refs.form.validate()) {
        this.markAsSynced()
      }
    },

    async markAsSynced() {
      await this.$store.dispatch('sales/UPDATE_STATUS', { id: this.sale.id, status: 36 }).then(res => {
        if (res.result === 'success') {
          this.disabled = false
          this.loadData()
        } else if (res.result === 'fail') {
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },

    async getPayments() {
      this.loading = true
      await this.$store.dispatch('deals/SHOW_DEAL', { id: this.form.deal_id, params: `?attributes=payments` }).then(res => {
        this.payments = res.data.payments.map(
          function(el) {
            let item = Object.assign({}, el);
            item.loading = false
            return item;
          }
        );
        this.loading = false
      })
    },

    async deletePayment(item) {
      item.loading = true
      await this.$store.dispatch('payments/DELETE_PAYMENT', item.id).then(response => {
        if (response.result === 'success') {
          item.loading = false
          this.getPayments()
        } else if (response.result === 'fail') {
          response.type = 'error'
          item.loading = false
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        item.loading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },

    async save(item) {
      let formData = {}
      formData.id = item.id
      formData.deal_id = item.deal_id
      formData.total = item.total
      formData.method = item.method
      formData.take_by = item.take_by
      await this.$store.dispatch('payments/UPDATE_PAYMENT', formData).then(response => {
        if (response.result === 'success') {
          this.loading = false
          this.getPayments()
        } else if (response.result === 'fail') {
          response.type = 'error'
          item.loading = false
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        item.loading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    }
  }
};

</script>
