import collection from '../../../api/collections/Statistics'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    comments: {},
    pagination: {}
  },
  mutations: {
    SET_COUNT(state, data) {
      state.item = data
    },
    SET_COMMENT_COUNT(state, data) {
      state.comments = data
    },
  },
  actions: {
    async GET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.count(payload)
        commit('SET_COUNT', data.data)
        return data
      }, commit, dispatch, 'GET_COUNT', true)
    },
    async GET_COMMENT_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.commentCount(payload)
        commit('SET_COMMENT_COUNT', data.data)
        return data
      }, commit, dispatch, 'GET_COMMENT_COUNT', true)
    }
  }
}
