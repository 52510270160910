<template>
	<div class="d-flex">
		<v-btn href="https://video.drift.com/v/abkLsHEYG95/" target="_blank" class="b-btn b-small mr-4" color="red" depressed v-if="!$vuetify.breakpoint.smAndDown && showVideoTraining === 'true'">
			Video Training
		</v-btn>
		<v-text-field flat prepend-inner-icon="mdi-magnify" autocomplete="off" label="Search for an offer…" solo hide-details class="search-input" @input="isTyping = true" v-model="search">
			<template v-slot:append>
				<v-menu :close-on-content-click="false" offset-y left>
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="b-btn b-advance bg-white-100" depressed v-on="on">
							Advanced <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<div class="advance-sec">
						<div class="head">
							Advanced Search
							<v-chip small class="este-chip yellow-chip float-right" text-color="black" @click="searchKey">
								Filter
							</v-chip>
							<v-chip small color="#221F20" class="este-chip mb-2 float-right" text-color="#FFFFFF" @click="reset">
								Reset
							</v-chip>
						</div>
						<v-divider></v-divider>
						<v-row class="filter-row">
							<v-col cols="12" md="4" sm="6" lg="4" xl="4">
								<span class="heading">Date Range:</span>
								<div class="d-flex mt-2 mb-4">
									<v-menu v-model="enquiryDateFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.start_date)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date from" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.start_date" no-title @input="enquiryDateFromMenu = false"></v-date-picker>
									</v-menu>
									<v-menu v-model="enquiryDateToMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.end_date)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date to" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.end_date" no-title @input="enquiryDateToMenu = false"></v-date-picker>
									</v-menu>
								</div>
							</v-col>
							<v-divider vertical></v-divider>
							<v-col cols="12" md="4" sm="6" lg="4" xl="4">
								<span class="heading">Status:
									<v-checkbox hide-details class="shrink mt-0" v-model="StatusToggle" @click="handleFunction('StatusCheckUncheck')" :ripple="false"></v-checkbox>
								</span>
								<div class="mt-2">
									<template v-for="stat in StatusDefault">
										<v-chip small class="este-chip status-chip mb-2" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color" @click="statusToggle(stat)">
											{{ stat.name | capitalize }}
											<v-icon right color="black" v-if="stat.active" size="15">mdil-check</v-icon>
										</v-chip>
									</template>
								</div>
							</v-col>
							<v-divider vertical></v-divider>
							<v-col cols="12" md="4" sm="6" lg="4" xl="4">
								<span class="heading">Branch:
									<v-checkbox hide-details class="shrink mt-0" v-model="BranchToggle" @click="handleFunction('BranchCheckUncheck')" :ripple="false"></v-checkbox>
								</span>
								<div class="mt-2">
									<template v-for="branch in BrachDefault">
										<v-chip small class="este-chip tag-chip mb-2" :class="(branch.active === true)  ? 'selected' : ''" text-color="black" @click="branchToggle(branch)">
											{{ branch.branch | capitalize }}
										</v-chip>
									</template>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-menu>
			</template>
		</v-text-field>
	</div>
</template>
<script>
import globalFn from '../../mixins/globalFn';
import advancedSearch from '../../mixins/advancedSearch';
import status from '../../mixins/status';
import eventBus from '../../eventBus.js';
import _ from 'lodash';
export default {
	name: "offerSearch",
	mixins: [globalFn, status, advancedSearch],
	props: {
		screen: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		}
	},
	data: () => ({
		menu: false,
		showDatePicker: false,
		enquiryDateFromMenu: false,
		enquiryDateToMenu: false,
		timeFromMenu: false,
		timeToMenu: false,
		user: JSON.parse(localStorage.getItem('user')),
		params: '?&sort_order=DESC&sort_by=name',
		filter: {
			search: '',
			start_date: '',
			end_date: '',
			branch: [],
			contacts: [],
			status: [],
		},
		contacts: [],
		search: "",
		isTyping: false,
		showVideoTraining: process.env.MIX_SHOW_VIDEO_TRAINING
	}),
	watch: {
		search: _.debounce(function() {
			this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.filter.search = this.search
				this.checkAll()
				this.searchKey();
			}
		}
	},
	mounted() {
		this.loadDefaults()
		this.$store.dispatch('staff/GET_STAFF_LIST', { data: '', params: '' })
	},
	computed: {
		userStaff() {
			this.$store.state.staff.all = this.$store.state.staff.all.map(
				function(el) {
					let item = Object.assign({}, el);
					item.active = false
					return item;
				}
			);
			return this.$store.state.staff.all
		}
	},
	methods: {
		async searchKey() {
			this.$root.$emit('searchOffers', this.filter)
		},
		async reset() {
			await this.resetFilter()
			await this.loadDefaults()
		},

		async userStaffToggle(item) {
			item.active = !item.active
			this.filter.contacts = this.userStaff.filter(function(el) {
				return el.active === true;
			}).map(function(el) {
				return el.id;
			});
		},
		async resetFilter() {
			this.filter.search = ''
			this.filter.start_date = ''
			this.filter.end_date = ''
			this.filter.contacts = []
			this.filter.status = []
			this.filter.branch = []
		}
	}
};

</script>
