<template>
  <div class="ml-4 search-cont">
    <template v-for="(a,index) in actions">
      <template v-if="screen === a.screen && slug === a.slug">
        <v-btn :href="`/calendar/${slug}`" class="b-btn b-calendar mr-4" icon color="primary" depressed v-if="(slug === 'appointments' || slug === 'app-treatments' || slug === 'leads' || slug === 'call-back') && a.calendar === 'true'">
          <img src="/images/calendar.svg">
        </v-btn>
        <v-btn @click="loadBackScreen" class="b-btn b-calendar mr-4" icon color="primary" depressed v-if="slug === 'calendar'">
          <img src="/images/list.svg">
        </v-btn>
        <v-btn class="b-btn b-small mr-4" color="secondary" depressed v-if="a.btn" @click.stop="handleFunction(a)">
          {{ a.btnText }}
        </v-btn>
       <!--  <v-btn class="b-btn b-small mr-4" color="secondary" depressed v-if="slug === 'leads' || slug === 'customer'" @click.stop="openDrawer('add', '750', 'leads/QuickAddLead');">
          Quick Lead
        </v-btn> -->
      </template>
    </template>
  </div>
</template>
<script>
import sliderFn from '../mixins/sliderFn';
import eventBus from '../eventBus.js';
export default {
  name: "tableAction",
  props: {
    screen: {
      type: String,
      required: true
    },
    slug: {
      required: true
    }
  },
  mixins: [sliderFn],
  data: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    calendar: true,
    actions: [{
      screen: 'Leads',
      btnText: 'Add Lead',
      sidePane: 'leads/addPane',
      tabPane: 'leads/addTab',
      btn: true,
      calendar: process.env.MIX_SHOW_CALENDAR,
      slug: 'leads'
    }, {
      screen: 'Clients',
      btnText: 'Add Client',
      sidePane: 'clients/addPane',
      tabPane: 'clients/addTab',
      btn: true,
      slug: 'clients'
    }, {
      screen: 'Contacts',
      btnText: 'Add Contact',
      sidePane: 'customers/add',
      tabPane: 'customers/add',
      btn: true,
      slug: 'customer'
    },{
      screen: 'Opportunities',
      btnText: 'Add Opportunity',
      sidePane: 'opportunities/addPane',
      tabPane: 'opportunities/addTab',
      btn: true,
      slug: 'opportunities'
    },{
      screen: 'Support Tickets',
      btnText: 'Add Ticket',
      sidePane: 'supportTickets/add',
      tabPane: 'supportTickets/add',
      btn: true,
      slug: 'support-tickets'
    }, {
      screen: 'Offers',
      btnText: 'Add',
      sidePane: 'offers/add',
      tabPane: 'offers/add',
      btn: true,
      slug: 'offers'
    }, {
      screen: 'Notifications',
      btnText: 'Add',
      sidePane: 'notifications/add',
      tabPane: 'notifications/add',
      btn: true,
      slug: 'notifications'
    }, {
      screen: 'Complaints',
      btnText: 'Add Complaint',
      sidePane: 'complaints/addPane',
      tabPane: 'complaints/addTab',
      btn: true,
      slug: 'complaints'
    }, {
      screen: 'Appointments',
      btnText: 'Add Appointment',
      sidePane: 'appointments/addPane',
      tabPane: 'appointments/addTab',
      btn: true,
      calendar: process.env.MIX_SHOW_CALENDAR,
      slug: 'appointments'
    }, {
      screen: 'Treatments',
      btnText: 'Add Appointment',
      sidePane: 'appointments/addPane',
      tabPane: 'appointments/addTab',
      btn: true,
      calendar: process.env.MIX_SHOW_CALENDAR,
      slug: 'app-treatments'
    }, {
      screen: 'Call Back',
      btnText: 'Add Call Back',
      sidePane: 'callback/addPane',
      tabPane: 'callback/addTab',
      btn: true,
      calendar: process.env.MIX_SHOW_CALENDAR,
      slug: 'call-back'
    }, {
      screen: 'Insights',
      btn: false,
      slug: 'insights'
    }, {
      screen: 'Calendar',
      btn: false,
      slug: 'calendar'
    }, {
      screen: 'Purchase Invoices',
      btnText: 'Add Invoice',
      btn: true,
      emitAction: 'AddNewInvoice',
      slug: 'purchase-invoices'
    }, {
      screen: 'Cash Payments',
      btnText: 'Add Payment',
      btn: true,
      emitAction: 'AddNewCashPayment',
      slug: 'cash-payments'
    }, {
      screen: 'Staff',
      btnText: 'Add Staff',
      sidePane: 'settings/staff/add',
      tabPane: 'settings/staff/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Branches',
      btnText: 'Add Branch',
      sidePane: 'settings/branches/add',
      tabPane: 'settings/branches/add',
      btn: true,
      slug: 'settings'
    },{
      screen: 'Campaigns',
      btnText: 'Add Campaign',
      sidePane: 'settings/campaigns/add',
      tabPane: 'settings/campaigns/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Machines',
      btnText: 'Add Machine',
      sidePane: 'settings/machines/add',
      tabPane: 'settings/machines/add',
      btn: true,
      slug: 'settings'
    },{
      screen: 'Warehouse',
      btnText: 'Add Warehouse',
      sidePane: 'settings/warehouses/add',
      tabPane: 'settings/warehouses/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Symptoms',
      btnText: 'Add Symptom',
      sidePane: 'settings/symptoms/add',
      tabPane: 'settings/symptoms/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Treatments',
      btnText: 'Add Treatment',
      sidePane: 'settings/treatments/add',
      tabPane: 'settings/treatments/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Products',
      btnText: 'Add Product',
      sidePane: 'settings/products/add',
      tabPane: 'settings/products/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Categories',
      btnText: 'Add Category',
      sidePane: 'settings/categories/add',
      tabPane: 'settings/categories/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Configurations',
      btnText: 'Add Configuration',
      sidePane: 'settings/configurations/edit',
      tabPane: 'settings/configurations/edit',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Points',
      btnText: 'Add Points',
      sidePane: 'settings/points/edit',
      tabPane: 'settings/points/edit',
      btn: true,
      slug: 'settings'
    }]
  }),
  methods: {
    handleFunction(item) {

      if (item.slug === 'customer' || item.slug === 'support-tickets') {
        this.openDrawer("add", "1100", item.sidePane);
      } else {
        if (['Purchase Invoices', 'Cash Payments'].includes(item.screen)) {
          this.$root.$emit(item.emitAction)
        } else if (['Leads', 'Clients', 'Appointments', 'Complaints', 'Treatments', 'Insights', 'Reports', 'Call Back', 'Opportunities'].includes(item.screen)) {
          if (item.slug !== 'settings' && (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl')) {
            this.openDrawer("add", "1100", item.sidePane);
          } else {
            this.openDrawer("add", "750", item.tabPane);
          }
        } else {
          this.openDrawer("add", "750", item.tabPane);
        }
      }


    },
    loadBackScreen() {
      const screen = this.$router.currentRoute.params.screen
      this.$router.push({ path: `/${screen}` })
    }
  }
}

</script>
