<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Add Opportunity</div>
        <v-tabs v-model="navigationTab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <template v-for="(item, index) in navigation">
            <v-tab :href="`#${item.slug}`">
              {{item.name}}
            </v-tab>
          </template>
        </v-tabs>
      </div>
      <div class="modal-inside pt-0 right-pane">
        <v-tabs-items v-model="navigationTab">
          <v-tab-item value="personal" :eager="true">
            <v-form ref="personalForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row mb-4">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt font-weight--medium">
                        Preference
                      </span>
                    </label>
                    <v-radio-group hide-details="auto" mandatory v-model="existing" row>
                      <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" :value="true">
                        <template #label>
                          <span class="label-txt">Choose from existing contacts</span>
                        </template>
                      </v-radio>
                      <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" :value="false">
                        <template #label>
                          <span class="label-txt">Create New</span>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <template v-if="existing">
                  <v-row dense class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <div>
                        <label>
                          <span class="label-txt">Select Contact:</span>
                        </label>
                      </div>
                      <template v-if="!isContactSelected">
                        <v-text-field flat rounded prepend-inner-icon="mdi-magnify" :rules="rules.user_id" autocomplete="off" label="Search for a contact…" class="solo-cust" clearable clear-icon="mdi-close-circle-outline" solo hide-details="auto" :loading="loading" @input="conatctTyping = true" v-model="contactSearch">
                        </v-text-field>
                        <ul class="contact--list" :class="(results.length > 20) ? 'height-300' : ''">
                          <li v-for="(item,index) in results" :key="index">
                            <a @click="findContact(item)" class="d-flex align-center flex-row cursor">
                              <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="item">
                                <span v-if="item.name">{{ item.name }} </span>
                                <span v-if="item.name === null">{{ item.social_handle }} </span>
                                <span v-if="item.contact_no">/ {{ item.contact_no }} </span>
                                <span v-if="item.email">/ {{ item.email }} </span>
                              </span>
                              <span class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                                {{ item.user_type_name === "Lead" ? "L" : "C" }}
                              </span>
                            </a>
                          </li>
                        </ul>
                      </template>
                      <template v-if="isContactSelected">
                        <v-text-field flat :readonly="true" rounded @click:clear="isContactSelected = false;results= []" autocomplete="off" class="solo-cust" clearable clear-icon="mdi-close-circle-outline" solo hide-details :value="formatContact">
                        </v-text-field>
                      </template>
                    </v-col>
                  </v-row>
                  <template v-if="showEditContactTrue">
                    <v-row dense class="e-row">
                      <v-col cols="6" md="2" class="e-col">
                        <div>
                          <label>
                            <span class="label-txt">Title</span>
                            <v-select autocomplete="off" :items="titles" v-model="selectedContact.title" solo outlined flat class="solo-cust" hide-details="auto">
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </label>
                        </div>
                      </v-col>
                      <v-col cols="6" md="10" class="e-col">
                        <div>
                          <label>
                            <span class="label-txt">
                              Name
                            </span>
                            <v-text-field autocomplete="off" solo outlined v-model="selectedContact.name" flat hide-details="auto" class="solo-cust"></v-text-field>
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="12" class="e-col">
                        <label>
                          <span class="label-txt">
                            Social Handle
                          </span>
                        </label>
                        <v-text-field autocomplete="off" solo outlined v-model="selectedContact.social_handle" flat hide-details="auto" class="solo-cust social-handle-input" :class="socialError ? 'v-input--has-state error--text' : '' ">
                          <template slot="append-outer">
                            <v-select autocomplete="off" v-model="selectedContact.source_id" :items="socialMedia" item-text="name" item-value="id" solo outlined flat class="este-autocomplete-input" hide-details="auto" :class="socialError ? 'v-input--has-state error--text' : '' ">
                              <template v-slot:selection="data">
                                <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
                              </template>
                              <template v-slot:item="data">
                                <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
                              </template>
                              <template slot="append">
                                <v-icon>mdi-chevron-down</v-icon>
                              </template>
                            </v-select>
                          </template>
                        </v-text-field>
                        <span class="quick-validation" v-if="socialError">
                          <a @click="selectContact" @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
                            <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
                              <span v-if="existUser.name !== null">{{ existUser.name }} </span>
                              <span v-if="existUser.name === null">{{ existUser.social_handle }} </span>
                              <span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
                              <span v-if="existUser.email">/ {{ existUser.email }} </span>
                            </span>
                            <span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                              {{ existUser.user_type_name === "Lead" ? "L" : "C" }}
                            </span>
                          </a>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="6" class="e-col">
                        <label>
                          <span class="label-txt">
                            Phone No.
                          </span>
                        </label>
                        <v-text-field autocomplete="off" solo outlined v-model="selectedContact.contact_no" flat hide-details="auto" class="solo-cust" append-icon="mdil-phone" :class="contactError ? 'v-input--has-state error--text' : '' "></v-text-field>
                        <span class="quick-validation" v-if="contactError">
                          <a @click="selectContact" @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
                            <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
                              <span v-if="existUser.name !== null">{{ existUser.name }} </span>
                              <span v-if="existUser.name === null">{{ existUser.social_handle }} </span>
                              <span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
                              <span v-if="existUser.email">/ {{ existUser.email }} </span>
                            </span>
                            <span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                              {{ existUser.user_type_name === "Lead" ? "L" : "C" }}
                            </span>
                          </a>
                        </span>
                      </v-col>
                      <v-col cols="12" md="6" class="e-col">
                        <label>
                          <span class="label-txt">
                            Email Address
                          </span>
                        </label>
                        <v-text-field autocomplete="off" v-model="selectedContact.email" solo outlined flat hide-details="auto" class="solo-cust" append-icon="mdil-email" :class="emailError ? 'v-input--has-state error--text' : '' "></v-text-field>
                        <span class="quick-validation" v-if="emailError">
                          <a @click="selectContact" @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
                            <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
                              <span v-if="existUser.name !== null">{{ existUser.name }} </span>
                              <span v-if="existUser.name === null">{{ existUser.social_handle }} </span>
                              <span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
                              <span v-if="existUser.email">/ {{ existUser.email }} </span>
                            </span>
                            <span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                              {{ existUser.user_type_name === "Lead" ? "L" : "C" }}
                            </span>
                          </a>
                        </span>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="12" class="e-col">
                        <label>
                          <span class="label-txt">
                            Address
                          </span>
                          <v-text-field autocomplete="off" solo outlined flat v-model="selectedContact.address_one" hide-details="auto" class="solo-cust mb-2"></v-text-field>
                          <v-text-field autocomplete="off" solo outlined flat v-model="selectedContact.address_two" hide-details="auto" class="solo-cust mar-8"></v-text-field>
                        </label>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="6" class="e-col">
                        <label>
                          <span class="label-txt">
                            Town / City
                          </span>
                        </label>
                        <v-text-field autocomplete="off" solo outlined flat v-model="selectedContact.city" hide-details="auto" class="solo-cust"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" class="e-col">
                        <label>
                          <span class="label-txt">
                            Post Code
                          </span>
                        </label>
                        <v-text-field autocomplete="off" solo outlined flat v-model="selectedContact.postcode" hide-details="auto" class="solo-cust"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="6" class="e-col">
                        <label>
                          <span class="label-txt">
                            Preferred Branch:
                          </span>
                        </label>
                        <v-select autocomplete="off" :items="branches" :rules="rules.branch_id" item-value="id" item-text="branch" v-model="selectedContact.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" class="e-col">
                        <label>
                          <span class="label-txt">
                            Responsible
                          </span>
                          <v-select autocomplete="off" :items="staff" v-model="selectedContact.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto" @change="leadForm.opp_responsible_id = selectedContact.responsible_id">
                            <template slot="append">
                              <v-icon>mdi-chevron-down</v-icon>
                            </template>
                          </v-select>
                        </label>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="12" class="e-col">
                        <label>
                          <span class="label-txt">
                            Contact Type
                          </span>
                        </label>
                        <v-radio-group class="mt-2" hide-details="auto" mandatory v-model="selectedContact.user_type" row>
                          <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Lead" :value="3"></v-radio>
                          <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Client" :value="4"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class="e-row">
                      <v-col cols="12" md="12" class="e-col">
                        <p class="sub-title mb-4">
                          Contact Preferences
                        </p>
                        <label>
                          <span class="label-txt">
                            Receive SMS
                          </span>
                        </label>
                        <v-radio-group class="mt-2" hide-details="auto" mandatory v-model="selectedContact.is_receive_sms" row>
                          <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
                          <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-else>
                  <v-row dense class="e-row">
                    <v-col cols="6" md="2" class="e-col">
                      <div>
                        <label>
                          <span class="label-txt">Title</span>
                          <v-select autocomplete="off" :items="titles" v-model="leadForm.title" solo outlined flat class="solo-cust" hide-details="auto">
                            <template slot="append">
                              <v-icon>mdi-chevron-down</v-icon>
                            </template>
                          </v-select>
                        </label>
                      </div>
                    </v-col>
                    <v-col cols="6" md="10" class="e-col">
                      <div>
                        <label>
                          <span class="label-txt">
                            Name
                          </span>
                          <v-text-field autocomplete="off" solo outlined v-model="leadForm.name" flat hide-details="auto" class="solo-cust"></v-text-field>
                        </label>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <label>
                        <span class="label-txt">
                          Social Handle
                        </span>
                      </label>
                      <v-text-field autocomplete="off" solo outlined v-model="leadForm.social_handle" flat hide-details="auto" class="solo-cust social-handle-input" :class="socialError ? 'v-input--has-state error--text' : '' ">
                        <template slot="append-outer">
                          <v-select autocomplete="off" v-model="leadForm.source_id" :items="socialMedia" item-text="name" item-value="id" solo outlined flat class="este-autocomplete-input" hide-details="auto" :class="socialError ? 'v-input--has-state error--text' : '' ">
                            <template v-slot:selection="data">
                              <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
                            </template>
                            <template v-slot:item="data">
                              <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
                            </template>
                            <template slot="append">
                              <v-icon>mdi-chevron-down</v-icon>
                            </template>
                          </v-select>
                        </template>
                      </v-text-field>
                      <span class="quick-validation" v-if="socialError">
                        <a @click="selectContact" @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
                          <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
                            <span v-if="existUser.name !== null">{{ existUser.name }} </span>
                            <span v-if="existUser.name === null">{{ existUser.social_handle }} </span>
                            <span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
                            <span v-if="existUser.email">/ {{ existUser.email }} </span>
                          </span>
                          <span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                            {{ existUser.user_type_name === "Lead" ? "L" : "C" }}
                          </span>
                        </a>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="6" class="e-col">
                      <label>
                        <span class="label-txt">
                          Phone No.
                        </span>
                      </label>
                      <v-text-field autocomplete="off" solo outlined v-model="leadForm.contact_no" flat hide-details="auto" class="solo-cust" append-icon="mdil-phone" :class="contactError ? 'v-input--has-state error--text' : '' "></v-text-field>
                      <span class="quick-validation" v-if="contactError">
                        <a @click="selectContact" @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
                          <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
                            <span v-if="existUser.name !== null">{{ existUser.name }} </span>
                            <span v-if="existUser.name === null">{{ existUser.social_handle }} </span>
                            <span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
                            <span v-if="existUser.email">/ {{ existUser.email }} </span>
                          </span>
                          <span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                            {{ existUser.user_type_name === "Lead" ? "L" : "C" }}
                          </span>
                        </a>
                      </span>
                    </v-col>
                    <v-col cols="12" md="6" class="e-col">
                      <label>
                        <span class="label-txt">
                          Email Address
                        </span>
                      </label>
                      <v-text-field autocomplete="off" v-model="leadForm.email" solo outlined flat hide-details="auto" class="solo-cust" append-icon="mdil-email" :class="emailError ? 'v-input--has-state error--text' : '' "></v-text-field>
                      <span class="quick-validation" v-if="emailError">
                        <a @click="selectContact" @mouseover="isHovering = true" @mouseout="isHovering = false" :class="{'text-decoration-underline': isHovering}" class="d-flex align-center flex-row position-relative mr-4 mb-2 mt-2  cursor">
                          <span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="existUser">
                            <span v-if="existUser.name !== null">{{ existUser.name }} </span>
                            <span v-if="existUser.name === null">{{ existUser.social_handle }} </span>
                            <span v-if="existUser.contact_no">/ {{ existUser.contact_no }} </span>
                            <span v-if="existUser.email">/ {{ existUser.email }} </span>
                          </span>
                          <span class="position-absolute small mt-n5 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                existUser.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
                            {{ existUser.user_type_name === "Lead" ? "L" : "C" }}
                          </span>
                        </a>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <label>
                        <span class="label-txt">
                          Address
                        </span>
                        <v-text-field autocomplete="off" solo outlined flat v-model="leadForm.address_one" hide-details="auto" class="solo-cust mb-2"></v-text-field>
                        <v-text-field autocomplete="off" solo outlined flat v-model="leadForm.address_two" hide-details="auto" class="solo-cust mar-8"></v-text-field>
                      </label>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="6" class="e-col">
                      <label>
                        <span class="label-txt">
                          Town / City
                        </span>
                      </label>
                      <v-text-field autocomplete="off" solo outlined flat v-model="leadForm.city" hide-details="auto" class="solo-cust"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="e-col">
                      <label>
                        <span class="label-txt">
                          Post Code
                        </span>
                      </label>
                      <v-text-field autocomplete="off" solo outlined flat v-model="leadForm.postcode" hide-details="auto" class="solo-cust"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="6" class="e-col">
                      <label>
                        <span class="label-txt">
                          Preferred Branch:
                        </span>
                      </label>
                      <v-select autocomplete="off" :items="branches" :rules="rules.branch_id" item-value="id" item-text="branch" v-model="leadForm.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="e-col">
                      <label>
                        <span class="label-txt">
                          Responsible
                        </span>
                        <v-select autocomplete="off" :items="staff" v-model="leadForm.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto" @change="leadForm.opp_responsible_id = leadForm.responsible_id">
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                        </v-select>
                      </label>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <label>
                        <span class="label-txt">
                          Contact Type
                        </span>
                      </label>
                      <v-radio-group class="mt-2" hide-details="auto" mandatory v-model="leadForm.contact_type" row>
                        <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Lead" :value="3"></v-radio>
                        <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Client" :value="4"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row class="e-row">
                    <v-col cols="12" md="12" class="e-col">
                      <p class="sub-title mb-4">
                        Contact Preferences
                      </p>
                      <label>
                        <span class="label-txt">
                          Receive SMS
                        </span>
                      </label>
                      <v-radio-group class="mt-2" hide-details="auto" mandatory v-model="leadForm.is_receive_sms" row>
                        <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
                        <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </template>
              </div>
            </v-form>
          </v-tab-item>
          <v-tab-item value="lead" :eager="true">
            <v-form ref="leadForm">
              <div class="tab-content-wrapper">
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Reason for Treatment:
                      </span>
                    </label>
                    <v-combobox v-model="leadForm.reasons" :items="reasons" item-text="name" item-value="id" multiple :search-input.sync="reasonSearch" solo outlined flat chips hide-details="auto" class="este-autocomplete-input">
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              No results matching "<strong>{{ reasonSearch }}</strong>". Press <kbd>enter</kbd> to create a new one
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                          <span class="pr-2" v-if="data.item.name">
                            {{ data.item.name }}
                          </span>
                          <span class="pr-2" v-else>
                            {{ data.item }}
                          </span>
                          <v-icon color="primary" small @click.stop="removeReason(data.item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Symptoms / Condition:
                      </span>
                    </label>
                    <v-autocomplete v-model="leadForm.symptoms" :items="symptoms" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="symptomChange">
                      <template v-slot:selection="data">
                        <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                          <span class="pr-2">
                            {{ data.item.name }}
                          </span>
                          <v-icon color="primary" small @click.stop="removeSymptoms(data.item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt">
                        Suggested Treatments:
                      </span>
                    </label>
                  </v-col>
                  <v-col cols="12" md="12" class="e-col">
                    <template v-for="treat in suggestedTreatments">
                      <v-chip small class="este-chip status-chip mb-2" text-color="black" @click="suggTreat(treat)">
                        {{ treat.name | capitalize }}
                      </v-chip>
                    </template>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Service Recommended:
                      </span>
                    </label>
                    <v-autocomplete v-model="leadForm.service_recommended" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                      <template v-slot:selection="data">
                        <v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
                          <span class="pr-2">
                            {{ data.item.name }}
                          </span>
                          <v-icon color="primary" small @click.stop="removeService(data.item)">
                            mdi-close
                          </v-icon>
                        </v-chip>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="4" class="e-col">
                    <label>
                      <span class="label-txt">
                        Enquiry Date
                      </span>
                      <v-menu v-model="enquiryMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field autocomplete="off" prepend-inner-icon="mdil-calendar" clearable clear-icon="mdi-close-circle-outline" :value="dateUkFormat(leadForm.enquiry_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
                        </template>
                        <v-date-picker v-model="leadForm.enquiry_date" no-title @input="enquiryMenu = false"></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="12" md="4" class="e-col">
                    <label>
                      <span class="label-txt">
                        Follow Up Date
                      </span>
                      <v-menu v-model="followUpMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field autocomplete="off" prepend-inner-icon="mdil-calendar" clearable clear-icon="mdi-close-circle-outline" :value="dateUkFormat(leadForm.follow_up_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust"></v-text-field>
                        </template>
                        <v-date-picker v-model="leadForm.follow_up_date" no-title @input="followUpMenu = false"></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="12" md="4" class="e-col">
                    <label>
                      <span class="label-txt">Follow Up Time:</span>
                      <v-menu ref="followUpTimeMenu" v-model="followUpTimeMenu" :close-on-content-click="false" :return-value.sync="leadForm.follow_up_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field outlined flat hide-details="auto" solo class="solo-cust" v-model="leadForm.follow_up_time" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="followUpTimeMenu" v-model="leadForm.follow_up_time" full-width @click:minute="$refs.followUpTimeMenu.save(leadForm.follow_up_time)"></v-time-picker>
                      </v-menu>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt">
                        Campaigns:
                      </span>
                    </label>
                    <v-select autocomplete="off" :items="campaigns" v-model="leadForm.campaign_ids" multiple item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt">
                        Source:
                      </span>
                    </label>
                    <v-select autocomplete="off" :rules="rules.source" :items="source" v-model="leadForm.opp_source_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Preferred Branch:
                      </span>
                    </label>
                    <v-select autocomplete="off" :items="branches" :rules="rules.opp_branch_id" item-value="id" item-text="branch" v-model="leadForm.opp_branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt">
                        Status
                      </span>
                      <v-select autocomplete="off" :item-disabled="disableStatusOption" :items="statusTags.filter(function(el) {return el.slug.includes('opportunitie')})" item-value="id" item-text="name" :rules="rules.status" v-model="leadForm.status" solo outlined flat class="solo-cust" hide-details="auto">
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt">
                        Responsible
                      </span>
                      <v-select autocomplete="off" :items="staff" v-model="leadForm.opp_responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="AddLead">
          Add Lead
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import status from '../../../mixins/status.js';
import globalFn from '../../../mixins/globalFn.js';
import addLead from './addLead.js';
export default {
  mixins: [status, globalFn, addLead],
};

</script>
