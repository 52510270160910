<template>
	<div class="d-flex">
		<v-btn href="https://video.drift.com/v/abkLsHEYG95/" target="_blank" class="b-btn b-small mr-4" color="red" depressed v-if="!$vuetify.breakpoint.smAndDown">
			Video Training
		</v-btn>
		<v-text-field flat prepend-inner-icon="mdi-magnify" autocomplete="off" label="Search for a appointment…" solo hide-details class="search-input" @input="isTyping = true" v-model="search">
			<template v-slot:append>
				<v-menu :close-on-content-click="false" offset-y left content-class=" mega-menu">
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="b-btn b-advance" depressed v-on="on">
							Advanced <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<div class="advance-sec">
						<div class="head">
							Advanced Search
							<v-chip small class="este-chip yellow-chip float-right" text-color="black" @click="searchKey">
								Filter
							</v-chip>
							<v-chip small color="#221F20" class="este-chip mb-2 float-right" text-color="#FFFFFF" @click="reset">
								Reset
							</v-chip>
						</div>
						<v-divider></v-divider>
						<v-row class="filter-row">
							<v-col cols="12" class="filter-column" sm="6">
								<span class="heading">Enquiry:</span>
								<div class="d-flex mt-2 mb-4">
									<v-menu v-model="enquiryDateFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.enquiry_date_from)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date from" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.enquiry_date_from" no-title @input="enquiryDateFromMenu = false"></v-date-picker>
									</v-menu>
									<v-menu v-model="enquiryDateToMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.enquiry_date_to)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date to" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.enquiry_date_to" no-title @input="enquiryDateToMenu = false"></v-date-picker>
									</v-menu>
								</div>
								<span class="heading">Appointment:</span>
								<div class="d-flex mt-2 mb-4">
									<v-menu v-model="appointmentdateFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.appointment_date_from)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date from" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.appointment_date_from" no-title @input="appointmentdateFromMenu = false"></v-date-picker>
									</v-menu>
									<v-menu v-model="appointmentdateToMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.appointment_date_to)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date to" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.appointment_date_to" no-title @input="appointmentdateToMenu = false"></v-date-picker>
									</v-menu>
								</div>
							</v-col>
							<v-col cols="12" class="filter-column" sm="6">
								<span class="heading">Status:
									<v-checkbox hide-details class="shrink mt-0" v-model="StatusToggle" @click="handleFunction('StatusCheckUncheck')" :ripple="false"></v-checkbox>
								</span>
								<div class="mt-2">
									<template v-for="stat in StatusDefault">
										<v-chip small class="este-chip status-chip mb-2" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color" @click="statusToggle(stat)">
											{{ stat.name | capitalize }}
											<v-icon right color="black" v-if="stat.active" size="15">mdil-check</v-icon>
										</v-chip>
									</template>
								</div>
							</v-col>
							<v-col cols="12" class="filter-column" sm="6">
								<span class="heading">Branch:
									<v-checkbox hide-details class="shrink mt-0" v-model="BranchToggle" @click="handleFunction('BranchCheckUncheck')" :ripple="false"></v-checkbox>
								</span>
								<div class="mt-2">
									<template v-for="branch in BrachDefault">
										<v-chip small class="este-chip tag-chip mb-2" :class="(branch.active === true)  ? 'selected' : ''" text-color="black" @click="branchToggle(branch)">
											{{ branch.branch | capitalize }}
										</v-chip>
									</template>
								</div>
							</v-col>
							<v-col cols="12" class="filter-column" sm="6">
								<span class="heading">Source:
									<v-checkbox hide-details class="shrink mt-0" v-model="SourceToggle" @click="handleFunction('SourceCheckUncheck')" :ripple="false"></v-checkbox>
								</span>
								<div class="mt-2">
									<v-chip small class="este-chip tag-chip mb-2 text-decoration-line-through" :class="(SourceBlank === true)  ? 'selected' : ''" text-color="black" @click.stop="selectSourceBlank">
										NONE
									</v-chip>
									<template v-for="item in SourceDefault">
										<v-chip small class="este-chip tag-chip mb-2" :class="(item.active === true)  ? 'selected' : ''" text-color="black" @click="sourceToggle(item)">
											{{ item.name | capitalize }}
										</v-chip>
									</template>
								</div>
							</v-col>
							<v-col cols="12" class="filter-column" sm="6">
								<span class="heading">Assigned To:</span>
								<div class="mt-2">
									<v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top>
										<template v-slot:activator="{ on, attrs }">
											<v-icon v-bind="attrs" v-on="on" class="mt-n2 ml-n1 ban-icon" :ripple="false" size="45" :color="unassignedCheck ? '#221F20' : 'rgba(0,0,0,0.4)'" @click="filterUnassign">mdil-cancel</v-icon>
										</template>
										<span>Nobody Assigned</span>
									</v-tooltip>
									<template v-for="item in staff">
										<v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top>
											<template v-slot:activator="{ on, attrs }">
												<v-chip v-bind="attrs" v-on="on" small class="tag-chip mb-2 staff-chip" :class="(item.active === true)  ? 'selected' : ''" text-color="black" @click="staffToggle(item)">
													{{ getInitials(item.name)}}
												</v-chip>
											</template>
											<span>{{item.name}}</span>
										</v-tooltip>
									</template>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-menu>
			</template>
		</v-text-field>
	</div>
</template>
<script>
import globalFn from '../../mixins/globalFn';
import advancedSearch from '../../mixins/advancedSearch';
import status from '../../mixins/status';
import eventBus from '../../eventBus.js';
import _ from 'lodash';
export default {
	name: "appointmentsearch",
	mixins: [globalFn, status, advancedSearch],
	props: {
		screen: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		}
	},
	data: () => ({
		menu: false,
		showDatePicker: false,
		enquiryDateFromMenu: false,
		enquiryDateToMenu: false,
		appointmentdateFromMenu: false,
		appointmentdateToMenu: false,
		user: JSON.parse(localStorage.getItem('user')),
		params: '?&sort_order=DESC&sort_by=name',
		filter: {
			search: '',
			enquiry_date_from: '',
			enquiry_date_to: '',
			appointment_date_from: '',
			appointment_date_to: '',
			status: [],
			branch: [],
			source: [],
			assigned: [],
			assigned_to: 0,
			source_blank: 1,
			widget_filter: false
		},
		search: "",
		isTyping: false
	}),
	watch: {
		search: _.debounce(function() {
			this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.filter.search = this.search
				this.checkAll()
				this.searchKey();
			}
		}
	},
	mounted() {
		this.loadDefaults()
	},
	methods: {
		async searchKey() {
			this.$root.$emit('searchAppointments', this.filter)
		},
		async reset() {
			await this.resetFilter()
			await this.loadDefaults()
		},
		async resetFilter() {
			this.filter.search = ''
			this.filter.widget_filter = true
			this.filter.enquiry_date_from = ''
			this.filter.enquiry_date_to = ''
			this.filter.appointment_date_from = ''
			this.filter.appointment_date_to = ''
			this.filter.assigned = []
			this.filter.assigned_to = 0
			this.filter.source_blank = 1
			this.unassignedCheck = false
			this.staff.forEach(value => {
				value.active = false
			});
		}
	}
};

</script>
