import http from '../http/common'

const resource = '/pulse'

export default {

	leadsGenerated: (payload) => http.get(`${resource}/leads-generated`, payload),
	appointmentsGenerated: (payload) => http.get(`${resource}/appointments-generated`, payload),
	dealBranchGenerated: (payload) => http.get(`${resource}/deals-branch-generated`, payload),
	dealChannelGenerated: (payload) => http.get(`${resource}/deals-source-generated`, payload),
}
