<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Machines</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Machine Name
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined :rules="rules.name" v-model="machine.name" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Treatments
                  </span>
                </label>
                <v-autocomplete v-model="machine.treatments" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="create">
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../../store/action-types.js';
import status from '../../../../mixins/status.js';
import globalFn from '../../../../mixins/globalFn.js';
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem('user')),
    tab: null,
    rules: {
      name: [
        v => !!v || 'Please enter name'
      ]
    }
  }),
  computed: {
    machine() {
      if (this.$store.state.machines.item) {
        return this.$store.state.machines.item
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList
      }
    }
  },
  created() {
    this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("machineTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async create() {
      let formData = {}
      formData.id = this.machine.id
      formData.name = this.machine.name
      formData.treatments = []
      this.machine.treatments.forEach(value => {
        if (value.id) {
          formData.treatments.push({ id: value.id });
        } else {
          formData.treatments.push(value);
        }
      });
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.$store.dispatch('machines/UPDATE_MACHINE', formData).then(response => {
          if (response.result === 'success') {
            this.loading = false
            this.loadData()
          } else if (response.result === 'fail') {
            response.type = 'error'
            this.loading = false
            this.$store.dispatch('snackbar/SHOW', response)
          }
        }).catch(err => {
          this.loading = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        });
      }
    }
  }
};

</script>
