<template>
  <div class="fill-height slider-cont">
    <div class="modal-inside pb-0 tab-brd">
      <div class="form-title mb-24">Edit Callback</div>
      <v-tabs v-model="navigationTab" class="cust-tabs">
        <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
        <v-tab href="#callback">
          Callback Details
        </v-tab>
      </v-tabs>
    </div>
    <div class="modal-inside py-0 right-pane">
      <v-tabs-items v-model="navigationTab">
        <v-tab-item value="callback" :eager="true">
          <v-form ref="callbackForm">
            <div class="tab-content-wrapper">
              <v-row dense class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <div class="client-info">
                    <template v-if="callback.user !== null">
                      <div class="name" v-if="callback.user.name">
                        {{ callback.user.name }}
                      </div>
                      <div class="d-flex name" v-if="!callback.user.name && callback.user.social_handle">
                        <img :src="`/images/${callback.user.source_name.toLowerCase()}.svg`" class="mr-2">
                        <span>
                          @{{ callback.user.social_handle }}
                        </span>
                      </div>
                    </template>
                    <p class="mt-4">
                      <span class="contact" v-if="callback.user !== null && callback.user.contact_no !== null">
                        <a :href="`tel:${callback.user.contact_no}`">
                          <img src="/images/phone.svg" height="15"> {{ callback.user.contact_no }}</a>
                      </span>
                      <span class="contact" v-if="callback.user !== null && callback.user.email !== null">
                        <a :href="`tel:${callback.user.email}`">
                          <img src="/images/email.svg" height="15"> {{ callback.user.email }}</a>
                      </span>
                    </p>
                  </div>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Treatment / Service:
                    </span>
                  </label>
                  <v-autocomplete v-model="callback.treatments" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ callback.treatments.length - 1 }} others)
                      </span>
                    </template>
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Call Back Date:
                    </span>
                    <v-menu v-model="callbackDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field autocomplete="off" :value="dateUKformat(callback.callback_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
                      </template>
                      <v-date-picker v-model="callback.callback_date" no-title @input="callbackDateMenu = false;"></v-date-picker>
                    </v-menu>
                  </label>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">Call Back Time:</span>
                    <v-menu ref="callbackTimeMenu" v-model="callbackTimeMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined flat hide-details="auto" solo class="solo-cust" v-model="callback.callback_time" label="From" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="callbackTimeMenu" v-model="callback.callback_time" full-width @click:minute="$refs.callbackTimeMenu.save(callback.callback_time)"></v-time-picker>
                    </v-menu>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="6" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Preferred Branch:
                    </span>
                    <v-select autocomplete="off" :items="branches" item-value="id" item-text="branch" v-model="callback.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
                <v-col cols="6" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Urgency
                    </span>
                  </label>
                  <v-select autocomplete="off" :items="priority" v-model="callback.urgency" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Select Consultant
                    </span>
                    <v-select autocomplete="off" :items="staff" v-model="callback.consultant_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Status
                    </span>
                  </label>
                  <v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('callback')}))" v-model="callback.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="form-btn-wrapper">
      <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
        Cancel
      </v-btn>
      <v-btn color="secondary" class="b-blue" depressed rounded x-large :loading="loading" @click="update">
        Save Changes
      </v-btn>
    </div>
  </div>
</template>
<script>
import globalFn from '../../../mixins/globalFn.js';
import status from '../../../mixins/status.js';
export default {
  mixins: [globalFn, status],
  data: () => ({
    navigationTab: 'callback',
    loading: false,
    callbackDateMenu: false,
    callbackTimeMenu: false,
    staff: [],
    staffParams: {
      branch: '',
      type: 'callback'
    },
  }),
  mounted() {
    this.staffByBranch()
  },
  computed: {
    callback() {
      if (this.$store.state.callback.item) {
        if (!this.$store.state.callback.item.user) {
          this.$store.state.callback.item.user = {}
        }
        return this.$store.state.callback.item
      }
    },
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList
      }
    },
    priority() {
      if (this.$store.state.complaints.priority) {
        return this.$store.state.complaints.priority
      }
    },
  },
  created() {
    this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
    this.$store.dispatch('branches/GET_ALL_BRANCH', '')
    this.$store.dispatch('complaints/GET_PRIORITY')
  },
  methods: {

    close() {
      this.$root.$emit('toggleCallbackDrawer', true)
    },
    async staffByBranch() {
      this.staff = []
      this.staffParams.branch = this.callback.branch_id
      await this.$store.dispatch('staff/GET_STAFF_LIST', { data: this.staffParams, params: '' }).then(response => {
        if (response.result === 'success') {
          this.staff = response.data
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },
    async update() {
      let formData = {}
      formData.id = this.callback.id
      formData.user_id = this.callback.user_id
      formData.callback_date = this.callback.callback_date
      formData.callback_time = this.callback.callback_time
      formData.branch_id = this.callback.branch_id
      formData.consultant_id = this.callback.consultant_id
      formData.status = this.callback.status
      formData.urgency = this.callback.urgency
      formData.treatment_id = []
      this.callback.treatments.forEach(value => {
        if (value.id) {
          formData.treatment_id.push(value.id);
        } else {
          formData.treatment_id.push(value);
        }
      });
      if (this.$refs.callbackForm.validate()) {
        this.loading = true
        await this.$store.dispatch('callback/UPDATE_CALLBACK', formData).then(response => {
          if (response.result === 'success') {
            this.loading = false
            this.close()
          } else if (response.result === 'fail') {
            response.type = 'error'
            this.loading = false
            this.$store.dispatch('snackbar/SHOW', response)
          }
        }).catch(err => {
          this.loading = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        });
      }
    },
  }
};

</script>
