import http from '../http/common'

const resource = '/dashboard'

export default {

	moduleStats: (payload) => http.post(`${resource}/get-modules-count`, payload),
	moduleStatsByMonth: (payload) => http.post(`${resource}/get-modules-count-month`, payload),
	dealStats: (payload) => http.post(`${resource}/get-deal-stats`, payload),
	dealByTreatment: (payload) => http.post(`${resource}/get-deals-by-treatment`, payload),
	dealByConsultant: (payload) => http.post(`${resource}/get-deals-by-consultant`, payload),
	overdueKpi: (payload) => http.post(`${resource}/get-overdue-kpi`, payload),
	dealChannel: (payload) => http.post(`${resource}/get-deals-by-treatment-channel`, payload),
	appointmentChannel: (payload) => http.post(`${resource}/get-appointments-by-treatment-channel`, payload),
	channelSourceByAppointmentMonth: (payload) => http.post(`${resource}/get-source-by-appointment-month`, payload),
	channelSourceByOpportunityToday: (payload) => http.post(`${resource}/get-source-by-opportunity-today`, payload),
	channelSourceByOpportunityEnd: (payload) => http.post(`${resource}/get-source-by-opportunity-end`, payload),
}
