<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 tab-brd">
        <div class="form-title mb-24">Add Appointment</div>
        <v-tabs v-model="tab" class="cust-tabs">
          <v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
          <v-tab href="#client">Client Details</v-tab>
        </v-tabs>
      </div>
      <div class="modal-inside py-0 right-pane">
        <v-tabs-items v-model="tab">
          <v-tab-item value="client">
            <v-form ref="form">
              <div class="tab-content-wrapper">
                <v-row dense class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <div v-if="dropdown">
                      <label>
                        <span class="label-txt">Find Client:</span>
                        <v-autocomplete v-model="form.user_id" :rules="rules.user_id" :items="items" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="clientChange">
                          <template slot="append">
                            <v-icon>mdi-chevron-down</v-icon>
                          </template>
                          <template slot="selection" slot-scope="data">
                            <!-- HTML that describe how select should rend.er selected items -->
                            <span v-if="data.item.name !== null">{{ data.item.name }}</span>
                            <span v-if="data.item.name === null && data.item.social_handle !== null">
                              <img v-if="data.item.source_id !== null" :src="`/images/${getSourceName(data.item.source_id)}.svg`" style="position: relative;top: 3px;">
                              <span class="ml-1">@{{ data.item.social_handle }}</span>
                            </span>
                            <span class="ml-1" v-if="data.item.email !== null"> - {{ data.item.email }}</span>
                            <span class="ml-1" v-if="data.item.contact_no !== null"> - {{ data.item.contact_no }}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <!-- HTML that describe how select should render items when the select is open -->
                            <span v-if="data.item.name !== null">{{ data.item.name }}</span>
                            <span v-if="data.item.name === null && data.item.social_handle !== null">
                              <img v-if="data.item.source_id !== null" :src="`/images/${getSourceName(data.item.source_id)}.svg`" style="position: relative;top: 3px;">
                              <span class="ml-1">@{{ data.item.social_handle }}</span>
                            </span>
                            <span class="ml-1" v-if="data.item.email !== null"> - {{ data.item.email }}</span>
                            <span class="ml-1" v-if="data.item.contact_no !== null"> - {{ data.item.contact_no }}</span>
                          </template>
                        </v-autocomplete>
                      </label>
                    </div>
                    <div class="client-info" v-if="!dropdown && selectedUser">
                      <div class="name" v-if="selectedUser.name !== null">
                        {{ selectedUser.name }}
                      </div>
                      <div class="d-flex name" v-if="selectedUser.name === null && selectedUser.social_handle !== null">
                        <img :src="`/images/${selectedUser.source_name.toLowerCase()}.svg`" class="mr-2" v-if="selectedUser.source_name !== null">
                        <span>
                          @{{ selectedUser.social_handle }}
                        </span>
                      </div>
                      <p class="mt-4">
                        <span class="contact" v-if="selectedUser.contact_no !== null">
                          <a :href="`tel:${selectedUser.contact_no}`">
                            <img src="/images/phone.svg" height="15"> {{ selectedUser.contact_no }}</a>
                        </span>
                        <span class="contact" v-if="selectedUser.email !== null">
                          <a :href="`tel:${selectedUser.email}`">
                            <img src="/images/email.svg" height="15"> {{ selectedUser.email }}</a>
                        </span>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Treatment / Service:
                      </span>
                    </label>
                    <v-autocomplete v-model="form.treatment_id" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="6" class="e-col">
                    <label>
                      <span class="label-txt">
                        Appointment Date:
                      </span>
                      <v-menu v-model="appointmentDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field autocomplete="off" v-model="appointmentDateUkFormat" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
                        </template>
                        <v-date-picker v-model="form.appointment_date" no-title @input="appointmentDateMenu = false;"></v-date-picker>
                      </v-menu>
                    </label>
                  </v-col>
                  <v-col cols="12" md="6" class="e-col">
                    <v-row>
                      <v-col cols="6">
                        <label>
                          <span class="label-txt">Appointment Time:</span>
                          <v-menu ref="appointmentFromTimeMenu" v-model="appointmentFromTimeMenu" :close-on-content-click="false" :return-value.sync="form.appointment_from_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined flat hide-details="auto" solo class="solo-cust" v-model="form.appointment_from_time" label="From" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="appointmentFromTimeMenu" v-model="form.appointment_from_time" full-width @click:minute="$refs.appointmentFromTimeMenu.save(form.appointment_from_time)"></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                      <v-col cols="6">
                        <label>
                          <v-menu ref="appointmentToTimeMenu" v-model="appointmentToTimeMenu" :close-on-content-click="false" :return-value.sync="form.appointment_to_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field outlined flat hide-details="auto" solo class="solo-cust mt-5" v-model="form.appointment_to_time" label="To" append-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="appointmentToTimeMenu" v-model="form.appointment_to_time" full-width @click:minute="$refs.appointmentToTimeMenu.save(form.appointment_to_time)"></v-time-picker>
                          </v-menu>
                        </label>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Preferred Branch:
                      </span>
                      <v-select autocomplete="off" :items="branches" item-value="id" item-text="branch" :rules="rules.branch_id" v-model="form.branch_id" solo outlined flat class="solo-cust" hide-details="auto" @change="staffByBranch">
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Select Consultant
                      </span>
                      <v-select autocomplete="off" :items="staff" v-model="form.consultant_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Appointment Type
                      </span>
                    </label>
                    <v-select autocomplete="off" :items="appointmentType" v-model="form.appointment_type" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="e-row">
                  <v-col cols="12" md="12" class="e-col">
                    <label>
                      <span class="label-txt">
                        Status
                      </span>
                    </label>
                    <v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('appointment')}))" v-model="form.status" :rules="rules.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="disabled" rounded x-large @click="addAppointment">
          Add Appointment
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn.js';
import status from '../../../mixins/status.js';
import add from './add.js';
export default {
  mixins: [globalFn, status, add],
};

</script>
