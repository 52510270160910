/* Auth */
export const USER_LOGIN = 'USER_LOGIN'
export const USER_REGISTRATION = 'USER_REGISTRATION'
export const USER_VERIFY = 'USER_VERIFY'
export const USER = 'USER'
export const LOGOUT = 'LOGOUT'
export const PASSWORD_RESET_LINK = 'PASSWORD_RESET_LINK'
export const PASSWORD_RESET = 'PASSWORD_RESET'
export const VERIFY_PASSWORD_RESET_LINK = 'VERIFY_PASSWORD_RESET_LINK'
export const UPGRADE_REQUEST = 'UPGRADE_REQUEST'
export const USER_LOGOUT = 'USER_LOGOUT'

/* Users */
export const GET_USERS = 'GET_USERS'
export const SHOW_USER = 'SHOW_USER'
export const CREATE_USER = 'CREATE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const CHANGE_STATUS_USER = 'CHANGE_STATUS_USER'
export const SEARCH_USER = 'SEARCH_USER'

/* GET_CUSTOMERS */
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const SHOW_CUSTOMER = 'SHOW_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const SEARCH_CUSTOMER = 'SEARCH_CUSTOMER'
export const FILTER_CUSTOMER = 'FILTER_CUSTOMER'
export const MAP_SEARCH = 'MAP_SEARCH'
export const CUSTOMER_STATUS_COUNT = 'CUSTOMER_STATUS_COUNT'
export const FILTER_MODULE = 'FILTER_MODULE'

/* Staff */
export const GET_STAFF = 'GET_STAFF'
export const CREATE_STAFF = 'CREATE_STAFF'
export const SHOW_STAFF = 'SHOW_STAFF'
export const UPDATE_STAFF = 'UPDATE_STAFF'
export const DELETE_STAFF = 'DELETE_STAFF'
export const SEARCH_STAFF = 'SEARCH_STAFF'

/* GET_INTRODUCERS */
export const GET_INTRODUCERS = 'GET_INTRODUCERS'
export const GET_INTRODUCERS_LIST = 'GET_INTRODUCERS_LIST'
export const CREATE_INTRODUCER = 'CREATE_INTRODUCER'
export const SHOW_INTRODUCER = 'SHOW_INTRODUCER'
export const UPDATE_INTRODUCER = 'UPDATE_INTRODUCER'
export const DELETE_INTRODUCER = 'DELETE_INTRODUCER'
export const SEARCH_INTRODUCER = 'SEARCH_INTRODUCER'


/* GET_PERMISSIONS */
export const GET_PERMISSIONS = 'GET_PERMISSIONS'

/* GET_SERVICES */
export const GET_SERVICES = 'GET_SERVICES'

/* Qualifies */
export const GET_QUALIFIES = 'GET_QUALIFIES'
export const CREATE_QUALIFY = 'CREATE_QUALIFY'
export const SHOW_QUALIFY = 'SHOW_QUALIFY'
export const UPDATE_QUALIFY = 'UPDATE_QUALIFY'
export const DELETE_QUALIFY = 'DELETE_QUALIFY'
export const SEARCH_QUALIFY = 'SEARCH_QUALIFY'
export const FILTER_QUALIFY = 'FILTER_QUALIFY'
export const QUALIFY_REQUEST = 'QUALIFY_REQUEST'
export const QUALIFY_STATUS_COUNT = 'QUALIFY_STATUS_COUNT'

/* Surveys */
export const GET_SURVEYS = 'GET_SURVEYS'
export const CREATE_SURVEY = 'CREATE_SURVEY'
export const SHOW_SURVEY = 'SHOW_SURVEY'
export const UPDATE_SURVEY = 'UPDATE_SURVEY'
export const DELETE_SURVEY = 'DELETE_SURVEY'
export const SEARCH_SURVEY = 'SEARCH_SURVEY'
export const FILTER_SURVEY = 'FILTER_SURVEY'
export const SURVEY_REQUEST = 'SURVEY_REQUEST'
export const SURVEY_STATUS_COUNT = 'SURVEY_STATUS_COUNT'
export const CHECK_SURVEY_SLOT = 'CHECK_SURVEY_SLOT'
export const GET_SURVEYOR_LIST = 'GET_SURVEYOR_LIST'

/* Underfloor */
export const GET_UNDERFLOOR = 'GET_UNDERFLOOR'
export const CREATE_UNDERFLOOR = 'CREATE_UNDERFLOOR'
export const SHOW_UNDERFLOOR = 'SHOW_UNDERFLOOR'
export const UPDATE_UNDERFLOOR = 'UPDATE_UNDERFLOOR'
export const DELETE_UNDERFLOOR = 'DELETE_UNDERFLOOR'
export const SEARCH_UNDERFLOOR = 'SEARCH_UNDERFLOOR'
export const FILTER_UNDERFLOOR = 'FILTER_UNDERFLOOR'
export const UNDERFLOOR_REQUEST = 'UNDERFLOOR_REQUEST'
export const UNDERFLOOR_STATUS_COUNT = 'UNDERFLOOR_STATUS_COUNT'
export const CHECK_UNDERFLOOR_SLOT = 'CHECK_UNDERFLOOR_SLOT'
export const GET_UFI_LIST = 'GET_UFI_LIST'

/* Boiler */
export const GET_BOILER = 'GET_BOILER'
export const CREATE_BOILER = 'CREATE_BOILER'
export const SHOW_BOILER = 'SHOW_BOILER'
export const UPDATE_BOILER = 'UPDATE_BOILER'
export const DELETE_BOILER = 'DELETE_BOILER'
export const SEARCH_BOILER = 'SEARCH_BOILER'
export const FILTER_BOILER = 'FILTER_BOILER'
export const BOILER_REQUEST = 'BOILER_REQUEST'
export const BOILER_STATUS_COUNT = 'BOILER_STATUS_COUNT'
export const CHECK_BOILER_SLOT = 'CHECK_BOILER_SLOT'
export const GET_BOILER_LIST = 'GET_BOILER_LIST'


/* ElecCert */
export const GET_ELEC = 'GET_ELEC'
export const CREATE_ELEC = 'CREATE_ELEC'
export const SHOW_ELEC = 'SHOW_ELEC'
export const UPDATE_ELEC = 'UPDATE_ELEC'
export const DELETE_ELEC = 'DELETE_ELEC'
export const SEARCH_ELEC = 'SEARCH_ELEC'
export const FILTER_ELEC = 'FILTER_ELEC'
export const ELEC_REQUEST = 'ELEC_REQUEST'
export const ELEC_STATUS_COUNT = 'ELEC_STATUS_COUNT'
export const CHECK_ELEC_SLOT = 'CHECK_ELEC_SLOT'
export const GET_ELEC_LIST = 'GET_ELEC_LIST'

/* Submissions */
export const GET_SUBMISSIONS = 'GET_SUBMISSIONS'
export const CREATE_SUBMISSION = 'CREATE_SUBMISSION'
export const SHOW_SUBMISSION = 'SHOW_SUBMISSION'
export const UPDATE_SUBMISSION = 'UPDATE_SUBMISSION'
export const DELETE_SUBMISSION = 'DELETE_SUBMISSION'
export const SEARCH_SUBMISSION = 'SEARCH_SUBMISSION'
export const FILTER_SUBMISSION = 'FILTER_SUBMISSION'
export const SUBMISSION_STATUS_COUNT = 'SUBMISSION_STATUS_COUNT'

/*Audit*/
export const GET_AUDIT = 'GET_AUDIT'


/* GET_STATUS */
export const GET_STATUS = 'GET_STATUS'

export const GET_DOCS = 'GET_DOCS'
export const CREATE_DOCS = 'CREATE_DOCS'
export const DELETE_DOCS = 'DELETE_DOCS'
export const SEARCH_DOCS = 'SEARCH_DOCS'
