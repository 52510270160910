export default {
	data: () => ({
		widgets: [{
			name: 'Urgent',
			active: false,
			slug: 'urgent',
			module: ['opportunities']
		}, {
			name: 'New',
			active: false,
			slug: 'new',
			module: ['opportunities']
		}, {
			name: 'Active',
			active: false,
			slug: 'active',
			module: ['client']
		}, {
			name: 'Appointments Today',
			active: false,
			slug: 'appointments',
			module: ['opportunities']
		}, {
			name: 'Callbacks Today',
			active: false,
			slug: 'callbacks',
			module: ['opportunities']
		}, {
			name: 'Follow-Up Today',
			active: false,
			slug: 'followup_today',
			module: ['opportunities']
		}, {
			name: 'Follow-Up Overdue',
			active: false,
			slug: 'followup_overdue',
			module: ['opportunities']
		}]
	}),
	mounted() {
		this.loadCount()
	},
	methods: {
		async loadCount() {
			let name = this.$router.currentRoute.meta.name
			let action = (name === 'opportunities') ? 'opportunities' : 'clients'
			let params = (name === 'Leads') ? this.leadParams.filter.branch : this.clientsParams.filter.branch
			let counts = {}
			await this.$store.dispatch(`${action}/WIDGET_COUNT`, { branch: params }).then(res => {
				counts = res.data
			})
			this.widgets = this.widgets.map(
				function(el) {
					let item = Object.assign({}, el);
					item.count = (counts.hasOwnProperty(el.slug)) ? counts[el.slug] : 0
					return item;
				}
			);
		},
	}

}
