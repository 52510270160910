import http from '../../http/common'

const resource = '/invoice-payment'

export default {

	create: (payload) => http.post(resource, payload),

	list: (payload, params) => http.post(`${resource}/get-all${params}`, payload),

	get: (payload, params) => http.post(`${resource}${params}`, payload),

	show: (id, params) => http.get(`${resource}/${id}${params}`),

	update: (id, payload) => http.put(`${resource}/${id}`, payload),

	delete: (id) => http.delete(`${resource}/${id}`),

	search: (params) => http.get(`${resource}/search${params}`)

}
