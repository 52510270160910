<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Stock History for {{product.name}}</div>
        <div class="tab-content-wrapper">
          <v-data-table class="results-table" item-key="id" :headers="headers" :items="items" :loading="loading" :options.sync="options" sort-by="updated_at" :items-per-page="itemsPerPage" :footer-props="footerProps" :server-items-length="totalCount">
            <template v-slot:item.amount="{ item }">
              <span v-if="item.quantity !== null">{{ item.quantity }}</span>
            </template>
            <template v-slot:item.movement="{ item }">
              <span>{{ (item.movement === 1) ? 'In' : 'Out' }}</span>
            </template>
            <template v-slot:item.user="{ item }">
              <span v-if="item.user && item.user !== null">{{item.user.name}}</span>
            </template>
            <template v-slot:item.movement_date="{ item }">
              <span v-if="item.movement_date !== null">{{item.movement_date | moment("DD/MM/YY @ H:mm")}}</span>
            </template>
          </v-data-table>
        </div>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Close
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../../store/action-types.js';
import status from '../../../../mixins/status.js';
import globalFn from '../../../../mixins/globalFn.js';
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem('user')),
    tab: null,
    headers: [{ text: 'Stock', align: 'start', value: 'amount', align: 'left', sortable: false },
      { text: 'Movement ', value: 'movement', sortable: false, align: 'left' },
      { text: 'Added By ', value: 'user', sortable: false, align: 'left' },
      { text: 'Added At', value: 'movement_date', sortable: false, align: 'left' }
    ],
    search: "",
    isTyping: false,
    page: 1,
    totalCount: 0,
    itemsPerPage: 10,
    options: {},
    sortOrder: 'desc',
    sortBy: 'updated_at',
    items: [],
    footerProps: {
      itemsPerPageOptions: [10, 15, 20, 25, 50],
      showFirstLastPage: true,
      showCurrentPage: true,
    },
  }),
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true
    },
  },
  computed: {
    product() {
      if (this.$store.state.products.item) {
        return this.$store.state.products.item
      }
    }
  },
  mounted() {
    this.readTheDataFromApi()
  },
  methods: {
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi()
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch('stock/SHOW_BY_PRODUCT', { product_id: this.product.id }).then(res => {
        this.items = res.data.data
        this.totalCount = res.data.total
      })
      this.loading = false;
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    }
  }
};

</script>
