<template>
  <div v-if="item">
    <v-snackbar v-model="show" :right="true" :top="true" :color="item.type" :timeout="6000">
      <template v-slot:action="{ attrs }">
        <v-btn icon color="#fff">
          <v-icon v-bind="attrs" color="#fff" small @click="show = false">
            mdi-close
          </v-icon>
        </v-btn>
      </template>
      <span v-if="item.display_message"> {{ item.display_message }}</span>
      <span v-else="item.message"> {{ item.message }}</span>
      <ul v-if="item.error && item.error.details">
        <template v-for="i  in item.error.details">
          <li :key="i">
            {{i}}
          </li>
        </template>
      </ul>
      <template v-if="item.treatments">
        <div v-html="item.treatments"></div>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
// import storeMixin from '~/mixins/storeMixin.js'

export default {
  // mixins: [storeMixin],
  data() {
    return {
      componentName: 'snackbar',
      options: {}
    }
  },
  computed: {
    list() {
      return this.$store.state.snackbar.list
    },
    item() {
      return this.$store.state.snackbar.item
    },
    show: {
      get() {
        return this.$store.state.snackbar.item
      },
      set() {
        this.$store.commit('snackbar/RESET_SNACKBAR')
      }
    }
  }
}

</script>
