<template>
	<div>
		<v-menu :close-on-content-click="false" offset-y left content-class="user-action">
			<template v-slot:activator="{ on, attrs }">
				<v-btn class="b-btn b-advance" depressed v-on="on">
					Advanced <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
				</v-btn>
			</template>
			<div class="advance-sec">
				<div class="head">
					Advanced Search
					<v-chip small class="este-chip yellow-chip float-right" text-color="black" @click="searchKey">
						Filter
					</v-chip>
					<v-chip small color="#221F20" class="este-chip mb-2 float-right" text-color="#FFFFFF" @click="reset">
						Reset
					</v-chip>
				</div>
				<v-divider></v-divider>
				<v-row class="filter-row">
					<v-col cols="12" md="6" sm="12" class="pa-5">
						<span class="heading">Created At:</span>
						<div class="d-flex mt-2 mb-4">
							<v-menu v-model="createdAtFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
								<template v-slot:activator="{ on }">
									<v-text-field autocomplete="off" :value="dateUkFormat(filter.created_at_from)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date from" prepend-inner-icon="mdil-calendar"></v-text-field>
								</template>
								<v-date-picker v-model="filter.created_at_from" no-title @input="createdAtFromMenu = false"></v-date-picker>
							</v-menu>
							<v-menu v-model="createdAtToMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
								<template v-slot:activator="{ on }">
									<v-text-field autocomplete="off" :value="dateUkFormat(filter.created_at_to)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date to" prepend-inner-icon="mdil-calendar"></v-text-field>
								</template>
								<v-date-picker v-model="filter.created_at_to" no-title @input="createdAtToMenu = false"></v-date-picker>
							</v-menu>
						</div>
					</v-col>
					<v-divider vertical></v-divider>
					<v-col cols="12" md="6" sm="12" class="pa-5">
						<span class="heading">Branch:
							<v-checkbox hide-details class="shrink mt-0" v-model="BranchToggle" @click="handleFunction('BranchCheckUncheck')" :ripple="false"></v-checkbox>
						</span>
						<div class="mt-2">
							<template v-for="branch in BrachDefault">
								<v-chip small class="este-chip tag-chip mb-2" :class="(branch.active === true)  ? 'selected' : ''" text-color="black" @click="branchToggle(branch)">
									{{ branch.branch | capitalize }}
								</v-chip>
							</template>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-menu>
	</div>
</template>
<script>
import advancedSearch from '../../mixins/advancedSearch';
import globalFn from '../../mixins/globalFn';
import status from '../../mixins/status';
import _ from 'lodash';
export default {
	name: "leadSearch",
	mixins: [globalFn, status, advancedSearch],
	props: {
		screen: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		}
	},
	mounted() {
		this.reset()
	},
	data: () => ({
		dateMenu: false,
		createdAtFromMenu: false,
		createdAtToMenu: false,
		user: JSON.parse(localStorage.getItem('user')),
		filter: {
			created_at_from: '',
			created_at_to: '',
			status: [],
			branch: [],
			date: new Date().toISOString().slice(0, 10)
		},
	}),
	methods: {
		getDateRange() {
			const today = new Date();
			const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
			const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));
			this.filter.created_at_from = firstDay.toISOString().slice(0, 10);
			this.filter.created_at_to = lastDay.toISOString().slice(0, 10);

		},
		async searchKey() {
			this.$root.$emit('SearchDashboard', this.filter)
		},
		async reset() {
			this.getDateRange()
			await this.loadDefaultBranch()
			await this.searchKey()
		}
	}
};

</script>
