var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-form',{ref:"purchaseInvoiceForm"},[_c('v-data-table',{staticClass:"results-table invoices",attrs:{"single-expand":true,"expanded":_vm.expanded,"item-class":_vm.AddRowClass,"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name-column"},[_c('p',{staticClass:"user-name"},[_vm._v("\n                    "+_vm._s(item.contact_name)+"\n                  ")]),_vm._v(" "),(item.contact_no !== null && item.contact_no !== '')?_c('p',{staticClass:"ml-n1"},[_c('span',{staticClass:"contact"},[_c('a',{attrs:{"href":("tel:" + (item.contact_no))}},[_c('img',{attrs:{"src":"/images/phone.svg","height":"15"}}),_vm._v(" "+_vm._s(item.contact_no))])])]):_vm._e(),_vm._v(" "),(item.email !== null && item.email !== '')?_c('p',{staticClass:"mt-4 ml-n1 mb-4"},[_c('span',{staticClass:"contact"},[_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_c('img',{attrs:{"src":"/images/email.svg","height":"15"}}),_vm._v(" "+_vm._s(item.email))])])]):_vm._e()])]}},{key:"item.dates",fn:function(ref){
var item = ref.item;
return [(item.invoice_date !== null && item.invoice_date !== '')?_c('div',{staticClass:"date-column"},[_c('p',{staticClass:"date-sec date-mute"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdil-calendar")])],1),_vm._v(" "+_vm._s(_vm._f("moment")(item.invoice_date,"DD/MM/YY"))+"\n                  ")],1),_vm._v(" "),(item.due_date !== null && item.due_date !== '')?[(item.due_date !== null && item.due_date !== '')?_c('p',{staticClass:"date-sec vertical-bar"},[_vm._v("|")]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"date-sec"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v("mdil-calendar")])],1),_vm._v(" "+_vm._s(_vm._f("moment")(item.due_date,"DD/MM/YY"))+"\n                    ")],1)]:_vm._e()],2):_vm._e()]}},{key:"item.contact_name",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-text-field',{staticClass:"solo-cust inline-input",attrs:{"autocomplete":"off","solo":"","outlined":"","flat":"","hide-details":"auto","placeholder":"Company Name"},model:{value:(_vm.editedItem.contact_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "contact_name", $$v)},expression:"editedItem.contact_name"}}):_c('div',{staticClass:"name-column"},[_c('p',{staticClass:"user-name"},[_vm._v("\n                    "+_vm._s(item.contact_name)+"\n                  ")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?[_c('v-text-field',{staticClass:"mb-2 solo-cust inline-input",attrs:{"placeholder":"Email Address","autocomplete":"off","solo":"","outlined":"","flat":"","hide-details":"auto"},on:{"input":_vm.validateEmailContact},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}}),_vm._v(" "),_c('v-text-field',{staticClass:"solo-cust inline-input",attrs:{"placeholder":"Contact Number","autocomplete":"off","solo":"","outlined":"","flat":"","hide-details":"auto"},on:{"input":_vm.validateEmailContact},model:{value:(_vm.editedItem.contact_no),callback:function ($$v) {_vm.$set(_vm.editedItem, "contact_no", $$v)},expression:"editedItem.contact_no"}})]:[(item.contact_no !== null && item.contact_no !== '')?_c('p',{staticClass:"ml-n1"},[_c('span',{staticClass:"contact"},[_c('a',{attrs:{"href":("tel:" + (item.contact_no))}},[_c('img',{attrs:{"src":"/images/phone.svg","height":"15"}}),_vm._v(" "+_vm._s(item.contact_no))])])]):_vm._e(),_vm._v(" "),(item.email !== null && item.email !== '')?_c('p',{staticClass:"mt-4 ml-n1 mb-4"},[_c('span',{staticClass:"contact"},[_c('a',{attrs:{"href":("tel:" + (item.email))}},[_c('img',{attrs:{"src":"/images/email.svg","height":"15"}}),_vm._v(" "+_vm._s(item.email))])])]):_vm._e()]]}},{key:"item.invoice_no",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-text-field',{staticClass:"solo-cust inline-input",attrs:{"placeholder":"Invoice No","autocomplete":"off","solo":"","outlined":"","flat":"","hide-details":"auto"},model:{value:(_vm.editedItem.invoice_no),callback:function ($$v) {_vm.$set(_vm.editedItem, "invoice_no", $$v)},expression:"editedItem.invoice_no"}}):[_vm._v("\n                  "+_vm._s(item.invoice_no)+"\n                ")]]}},{key:"item.net_amount",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-text-field',{staticClass:"solo-cust inline-input",attrs:{"placeholder":"Net Amount","autocomplete":"off","solo":"","outlined":"","rules":_vm.rules.net_amount,"flat":"","hide-details":"auto"},model:{value:(_vm.editedItem.net_amount),callback:function ($$v) {_vm.$set(_vm.editedItem, "net_amount", $$v)},expression:"editedItem.net_amount"}}):[_vm._v("\n                  "+_vm._s(_vm._f("currency")(item.net_amount,'£'))+"\n                ")]]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("currency")(item.total,'£'))+"\n              ")]}},{key:"item.balance_amount",fn:function(ref){
var item = ref.item;
return [_vm._v("\n                "+_vm._s(_vm._f("currency")(item.balance_amount,'£'))+"\n              ")]}},{key:"item.tax_type",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-select',{staticClass:"solo-cust",staticStyle:{"width":"150px"},attrs:{"placeholder":"Tax Type","autocomplete":"off","items":_vm.tax,"rules":_vm.rules.tax_type,"item-value":"id","item-text":"name","solo":"","outlined":"","flat":"","hide-details":"auto"},model:{value:(_vm.editedItem.tax_type),callback:function ($$v) {_vm.$set(_vm.editedItem, "tax_type", $$v)},expression:"editedItem.tax_type"}},[_c('template',{slot:"append"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],2):[_vm._v("\n                  "+_vm._s(item.tax_type)+"%\n                ")]]}},{key:"item.branch_id",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-select',{staticClass:"solo-cust",staticStyle:{"width":"150px"},attrs:{"placeholder":"Branch","autocomplete":"off","items":_vm.branches,"rules":_vm.rules.branch_id,"item-value":"id","item-text":"branch","solo":"","outlined":"","flat":"","hide-details":"auto"},model:{value:(_vm.editedItem.branch_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "branch_id", $$v)},expression:"editedItem.branch_id"}},[_c('template',{slot:"append"},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],2):_c('div',{staticClass:"name-column"},[_c('p',{staticClass:"user-name"},[_vm._v("\n                    "+_vm._s(item.branch_name)+"\n                  ")])])]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"solo-cust inline-input",attrs:{"placeholder":"Invoice Date","autocomplete":"off","value":_vm.dateUKformat(_vm.editedItem.invoice_date),"solo":"","outlined":"","flat":"","hide-details":"auto","append-icon":"mdil-calendar"}},on))]}}],null,true),model:{value:(_vm.invoiceMenu),callback:function ($$v) {_vm.invoiceMenu=$$v},expression:"invoiceMenu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.invoiceMenu = false}},model:{value:(_vm.editedItem.invoice_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "invoice_date", $$v)},expression:"editedItem.invoice_date"}})],1):[(item.invoice_date !== null && item.invoice_date !== '')?_c('p',{staticClass:"date-sec date-mute"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdil-calendar")])],1),_vm._v("\n                    "+_vm._s(_vm._f("moment")(item.invoice_date,"DD/MM/YY"))+"\n                  ")],1):_vm._e()]]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [(item.id === _vm.editedItem.id)?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"solo-cust inline-input",attrs:{"placeholder":"Due Date","autocomplete":"off","value":_vm.dateUKformat(_vm.editedItem.due_date),"solo":"","outlined":"","flat":"","hide-details":"auto","append-icon":"mdil-calendar"}},on))]}}],null,true),model:{value:(_vm.dueMenu),callback:function ($$v) {_vm.dueMenu=$$v},expression:"dueMenu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.dueMenu = false}},model:{value:(_vm.editedItem.due_date),callback:function ($$v) {_vm.$set(_vm.editedItem, "due_date", $$v)},expression:"editedItem.due_date"}})],1):[(item.due_date !== null && item.due_date !== '')?_c('p',{staticClass:"date-sec date-mute"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdil-calendar")])],1),_vm._v(" "+_vm._s(_vm._f("moment")(item.due_date,"DD/MM/YY"))+"\n                  ")],1):_vm._e()]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.id !== _vm.editedItem.id)?_c('v-chip',{staticClass:"este-chip status-chip",attrs:{"small":"","text-color":"black","color":_vm.getPaymentStatusColor(item.payment_status)}},[_vm._v("\n                  "+_vm._s(_vm._f("capitalize")(item.payment_status.replace(/[^a-zA-Z ]/g, " ")))+"\n                ")]):_vm._e(),_vm._v(" "),(item.id === _vm.editedItem.id)?[_c('v-btn',{staticClass:"px-3 btn-inline",attrs:{"min-width":"32","height":"32","icon":"","color":"#4ACE28","loading":_vm.saveLoading},on:{"click":_vm.update}},[_c('v-icon',[_vm._v("mdi-check")])],1),_vm._v(" "),_c('v-btn',{staticClass:"px-3 btn-inline",attrs:{"min-width":"32","height":"32","icon":"","color":"#D34141","loading":_vm.cancelLoading},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("mdi-close")])],1)]:[_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")])]),_vm._v(" "),_c('li',[(!_vm.expanded.includes(item))?_c('a',{on:{"click":function($event){return _vm.toggleRow(item)}}},[_vm._v("View/Add Payments")]):_vm._e()]),_vm._v(" "),_c('li',[(_vm.expanded.includes(item))?_c('a',{on:{"click":function($event){return _vm.toggleRow(item)}}},[_vm._v("Hide Payments")]):_vm._e()]),_vm._v(" "),_c('li',[_c('a',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("Delete")])])])])])]]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expanded-table pa-5",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"child",attrs:{"no-gutters":""}},[_c('PaymentComponent',{attrs:{"invoiceId":item.id}}),_vm._v(" "),_c('AddPaymentComponent',{attrs:{"invoiceId":item.id}})],1)],1)]}}])})],1)],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalDialog),callback:function ($$v) {_vm.modalDialog=$$v},expression:"modalDialog"}},[_c('v-card',{staticClass:"delete-alert"},[_c('v-card-title',[_vm._v("\n        Confirm\n        "),_c('a',{staticClass:"float-right pa-6",on:{"click":function($event){_vm.modalDialog = false}}},[_c('img',{attrs:{"src":"/images/close.svg","alt":"Close"}})])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.message)+"\n      ")]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"delete-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap",attrs:{"depressed":"","rounded":"","outlined":"","color":"dark","x-large":""},on:{"click":function($event){_vm.modalDialog = false}}},[_vm._v("\n            Cancel\n          ")]),_vm._v(" "),_c('v-btn',{staticClass:"delete-btn",attrs:{"color":(_vm.actionText === 'Delete') ? 'red' : 'secondary',"depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.handleFunction(_vm.action)}}},[_vm._v("\n            "+_vm._s(_vm.actionText)+"\n          ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }