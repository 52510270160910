<template>
	<div>
		<div class="fill-height slider-cont">
			<div class="modal-inside pb-0 tab-brd">
				<div class="form-title mb-24">Edit Complaint</div>
				<v-tabs v-model="navigationTab" class="cust-tabs">
					<template v-for="(item, index) in navigation">
						<v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
						<v-tab :href="`#${item.slug}`">
							{{item.name}}
						</v-tab>
					</template>
				</v-tabs>
			</div>
			<div class="modal-inside pt-0 right-pane">
				<v-tabs-items v-model="navigationTab">
					<v-tab-item value="client" :eager="true">
						<v-form ref="leadForm">
							<div class="tab-content-wrapper">
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Find Client:
											</span>
										</label>
										<v-autocomplete v-model="complaint.user_id" :rules="rules.user_id" :items="clients" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="loadPersonal">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
											<template slot="selection" slot-scope="data">
												<!-- HTML that describe how select should rend.er selected items -->
												<span v-if="data.item.name">{{ data.item.name }}</span>
												<span v-else>
													<img v-if="data.item.source_id !== null" :src="`/images/${getSourceName(data.item.source_id)}.svg`" style="position: relative;top: 3px;">
													<span class="ml-1">@{{ data.item.social_handle }}</span>
												</span>
												<span class="ml-1" v-if="data.item.email !== null"> - {{ data.item.email }}</span>
												<span class="ml-1" v-if="data.item.contact_no !== null"> - {{ data.item.contact_no }}</span>
											</template>
											<template slot="item" slot-scope="data">
												<!-- HTML that describe how select should render items when the select is open -->
												<span v-if="data.item.name">{{ data.item.name }}</span>
												<span v-else>
													<img v-if="data.item.source_id !== null" :src="`/images/${getSourceName(data.item.source_id)}.svg`" style="position: relative;top: 3px;">
													<span class="ml-1">@{{ data.item.social_handle }}</span>
												</span>
												<span class="ml-1" v-if="data.item.email !== null"> - {{ data.item.email }}</span>
												<span class="ml-1" v-if="data.item.contact_no !== null"> - {{ data.item.contact_no }}</span>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Phone No.
											</span>
											<v-text-field autocomplete="off" solo outlined v-model="personal.contact_no" flat hide-details="auto" class="solo-cust" append-icon="mdil-phone"></v-text-field>
										</label>
									</v-col>
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Email Address
											</span>
											<v-text-field autocomplete="off" :rules="rules.email" v-model="personal.email" solo outlined flat hide-details="auto" class="solo-cust" append-icon="mdil-email"></v-text-field>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Address
											</span>
											<v-text-field autocomplete="off" solo outlined flat v-model="personal.address_one" hide-details="auto" class="solo-cust mb-2"></v-text-field>
											<v-text-field autocomplete="off" solo outlined flat v-model="personal.address_two" hide-details="auto" class="solo-cust mar-8"></v-text-field>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Town / City
											</span>
											<v-text-field autocomplete="off" solo outlined flat v-model="personal.city" hide-details="auto" class="solo-cust"></v-text-field>
										</label>
									</v-col>
									<v-col cols="12" md="6" class="e-col">
										<v-row no-gutters>
											<v-col cols="6">
												<label>
													<span class="label-txt">
														Post Code
													</span>
													<v-text-field autocomplete="off" solo outlined flat v-model="personal.postcode" hide-details="auto" class="solo-cust"></v-text-field>
												</label>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Preferred Branch:
											</span>
											<v-select autocomplete="off" :items="branches" item-value="id" item-text="branch" v-model="personal.branch_id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</label>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</v-tab-item>
					<v-tab-item value="complaint" :eager="true">
						<v-form ref="complaintForm">
							<div class="tab-content-wrapper">
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Date Raised
											</span>
										</label>
										<v-menu v-model="dateRaisedMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field autocomplete="off" v-model="dateRaisedUkFormat" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
											</template>
											<v-date-picker v-model="complaint.date_raised" no-title @input="dateRaisedMenu = false"></v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Complaint Type
											</span>
										</label>
										<v-select autocomplete="off" :items="category" v-model="complaint.category_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Escalation Level
											</span>
										</label>
										<v-select autocomplete="off" :items="escalation" v-model="complaint.escalation_level" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Responsible
											</span>
										</label>
										<v-select autocomplete="off" :items="staff" :rules="rules.responsible_id" v-model="complaint.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Complaint Overview
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="complaint.description" flat hide-details="auto" rows="5" row-height="25" placeholder="Base of complaint, who it was raised with, what it relates to, actions take so far"></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Deals List
											</span>
										</label>
										<v-select autocomplete="off" :items="dealsbyclient" v-model="deal_id" item-text="deal_info" item-value="id" solo outlined flat class="solo-cust" hide-details="auto" @change="loadDealInformation(deal_id)">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<div v-if="Object.keys(dealInfoById).length > 0">
									<deal :deal="dealInfoById" />
								</div>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Pabau ID / Link
											</span>
										</label>
										<v-text-field autocomplete="off" solo outlined flat v-model="complaint.pabau_id" hide-details="auto" class="solo-cust mb-2"></v-text-field>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Any medical conditions?
											</span>
										</label>
										<v-select autocomplete="off" :items="medical_conditions" v-model="complaint.medical_condition" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Machine Used
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="complaint.machine_used" flat hide-details="auto" rows="5" row-height="25" placeholder="Machine, temperature, any client comments/concerns on higher/lower temperature from client, side effects mentioned."></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Products Sold
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="complaint.products_sold" flat hide-details="auto" rows="5" row-height="25" placeholder="Items that were sold, why where they sold, were they part of the package or additional."></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Were complimentary sessions offered?
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="complaint.complimentary_sessions" flat hide-details="auto" rows="5" row-height="25" placeholder="Details of what was offered, why they were offered, what was the outcome."></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Next Action
											</span>
										</label>
										<v-select autocomplete="off" :items="nextActions" v-model="complaint.next_action" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Next Action Date
											</span>
										</label>
										<v-menu v-model="nextActionDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field autocomplete="off" v-model="nextActionUkFormat" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
											</template>
											<v-date-picker v-model="complaint.next_action_date" no-title @input="nextActionDateMenu = false"></v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Urgency
											</span>
										</label>
										<v-select autocomplete="off" :items="priority" v-model="complaint.urgency" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Status
											</span>
										</label>
										<v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('complaint')}))" v-model="complaint.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</v-tab-item>
					<v-tab-item value="insight" :eager="true">
						<tab-insight :itemData="complaint" :userId="complaint.user_id" :keyId="complaint.id"></tab-insight>
					</v-tab-item>
					<v-tab-item value="activity" :eager="true">
						<tab-activity :itemData="complaint" :userId="complaint.user_id" :keyId="complaint.id"></tab-activity>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
					Cancel
				</v-btn>
				<v-btn color="secondary" class="b-blue" depressed :loading="loading" rounded x-large @click="updateComplaint">
					Save changes
				</v-btn>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { USER, CREATE_CUSTOMER, GET_SERVICES } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn.js';
import status from '../../../mixins/status.js';
import TabActivity from '../../../components/TabItems/TabActivity';
import TabInsight from '../../../components/TabItems/TabInsight';
import edit from './edit.js';
import deal from './deal.vue';
export default {
	mixins: [globalFn, status, edit],
	components: {
		TabActivity,
		TabInsight,
		deal
	},
};

</script>
