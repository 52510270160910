<template>
	<div>
		<v-text-field flat prepend-inner-icon="mdi-magnify" autocomplete="off" label="Search for a payment…" solo hide-details class="search-input" @input="isTyping = true" v-model="search">
			<template v-slot:append>
				<v-menu :close-on-content-click="false" offset-y left content-class="">
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="b-btn b-advance" depressed v-on="on">
							Advanced <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
						</v-btn>
					</template>
					<div class="advance-sec">
						<div class="head">
							Advanced Search
							<v-chip small class="este-chip yellow-chip float-right" text-color="black" @click="searchKey">
								Filter
							</v-chip>
							<v-chip small color="#221F20" class="este-chip mb-2 float-right" text-color="#FFFFFF" @click="reset">
								Reset
							</v-chip>
						</div>
						<v-divider></v-divider>
						<v-row class="filter-row">
							<v-col cols="12" sm="6" md="4">
								<span class="heading">Payment:</span>
								<div class="d-flex mt-2 mb-4">
									<v-menu v-model="paymentdateFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.payment_from)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date from" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.payment_from" no-title @input="paymentdateFromMenu = false"></v-date-picker>
									</v-menu>
									<v-menu v-model="paymentdateToMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template v-slot:activator="{ on }">
											<v-text-field autocomplete="off" :value="dateUkFormat(filter.payment_to)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date to" prepend-inner-icon="mdil-calendar"></v-text-field>
										</template>
										<v-date-picker v-model="filter.payment_to" no-title @input="paymentdateToMenu = false"></v-date-picker>
									</v-menu>
								</div>
							</v-col>
							<v-col cols="12" sm="6" md="4">
								<span class="heading">Status:</span>
								<div class="mt-2">
									<template v-for="stat in StatusExpenses">
										<v-chip small class="este-chip status-chip mb-2" :outlined="stat.outlined" :text-color="stat.text ===  true ? getColor(stat.name) : '#221F20'" :color="getColor(stat.name)" @click="statusToggle(stat)">
											{{ stat.name | capitalize }}
											<v-icon right color="black" v-if="stat.active" size="15">mdil-check</v-icon>
										</v-chip>
									</template>
								</div>
							</v-col>
							<v-col cols="12" sm="6" md="4">
								<span class="heading">Branch:
									<v-checkbox hide-details class="shrink mt-0" v-model="BranchToggle" @click="handleFunction('BranchCheckUncheck')" :ripple="false"></v-checkbox>
								</span>
								<div class="mt-2">
									<template v-for="branch in BrachDefault">
										<v-chip small class="este-chip tag-chip mb-2" :class="(branch.active === true)  ? 'selected' : ''" text-color="black" @click="branchToggle(branch)">
											{{ branch.branch | capitalize }}
										</v-chip>
									</template>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-menu>
			</template>
		</v-text-field>
	</div>
</template>
<script>
import advancedSearch from '../../mixins/advancedSearch';
import globalFn from '../../mixins/globalFn';
import status from '../../mixins/status';
import _ from 'lodash';
export default {
	name: "leadSearch",
	mixins: [globalFn, status, advancedSearch],
	props: {
		screen: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		}
	},
	data: () => ({
		paymentdateFromMenu: false,
		paymentdateToMenu: false,
		user: JSON.parse(localStorage.getItem('user')),
		params: '?&sort_order=DESC&sort_by=name',
		filter: {
			search: '',
			payment_from: '',
			payment_to: '',
			status: [],
			branch: []
		},
		search: "",
		isTyping: false
	}),
	watch: {
		search: _.debounce(function() {
			this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.filter.search = this.search
				this.searchKey();
			}
		}
	},
	mounted() {
		this.loadDefaults()
	},
	methods: {
		async searchKey() {
			this.$root.$emit('searchCashPayments', this.filter)
		},
		async statusToggle(item) {
			this.filter.status = []
			item.active = !item.active
			this.StatusExpenses.forEach(value => {
				if (value.active === true) {
					this.filter.status.push(parseInt(value.id));
				}
			});
			await this.searchKey()
		},
		async reset() {
			this.filter.search = ''
			this.filter.payment_to = ''
			this.filter.payment_from = ''
			this.filter.status = []
			this.filter.branch = []
			this.branches.forEach(value => {
				value.active = false
			});
			this.StatusExpenses.forEach(value => {
				value.active = false
			});
			await this.searchKey()
		}
	}
};

</script>
