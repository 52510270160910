import collection from '../../api/collections/Configurations'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    item: {}
  },
  mutations: {
    SET_LOGO(state, data) {
      state.item = data
    }
  },
  actions: {
    async GET_LOGO({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload)
        commit('SET_LOGO', data.data)
        return data
      }, commit, dispatch, 'GET_LOGO', true)
    },
    async UPLOAD_LOGO({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.uploadLogo(payload)
        return data
      }, commit, dispatch, 'UPLOAD_LOGO', true)
    }
  }
}
