<template>
	<div>
		<div class="fill-height slider-cont">
			<div class="modal-inside pb-0 tab-brd">
				<div class="form-title mb-24">Add Complaint</div>
				<v-tabs v-model="tab" class="cust-tabs">
					<v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
					<v-tab href="#client">Client Details</v-tab>
					<v-tab href="#complaint">Complaint</v-tab>
				</v-tabs>
			</div>
			<div class="modal-inside pt-0 right-pane">
				<v-tabs-items v-model="tab">
					<v-tab-item value="client" :eager="true">
						<v-form ref="leadForm">
							<div class="tab-content-wrapper">
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<div v-if="dropdown">
											<label>
												<span class="label-txt">Find Client:</span>
												<v-autocomplete v-model="form.user_id" :rules="rules.user_id" :items="clients" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="loadDealsByClient">
													<template slot="append">
														<v-icon>mdi-chevron-down</v-icon>
													</template>
													<template slot="selection" slot-scope="data">
														<!-- HTML that describe how select should rend.er selected items -->
														<span v-if="data.item.name !== null">{{ data.item.name }}</span>
														<span v-if="data.item.name === null && data.item.social_handle !== null">
															<img v-if="data.item.source_id !== null" :src="`/images/${getSourceName(data.item.source_id)}.svg`" style="position: relative;top: 3px;">
															<span class="ml-1">@{{ data.item.social_handle }}</span>
														</span>
														<span class="ml-1" v-if="data.item.email !== null"> - {{ data.item.email }}</span>
														<span class="ml-1" v-if="data.item.contact_no !== null"> - {{ data.item.contact_no }}</span>
													</template>
													<template slot="item" slot-scope="data">
														<!-- HTML that describe how select should render items when the select is open -->
														<span v-if="data.item.name !== null">{{ data.item.name }}</span>
														<span v-if="data.item.name === null && data.item.social_handle !== null">
															<img v-if="data.item.source_id !== null" :src="`/images/${getSourceName(data.item.source_id)}.svg`" style="position: relative;top: 3px;">
															<span class="ml-1">@{{ data.item.social_handle }}</span>
														</span>
														<span class="ml-1" v-if="data.item.email !== null"> - {{ data.item.email }}</span>
														<span class="ml-1" v-if="data.item.contact_no !== null"> - {{ data.item.contact_no }}</span>
													</template>
												</v-autocomplete>
											</label>
										</div>
										<div class="client-info" v-if="!dropdown">
											<div class="name" v-if="selectedUser.name !== null">
												{{ selectedUser.name }}
											</div>
											<div class="d-flex name" v-if="selectedUser.name === null && selectedUser.social_handle !== null">
												<img :src="`/images/${selectedUser.source_name.toLowerCase()}.svg`" class="mr-2" v-if="selectedUser.source_name !== null">
												<span>
													@{{ selectedUser.social_handle }}
												</span>
											</div>
											<p class="mt-4">
												<span class="contact" v-if="selectedUser.contact_no !== null">
													<a :href="`tel:${selectedUser.contact_no}`">
														<img src="/images/phone.svg" height="15"> {{ selectedUser.contact_no }}</a>
												</span>
												<span class="contact" v-if="selectedUser.email !== null">
													<a :href="`tel:${selectedUser.email}`">
														<img src="/images/email.svg" height="15"> {{ selectedUser.email }}</a>
												</span>
											</p>
										</div>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</v-tab-item>
					<v-tab-item value="complaint" :eager="true">
						<v-form ref="complaintForm">
							<div class="tab-content-wrapper">
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Date Raised
											</span>
										</label>
										<v-menu v-model="dateRaisedMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field autocomplete="off" v-model="dateRaisedUkFormat" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
											</template>
											<v-date-picker v-model="form.date_raised" no-title @input="dateRaisedMenu = false"></v-date-picker>
										</v-menu>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Complaint Type
											</span>
										</label>
										<v-select autocomplete="off" :items="category" v-model="form.category_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Escalation Level
											</span>
										</label>
										<v-select autocomplete="off" :items="escalation" v-model="form.escalation_level" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Responsible
											</span>
										</label>
										<v-select autocomplete="off" :items="staff" :rules="rules.responsible_id" v-model="form.responsible_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Complaint Overview
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="form.description" flat hide-details="auto" rows="5" row-height="25" placeholder="Base of complaint, who it was raised with, what it relates to, actions take so far"></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Deals List
											</span>
										</label>
										<v-select autocomplete="off" :items="dealsbyclient" v-model="form.deal_id" item-text="deal_info" item-value="id" solo outlined flat class="solo-cust" hide-details="auto" @change="loadDealInformation(form.deal_id)">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<div v-if="Object.keys(dealInfoById).length > 0">
									<deal :deal="dealInfoById" />
								</div>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Pabau ID / Link
											</span>
										</label>
										<v-text-field autocomplete="off" solo outlined flat v-model="form.pabau_id" hide-details="auto" class="solo-cust mb-2"></v-text-field>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Any medical conditions?
											</span>
										</label>
										<v-select autocomplete="off" :items="medical_conditions" v-model="form.medical_condition" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Machine Used
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="form.machine_used" flat hide-details="auto" rows="5" row-height="25" placeholder="Machine, temperature, any client comments/concerns on higher/lower temperature from client, side effects mentioned."></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Products Sold
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="form.products_sold" flat hide-details="auto" rows="5" row-height="25" placeholder="Items that were sold, why where they sold, were they part of the package or additional."></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Were complimentary sessions offered?
											</span>
										</label>
										<v-textarea class="text-area" solo outlined v-model="form.complimentary_sessions" flat hide-details="auto" rows="5" row-height="25" placeholder="Details of what was offered, why they were offered, what was the outcome."></v-textarea>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Next Action
											</span>
										</label>
										<v-select autocomplete="off" :items="nextActions" v-model="form.next_action" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Next Action Date
											</span>
										</label>
										<v-menu v-model="nextActionDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
											<template v-slot:activator="{ on }">
												<v-text-field autocomplete="off" v-model="nextActionUkFormat" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" append-icon="mdil-calendar"></v-text-field>
											</template>
											<v-date-picker v-model="form.next_action_date" no-title @input="nextActionDateMenu = false"></v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Urgency
											</span>
										</label>
										<v-select autocomplete="off" :items="priority" v-model="form.urgency" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
									<v-col cols="6" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Status
											</span>
										</label>
										<v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('complaint')}))" v-model="form.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
					Cancel
				</v-btn>
				<v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="addComplaint">
					Add Complaint
				</v-btn>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn.js';
import status from '../../../mixins/status.js';
import add from './add.js';
import deal from './deal.vue';
export default {
	mixins: [globalFn, status, add],
	components: {
		deal
	},
};

</script>
