import http from '../../http/common'

const resource = '/complaint'

export default {

	create: (payload) => http.post(resource, payload),

	get: (payload) => http.post(`${resource}/get-data`, payload),

	show: (id, params) => http.get(`${resource}/${id}${params}`),

	update: (id, payload) => http.put(`${resource}/${id}`, payload),

	delete: (id) => http.delete(`${resource}/${id}`),

	search: (params) => http.get(`${resource}/search${params}`),

	nextAction: (payload) => http.get(`${resource}/get-next-action`, payload),

	priority: (payload) => http.get(`${resource}/get-priority`, payload),

	category: (payload) => http.get(`${resource}/get-category`, payload),

	escalation: (payload) => http.get(`${resource}/get-escalation-level`, payload),

	updateStatus: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

	comment: (payload) => http.post(`${resource}/add-comment`, payload),

	upload: (payload) => http.post(`${resource}/upload-docs`, payload),

	assignedTo: (payload) => http.get(`${resource}/staff-list`, payload),

	widgetCount: (payload) => http.post(`${resource}/widget-count`, payload),


}
