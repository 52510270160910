<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Add Product</div>
        <v-form ref="form">
          <div class="tab-content-wrapper">
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Product Name
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined :rules="rules.name" v-model="form.name" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Associated Treatments
                  </span>
                </label>
                <v-autocomplete v-model="form.treatments" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0">
                      <span>{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">
                      (+{{ form.treatments.length - 1 }} others)
                    </span>
                  </template>
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Category
                  </span>
                </label>
                <v-autocomplete v-model="form.category_id" :rules="rules.category_id" :items="categories" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Warehouse
                  </span>
                </label>
                <v-autocomplete v-model="form.warehouse_id" :rules="rules.warehouse_id" :items="warehouses" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
                  <template slot="append">
                    <v-icon>mdi-chevron-down</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Cost Price £
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined :rules="rules.cost_price" v-model="form.cost_price" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="e-row">
              <v-col cols="12" md="12" class="e-col">
                <label>
                  <span class="label-txt">
                    Recommended Selling Price £
                  </span>
                </label>
                <v-text-field autocomplete="off" solo outlined :rules="rules.selling_price" v-model="form.selling_price" flat hide-details="auto" class="solo-cust"></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="create">
          Add Product
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../../store/action-types.js';
import status from '../../../../mixins/status.js';
import globalFn from '../../../../mixins/globalFn.js';
export default {
  mixins: [status, globalFn],
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem('user')),
    tab: null,
    form: {
      name: '',
      treatments: [],
      category_id: '',
      warehouse_id: '',
      cost_price: '',
      selling_price: '',
      quantity: ''
    },
    rules: {
      name: [
        v => !!v || 'Please enter name'
      ],
      category_id: [
        v => !!v || 'Please select category'
      ],
      warehouse_id: [
        v => !!v || 'Please select warehouse'
      ],
      quantity: [
        v => !!v || 'Please enter quantity'
      ],
      cost_price: [
        v => !!v || 'Please enter Cost Price £'
      ],
      selling_price: [
        v => !!v || 'Please enter  Recommended Selling Price £'
      ]
    }
  }),
  computed: {
    treatments() {
      if (this.$store.state.treatments.allList) {
        return this.$store.state.treatments.allList
      }
    },
    categories() {
      if (this.$store.state.categories.allList) {
        return this.$store.state.categories.allList
      }
    },
    warehouses() {
      if (this.user.user_type_name === 'Admin') {
        if (this.$store.state.warehouse.all) {
          return this.$store.state.warehouse.all
        }
      } else {
        return this.user.permission_warehouses
      }
    }
  },
  created() {
    this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
    this.$store.dispatch('categories/GET_ALL_CATEGORY', '')
    this.$store.dispatch('warehouse/LIST', '')
  },
  methods: {
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("productsTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async create() {
      let formData = {}
      formData.name = this.form.name
      formData.cost_price = this.form.cost_price
      formData.selling_price = this.form.selling_price
      formData.category_id = this.form.category_id
      formData.warehouse_id = this.form.warehouse_id
      formData.treatments = []
      this.form.treatments.forEach(value => {
        if (value.id) {
          formData.treatments.push(value.id);
        } else {
          formData.treatments.push(value);
        }
      });
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.$store.dispatch('products/CREATE_PRODUCT', formData).then(response => {
          if (response.result === 'success') {
            this.loading = false
            this.loadData()
          } else if (response.result === 'fail') {
            response.type = 'error'
            this.loading = false
            this.$store.dispatch('snackbar/SHOW', response)
          }
        }).catch(err => {
          this.loading = false
          err.type = 'error'
          this.$store.dispatch('snackbar/SHOW', err)
        });
      }
    }
  }
};

</script>
