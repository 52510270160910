import http from '../http/common'

const resource = '/customer'

export default {

	widgetCount: (payload) => http.post(`${resource}/widget-count`, payload),

	get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

	leadActivityReport: (payload) => http.post(`${resource}/lead-activity-report`, payload),

	phoneActivityReport: (payload) => http.post(`${resource}/phone-activity-report`, payload),

	markCommentAsCall: (payload) => http.post(`${resource}/mark-comment-as-call`, payload),

	markCommentAsPinNote: (payload) => http.post(`${resource}/mark-comment-as-pin-note`, payload),

	pabauAppointments: (payload) => http.post(`${resource}/appointments/get`, payload),

	getContacts: (payload) => http.post(`${resource}/get-all-contacts`, payload),

	enrollUser: (payload) => http.post(`${resource}/enable-app-user`, payload),

	allContacts: (payload) => http.post(`/get-all-contacts`, payload),
	
	getContactPabauAppointments: (payload) => http.post(`${resource}/get-appointments`, payload),


}
