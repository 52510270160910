<template>
	<div>
		<template v-for="(a,index) in actions">
			<template v-if="screen === a.screen">
				<v-text-field flat prepend-inner-icon="mdi-magnify" autocomplete="off" :label="a.placeholder" solo hide-details class="search-input" @input="isTyping = true" v-model="search">
				</v-text-field>
			</template>
		</template>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import {} from '../../store/action-types.js';
import globalFn from '../../mixins/globalFn';
import status from '../../mixins/status';
import _ from 'lodash';
export default {
	name: "searchBar",
	props: {
		screen: {
			type: String,
			required: true
		},
		slug: {
			type: String,
			required: true
		}
	},
	mixins: [globalFn, status],
	data: () => ({
		user: JSON.parse(localStorage.getItem('user')),
		params: '?&sort_order=DESC&sort_by=name',
		filter: {
			search: '',
		},
		search: "",
		isTyping: false,
		actions: []
	}),
	watch: {
		search: _.debounce(function() {
			this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.filter.search = this.search
				this.searchKey();
			}
		}
	},
	computed: {

	},
	created() {

	},
	mounted() {
		this.loadActions()
	},
	methods: {
		...mapActions({}),
		async loadActions() {
			this.actions = [{
				screen: 'Staff',
				placeholder: 'Search for a staff…',
				filter: false,
				emitAction: 'searchStaff',
				status: [],
				btnText: 'Add Staff',
				sidePane: 'settings/staff/add'
			}, {
				screen: 'Branches',
				placeholder: 'Search for a branch…',
				filter: false,
				emitAction: 'searchBranches',
				status: [],
				btnText: 'Add Branch',
				sidePane: 'settings/branches/add'
			}, {
				screen: 'Campaigns',
				placeholder: 'Search for a campaign…',
				filter: false,
				emitAction: 'searchCampaigns',
				status: [],
				btnText: 'Add Campaign',
				sidePane: 'settings/campaigns/add'
			}, {
				screen: 'Machines',
				placeholder: 'Search for a machine…',
				filter: false,
				emitAction: 'searchMachines',
				status: [],
				btnText: 'Add Machine',
				sidePane: 'settings/machines/add'
			}, {
				screen: 'Symptoms',
				placeholder: 'Search for a symptom…',
				filter: false,
				emitAction: 'searchSymptoms',
				status: [],
				btnText: 'Add Symptom',
				sidePane: 'settings/symptoms/add'
			}, {
				screen: 'Treatments',
				placeholder: 'Search for a treatment…',
				filter: false,
				emitAction: 'searchTreatments',
				status: [],
				btnText: 'Add Treatment',
				sidePane: 'settings/treatments/add'
			}, {
				screen: 'Products',
				placeholder: 'Search for a product…',
				filter: false,
				emitAction: 'searchProducts',
				status: [],
				btnText: 'Add Product',
				sidePane: 'settings/products/add'
			}, {
				screen: 'Warehouse',
				placeholder: 'Search for a warehouse…',
				filter: false,
				emitAction: 'searchWarehouses',
				status: [],
				btnText: 'Add Warehouse',
				sidePane: 'settings/warehouses/add'
			}, {
				screen: 'Categories',
				placeholder: 'Search for a category…',
				filter: false,
				emitAction: 'searchCategories',
				status: [],
				btnText: 'Add Category',
				sidePane: 'settings/categories/add'
			}]
		},
		async searchKey() {
			let routeName = this.$router.currentRoute.meta.name
			self = this
			this.actions.forEach(function(el) {
				if (el.screen === routeName) {
					self.$root.$emit(el.emitAction, self.filter)
				}
			});
		},
	}
};

</script>
