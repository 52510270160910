<template>
	<div class="content-wrapper">
		<v-layout>
			<v-flex>
				<v-card class="global-search-card">
					<v-card-title class="font-32 line-h-41 text-color-primary-100 font-weight--bold font-family--head d-flex align-center justify-space-between">
						Search
						<v-btn icon @click="hide">
							<img src="/images/g-close.svg" height="48">
						</v-btn>
					</v-card-title>
					<v-card-text style="padding-top: 0 !important;">
						<div>
							<v-text-field flat rounded prepend-inner-icon="mdi-magnify" autocomplete="off" label="Search for a lead/client…" solo hide-details @input="isTyping = true" v-model="search">
							</v-text-field>
						</div>
						<v-skeleton-loader v-if="loading" type="article, actions"></v-skeleton-loader>
						<v-data-table v-if="!loading" dense :headers="headers" :single-expand="true" :expanded.sync="expanded" :hide-default-header="true" class="results-table mt-5 global-search-results" :items="results" :hide-default-footer="true">
							<template v-slot:item.name="{ item }">
								<div class="d-flex align-center" :class="$vuetify.breakpoint.xsOnly ? 'flex-column justify-center' : 'justify-space-between'">
									<div class="d-flex align-center" :class="$vuetify.breakpoint.xsOnly ? 'flex-column' : ''">
										<span class="font-18 line-h-22 mr-4 text-color-primary-100 font-weight--bold" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : ''" v-if="item.name">
											{{item.name | capitalize}}
										</span>
										<span :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : ''" class="d-flex font-18 line-h-22 mr-4 text-color-primary-100 font-weight--bold" v-if="!item.name && item.social_handle">
											<img :src="`/images/${item.source_name.toLowerCase()}.svg`" class="mr-2" v-if="item.source_name !== null">
											<span>
												@{{ item.social_handle }}
											</span>
										</span>
										<v-chip :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : ''" v-if="item.contact_no" small class="este-chip mr-4" text-color="rgba(34, 31, 32, 0.9)" color="rgba(34, 31, 32, 0.06)">
											<img src="/images/phone.svg" height="12" class="mr-2"> {{item.contact_no }}
										</v-chip>
										<v-chip v-if="item.email" small class="este-chip" text-color="rgba(34, 31, 32, 0.9)" color="rgba(34, 31, 32, 0.06)">
											<img src="/images/email.svg" height="12" class="mr-2"> {{item.email }}
										</v-chip>
									</div>
									<div class="d-flex" :class="$vuetify.breakpoint.xsOnly ? 'mt-4' : ''">
										<v-chip small class="tag-chip mr-2" text-color="#221F20">
											{{item.branch_name | capitalize}}
										</v-chip>
										<v-chip small class="este-chip status-chip" :text-color="item.user_type_name === 'Lead' ? 'white' : 'black'" :color="item.user_type_name === 'Lead' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(223, 180, 71, 0.4)'">
											{{ item.user_type_name | capitalize }}
										</v-chip>
										<v-btn @click="toggleRow(item)" v-if="!expanded.includes(item)" :elevation="0" color="#F0F3F6" fab class="width-27 height-27">
											<v-icon size="20" color="black">mdil-chevron-down</v-icon>
										</v-btn>
										<v-btn @click="toggleRow(item)" v-if="expanded.includes(item)" :elevation="0" color="#F0F3F6" fab class="width-27 height-27">
											<v-icon size="20" color="black">mdil-chevron-up</v-icon>
										</v-btn>
									</div>
								</div>
							</template>
							<!-- Expand row item here -->
							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length" class="expanded-table">
									<v-row class="ma-0 child">
										<v-col cols="12" sm="12" md="6" class="module-item border">
											<div>
												<p class="font-15 line-h-16 mb-4 font-weight--bold letter-spacing-20 text-color-primary-100">
													<v-icon size="18" class="mt-n1 mr-1">mdil-account</v-icon>Callbacks
												</p>
												<template v-if="item.callbacks.length > 0">
													<p class="font-11 line-h-13 font-weight--semibold letter-spacing-20 text-color-primary-100 mb-3">Date & Time:</p>
													<div class="d-flex align-center mb-5" :class="item.callback_date !== null ? 'justify-space-between' : 'justify-end'" v-for="item in item.callbacks">
														<span class="font-14 line-h-16 text-color-primary-200">
															{{ item.callback_date | moment("DD/MM/YY") }}
															<span v-if="item.callback_time !== null"> : {{item.callback_time }}</span>
														</span>
														<div class="d-flex align-center justify-end">
															<template v-for="stat in statusTags" v-if="stat.id === item.status">
																<v-chip small class="este-chip" :class="stat.outline ? 'tag-chip' : 'status-chip'" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color">
																	{{ stat.name | capitalize }}
																</v-chip>
															</template>
															<div class="profile-icon mr-2 width-27 height-27" alt="avatar" v-if="item.consultant_name">
																<p class="font-11 line-h-13 font-weight--medium"> {{ getInitials(item.consultant_name)}} </p>
															</div>
															<v-btn @click="callbackItem(item)" :elevation="0" color="#F0F3F6" fab class="width-27 height-27">
																<img src="/images/right-arrow.svg" height="9">
															</v-btn>
														</div>
													</div>
												</template>
												<template v-else>
													<p class="font-14 line-h-16 font-weight-regular letter-spacing-20 text-color-primary-200">This customer has no callbacks.</p>
												</template>
											</div>
										</v-col>
										<v-divider class="my-3"></v-divider>
										<v-col cols="12" sm="12" md="6" class="module-item border">
											<div>
												<p class="font-15 line-h-16 mb-4 font-weight--bold letter-spacing-20 text-color-primary-100">
													<v-icon size="18" class="mt-n1 mr-1">mdil-account</v-icon>Appointments
												</p>
												<template v-if="item.consultations.length > 0">
													<p class="font-11 line-h-13 font-weight--semibold letter-spacing-20 text-color-primary-100 mb-3">Date & Time:</p>
													<template v-for="item in item.consultations">
														<div class="d-flex align-center  mb-5" :class="item.appointment_date !== null ? 'justify-space-between' : 'justify-end'">
															<span class="font-14 line-h-16 text-color-primary-200" v-if="item.appointment_date !== null">
																{{ item.appointment_date | moment("DD/MM/YY") }} :
																<span v-if="item.appointment_from_time !== null">{{ item.appointment_from_time }}</span>
																<span v-if="item.appointment_to_time !== null"> - {{ item.appointment_to_time}}</span>
															</span>
															<div class="d-flex align-center justify-end">
																<template v-for="stat in statusTags" v-if="stat.id === item.status">
																	<v-chip small class="este-chip" :class="stat.outline ? 'tag-chip' : 'status-chip'" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color">
																		{{ stat.name | capitalize }}
																	</v-chip>
																</template>
																<div class="profile-icon mr-2 width-27 height-27" alt="avatar" v-if="item.consultant_name">
																	<p class="font-11 line-h-13 font-weight--medium"> {{ getInitials(item.consultant_name)}} </p>
																</div>
																<v-btn @click="appointmentItem(item)" :elevation="0" color="#F0F3F6" fab class="width-27 height-27">
																	<img src="/images/right-arrow.svg" height="9">
																</v-btn>
															</div>
														</div>
													</template>
												</template>
												<template v-else>
													<p class="font-14 line-h-16 font-weight-regular  letter-spacing-20 text-color-primary-200">This customer has no appointments.</p>
												</template>
											</div>
										</v-col>
										<v-divider class="my-3"></v-divider>
										<v-col cols="12" sm="12" md="6" class="module-item">
											<div>
												<p class="font-15 line-h-16 mb-4 font-weight--bold letter-spacing-20 text-color-primary-100">
													<v-icon size="18" class="mt-n1 mr-1">mdil-account</v-icon>Deals
												</p>
												<template v-if="item.deals.length > 0">
													<p class="font-11 line-h-13 font-weight--semibold letter-spacing-20 text-color-primary-100 mb-3">Deal:</p>
													<div class="d-flex align-center justify-space-between mb-5" v-for="item in item.deals">
														<span>{{ item.deal_info }}</span>
														<div class="d-flex">
															<template v-if="item.treatments.length > 0">
																<template v-for="tag in item.treatments.slice(0,1)">
																	<v-chip small class="este-chip tag-chip" text-color="black">
																		{{ tag.name | capitalize }}
																	</v-chip>
																</template>
																<span class="badge-label" v-if="item.treatments.length > 1">+{{item.treatments.length -1}}</span>
															</template>
															<v-btn href="/deals" target="_blank" :elevation="0" color="#F0F3F6" fab class="width-27 height-27">
																<img src="/images/right-arrow.svg" height="9">
															</v-btn>
														</div>
													</div>
												</template>
												<template v-else>
													<p class="font-14 line-h-16 font-weight-regular letter-spacing-20 text-color-primary-200">This customer has no deals.</p>
												</template>
											</div>
										</v-col>
										<v-col cols="12" sm="12" md="6" class="module-item">
											<div>
												<p class="font-15 line-h-16 mb-4 font-weight--bold letter-spacing-20 text-color-primary-100">
													<v-icon size="18" class="mt-n1 mr-1">mdil-account</v-icon>Complaints
												</p>
												<template v-if="item.complaints.length > 0">
													<div class="d-flex align-center">
														<p class="font-11 line-h-13 font-weight--semibold letter-spacing-20 text-color-primary-100 mb-3 mr-7">Date Raised:</p>
														<p class="font-11 line-h-13 font-weight--semibold letter-spacing-20 text-color-primary-100 mb-3">Type:</p>
													</div>
													<div class="d-flex align-center justify-space-between mb-5" v-for="item in item.complaints">
														<div class="d-flex">
															<span class="font-14 line-h-16 text-color-primary-200 mr-7">{{ item.date_raised | moment("DD/MM/YY") }}</span>
															<span class="font-14 line-h-16 text-color-primary-200">{{item.category}}</span>
														</div>
														<div class="d-flex">
															<template v-for="stat in statusTags" v-if="stat.id === item.status">
																<v-chip small class="este-chip" :class="stat.outline ? 'tag-chip' : 'status-chip'" :outlined="stat.outline" :text-color="stat.text  ? stat.color : '#221F20'" :color="stat.color">
																	{{ stat.name | capitalize }}
																</v-chip>
															</template>
															<div class="profile-icon mr-2 width-27 height-27" alt="avatar" v-if="item.consultant_name">
																<p class="font-11 line-h-13 font-weight--medium"> {{ getInitials(item.consultant_name)}} </p>
															</div>
															<v-btn @click="complaintItem(item)" :elevation="0" color="#F0F3F6" fab class="width-27 height-27">
																<img src="/images/right-arrow.svg" height="9">
															</v-btn>
														</div>
													</div>
												</template>
												<template v-else>
													<p class="font-14 line-h-16 font-weight-regular letter-spacing-20 text-color-primary-200">This customer has no complaints.</p>
												</template>
											</div>
										</v-col>
									</v-row>
								</td>
							</template>
							<!-- End expand row item here -->
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
import _ from 'lodash';
import globalFn from '../mixins/globalFn';
import sliderFn from '../mixins/sliderFn';
import status from '../mixins/status';
export default {

	name: 'TheGlobalSearch',
	mixins: [globalFn, status, sliderFn],

	data() {
		return {
			show: false,
			search: "",
			loading: false,
			isTyping: false,
			expanded: [],
			results: [],
			headers: [{ text: 'Name', align: 'start', value: 'name', align: 'left', sortable: false }],
		}
	},
	watch: {
		search: _.debounce(function() {
			this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value) {
				this.globalSearchAPI()
			}
		}
	},
	mounted() {
		this.$root.$on('updateGlobalSearch', this.globalSearchAPI)
	},
	methods: {
		hide() {
			this.$root.$emit('hideGlobalScreen')
		},
		async globalSearchAPI() {
			let data = {}
			data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22]
			data.page_size = 1000
			data.search = this.search
			this.loading = true
			await this.$store.dispatch('user/GLOBAL_SEARCH', data).then(res => {
				this.results = res.data.data
				this.loading = false
			})
		},

		async callbackItem(item) {
			let result = []
			await this.$store.dispatch('callback/SHOW_CALLBACK', { id: item.id, param: '?attributes=user,user.campaigns,branch,treatments,consultant,documents,comments' }).then(res => {
				if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
					this.openDrawer("add", "1100", "callback/editPane");
				} else {
					this.openDrawer("add", "750", "callback/editTab");
				}
				result = res.data
			})
			this.$root.$emit('loadLeadDetails', result)
		},

		async appointmentItem(item) {
			let result = []
			await this.$store.dispatch('appointments/SHOW_APPOINTMENT', { id: item.id, param: '?attributes=user,treatments,consultations,consultant,branch' }).then(res => {
				if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
					this.openDrawer("add", "1100", "appointments/editPane");
				} else {
					this.openDrawer("add", "750", "appointments/editTab");
				}
				result = res.data
			})
		},

		async complaintItem(item) {
			await this.$store.dispatch('complaints/SHOW_COMPLAINT', { id: item.id, param: '?attributes=user,responsible,documents,comments' }).then(res => {
				if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
					this.openDrawer("add", "1100", "complaints/editPane");
				} else {
					this.openDrawer("add", "750", "complaints/editTab");
				}
			})
		},

		async toggleRow(item) {
			const index = this.expanded.indexOf(item)
			if (index === -1) {
				this.expanded.push(item)
			} else {
				this.expanded.splice(index, 1)
			}
		},
	}
}

</script>
<style lang="css" scoped>
</style>
