import http from '../http/common'

const resource = '/contact'

export default {

	getData: (payload) => http.post(`${resource}/get-data`, payload),
	getAppointments: (payload) => http.post(`${resource}/get-appointments`, payload),
	getOpportunities: (payload) => http.post(`${resource}/get-opportunities`, payload),
	getSupportTickets: (payload) => http.post(`${resource}/get-support-tickets`, payload),
	getDeals: (payload) => http.post(`${resource}/get-deals`, payload),
	getCallbackNotes: (payload) => http.post(`${resource}/get-callback-notes`, payload),
	getPointsHistory: (payload) => http.post(`${resource}/get-points-history`, payload),
	getContactById: (payload) => http.post(`${resource}/get-by-id`, payload),
	getContactById: (payload) => http.post(`${resource}/get-by-id`, payload),
	enrollUser: (payload) => http.post(`${resource}/enable-app-user`, payload),
	getWidgetCount: (payload) => http.post(`${resource}/widget-count`, payload),
	getContactList: (payload) => http.post(`${resource}/get-list`, payload),
	getLoyalty: (payload) => http.post(`${resource}/get-loyalty-history`, payload),
	update: (payload) => http.post(`${resource}/update`, payload),
	create: (payload) => http.post(`${resource}/create`, payload),
	resetAppUserPassword: (payload) => http.post(`${resource}/reset-password`, payload),


}
