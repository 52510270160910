<template>
	<div>
		<v-card class="mt-6 pb-5">
			<v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold pb-0 pl-7 pt-6">
				Support Tickets
			</v-card-title>
			<v-divider class="my-5"></v-divider>
			<v-card-text class="px-7 py-0">
				<v-skeleton-loader class="widget-card" v-show="loading" :loading="loading" type="table-row-divider@6"></v-skeleton-loader>
				<v-data-table :fixed-header="true" :item-class="itemRowBackground" v-show="!loading" class="results-table" item-key="id" :headers="headers" :items="items">
					<template v-slot:item.id="{ item }">
						<span class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border cursor" @click="editItem(item)">
							{{item.id}}
						</span>
					</template>
					<template v-slot:item.type="{ item }">
						<span v-if="item.support_ticket_type" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold">
							{{ item.support_ticket_type | capitalize }}
						</span>
					</template>
					<template v-slot:item.urgency="{ item }">
						<span v-if="item.urgency_name" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold">
							{{ item.urgency_name | capitalize }}
						</span>
					</template>
					<template v-slot:item.sla="{ item }">
						<div class="d-flex flex-column">
							<template v-if="item.support_ticket_type !== 'complaint'">
								<span class="d-flex font-13 line-h-16 font-weight--semibold text-black-300" v-if="item.callback_date">
									{{item.callback_date | moment("DD/MM/YY")}} {{item.callback_time}}
									<img src="/images/icons/duotune/arrows/arr050.svg" class="text-black-100 ma-0 ml-1" width="18" height="18" />
								</span>
							</template>
							<template v-else>
								<span class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1" v-if="item.date_raised">
									{{item.date_raised | moment("DD/MM/YY")}}
								</span>
								<span class="d-flex font-13 line-h-16 font-weight--bold text-black-300" v-if="item.next_action_date">
									{{item.next_action_date | moment("DD/MM/YY")}}
									<img src="/images/icons/duotune/arrows/arr050.svg" class="text-black-100 ma-0 ml-1" width="18" height="18" />
								</span>
							</template>
						</div>
					</template>
					<template v-slot:item.responsible="{ item }">
						<v-tooltip content-class="este-tooltip" color="#000" text-color="#fff" top v-if="item.responsible !== null">
							<template v-slot:activator="{ on, attrs }">
								<span v-bind="attrs" v-on="on" class="badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold cursor">
									{{ getInitials(item.responsible.name) }}
								</span>
							</template>
							<span v-if="item.responsible !== null">{{item.responsible.name}}</span>
						</v-tooltip>
					</template>
					<template v-slot:item.updated_at="{ item }">
						<div class="d-flex flex-column width-100">
							<span class="font-13 line-h-16 font-weight--semibold text-black-300 mb-1">
								{{item.updated_at}}
							</span>
						</div>
					</template>
					<template v-slot:item.status="{ item }">
						<template v-for="(val, index) in statusTags">
							<span class="badge--chip font-weight--semibold text-black-300 font-11 line-h-13" :style="
                val.outline
                  ? `border: 1px solid ${val.color}`
                  : `background: ${val.color}`
              " :key="index" v-if="item.status === val.id">{{ val.name }}</span>
						</template>
						<div class="mt-2">
							<span class="font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border" v-if="item.branch_name">{{ item.branch_name }}</span>
						</div>
					</template>
					<template v-slot:item.action="{ item }">
						<div class="d-flex align-center justify-end">
							<v-btn v-if="item.is_urgent" :elevation="0" class="bg-red-100 btn-action mr-2" @click="flagStatus(item, 0)">
								<inline-svg class="white--text" width="16" height="16" src="/images/icons/duotune/maps/map001.svg" />
							</v-btn>
							<v-btn v-if="!item.is_urgent" :elevation="0" class="bg-gray-100 btn-action mr-2" @click="flagStatus(item, 1)">
								<inline-svg class="text-black-100" width="16" height="16" src="/images/icons/duotune/maps/map001.svg" />
							</v-btn>
							<v-btn :elevation="0" class="bg-gray-100 btn-action" @click="editItem(item)">
								<img height="16" src="/images/icons/duotune/general/gen055.svg" />
							</v-btn>
						</div>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
		<v-dialog v-model="modalDialog" max-width="490">
			<v-card class="delete-alert">
				<v-card-title>
					Confirm
					<a class="float-right pa-6" @click="modalDialog = false"><img src="/images/close.svg" alt="Close" /></a>
				</v-card-title>
				<v-card-text>
					{{message}}
				</v-card-text>
				<v-card-actions class="d-flex justify-center align-center">
					<div class="delete-wrapper">
						<v-btn class="cancel-btn btn-gap" depressed rounded outlined @click="modalDialog = false" color="dark" x-large>
							Cancel
						</v-btn>
						<v-btn class="delete-btn" :color="(actionText === 'Archive') ? 'red' : 'secondary'" depressed @click="handleFunction(action)" rounded x-large>
							{{actionText}}
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import globalFn from '../../mixins/globalFn'
import sliderFn from '../../mixins/sliderFn'
import status from '../../mixins/status'
export default {

	name: 'WidgetComplaints',
	mixins: [globalFn, status, sliderFn],
	data() {
		return {
			loading: false,
			items: [],
			headers: [
				{ text: 'ID', value: 'id', sortable: false },
				{ text: 'Type', value: 'type', sortable: false },
				{ text: 'Priority', value: 'urgency', sortable: false },
				{ text: 'Staff', value: 'responsible', sortable: false },
				{ text: 'sla', value: 'sla', sortable: false, },
				{ text: 'Updated', value: 'updated_at', sortable: false, },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Action', value: 'action', sortable: false, align: 'end' }
			],
			message: '',
			action: '',
			actionText: '',
			enrolleUserValue: 0,
			flagStatusValue: 0,
			modalDialog: false,
			selectedItem: null
		}
	},
	mounted() {
		this.getWidgetData()
		this.$root.$on('refreshTicketsWidget', this.getWidgetData)
	},
	methods: {
		async getWidgetData() {
			this.loading = true;
			await this.$store.dispatch('contacts/SUPPORT_TICKETS', { user_id: this.$router.currentRoute.params.id  }).then(res => {
				this.items = res.data
			})
			this.loading = false;
		},
		handleFunction(funName) {
			this[funName]()
		},
		async flagStatus(item, val) {
			this.selectedItem = item
			this.flagStatusValue = val
			this.modalDialog = true
			this.message = `Please confirm you want to flag this ticket?`
			this.actionText = "Confirm"
			this.action = "flagOpportunity"
		},
		itemRowBackground(item) {
			return (item.is_urgent) ? 'bg-red-200' : '';
		},
		async flagOpportunity() {
			await this.$store.dispatch(`complaints/UPDATE_COMPLAINT`, { id: this.selectedItem.id, user_id: this.selectedItem.user_id, is_urgent: this.flagStatusValue })
				.then(res => {
					if (res.result === 'success') {
						this.modalDialog = false
						this.getWidgetData()
					} else if (res.result === 'fail') {
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
		},
		async editItem(item) {
			await this.$store.dispatch('complaints/SHOW_COMPLAINT', { id: item.id, param: `?attributes=user,responsible,documents,comments,treatments` }).then(res => {
				if (item.support_ticket_type === 'complaint') {
					this.openDrawer("add", "1100", "supportTickets/complaint");
				} else {
					this.openDrawer("add", "1100", "supportTickets/callback");
				}
			})
		}
	},
}

</script>
<style lang="css" scoped>
</style>
