var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0 pt-3"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('div',{staticClass:"d-flex align-center flex-row"},[_c('span',{staticClass:"font-14 line-h-17 font-weight--bold text-black-100"},[_vm._v("\n                  "+_vm._s(_vm._f("capitalize")(item.name))+"\n                ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex align-center mt-2"},[_c('span',{staticClass:"font-13 line-h-16 text-black-300 font-weight--semibold mr-2"},[_vm._v("\n                  "+_vm._s(item.role_type_name)+"\n                ")])])]}},{key:"item.contact_no",fn:function(ref){
var item = ref.item;
return [(item.contact_no !== null)?_c('span',{staticClass:"font-14 line-h-17 font-weight--bold text-black-100"},[_vm._v("\n                "+_vm._s(item.contact_no)+"\n              ")]):_vm._e(),_vm._v(" "),(item.email !== null)?_c('div',{staticClass:"font-13 line-h-16 font-weight--semibold text-black-300"},[_vm._v("\n                "+_vm._s(item.email)+"\n              ")]):_vm._e()]}},{key:"item.branch_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"},[_vm._v(_vm._s(item.branch_name))])]}},{key:"item.permission_treatments",fn:function(ref){
var item = ref.item;
return [(item.permission_treatments.length > 0)?[_vm._l((item.permission_treatments.slice(0,1)),function(val,index){return [_c('span',{key:index,staticClass:"badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"},[_vm._v("\n                    "+_vm._s(val.name)+"\n                  ")])]}),_vm._v(" "),(item.permission_treatments.length > 1)?_c('span',{staticClass:"badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"},[_vm._v("+"+_vm._s(item.permission_treatments.length -1))]):_vm._e()]:_vm._e()]}},{key:"item.permission_consultations",fn:function(ref){
var item = ref.item;
return [(item.permission_consultations.length > 0)?[_vm._l((item.permission_consultations.slice(0,1)),function(val,index){return [_c('span',{key:index,staticClass:"badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold mr-2"},[_vm._v("\n                    "+_vm._s(val.name)+"\n                  ")])]}),_vm._v(" "),(item.permission_consultations.length > 1)?_c('span',{staticClass:"badge--chip bg-gray-100 font-11 line-h-13 text-black-300 font-weight--semibold"},[_vm._v("+"+_vm._s(item.permission_consultations.length -1))]):_vm._e()]:_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.statusTags),function(val,index){return [(item.status === val.id)?_c('span',{key:index,staticClass:"badge--chip font-weight--semibold text-black-300 font-11 line-h-13",style:(val.outline
                ? ("border: 1px solid " + (val.color))
                : ("background: " + (val.color)))},[_vm._v(_vm._s(val.name))]):_vm._e()]}),_vm._v(" "),_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("View")])]),_vm._v(" "),_c('li',[(item.status !== 7)?_c('a',{on:{"click":function($event){return _vm.archiveItem(item)}}},[_vm._v("Archive")]):_vm._e()]),_vm._v(" "),_c('li',[(item.status === 7)?_c('a',{on:{"click":function($event){return _vm.archiveItem(item)}}},[_vm._v("Unarchive")]):_vm._e()])])])])]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalDialog),callback:function ($$v) {_vm.modalDialog=$$v},expression:"modalDialog"}},[_c('v-card',{staticClass:"delete-alert"},[_c('v-card-title',[_vm._v("\n        Confirm\n        "),_c('a',{staticClass:"float-right pa-6",on:{"click":function($event){_vm.modalDialog = false}}},[_c('img',{attrs:{"src":"/images/close.svg","alt":"Close"}})])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.message)+"\n      ")]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"delete-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap",attrs:{"depressed":"","rounded":"","outlined":"","color":"dark","x-large":""},on:{"click":function($event){_vm.modalDialog = false}}},[_vm._v("\n            Cancel\n          ")]),_vm._v(" "),_c('v-btn',{staticClass:"delete-btn",attrs:{"color":(_vm.actionText === 'Archive') ? 'red' : 'secondary',"depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.handleFunction(_vm.action)}}},[_vm._v("\n            "+_vm._s(_vm.actionText)+"\n          ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }