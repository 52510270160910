export default {
  namespaced: true,
  state: () => ({
    item: false,
    title: '',
    list: [],
    width: null,
    component: null,
    componentName: null,
    props: {}
  }),
  getters: {
    RightSlider(state) {
      return state
    }
  },
  mutations: {
    RESET_SLIDER(state) {
      state.item = false
      state.componentName = null
      state.width = null
      state.props = {}
    },
    SET_SLIDER(state, payload) {
      state.componentName = payload.componentName
      state.props = payload.props
      state.title = payload.title
      state.item = true
      state.width = payload.width
    }
  },
  actions: {
    UPDATE({ commit, dispatch }, payload) {
      commit('RESET_SLIDER')
      commit('SET_SLIDER', payload)
      return payload
    },
    CREATE({ commit, dispatch }, payload) {
      commit('RESET_SLIDER')
      commit('SET_SLIDER', payload)
      return payload
    }
  }
}