<template>
  <div>
    <v-navigation-drawer class="este-drawer" v-model="currentContent.item" stateless app right fixed temporary :width="$vuetify.breakpoint.smAndDown ? '100%' : currentContent.width">
      <component v-if="loader" v-bind:is="loader"></component>
    </v-navigation-drawer>
  </div>
</template>
<script>
import SliderFn from "../../mixins/sliderFn";
export default {
  name: 'RightSlider',
  data() {
    return {
      componentName: 'rightSlider',
      loading: false,
      component: null,
      options: {},
      headers: []
    }
  },
  computed: {
    loader() {
      if (!this.currentContent.componentName) {
        return null
      }
      return require(`../../templates/forms/${this.currentContent.componentName}`).default
    },
    currentContent() {
      return this.$store.state.rightSlider
    }
  },
  mounted() {},
  mixins: [SliderFn],
  methods: {
    closeSlider() {
      this.$store.commit('rightSlider/RESET_SLIDER')
    }
  }
}

</script>
<style scoped></style>
