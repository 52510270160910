<template>
	<div :class="comment.length > 50 ? 'width-200' : ''">
		<template v-if="!readMore">
			<span class="font-14 mt-2 line-h-18 font-weight--regular" v-if="comment.length > 50">{{comment.slice(0, 50).concat('...')}}</span>
			<span class="font-14 mt-2 line-h-18 font-weight--regular" v-else>{{comment}}</span>
			<p class="font-12 line-h-17 font-weight--regular cursor text-decoration-underline mt-1" v-if="!readMore && comment.length > 50" @click="readMore = !readMore"> Read more</p>
		</template>
		<template v-if="readMore">
			<span class="font-14 mt-2 line-h-18 font-weight--regular text-capitalize">{{comment}}</span>
			<p v-if="readMore" class="font-12 line-h-17 cursor font-weight--regular text-decoration-underline mt-1" @click="readMore = !readMore"> Read less</p>
		</template>
	</div>
</template>
<script>
export default {

	name: 'ReadMore',
	props: {
		comment: {
			required: true,
		}
	},

	data() {
		return {
			readMore: false
		}
	}
}

</script>
<style lang="css" scoped>
</style>
