import collection from '../../api/collections/Dashboard'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    moduleStats: {},
    dealStats: {},
    overdue: {},
    dealByTreatment: [],
    dealByConsultant: [],
    dealByChannel: [],
    appointmentByChannel: []
  },
  mutations: {
    SET_MODULE_STATS(state, data) {
      state.moduleStats = data
    },
    SET_DEAL_STATS(state, data) {
      state.dealStats = data
    },
    SET_OVERDUE_KPIS(state, data) {
      state.overdue = data
    },
    SET_DEAL_BY_TREATMENT(state, data) {
      state.dealByTreatment = data
    },
    SET_DEAL_BY_CONSULTANT(state, data) {
      state.dealByConsultant = data
    },
    SET_DEAL_BY_TREATMENT_CHANNEL(state, data) {
      state.dealByChannel = data
    },
    SET_APPOINTMENT_BY_TREATMENT_CHANNEL(state, data) {
      state.appointmentByChannel = data
    }
  },
  actions: {
    async MODULE_STATS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.moduleStats(payload)
        commit('SET_MODULE_STATS', data.data)
        return data
      }, commit, dispatch, 'MODULE_STATS', true)
    },
    async MODULE_STATS_BY_MONTH({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.moduleStatsByMonth(payload)
        return data
      }, commit, dispatch, 'MODULE_STATS_BY_MONTH', true)
    },
    async DEAL_STATS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.dealStats(payload)
        commit('SET_DEAL_STATS', data.data)
        return data
      }, commit, dispatch, 'DEAL_STATS', true)
    },
    async DEAL_BY_TREATMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.dealByTreatment(payload)
        commit('SET_DEAL_BY_TREATMENT', data.data)
        return data
      }, commit, dispatch, 'DEAL_BY_TREATMENT', true)
    },
    async DEAL_BY_CONSULTANT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.dealByConsultant(payload)
        commit('SET_DEAL_BY_CONSULTANT', data.data)
        return data
      }, commit, dispatch, 'DEAL_BY_CONSULTANT', true)
    },
    async OVERDUE_KPIS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.overdueKpi(payload)
        commit('SET_OVERDUE_KPIS', data.data)
        return data
      }, commit, dispatch, 'OVERDUE_KPIS', true)
    },
    async DEAL_BY_TREATMENT_CHANNEL({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.dealChannel(payload)
        commit('SET_DEAL_BY_TREATMENT_CHANNEL', data.data)
        return data
      }, commit, dispatch, 'DEAL_BY_TREATMENT_CHANNEL', true)
    },
    async APPOINTMENT_BY_TREATMENT_CHANNEL({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.appointmentChannel(payload)
        commit('SET_APPOINTMENT_BY_TREATMENT_CHANNEL', data.data)
        return data
      }, commit, dispatch, 'APPOINTMENT_BY_TREATMENT_CHANNEL', true)
    },
    async CHANNEL_SOURCE_BY_APPOINTMENT_MONTH({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.channelSourceByAppointmentMonth(payload)
        return data
      }, commit, dispatch, 'CHANNEL_SOURCE_BY_APPOINTMENT_MONTH', true)
    },

    async CHANNEL_SOURCE_BY_OPPORTUNITY_TODAY({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.channelSourceByOpportunityToday(payload)
        return data
      }, commit, dispatch, 'CHANNEL_SOURCE_BY_OPPORTUNITY_TODAY', true)
    },

    async CHANNEL_SOURCE_BY_OPPORTUNITY_END({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.channelSourceByOpportunityEnd(payload)
        return data
      }, commit, dispatch, 'CHANNEL_SOURCE_BY_OPPORTUNITY_END', true)
    },
  }
}
