<template>
  <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
        <v-icon large>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <div class="logout-block">
      <ul>
        <template v-for="(a,index) in actions">
          <template v-if="screen === a.screen">
            <li><a class="video-badge" v-if="a.videoTraining === 'true'" :href="a.videoUrl" target="_blank">Video Training</a></li>
            <li><a class="text-decoration-none" :href="`/calendar/${slug}`" v-if="(slug === 'appointments' || slug === 'leads') && a.calendar === 'true'">Calendar</a></li>
            <li><a @click="loadBackScreen" v-if="slug === 'calendar'">Back To {{$router.currentRoute.params.screen}}</a></li>
            <li><a v-if="a.btn" @click.stop="handleFunction(a)">{{ a.btnText }}</a></li>
           <!--  <li><a v-if="slug === 'leads' || slug === 'customer'" @click.stop="openDrawer('add', '750', 'leads/QuickAddLead');">Quick Lead</a></li> -->
          </template>
        </template>
      </ul>
    </div>
  </v-menu>
</template>
<script>
import sliderFn from '../mixins/sliderFn';
import eventBus from '../eventBus.js';
export default {
  name: "tableAction",
  props: {
    screen: {
      type: String,
      required: true
    },
    slug: {
      required: true
    }
  },
  mixins: [sliderFn],
  data: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    calendar: true,
    actions: [{
      screen: 'Leads',
      btnText: 'Add Lead',
      sidePane: 'leads/addPane',
      tabPane: 'leads/addTab',
      btn: true,
      videoTraining: process.env.MIX_SHOW_VIDEO_TRAINING,
      videoUrl: 'https://video.drift.com/v/abMd0iCDJQ5/'
    }, {
      screen: 'Clients',
      btnText: 'Add Client',
      sidePane: 'clients/addPane',
      tabPane: 'clients/addTab',
      btn: true
    }, {
      screen: 'Contacts',
      btnText: 'Add',
      sidePane: 'customers/add',
      tabPane: 'customers/add',
      btn: true,
      slug: 'customer'
    },{
      screen: 'Opportunities',
      btnText: 'Add',
      sidePane: 'opportunities/addPane',
      tabPane: 'opportunities/addTab',
      btn: true,
      slug: 'opportunities'
    }, {
      screen: 'Support Tickets',
      btnText: 'Add Ticket',
      sidePane: 'supportTickets/add',
      tabPane: 'supportTickets/add',
      btn: true,
      slug: 'support-tickets'
    }, {
      screen: 'Offers',
      btnText: 'Add',
      sidePane: 'offers/add',
      tabPane: 'offers/add',
      btn: true,
      slug: 'offers'
    },{
      screen: 'Notifications',
      btnText: 'Add',
      sidePane: 'notifications/add',
      tabPane: 'notifications/add',
      btn: true,
      slug: 'notifications'
    }, {
      screen: 'Complaints',
      btnText: 'Add Complaint',
      sidePane: 'complaints/addPane',
      tabPane: 'complaints/addTab',
      btn: true
    }, {
      screen: 'Appointments',
      btnText: 'Add Appointment',
      sidePane: 'appointments/addPane',
      tabPane: 'appointments/addTab',
      btn: true,
      calendar: process.env.MIX_SHOW_CALENDAR,
      videoTraining: process.env.MIX_SHOW_VIDEO_TRAINING,
      videoUrl: 'https://video.drift.com/v/abHkrWkIUz5/'
    }, {
      screen: 'Call Back',
      btnText: 'Add Call Back',
      sidePane: 'callback/addPane',
      tabPane: 'callback/addTab',
      btn: true,
      calendar: process.env.MIX_SHOW_CALENDAR,
      videoTraining: process.env.MIX_SHOW_VIDEO_TRAINING,
      videoUrl: 'https://video.drift.com/v/abNwmuNznz5/'
    }, {
      screen: 'Insights',
      btn: false
    }, {
      screen: 'Calendar',
      btn: false
    }, {
      screen: 'Purchase Invoices',
      btnText: 'Add Invoice',
      btn: true,
      emitAction: 'AddNewInvoice'
    }, {
      screen: 'Cash Payments',
      btnText: 'Add Payment',
      btn: true,
      emitAction: 'AddNewCashPayment'
    }, {
      screen: 'Staff',
      btnText: 'Add Staff',
      sidePane: 'settings/staff/add',
      tabPane: 'settings/staff/add',
      btn: true
    }, {
      screen: 'Branches',
      btnText: 'Add Branch',
      sidePane: 'settings/branches/add',
      tabPane: 'settings/branches/add',
      btn: true
    }, {
      screen: 'Campaigns',
      btnText: 'Add Campaign',
      sidePane: 'settings/campaigns/add',
      tabPane: 'settings/campaigns/add',
      btn: true,
      slug: 'settings'
    }, {
      screen: 'Machines',
      btnText: 'Add Machine',
      sidePane: 'settings/machines/add',
      tabPane: 'settings/machines/add',
      btn: true
    }, {
      screen: 'Symptoms',
      btnText: 'Add Symptom',
      sidePane: 'settings/symptoms/add',
      tabPane: 'settings/symptoms/add',
      btn: true
    }, {
      screen: 'Treatments',
      btnText: 'Add Treatment',
      sidePane: 'settings/treatments/add',
      tabPane: 'settings/treatments/add',
      btn: true
    }, {
      screen: 'Products',
      btnText: 'Add Product',
      sidePane: 'settings/products/add',
      tabPane: 'settings/products/add',
      btn: true
    }, {
      screen: 'Categories',
      btnText: 'Add Category',
      sidePane: 'settings/categories/add',
      tabPane: 'settings/categories/add',
      btn: true
    }, {
      screen: 'Configurations',
      btnText: 'Add Configuration',
      sidePane: 'settings/configurations/edit',
      tabPane: 'settings/configurations/edit',
      btn: true
    }, {
      screen: 'Points',
      btnText: 'Add Points',
      sidePane: 'settings/points/edit',
      tabPane: 'settings/points/edit',
      btn: true,
      slug: 'settings'
    }]
  }),
  methods: {
    handleFunction(item) {

      if (item.slug === 'customer') {
        this.openDrawer("add", "1100", item.sidePane);
      } else {
        if (['Purchase Invoices', 'Cash Payments'].includes(item.screen)) {
          this.$root.$emit(item.emitAction)
        } else if (['Leads', 'Clients', 'Appointments', 'Complaints', 'Insights', 'Reports', 'Call Back', 'Opportunities'].includes(item.screen)) {
          if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
            this.openDrawer("add", "1100", item.sidePane);
          } else {
            this.openDrawer("add", "750", item.tabPane);
          }
        } else {
          this.openDrawer("add", "750", item.tabPane);
        }

      }

    },
    loadBackScreen() {
      const screen = this.$router.currentRoute.params.screen
      this.$router.push({ path: `/${screen}` })
    }
  }
}

</script>
