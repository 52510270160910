<template>
	<div>
		Search
	</div>
</template>

<script>
export default {

  name: 'DuplicateContacts',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>