import collection from '../../api/collections/PulseDashboard'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    leads: {},
    appointments: {},
    dealsBranch: {},
    dealsChannel: {}
  },
  mutations: {
    SET_LEADS_GENERATED(state, data) {
      state.leads = data
    },
    SET_APPOINTMENTS_GENERATED(state, data) {
      state.appointments = data
    },
    SET_DEALS_BRANCH_GENERATED(state, data) {
      state.dealsBranch = data
    },
    SET_DEALS_CHANNEL_GENERATED(state, data) {
      state.dealsChannel = data
    }
  },
  actions: {
    async LEADS_GENERATED({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.leadsGenerated(payload)
        commit('SET_LEADS_GENERATED', data.data)
        return data
      }, commit, dispatch, 'LEADS_GENERATED', true)
    },
    async APPOINTMENTS_GENERATED({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.appointmentsGenerated(payload)
        commit('SET_APPOINTMENTS_GENERATED', data.data)
        return data
      }, commit, dispatch, 'APPOINTMENTS_GENERATED', true)
    },
    async DEALS_BRANCH_GENERATED({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.dealBranchGenerated(payload)
        commit('SET_DEALS_BRANCH_GENERATED', data.data)
        return data
      }, commit, dispatch, 'DEALS_BRANCH_GENERATED', true)
    },
    async DEALS_CHANNEL_GENERATED({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.dealChannelGenerated(payload)
        commit('SET_DEALS_CHANNEL_GENERATED', data.data)
        return data
      }, commit, dispatch, 'DEALS_CHANNEL_GENERATED', true)
    }
  }
}
