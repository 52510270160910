var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name-column"},[(item.user.client_satisfaction !== null)?[(item.user.client_satisfaction === 'happy')?_c('img',{attrs:{"src":"/images/happy.svg"}}):(item.user.client_satisfaction === 'sad')?_c('img',{attrs:{"src":"/images/sad.svg"}}):(item.user.client_satisfaction === 'neutral')?_c('img',{attrs:{"src":"/images/neutral.svg"}}):_c('img',{attrs:{"src":"/images/vip-gold.svg"}})]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"name-column"},[(item.user.name)?_c('div',{staticClass:"user-name"},[_vm._v("\n                  "+_vm._s(item.user.name)+"\n                ")]):_vm._e(),_vm._v(" "),(!item.user.name && item.user.social_handle)?_c('div',{staticClass:"d-flex user-name"},[(item.user.source_name !== null)?_c('img',{staticClass:"mr-2",attrs:{"src":("/images/" + (item.user.source_name.toLowerCase()) + ".svg")}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                    @"+_vm._s(item.user.social_handle)+"\n                  ")])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"user-location"},[_vm._v("\n                  "+_vm._s(item.user.branch_name)+"\n                ")])])]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [(item.user.contact_no !== null && item.user.contact_no !== '')?_c('p',{staticClass:"mt-4 ml-n1"},[_c('span',{staticClass:"contact"},[_c('a',{attrs:{"href":("tel:" + (item.user.contact_no))}},[_c('img',{attrs:{"src":"/images/phone.svg","height":"15"}}),_vm._v(" "+_vm._s(item.user.contact_no))])])]):_vm._e(),_vm._v(" "),(item.user.email !== null && item.user.email !== '')?_c('p',{staticClass:"mt-4 ml-n1 mb-4"},[_c('span',{staticClass:"contact"},[_c('a',{attrs:{"href":("mailto:" + (item.user.email))}},[_c('img',{attrs:{"src":"/images/email.svg","height":"15"}}),_vm._v(" "+_vm._s(item.user.email))])])]):_vm._e()]}},{key:"item.date_raised",fn:function(ref){
var item = ref.item;
return [(item.date_raised !== null)?_c('p',{staticClass:"date-sec date-mute"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"rgba(34, 31, 32, 0.9)"}},[_vm._v("mdil-calendar")])],1),_vm._v(" "+_vm._s(_vm._f("moment")(item.date_raised,"DD/MM/YY"))+"\n              ")],1):_vm._e(),_vm._v(" "),(item.next_action_date !== null)?[_c('p',{staticClass:"date-sec vertical-bar"},[_vm._v("|")]),_vm._v(" "),_c('p',{staticClass:"date-sec"},[_c('v-btn',{staticClass:"badge badge-grey",attrs:{"icon":""}},[_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v("mdil-calendar")])],1),_vm._v(" "+_vm._s(_vm._f("moment")(item.next_action_date,"DD/MM/YY"))+"\n                ")],1)]:_vm._e()]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [(item.category !== null)?_c('v-chip',{staticClass:"este-chip tag-chip",attrs:{"small":"","text-color":"black"}},[_vm._v("\n                "+_vm._s(_vm._f("capitalize")(item.category))+"\n              ")]):_vm._e()]}},{key:"item.next_action_name",fn:function(ref){
var item = ref.item;
return [(item.next_action_name !== null)?_c('v-chip',{staticClass:"este-chip tag-chip",attrs:{"small":"","text-color":"black"}},[_vm._v("\n                "+_vm._s(_vm._f("capitalize")(item.next_action_name))+"\n              ")]):_vm._e()]}},{key:"item.urgency_name",fn:function(ref){
var item = ref.item;
return [(item.urgency_name !== null)?_c('v-chip',{staticClass:"este-chip status-chip",attrs:{"small":"","text-color":"#221F20","color":_vm.priorityColor(item.urgency)}},[_vm._v("\n                "+_vm._s(_vm._f("capitalize")(item.urgency_name))+"\n              ")]):_vm._e()]}},{key:"item.responsible",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"profile-icon",attrs:{"alt":"avatar"}},[_c('p',[_vm._v(_vm._s(_vm.getInitials(item.responsible.name))+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.SortAtoZ(_vm.statusTags.filter(function(el) {return el.slug.includes('complaint')}))),function(stat){return (stat.id === item.status)?[_c('v-chip',{staticClass:"este-chip mb-2",class:stat.outline ? 'tag-chip' : 'status-chip',attrs:{"small":"","outlined":stat.outline,"text-color":stat.text  ? stat.color : '#221F20',"color":stat.color}},[_vm._v("\n                  "+_vm._s(_vm._f("capitalize")(stat.name))+"\n                ")])]:_vm._e()}),_vm._v(" "),_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit Complaint")])]),_vm._v(" "),_c('li',[_c('a',{on:{"click":function($event){return _vm.markItem(item)}}},[_vm._v("Mark as Closed")])]),_vm._v(" "),_c('li',[(item.status !== 7)?_c('a',{staticClass:"text-mute",on:{"click":function($event){return _vm.archiveItem(item)}}},[_vm._v("Archive Complaint")]):_vm._e()])])])])]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalDialog),callback:function ($$v) {_vm.modalDialog=$$v},expression:"modalDialog"}},[_c('v-card',{staticClass:"delete-alert"},[_c('v-card-title',[_vm._v("\n        Confirm\n        "),_c('a',{staticClass:"float-right pa-6",on:{"click":function($event){_vm.modalDialog = false}}},[_c('img',{attrs:{"src":"/images/close.svg","alt":"Close"}})])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.message)+"\n      ")]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"delete-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap",attrs:{"depressed":"","rounded":"","outlined":"","color":"dark","x-large":""},on:{"click":function($event){_vm.modalDialog = false}}},[_vm._v("\n            Cancel\n          ")]),_vm._v(" "),_c('v-btn',{staticClass:"delete-btn",attrs:{"color":(_vm.actionText === 'Archive') ? 'red' : 'secondary',"depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.handleFunction(_vm.action)}}},[_vm._v("\n            "+_vm._s(_vm.actionText)+"\n          ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }