import { USER_LOGIN, USER_REGISTRATION, USER, USER_VERIFY, LOGOUT, PASSWORD_RESET_LINK, PASSWORD_RESET, VERIFY_PASSWORD_RESET_LINK } from '../action-types'
import {} from '../mutate-types'

import auth from '../../api/collections/auth'
import asyncMiddleware from '../../utils/action-handler'

export default {
  state: {
    currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    token: '',
    userRole: ''
  },
  getters: {
    currentUser: state => state.currentUser
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload
    },
    setToken(state, payload) {
      state.token = payload
    },
    setUserRole(state, payload) {
      state.userRole = payload
    }
  },
  actions: {
    async [USER_LOGIN]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        localStorage.removeItem('token')
        const { data } = await auth.login(payload)
        if (data.hasOwnProperty('data')) {
          if (data.result === 'success') {
            localStorage.setItem('user', JSON.stringify(data.data))
            localStorage.setItem('token', data.data.token)
            localStorage.setItem('reminderFollowup', true)
            commit('setUser', JSON.stringify(data.data))
            commit('setToken', data.data.token)
          }
        }
        return data
      }, commit, dispatch, USER_LOGIN, true)

      return data
    },

    async [USER_REGISTRATION]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.registration(payload)
        return data
      }, commit, dispatch, USER_REGISTRATION, false)
      return data
    },

    async [USER]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.getUser(payload)
        commit('setUser', data.data)
        return data
      }, commit, dispatch, USER, false)
      return data
    },

    async [USER_VERIFY]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.emailverify(payload)
        return data
      }, commit, dispatch, USER_VERIFY, false)
      return data
    },

    async [PASSWORD_RESET_LINK]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.passwordResetLink(payload)

        return data
      }, commit, dispatch, PASSWORD_RESET_LINK, false)
      return data
    },

    async [PASSWORD_RESET]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.passwordReset(payload)

        return data
      }, commit, dispatch, PASSWORD_RESET, false)
      return data
    },

    async [VERIFY_PASSWORD_RESET_LINK]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.verifyPasswordResetLink(payload)

        return data
      }, commit, dispatch, VERIFY_PASSWORD_RESET_LINK, false)
      return data
    },

    async [LOGOUT]({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await auth.signout(payload)
        localStorage.clear()
        return data
      }, commit, dispatch, LOGOUT, true)

      return data
    }
  }
}
