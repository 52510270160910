import http from '../http/common'

const resource = '/sales'

export default {

	get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),
	update: (payload) => http.post(`${resource}/update-user`, payload),
	show: (id, params) => http.get(`${resource}/${id}${params}`),
	package: (payload) => http.get(`/package?sort_by=name&sort_order=ASC`, payload),
	showPackage: (id) => http.get(`/package/${id}`),
	status: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),
}
