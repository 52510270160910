import collection from '../../api/collections/SendSms'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  actions: {
    async SEND_SMS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.send(payload)
        return data
      }, commit, dispatch, 'SEND_SMS', true)
    }
  }
}
