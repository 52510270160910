<template>
	<div>
		<template v-if="statSkeleton">
			<v-skeleton-loader :loading="loading" type="image"></v-skeleton-loader>
		</template>
		<template v-if="!statSkeleton">
			<h4 class="graph-title pt-6 pl-0">Channel Source By Opportunites Status
				<v-tooltip content-class="doc-tooltip" color="#fff" text-color="#141715" top>
					<template v-slot:activator="{ on, attrs }">
						<v-icon v-bind="attrs" v-on="on" size="20" color="rgba(34, 31, 32, 0.9)">
							mdil-information
						</v-icon>
					</template>
					<span class="content">All Opportunites data by source between {{dateUkFormat(filter.created_at_from)}} and {{dateUkFormat(filter.created_at_to)}} showing their status as of today</span>
				</v-tooltip>
			</h4>
			<div class="graph-wrap">
				<BarChart class="chart" :width="600" :height="230" :options="options" :chartData="channelSourceOpps"></BarChart>
			</div>
		</template>
	</div>
</template>
<script>
import BarChart from "../../plugins/BarChart.js";
import status from "../../mixins/status.js";
import globalFn from "../../mixins/globalFn.js";
export default {
	components: {
		BarChart
	},
	mixins: [status, globalFn],
	data: () => ({
		filter: {
			branches: [],
			created_at_from: '',
			created_at_to: '',
			status: [],
		},
		statSkeleton: false,
		loading: false,
		channelSourceOpps: [],
		options: {
			plugins: {
				datalabels: {
					display: false
				}
			},
			legend: {
				position: 'bottom',
				display: true,
				labels: {
					usePointStyle: true,
					boxWidth: 6,
					fontColor: '#221F20',
					fontFamily: "'Inter', sans-serif",
					fontSize: 14,
				}
			},
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				xAxes: [{
					stacked: true,
					gridLines: {
						display: false,
					},
					ticks: {
						fontColor: '#221F20',
						fontFamily: "'Inter', sans-serif",
					}
				}],
				yAxes: [{
					stacked: true,
					gridLines: {
						display: false,
					},
					ticks: {
						fontColor: '#221F20',
						fontFamily: "'Inter', sans-serif",
						beginAtZero: true,
						callback: function(value) { if (value % 1 === 0) { return value; } }
					}
				}]
			}
		},
	}),
	mounted() {
		this.$root.$on('SearchDashboard', this.SearchDashboard)
	},
	computed: {
		source() {
			let _this = this
			if (this.$store.state.source.list) {
				this.$store.state.source.list = this.$store.state.source.list.map(
					function(el, index) {
						let item = Object.assign({}, el);
						item.slug = el.name.replace(/\s+/g, '_').toLowerCase();
						item.color = _this.statsColors[index]
						return item;
					}
				);
				return this.$store.state.source.list
			}
		},
	},
	created() {
		this.$store.dispatch('source/GET_SOURCE', '')
	},
	methods: {
		async SearchDashboard(data) {
			this.filter.branches = data.branch
			this.filter.created_at_from = data.created_at_from
			this.filter.created_at_to = data.created_at_to
			await this.loadStats()
		},
		async loadStats() {
			this.loading = true
			this.statSkeleton = true
			await this.loadChannelSourceByOpportunityMonth()
			this.loading = false
			this.statSkeleton = false
		},
		getSourceDataset(objArray) {
			let result = []
			this.source.forEach((item) => {
				result.push({
					label: item.name,
					borderWidth: 1,
					borderColor: item.color,
					backgroundColor: item.color,
					data: objArray.map(function(el) { return el[item.slug]; })
				})
			})
			return result
		},

		async loadChannelSourceByOpportunityMonth() {
			let data = []
			await this.$store.dispatch('dashboard/CHANNEL_SOURCE_BY_OPPORTUNITY_TODAY', this.filter).then(res => {
				data = res.data
			})
			this.channelSourceOpps = {
				labels: data.map(function(el) { return el.source; }),
				datasets: this.getStatusDatasetForOpp(data)
			}
		},

		getStatusDatasetForOpp(objArray) {
			let _this = this
			let result = []
			this.SortAtoZ(this.statusTags.filter(function(el) { return el.slug.includes('opportunitie') })).forEach((item) => {
				result.push({
					label: item.name,
					borderWidth: 1,
					borderColor: item.color,
					backgroundColor: item.color,
					data: objArray.map(function(el) { return el[item.name.replace(/\s+/g, '_').toLowerCase()]; })
				})
			})
			return result
		},
	}
}

</script>
