import http from '../http/common'

const resource = '/points'

export default {

	update: (payload) => http.post(`${resource}/update-points-config`, payload),
	pointHistoryByUser: (payload) => http.post(`${resource}/get-points-by-user`, payload),
	get: (payload) => http.get(`${resource}/get-points-config${payload}`)
}
