import { mapActions } from "vuex";
import { USER } from "../store/action-types";
export default {
	data: () => ({
		user: '',
		paneTab: '',
		personalUnit: false,
		leadUnit: false,
		clientUnit: false,
		appointmentUnit: false,
		callbackUnit: false,
		dealUnit: false,
		insightUnit: false,
		complaintUnit: false,
		activityUnit: false,
		leadMenu: [{
			name: 'Personal Details',
			icon: 'mdil-account',
			slug: 'personal',
			module: 'personal'
		}, {
			name: 'Lead Details',
			icon: 'mdil-star',
			slug: 'lead',
			module: 'lead'
		}, {
			name: 'Insights',
			icon: 'mdil-chart-areaspline',
			slug: 'insight',
			module: 'insight'
		}, {
			name: 'Activity',
			icon: 'mdi-history',
			slug: 'activity',
			module: 'activity'
		}],
		clientMenu: [{
			name: 'Personal Details',
			icon: 'mdil-account',
			slug: 'personal',
			module: 'personal'
		}, {
			name: 'Client Details',
			icon: 'mdil-star',
			slug: 'client',
			module: 'client'
		}, {
			name: 'Deals',
			icon: 'mdil-book',
			slug: 'deal',
			module: 'deal'
		}, {
			name: 'Insights',
			icon: 'mdil-chart-areaspline',
			slug: 'insight',
			module: 'insight'
		}, {
			name: 'Activity',
			icon: 'mdi-history',
			slug: 'activity',
			module: 'activity'
		}],
		appointmentMenu: [{
			name: 'Personal Details',
			icon: 'mdil-account',
			slug: 'personal',
			module: 'personal'
		}, {
			name: 'Lead/Client Details',
			icon: 'mdil-star',
			slug: 'client',
			module: 'client'
		}, {
			name: 'Appointments',
			icon: 'mdil-calendar',
			slug: 'appointment',
			module: 'appointment'
		}, {
			name: 'Insights',
			icon: 'mdil-chart-areaspline',
			slug: 'insight',
			module: 'insight'
		}, {
			name: 'Activity',
			icon: 'mdi-history',
			slug: 'activity',
			module: 'activity'
		}],
		callbackMenu: [{
			name: 'Personal Details',
			icon: 'mdil-account',
			slug: 'personal',
			module: 'personal'
		}, {
			name: 'Lead/Client Details',
			icon: 'mdil-star',
			slug: 'client',
			module: 'client'
		}, {
			name: 'Call Back',
			icon: 'mdil-phone',
			slug: 'callback',
			module: 'callback'
		}, {
			name: 'Insights',
			icon: 'mdil-chart-areaspline',
			slug: 'insight',
			module: 'insight'
		}, {
			name: 'Activity',
			icon: 'mdi-history',
			slug: 'activity',
			module: 'activity'
		}],
		complaintMenu: [{
			name: 'Client Details',
			icon: 'mdil-star',
			slug: 'client',
			module: 'client'
		}, {
			name: 'Complaints',
			icon: 'mdil-thumb-down',
			slug: 'complaint',
			module: 'complaint'
		}, {
			name: 'Insights',
			icon: 'mdil-chart-areaspline',
			slug: 'insight',
			module: 'insight'
		}, {
			name: 'Activity',
			icon: 'mdi-history',
			slug: 'activity',
			module: 'activity'
		}]
	}),
	created() {
		let _this = this
		window.Echo.channel("update-staff").listen(".staff-updated", e => {
			_this.loadPermissions()
		})
	},
	mounted() {
		this.loadPermissions()
	},
	methods: {
		...mapActions({
			USER
		}),
		async loadPermissions() {
			await this.USER().then(res => {
				this.user = res.data
			})
		},
		async showTab(slug) {
			this.paneTab = slug
			switch (slug) {
				case 'personal':
					this.personalUnit = true
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'lead':
					this.personalUnit = false
					this.leadUnit = true
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'client':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = true
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'appointment':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = true
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'callback':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = true
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'deal':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = true
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'insight':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = true
					this.complaintUnit = false
					this.activityUnit = false
					break;
				case 'complaint':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = true
					this.activityUnit = false
					break;
				case 'activity':
					this.personalUnit = false
					this.leadUnit = false
					this.clientUnit = false
					this.appointmentUnit = false
					this.callbackUnit = false
					this.dealUnit = false
					this.insightUnit = false
					this.complaintUnit = false
					this.activityUnit = true
					break;
			}
		},
		hasPermission(item) {
			let permission = true
			if (this.user.user_type_name !== 'Admin') {
				if (item.module === 'report') {
					permission = this.user.permission_modules.some(function(el) {
						return ['commission_report', 'invoice_report', 'cash_payment_report', 'social_media_report', 'lead_source_report', 'email_report', 'social_media_chased_report'].includes(el.module)
					});
				} else if (item.module === 'support-tickets') {
					permission = this.user.permission_modules.some(function(el) {
						return ['complaint', 'support-tickets', 'callback'].includes(el.module)
					});
				} else if (['personal', 'activity'].includes(item.module)) {
					permission = true
				} else if (item.module === 'pulse_dashboard') {
					permission = ['Manager'].includes(this.user.role_type_name)
				} else {
					if (this.user.permission_modules) {
						permission = this.user.permission_modules.filter(e => e.module === item.module).length > 0;
					}
				}
			}
			return permission
		},
		subMenuPermission(item) {
			let modules = item.map(a => a.module);
			let found = true
			if (this.user.user_type_name !== 'Admin') {

				found = this.user.permission_modules.some(function(el) {
					return modules.includes(el.module)
				});
			}
			return found
		},

		settingsMenuPermission(item) {
			let modules = item.map(a => a.module);
			let found = true
			if (this.user.user_type_name !== 'Admin') {

				found = this.user.permission_modules.some(function(el) {
					return modules.includes(el.module)
				});
			}
			return found
		},
		rolePermission() {
			let permission = true
			if (this.user.user_type_name !== 'Admin') {
				permission = ['Branch Manager', 'Manager'].includes(this.user.role_type_name)
			}
			return permission
		}
	}
}
