import collection from '../../../api/collections/Callback'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    assignedTo: [],
    item: {},
    widget: {},
    pagination: {}
  },
  mutations: {
    SET_CALLBACK(state, data) {
      state.list = data
    },
    SET_ASSIGNED_TO(state, data) {
      state.assignedTo = data
    },
    SET_CALLBACK_ITEM(state, data) {
      state.item = data
    },
    RESET_CALLBACK(state, data) {
      state.item = {}
    },
    SET_WIDGET_COUNT(state, data) {
      state.widget = {}
    },
    SET_CALLBACK_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_CALLBACK({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_CALLBACK', data.data.data)
        return data
      }, commit, dispatch, 'GET_CALLBACK', true)
    },
    async GET_CALENDAR({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.calendar(payload.data, payload.params)
        return data
      }, commit, dispatch, 'GET_CALENDAR', true)
    },
    async GET_PABAU({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.pabau(payload.data, payload.params)
        return data
      }, commit, dispatch, 'GET_PABAU', true)
    },
    async CREATE_CALLBACK({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_CALLBACK', true)
      return data
    },
    async SHOW_CALLBACK({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_CALLBACK_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW_CALLBACK', false)
      return data
    },
    async UPDATE_CALLBACK({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_CALLBACK', true)

      return data
    },
    async UPDATE_STATUS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.archive(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_STATUS', true)

      return data
    },
    async ADD_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.comment(payload)
        return data
      }, commit, dispatch, 'ADD_COMMENT', true)
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.upload(payload)
        return data
      }, commit, dispatch, 'UPLOAD_DOCS', true)
      return data
    },

    async CREATE_CALLBACK_USER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.callbackUser(payload)
        return data
      }, commit, dispatch, 'CREATE_CALLBACK_USER', true)
      return data
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.callbackCount(payload)
        commit('SET_WIDGET_COUNT', data.data)
        return data
      }, commit, dispatch, 'WIDGET_COUNT', true)
    },

    async ASSIGNED_TO({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.assignedTo(payload)
        commit('SET_ASSIGNED_TO', data.data)
        return data
      }, commit, dispatch, 'ASSIGNED_TO', true)
    },

    async CALLBACKS_BY_USERID({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.callbacksByUserId(payload)
        return data
      }, commit, dispatch, 'CALLBACKS_BY_USERID', true)

      return data
    },
  }
}
