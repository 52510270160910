<template>
	<v-dialog v-model="logCallbackNote" max-width="550" class="rounded-6">
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on" class="badge--chip d-flex align-center justify-center cursor" :class="showPlus ? 'bg-gold-100': 'bg-gray-100' " @mouseover="showPlus = true" @mouseleave="showPlus = false">
				<img src="/images/icons/phone.svg" class="text-black-100 ma-0 mr-1" width="13" height="13" />
				<span class="font-weight--semibold text-black-300 font-11 line-h-13">
					{{opportunity.callback_note_count}}
				</span>
				<inline-svg class="ml-1" src="/images/plus.svg" v-if="showPlus" />
			</div>
		</template>
		<v-card class="rounded-6 advance-sec">
			<v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold">
				Log Call
			</v-card-title>
			<v-divider class="my-5"></v-divider>
			<v-form ref="form">
				<v-card-text class="pt-0">
					<p class="font-13 line-h-16 font-weight--semibold text-black-300 mb-2">Please describe the callback</p>
					<v-text-field autocomplete="off" solo outlined flat v-model="comment" hide-details="auto" class="mb-2 solo-cust"></v-text-field>
					<div class="d-flex align-center mb-8">
						<template v-for="(item,index) in options">
							<div :class="['font-11 line-h-13 font-weight--medium height-29 py-2 px-3 border rounded-6 mr-1 cursor', { 'bg-gray-100': suggestedIndex === index  }]" @click="updateSuggestions(item, index)">
								{{item}}
							</div>
						</template>
					</div>
					<v-row class="e-row">
						<v-col cols="12" md="6" class="e-col">
							<label>
								<span class="font-13 line-h-16 font-weight--semibold text-black-300">
									Follow Up Date
								</span>
							</label>
							<v-menu v-model="followUpMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
								<template v-slot:activator="{ on }">
									<v-text-field :full-width="true" height="35" @click:clear="clearFollowUpDate" clearable clear-icon="mdi-close-circle-outline" autocomplete="off" :value="dateUkFormat(opportunity.follow_up_date)" solo outlined v-on="on" flat hide-details="auto" class="advance-input no-width" prepend-inner-icon="mdil-calendar"></v-text-field>
								</template>
								<v-date-picker v-model="opportunity.follow_up_date" no-title @input="followUpMenu = false"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="12" md="6" class="e-col">
							<label>
								<span class="font-13 line-h-16 font-weight--semibold text-black-300">Follow Up Time:</span>
							</label>
							<v-menu ref="followUpTimeMenu" v-model="followUpTimeMenu" :close-on-content-click="false" :return-value.sync="opportunity.follow_up_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field :full-width="true" height="35" @click:clear="clearFollowUpTime" clearable clear-icon="mdi-close-circle-outline" outlined flat hide-details="auto" solo class="advance-input no-width" v-model="opportunity.follow_up_time" prepend-inner-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="followUpTimeMenu" v-model="opportunity.follow_up_time" full-width @click:minute="$refs.followUpTimeMenu.save(opportunity.follow_up_time)"></v-time-picker>
							</v-menu>
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider class="my-5"></v-divider>
				<v-card-actions class="d-flex justify-space-between align-center px-4 pb-4">
					<div class="d-flex align-center">
						<p class="mb-0 font-13 line-h-16 font-weight--semibold mr-2">Set Status</p>
						<v-select :full-width="true" autocomplete="off" :items="statusTags.filter(function(el) {return el.slug.includes('opportunitie')})" v-model="opportunity.status" item-text="name" item-value="id" solo outlined flat class="advance-input" hide-details="auto">
							<template slot="append">
								<v-icon>mdi-chevron-down</v-icon>
							</template>
						</v-select>
					</div>
					<div class="d-flex align-center">
						<v-btn class="height-35 border rounded-6 text-capitalize px-3 py-2 letter-s-0 mr-2 font-12 line-h-15 font-weight--regular" depressed outlined x-large @click="logCallbackNote = false">
							Cancel
						</v-btn>
						<v-btn @click="addComment" color="secondary" class="height-35 text-capitalize rounded-6 letter-s-0 bg-gold-100 px-3 py-2 font-12 line-h-15 font-weight--bold" depressed x-large>
							Save
						</v-btn>
					</div>
				</v-card-actions>
			</v-form>
		</v-card>
	</v-dialog>
</template>
<script>
import status from '../../../mixins/status';
import globalFn from '../../../mixins/globalFn';
export default {

	name: 'CallbackNote',
	mixins: [status, globalFn],
	props: {
		opportunity: {
			required: true,
			default: null
		}
	},
	data() {
		return {
			logCallbackNote: false,
			followUpTimeMenu: false,
			followUpMenu: false,
			showPlus: false,
			suggestedIndex: null,
			comment: '',
			status: null,
			options: ['Did not answer', `Wasn't interested`, `Left Voicemail`, `Sent WhatsApp`]
		}
	},
	methods: {
		updateSuggestions(item, index) {
			this.suggestedIndex = index;
			this.comment = item
		},

		clearFollowUpDate() {
			this.opportunity.follow_up_date = null
		},
		clearFollowUpTime() {
			this.opportunity.follow_up_time = null
		},

		async addComment() {
			let formData = {}
			formData.id = this.opportunity.id
			formData.comment = this.comment
			formData.category = 'callback_note'
			if (this.$refs.form.validate()) {
				await this.$store.dispatch(`opportunities/ADD_COMMENT`, formData).then(res => {
					if (res.result === 'success') {
						this.loading = false
						this.markCall(res.data)
						if (this.comment === 'Chasing Callback') {
							this.flagOpportunity()
						}
					} else if (res.result === 'fail') {
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
			}
		},

		async markCall(item) {
			let form = {}
			form.comment_id = item.id
			form.is_marked = 1
			await this.$store.dispatch('customers/MARK_AS_CALL', form).then(res => {
				if (res.result === 'success') {
					this.loading = false
					this.updateStatus()
				} else if (res.result === 'fail') {
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},

		async flagOpportunity() {
			await this.$store.dispatch(`opportunities/UPDATE`, { id: this.opportunity.id, is_urgent: 1 }).then(res => {
				if (res.result === 'success') {} else if (res.result === 'fail') {
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},

		async updateStatus() {
			let formData = {}
			formData.id = this.opportunity.id
			formData.follow_up_time = this.opportunity.follow_up_time
			formData.follow_up_date = this.opportunity.follow_up_date
			formData.status = this.opportunity.status
			if (this.$refs.form.validate()) {
				await this.$store.dispatch(`opportunities/UPDATE`, formData).then(res => {
					if (res.result === 'success') {
						this.loading = false
						this.logCallbackNote = false
						this.$root.$emit("opportunitiesTable", "");
					} else if (res.result === 'fail') {
						res.type = 'error'
						this.$store.dispatch('snackbar/SHOW', res)
					}
				}).catch(err => {
					err.type = 'error'
					this.$store.dispatch('snackbar/SHOW', err)
				});
			}
		}
	}
}

</script>
<style lang="css" scoped>
</style>
