import http from '../../http/common'

const resource = '/expenses/cash-payment'

export default {

	create: (payload) => http.post(resource, payload),

	get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

	show: (id, params) => http.get(`${resource}/${id}${params}`),

	update: (id, payload) => http.put(`${resource}/${id}`, payload),

	delete: (id) => http.delete(`${resource}/${id}`),

}
