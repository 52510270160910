<template>
	<div>
		<div class="fill-height slider-cont">
			<div class="modal-inside pb-0 no-tab-right-pane">
				<div class="form-title mb-24">Add Notification</div>
				<v-form ref="form">
					<div class="tab-content-wrapper">
						<v-row class="e-row">
							<v-col cols="12" md="12" class="e-col">
								<label>
									<span class="label-txt">
										Title
									</span>
								</label>
								<v-text-field autocomplete="off" solo outlined flat :rules="rules.title" v-model="form.title" hide-details="auto" class="solo-cust"></v-text-field>
							</v-col>
						</v-row>
						<v-row class="e-row">
							<v-col cols="12" md="12" class="e-col">
								<label>
									<span class="label-txt">
										Message
									</span>
								</label>
								<v-textarea :rules="rules.body" autocomplete="off" solo outlined v-model="form.body" flat hide-details="auto" class="solo-cust"></v-textarea>
							</v-col>
						</v-row>
						<v-row class="e-row mb-4">
							<v-col cols="12" md="12" class="e-col">
								<label>
									<span class="label-txt font-weight--medium">
										Recipient
									</span>
								</label>
								<v-radio-group hide-details="auto" mandatory v-model="form.recepiant_segment" row>
									<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" value="all">
										<template #label>
											<span class="label-txt">All</span>
										</template>
									</v-radio>
									<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" value="user">
										<template #label>
											<span class="label-txt">User</span>
										</template>
									</v-radio>
								</v-radio-group>
							</v-col>
						</v-row>
						<v-row class="e-row" v-if="form.recepiant_segment === 'user'">
							<v-col cols="12" md="12" class="e-col">
								<label>
									<span class="label-txt">
										Send to:
									</span>
								</label>
								<div class="mt-2 mb-4" v-if="selectedContacts.length > 0">
									<v-chip class="este-chip tag-chip px-3 mb-2" v-for="(item,index) in selectedContacts" :key="index">
										<span v-if="item.name">{{ item.name }} </span>
										<span v-if="item.name === null">{{ item.social_handle }} </span>
										<span v-if="item.contact_no">/ {{ item.contact_no }} </span>
										<span v-if="item.email">/ {{ item.email }} </span>
										<v-icon color="primary" class="ml-2" small @click.stop="removeContact(item)">
											mdi-close
										</v-icon>
									</v-chip>
								</div>
								<v-text-field flat rounded prepend-inner-icon="mdi-magnify" :rules="rules.user_id" autocomplete="off" label="Search for a contact…" class="solo-cust" clearable clear-icon="mdi-close-circle-outline" solo hide-details="auto" @input="isTyping = true" v-model="search" :loading="loading">
								</v-text-field>
								<ul class="contact--list" :class="(contacts.length > 20) ? 'height-300' : ''">
									<li v-for="(item,index) in contacts" :key="index">
										<a @click="selectContact(item)" class="d-flex align-center flex-row cursor">
											<span class="font-12 line-h-15 font-weight--regular text-black-100 ml-3" v-if="item">
												<span v-if="item.name">{{ item.name }} </span>
												<span v-if="item.name === null">{{ item.social_handle }} </span>
												<span v-if="item.contact_no">/ {{ item.contact_no }} </span>
												<span v-if="item.email">/ {{ item.email }} </span>
											</span>
											<span class="small ml-2 badge--chip font-weight--bold text-black-300 font-11 line-h-13" :class="
                item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'
              ">
												{{ item.user_type_name === "Lead" ? "L" : "C" }}
											</span>
										</a>
									</li>
								</ul>
							</v-col>
						</v-row>
						<v-row class="e-row">
							<v-col cols="12" md="6" class="e-col">
								<label>
									<span class="font-13 line-h-16 font-weight--semibold text-black-300">
										Schedule Send Date:
									</span>
								</label>
								<v-menu v-model="scheduleSentDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
									<template v-slot:activator="{ on }">
										<v-text-field :rules="rules.schedule_send_date" clearable clear-icon="mdi-close-circle-outline" autocomplete="off" :value="dateUkFormat(form.schedule_send_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" prepend-inner-icon="mdil-calendar"></v-text-field>
									</template>
									<v-date-picker v-model="form.schedule_send_date" no-title @input="scheduleSentDateMenu = false"></v-date-picker>
								</v-menu>
							</v-col>
							<v-col cols="12" md="6" class="e-col">
								<label>
									<span class="font-13 line-h-16 font-weight--semibold text-black-300">Schedule Send Time:</span>
								</label>
								<v-menu ref="scheduleSentTimeMenu" v-model="scheduleSentTimeMenu" :close-on-content-click="false" :return-value.sync="form.schedule_send_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field :rules="rules.schedule_send_time" clearable clear-icon="mdi-close-circle-outline" outlined flat hide-details="auto" solo class="solo-cust" v-model="form.schedule_send_time" prepend-inner-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
									</template>
									<v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="scheduleSentTimeMenu" v-model="form.schedule_send_time" full-width @click:minute="$refs.scheduleSentTimeMenu.save(form.schedule_send_time)"></v-time-picker>
								</v-menu>
							</v-col>
						</v-row>
						<v-row class="e-row">
							<v-col cols="12" md="12" class="e-col">
								<label>
									<span class="label-txt">
										Status
									</span>
								</label>
								<v-select autocomplete="off" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('notification')}))" v-model="form.status" :rules="rules.status" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
									<template slot="append">
										<v-icon>mdi-chevron-down</v-icon>
									</template>
								</v-select>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</div>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap" depressed rounded outlined x-large @click="close">
					Cancel
				</v-btn>
				<v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="create">
					Add Notification
				</v-btn>
			</div>
		</div>
	</div>
</template>
<script>
import status from '../../../mixins/status.js';
import globalFn from '../../../mixins/globalFn.js';
import _ from 'lodash';
export default {
	mixins: [status, globalFn],
	data: () => ({
		loading: false,
		contactLoading: false,
		user: JSON.parse(localStorage.getItem('user')),
		tab: null,
		brancAllSelect: false,
		scheduleSentDateMenu: false,
		scheduleSentTimeMenu: false,
		treatmentSelect: false,
		staffSelect: false,
		contactSearch: null,
		selectedContacts: [],
		form: {
			title: null,
			body: null,
			user_ids: [],
			expired_at: null,
			schedule_send_date: null,
			schedule_send_time: null,
			status: null,
			recepiant_segment: null,
			search: null
		},
		rules: {
			user_id: [],
			title: [
				v => !!v || 'Please enter title'
			],
			body: [
				v => !!v || 'Please enter message'
			],
			schedule_send_date: [
				v => !!v || 'Please select schedule send date'
			],
			schedule_send_time: [
				v => !!v || 'Please select schedule send time'
			],
			status: [
				v => !!v || 'Please select status'
			],

		},
		contacts: [],
		search: "",
		isTyping: false,
	}),
	watch: {
		search: _.debounce(function() {
			this.isTyping = false;
		}, 1000),
		isTyping: function(value) {
			if (!value && this.search) {
				this.globalSearchAPI();
			}
		}
	},
	mounted() {},
	methods: {
		async globalSearchAPI() {
			let data = {}
			data.status = [1, 3, 5, 15, 19, 20, 23, 26, 7, 13, 28, 21, 22]
			data.page_size = 1000
			data.search = this.search
			this.loading = true
			await this.$store.dispatch('user/GLOBAL_SEARCH', data).then(res => {
				this.contacts = res.data.data
				this.loading = false
			})
		},
		async removeContact(item) {
			const index = this.selectedContacts.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.selectedContacts.splice(index, 1)
			} else {
				const i = this.selectedContacts.findIndex(x => x === item.id)
				if (i >= 0) this.selectedContacts.splice(i, 1)
			}
		},
		close() {
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		loadData() {
			this.$root.$emit("refreshNotificationsTable", "");
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		treatmentsSelectToggle() {
			this.treatmentSelect = !this.treatmentSelect
			if (this.treatmentSelect === true) {
				this.form.for_treatment = []
				this.treatments.forEach(value => {
					this.form.for_treatment.push(parseInt(value.id));
				});
			} else {
				this.form.for_treatment = []
			}
		},
		branchChange() {
			this.brancAllSelect = !this.brancAllSelect
			if (this.brancAllSelect === true) {
				this.form.for_branch = []
				this.branches.forEach(value => {
					this.form.for_branch.push(parseInt(value.id));
				});
			} else {
				this.form.for_branch = []
			}
		},

		toggleAllStaff() {
			this.staffSelect = !this.staffSelect
			if (this.staffSelect === true) {
				this.form.for_user = []
				this.contacts.forEach(value => {
					this.form.for_user.push(parseInt(value.id));
				});
			} else {
				this.form.for_user = []
			}
		},
		async selectContact(item) {
			this.selectedContacts.push(item)
		},
		async create() {

			if (this.form.recepiant_segment === 'user' && this.selectedContacts.length === 0) {
				this.rules.user_id = [
					v => !!v || 'Please select contact'
				]
			} else {

				this.rules.user_id = []

				let formData = {}
				formData.title = this.form.title
				formData.body = this.form.body
				formData.schedule_send_date = this.form.schedule_send_date
				formData.schedule_send_time = this.form.schedule_send_time
				formData.status = this.form.status
				formData.recepiant_segment = this.form.recepiant_segment

				formData.user_ids = []
				this.selectedContacts.forEach(value => {
					if (value.id) {
						formData.user_ids.push(value.id);
					} else {
						formData.user_ids.push(value);
					}
				});

				if (this.$refs.form.validate()) {
					this.loading = true
					await this.$store.dispatch('notifications/CREATE', formData).then(response => {
						if (response.result === 'success') {
							this.loading = false
							this.loadData()
						} else if (response.result === 'fail') {
							response.type = 'error'
							this.loading = false
							this.$store.dispatch('snackbar/SHOW', response)
						}
					}).catch(err => {
						this.loading = false
						err.type = 'error'
						this.$store.dispatch('snackbar/SHOW', err)
					});
				}
			}
		}
	}
};

</script>
