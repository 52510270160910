<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-form ref="purchaseInvoiceForm">
              <v-data-table class="results-table invoices" :single-expand="true" :expanded.sync="expanded" :item-class="AddRowClass" item-key="id" :headers="headers" :items="items" :loading="loading" :options.sync="options" sort-by="updated_at" :items-per-page="itemsPerPage" :footer-props="footerProps" :server-items-length="totalCount">
                <template v-slot:item.contact="{ item }">
                  <div class="name-column">
                    <p class="user-name">
                      {{ item.contact_name }}
                    </p>
                    <p class="ml-n1" v-if="item.contact_no !== null && item.contact_no !== ''">
                      <span class="contact">
                        <a :href="`tel:${item.contact_no}`">
                          <img src="/images/phone.svg" height="15"> {{ item.contact_no }}</a>
                      </span>
                    </p>
                    <p class="mt-4 ml-n1 mb-4" v-if="item.email !== null && item.email !== ''">
                      <span class="contact">
                        <a :href="`mailto:${item.email}`">
                          <img src="/images/email.svg" height="15"> {{ item.email }}</a>
                      </span>
                    </p>
                  </div>
                </template>
                <template v-slot:item.dates="{ item }">
                  <div class="date-column" v-if="item.invoice_date !== null && item.invoice_date !== ''">
                    <p class="date-sec date-mute">
                      <v-btn class="badge badge-grey" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                      </v-btn> {{ item.invoice_date | moment("DD/MM/YY") }}
                    </p>
                    <template v-if="item.due_date !== null && item.due_date !== ''">
                      <p class="date-sec vertical-bar" v-if="item.due_date !== null && item.due_date !== ''">|</p>
                      <p class="date-sec">
                        <v-btn class="badge badge-grey" icon>
                          <v-icon size="20" color="black">mdil-calendar</v-icon>
                        </v-btn> {{ item.due_date | moment("DD/MM/YY") }}
                      </p>
                    </template>
                  </div>
                </template>
                <template v-slot:item.contact_name="{ item }">
                  <v-text-field autocomplete="off" solo outlined v-model="editedItem.contact_name" flat hide-details="auto" class="solo-cust inline-input" placeholder="Company Name" v-if="item.id === editedItem.id">
                  </v-text-field>
                  <div class="name-column" v-else>
                    <p class="user-name">
                      {{ item.contact_name }}
                    </p>
                  </div>
                </template>
                <template v-slot:item.email="{ item }">
                  <template v-if="item.id === editedItem.id">
                    <v-text-field placeholder="Email Address" @input="validateEmailContact" autocomplete="off" solo outlined v-model="editedItem.email" flat hide-details="auto" class="mb-2 solo-cust inline-input">
                    </v-text-field>
                    <v-text-field placeholder="Contact Number" @input="validateEmailContact" autocomplete="off" solo outlined v-model="editedItem.contact_no" flat hide-details="auto" class="solo-cust inline-input">
                    </v-text-field>
                  </template>
                  <template v-else>
                    <p class="ml-n1" v-if="item.contact_no !== null && item.contact_no !== ''">
                      <span class="contact">
                        <a :href="`tel:${item.contact_no}`">
                          <img src="/images/phone.svg" height="15"> {{ item.contact_no }}</a>
                      </span>
                    </p>
                    <p class="mt-4 ml-n1 mb-4" v-if="item.email !== null && item.email !== ''">
                      <span class="contact">
                        <a :href="`tel:${item.email}`">
                          <img src="/images/email.svg" height="15"> {{ item.email }}</a>
                      </span>
                    </p>
                  </template>
                </template>
                <template v-slot:item.invoice_no="{ item }">
                  <v-text-field placeholder="Invoice No" autocomplete="off" solo outlined v-model="editedItem.invoice_no" flat hide-details="auto" class="solo-cust inline-input" v-if="item.id === editedItem.id">
                  </v-text-field>
                  <template v-else>
                    {{ item.invoice_no}}
                  </template>
                </template>
                <template v-slot:item.net_amount="{ item }">
                  <v-text-field placeholder="Net Amount" autocomplete="off" solo outlined v-model="editedItem.net_amount" :rules="rules.net_amount" flat hide-details="auto" class="solo-cust inline-input" v-if="item.id === editedItem.id">
                  </v-text-field>
                  <template v-else>
                    {{ item.net_amount | currency('£')}}
                  </template>
                </template>
                <template v-slot:item.total="{ item }">
                  {{ item.total | currency('£')}}
                </template>
                <template v-slot:item.balance_amount="{ item }">
                  {{ item.balance_amount | currency('£')}}
                </template>
                <template v-slot:item.tax_type="{ item }">
                  <v-select placeholder="Tax Type" autocomplete="off" v-if="item.id === editedItem.id" :items="tax" :rules="rules.tax_type" item-value="id" item-text="name" v-model="editedItem.tax_type" solo outlined flat class="solo-cust" hide-details="auto" style="width: 150px;">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                  <template v-else>
                    {{ item.tax_type }}%
                  </template>
                </template>
                <template v-slot:item.branch_id="{ item }">
                  <v-select placeholder="Branch" autocomplete="off" v-if="item.id === editedItem.id" :items="branches" :rules="rules.branch_id" item-value="id" item-text="branch" v-model="editedItem.branch_id" solo outlined flat class="solo-cust" hide-details="auto" style="width: 150px;">
                    <template slot="append">
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>
                  </v-select>
                  <div class="name-column" v-else>
                    <p class="user-name">
                      {{ item.branch_name }}
                    </p>
                  </div>
                </template>
                <template v-slot:item.invoice_date="{ item }">
                  <v-menu v-if="item.id === editedItem.id" v-model="invoiceMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field placeholder="Invoice Date" autocomplete="off" :value="dateUKformat(editedItem.invoice_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust inline-input" append-icon="mdil-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.invoice_date" no-title @input="invoiceMenu = false"></v-date-picker>
                  </v-menu>
                  <template v-else>
                    <p class="date-sec date-mute" v-if="item.invoice_date !== null && item.invoice_date !== ''">
                      <v-btn class="badge badge-grey" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                      </v-btn>
                      {{ item.invoice_date | moment("DD/MM/YY") }}
                    </p>
                  </template>
                </template>
                <template v-slot:item.due_date="{ item }">
                  <v-menu v-if="item.id === editedItem.id" v-model="dueMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field placeholder="Due Date" autocomplete="off" :value="dateUKformat(editedItem.due_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust inline-input" append-icon="mdil-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.due_date" no-title @input="dueMenu = false"></v-date-picker>
                  </v-menu>
                  <template v-else>
                    <p class="date-sec date-mute" v-if="item.due_date !== null && item.due_date !== ''">
                      <v-btn class="badge badge-grey" icon>
                        <v-icon size="20" color="rgba(34, 31, 32, 0.9)">mdil-calendar</v-icon>
                      </v-btn> {{ item.due_date | moment("DD/MM/YY") }}
                    </p>
                  </template>
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip v-if="item.id !== editedItem.id" small class="este-chip status-chip" text-color="black" :color="getPaymentStatusColor(item.payment_status)">
                    {{ item.payment_status.replace(/[^a-zA-Z ]/g, " ") | capitalize }}
                  </v-chip>
                  <template v-if="item.id === editedItem.id">
                    <v-btn class="px-3 btn-inline" min-width="32" height="32" icon color="#4ACE28" :loading="saveLoading" @click="update">
                      <v-icon>mdi-check</v-icon>
                    </v-btn>
                    <v-btn class="px-3 btn-inline" min-width="32" height="32" icon color="#D34141" :loading="cancelLoading" @click="cancel">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <template v-else>
                    <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                          <v-icon large>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <div class="logout-block">
                        <ul>
                          <li><a @click="editItem(item)">Edit</a></li>
                          <li><a @click="toggleRow(item)" v-if="!expanded.includes(item)">View/Add Payments</a></li>
                          <li><a @click="toggleRow(item)" v-if="expanded.includes(item)">Hide Payments</a></li>
                          <li><a @click="deleteItem(item)">Delete</a></li>
                        </ul>
                      </div>
                    </v-menu>
                  </template>
                </template>
                <!-- Expand row item here -->
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="expanded-table pa-5">
                    <v-row no-gutters class="child">
                      <PaymentComponent :invoiceId="item.id"></PaymentComponent>
                      <AddPaymentComponent :invoiceId="item.id"></AddPaymentComponent>
                    </v-row>
                  </td>
                </template>
                <!-- End expand row item here -->
              </v-data-table>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"><img src="/images/close.svg" alt="Close" /></a>
        </v-card-title>
        <v-card-text>
          {{message}}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn class="cancel-btn btn-gap" depressed rounded outlined @click="modalDialog = false" color="dark" x-large>
              Cancel
            </v-btn>
            <v-btn class="delete-btn" :color="(actionText === 'Delete') ? 'red' : 'secondary'" depressed @click="handleFunction(action)" rounded x-large>
              {{actionText}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn';
import sliderFn from '../../../mixins/sliderFn';
import status from '../../../mixins/status';
import eventBus from '../../../eventBus.js';
import moment from 'moment';
import PaymentComponent from './Payment';
import AddPaymentComponent from './AddPayment';
export default {
  name: "PurchaseInvoices",
  mixins: [globalFn, sliderFn, status],
  components: {
    PaymentComponent,
    AddPaymentComponent
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      cancelLoading: false,
      modalDialog: false,
      invoiceMenu: false,
      dueMenu: false,
      expanded: [],
      headers: [],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: 'desc',
      sortBy: 'updated_at',
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: '',
      params: {
        attributes: 'branch',
        search: '',
        filter: {
          invoice_from: '',
          invoice_to: '',
          due_from: '',
          due_to: '',
          status: [],
          branch: []
        }
      },
      message: '',
      action: '',
      actionText: '',
      editedIndex: -1,
      editedItem: {
        id: 0,
        contact_name: '',
        email: '',
        contact_no: '',
        invoice_no: '',
        net_amount: '',
        tax_type: '',
        invoice_date: '',
        due_date: '',
        branch_id: '',
        type: ''
      },
      defaultItem: {
        id: 0,
        contact_name: '',
        email: '',
        contact_no: '',
        invoice_no: '',
        net_amount: '',
        tax_type: '',
        invoice_date: '',
        due_date: '',
        branch_id: '',
        type: 'new'
      },
      rules: {
        net_amount: [v => !!v || 'Please enter net amount'],
        tax_type: [v => !!v || 'Please enter tax type'],
        branch_id: [v => !!v || 'Please select branch'],
      }
    }
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true
    },
  },
  computed: {
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList
      }
    },
    validateEmailContact() {
      if (this.editedItem.email) {
        this.rules.email = [
          v => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com"
        ]
        this.rules.contact_no = []
      }

      if (this.editedItem.contact_no) {
        this.rules.email = []
        this.rules.contact_no = []
      }
    },
  },
  mounted() {
    this.loadHeaders()
    this.$root.$on('searchPurchaseInvoices', this.getSearchPurchaseInvoices)
    this.$root.$on('AddNewInvoice', this.addNewInvoice)
  },
  created() {
    this.$store.dispatch('branches/GET_ALL_BRANCH', '')
  },
  methods: {
    loadHeaders() {
      this.headers = [{ text: 'Contact Details', sortable: false, align: 'start', value: 'contact' },
        { text: 'Invoice No', value: 'invoice_no', sortable: false, align: 'left' },
        { text: 'Net Amount', value: 'net_amount', sortable: false, align: 'left' },
        { text: 'Tax Type', value: 'tax_type', sortable: false, align: 'left' },
        { text: 'Total', value: 'total', sortable: false, align: 'left' },
        { text: 'Balance', value: 'balance_amount', sortable: false, align: 'left' },
        { text: 'Dates', value: 'dates', sortable: false, align: 'left' },
        { text: 'Branch', value: 'branch_id', sortable: false, align: 'left' },
        { text: 'Status', value: 'status', sortable: false, align: 'end' }
      ]
    },
    AddRowClass(item) {
      if (item.id === this.editedItem.id && this.editedIndex > -1) {
        return "edit-row";
      }
    },
    addNewInvoice() {
      const index = this.items.findIndex(x => x.type === 'new');
      if (index >= 0) {
        this.items.splice(index, 1);
      } else {
        const addObj = Object.assign({}, this.defaultItem);
        if (this.items.length) {
          addObj.id = Math.max.apply(Math, this.items.map(function(o) { return o.id; })) + 1
        } else {
          addObj.id = 0
        }
        this.items.unshift(addObj);
        this.totalCount = this.items.length
        this.editItem(addObj)
      }
    },
    async getSearchPurchaseInvoices(data) {
      this.options.page = 1;
      this.items = []
      if (data) {
        this.params.search = data.search
        this.params.filter.invoice_from = data.invoice_from
        this.params.filter.invoice_to = data.invoice_to
        this.params.filter.due_from = data.due_from
        this.params.filter.due_to = data.due_to
        this.params.filter.status = data.status
        this.params.filter.branch = data.branch
        await this.readTheDataFromApi()
      } else {
        this.params.search = ''
        this.params.filter.invoice_from = ''
        this.params.filter.invoice_to = ''
        this.params.filter.due_from = ''
        this.params.filter.due_to = ''
        this.params.filter.status = []
        this.params.filter.branch = []
        await this.readTheDataFromApi()
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi()
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch('purchaseInvoices/GET_PURCHASE_INVOICE', { data: this.params, params: `?page=${this.page}&per_page=${this.itemsPerPage}` }).then(res => {
        res.data.data = res.data.data.map(
          function(el) {
            let element = Object.assign({}, el);
            element.type = 'old'
            return element;
          }
        );
        this.items = res.data.data
        this.totalCount = res.data.total
      })
      this.loading = false;
    },
    async loadUpdatedApiData() {
      this.items = []
      this.loading = true;
      await this.$store.dispatch('purchaseInvoices/GET_PURCHASE_INVOICE', { data: this.params, params: `?page=${this.page}&per_page=${this.itemsPerPage}` }).then(res => {
        res.data.data = res.data.data.map(
          function(el) {
            let element = Object.assign({}, el);
            element.type = 'old'
            return element;
          }
        );
        this.items = res.data.data
        this.totalCount = res.data.total
      })
      this.loading = false;
    },
    editItem(item) {
      item.tax_type = (item.tax_type === 0) ? 1 : item.tax_type
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      const index = this.expanded.indexOf(item)
      if (index > -1) {
        this.expanded.splice(index, 1)
      }
      this.headers = [{ text: 'Company Name', sortable: false, align: 'start', value: 'contact_name' },
        { text: 'Contact Details', value: 'email', sortable: false, align: 'left' },
        { text: 'Invoice No', value: 'invoice_no', sortable: false, align: 'left' },
        { text: 'Net Amount', value: 'net_amount', sortable: false, align: 'left' },
        { text: 'Tax Type', value: 'tax_type', sortable: false, align: 'left' },
        { text: 'Invoice Date', value: 'invoice_date', sortable: false, align: 'left' },
        { text: 'Due Date', value: 'due_date', sortable: false, align: 'left' },
        { text: 'Branch', value: 'branch_id', sortable: false, align: 'left' },
        { text: 'Status', value: 'status', sortable: false, align: 'end' }
      ]
    },
    cancel() {
      this.cancelLoading = true
      if (this.editedItem.type === 'new') {
        const index = this.items.findIndex(x => x.id === this.editedItem.id);
        if (index >= 0) {
          this.items.splice(index, 1);
        }
      } else {
        this.editedIndex = -1;
        this.editedItem = Object.assign({}, this.defaultItem);
        this.loadUpdatedApiData()
      }
      this.cancelLoading = false
      this.resetForm()
      this.loadHeaders()
    },
    resetForm() {
      this.$refs.purchaseInvoiceForm.reset()
      this.$refs.purchaseInvoiceForm.resetValidation()
    },
    async update() {
      if (this.editedItem.contact_no === '' && this.editedItem.email === '') {
        let response = []
        response.type = 'error'
        response.message = 'Please enter either email address/contact number'
        this.$store.dispatch('snackbar/SHOW', response)
        this.$refs.purchaseInvoiceForm.validate()
      } else {
        if (this.$refs.purchaseInvoiceForm.validate()) {
          if (this.editedItem.type === 'new') {
            this.updateOrCreate('purchaseInvoices/CREATE_PURCHASE_INVOICE')
          } else {
            this.updateOrCreate('purchaseInvoices/UPDATE_PURCHASE_INVOICE')
          }
        }
      }
    },
    async updateOrCreate(action) {
      let formData = {}
      if (this.editedItem.type === 'old') {
        formData.id = this.editedItem.id
      }
      formData.contact_name = this.editedItem.contact_name
      formData.contact_no = this.editedItem.contact_no
      formData.invoice_no = this.editedItem.invoice_no
      formData.net_amount = this.editedItem.net_amount
      formData.invoice_date = this.editedItem.invoice_date
      formData.due_date = this.editedItem.due_date
      formData.branch_id = this.editedItem.branch_id
      formData.tax_type = (this.editedItem.tax_type === 1) ? 0 : this.editedItem.tax_type;
      if (this.editedItem.email) {
        formData.email = this.editedItem.email;
      }
      await this.$store.dispatch(action, formData).then(response => {
        if (response.result === 'success') {
          this.saveLoading = false
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
          this.loadUpdatedApiData()
          this.resetForm()
          this.loadHeaders()
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.saveLoading = false
          this.$store.dispatch('snackbar/SHOW', response)
          this.resetForm()
        }
      }).catch(err => {
        this.saveLoading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
        this.resetForm()
      });
    },
    handleFunction(funName) {
      this[funName]()
    },
    async deleteItem(item) {
      this.selectedItem = item
      this.modalDialog = true
      this.message = "Please confirm you want to delete this Purchase Invoice?"
      this.actionText = "Delete"
      this.action = "delete"
    },
    async delete() {
      await this.$store.dispatch('purchaseInvoices/DELETE_PURCHASE_INVOICE', this.selectedItem.id).then(res => {
        if (res.result === 'success') {
          this.modalDialog = false
          this.loadUpdatedApiData()
        } else if (res.result === 'fail') {
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },
    async toggleRow(item) {
      const index = this.expanded.indexOf(item)
      if (index === -1) {
        this.expanded.push(item)
      } else {
        this.expanded.splice(index, 1)
      }
    },
  }
};

</script>
