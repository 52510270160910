import http from '../../http/common'

const resource = '/appointment'

export default {

	create: (payload) => http.post(resource, payload),

	get: (payload, params) => http.post(`${resource}/get-data${params}`, payload),

	calendar: (payload, params) => http.post(`${resource}/get-by-week${params}`, payload),

	show: (id, params) => http.get(`${resource}/${id}${params}`),

	update: (id, payload) => http.put(`${resource}/${id}`, payload),

	delete: (id) => http.delete(`${resource}/${id}`),

	search: (params) => http.get(`${resource}/search${params}`),

	archive: (id, payload) => http.post(`${resource}/${id}/update-status`, payload),

	convertToClient: (payload) => http.post(`${resource}/convert-to-client`, payload),

	comment: (payload) => http.post(`${resource}/add-comment`, payload),

	upload: (payload) => http.post(`${resource}/upload-docs`, payload),

	deal: (payload) => http.post(`${resource}/create-deal`, payload),

	pabau: (payload, params) => http.post(`${resource}/get-pabau-appointments${params}`, payload),

	appointmentCount: (payload) => http.post(`${resource}/appointment-count`, payload),

	assignedTo: (payload) => http.get(`${resource}/staff-list${payload}`),

	calendarDashboard: (payload, params) => http.post(`${resource}/calendar-dashboard${params}`, payload),

	appointmentsByUserId: (id) => http.get(`${resource}/get-by-user/${id}`),

	pabauAppointments: (payload) => http.post(`${resource}/pabau`, payload),

	pabauAppointmentShow: (payload) => http.post(`${resource}/pabau/show`, payload),
	
	pabauAppointmentUpdateUser: (payload) => http.post(`${resource}/pabau/update-user`, payload),
}
