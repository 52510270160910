<template>
	<v-row class="e-row deal-block" v-if="Object.keys(deal).length > 0">
		<v-col cols="12" md="12" class="e-col">
			<label>
				<span class="label-txt">
					Deals Info
				</span>
			</label>
			<div class="deal-wrap">
				<v-row class="e-row">
					<v-col cols="12" md="12">
						<div class="d-flex justify-space-between mb-1">
							<p class="name">{{deal.deal_info}}</p>
							<div>
								<div class="d-flex justify-space-between mb-1">
									<p class="total" v-if="deal.latest_payment !== null">{{deal.latest_payment.amount | currency('£')}}</p>
									<p class="total" v-else>£0.00</p>
								</div>
								<p class="payment-date">
									Deal Created at : {{ deal.created_at | moment("DD/MM/YY") }}
								</p>
							</div>
						</div>
						<p>
							<span class="total mr-2">{{deal.total | currency('£')}}</span>
							<span>
								<template v-for="treat in deal.deal_treatments">
									<v-chip small class="este-chip status-chip" text-color="#221F20" color="rgba(34, 31, 32, 0.08)">
										{{treat.treatment_name}}
									</v-chip>
								</template>
							</span>
						</p>
					</v-col>
				</v-row>
				<v-row class="e-row">
					<v-col cols="12" md="12" class="e-col">
						<p class="sold-by">Sold by: {{ deal.sold_by.name }}</p>
					</v-col>
				</v-row>
				<v-row class="e-row" v-if="deal.notes">
					<v-col cols="12" md="12" class="e-col">
						<p class="sold-by font-weight-bold mb-2">Deal Notes: </p>
						<div class="sold-by line-height-20" v-if="!readMore">
							{{ deal.notes.slice(0, 100) }}
							<a class="read-text text-decoration-underline" v-if="!readMore && deal.notes.length > 100" @click="readMore = !readMore"> Read more</a>
						</div>
						<div class="sold-by line-height-20" v-if="readMore">
							{{ deal.notes }}
							<a class="read-text text-decoration-underline" v-if="readMore" @click="readMore = !readMore"> Read less</a>
						</div>
					</v-col>
				</v-row>
				<v-row class="e-row">
					<v-col cols="12" md="12" class="e-col">
						<v-tabs right v-model="dealTab" class="cust-tabs mb-5">
							<v-tabs-slider class="cust-tabs-slider"></v-tabs-slider>
							<v-tab href="#payment" :class="(dealTab === 'payment') ? 'v-tab--active' : ''">Payments</v-tab>
							<v-tab href="#appointment" :class="(dealTab === 'appointment') ? 'v-tab--active' : ''">Appointments</v-tab>
						</v-tabs>
						<v-tabs-items v-model="dealTab">
							<v-tab-item value="payment" :eager="true">
								<v-data-table :headers="headers" :items="deal.payments" :hide-default-footer="true" dense class="inner-table">
									<template v-slot:item.date="{ item }">
										{{item.date | moment("DD/MM/YY")}}
									</template>
									<template v-slot:item.total="{ item }">
										<span class="red--text" v-if="item.method === 7">- {{item.total | currency('£')}}</span>
										<span v-else>{{item.total | currency('£')}}</span>
									</template>
									<template v-slot:item.taken_by="{ item }">
										{{ item.taken_by.name }}
									</template>
									<template v-slot:item.points_earned="{ item }">
										<span class="green--text" v-if="item.method !== 5 && item.method !== 7">+
											{{item.points_earned}}</span>
										<span class="red--text" v-if="item.method === 5 || item.method === 7">-
											{{item.points_earned}}</span>
									</template>
									<template v-slot:item.balance="{ item }">
										<span class="red--text" v-if="item.method === 7">-{{item.balance | currency('£')}}</span>
										<span v-else>{{item.balance | currency('£')}}</span>
									</template>
								</v-data-table>
							</v-tab-item>
							<v-tab-item value="appointment" :eager="true">
								<div class="support-wrap">
									<v-expansion-panels multiple>
										<template v-for="(treat, index) in deal.deal_treatments">
											<v-expansion-panel :key="index">
												<v-expansion-panel-header>
													<span class="total mr-2" style="width: 250px">{{treat.treatment_name}}</span>
													<span class="total mr-2" style="width: 150px"><span class="sold-by">No of Sessions: </span>
														{{treat.appointments_completed}}/{{treat.no_of_sessions}}</span>
													<span class="total mr-2" style="width: 150px"><span class="sold-by">Cost: </span>£{{treat.cost}}</span>
												</v-expansion-panel-header>
												<v-expansion-panel-content class="group-panel-content">
													<v-data-table class="history-table form-wrap" :headers="appHeaders" :items="treat.deal_appointments">
														<template v-slot:item.appointment_date="{ item }">
															<span v-if="item.appointment_date">{{ item.appointment_date | moment("DD/MM/YY") }}</span>
															<span v-else>-</span>
														</template>
														<template v-slot:item.appointment_from_time="{ item }">
															<span v-if="item.appointment_from_time">{{ item.appointment_from_time}}</span>
															<span v-else>-</span>
														</template>
														<template v-slot:item.appointment_to_time="{ item }">
															<span>{{ item.appointment_to_time}}</span>
															<span v-if="item.appointment_to_time">{{ item.appointment_to_time}}</span>
															<span v-else>-</span>
														</template>
													</v-data-table>
												</v-expansion-panel-content>
											</v-expansion-panel>
										</template>
									</v-expansion-panels>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</v-col>
				</v-row>
			</div>
		</v-col>
	</v-row>
</template>
<script>
export default {

	name: 'deal',

	data() {
		return {
			readMore: false,
			loading: false,
			dealTab: null,
			headers: [{
					text: 'Payment Date',
					value: 'date',
					sortable: false,
				},
				{ text: 'Method', value: 'payment_method', sortable: false, },
				{ text: '£', value: 'total', sortable: false, },
				{ text: 'Taken By', value: 'taken_by', align: 'right', sortable: false, },
				{ text: 'Balance', value: 'balance', align: 'right', sortable: false, },
				{ text: 'Points', value: 'points_earned', align: 'right', sortable: false, }
			],

			appHeaders: [{
					text: 'Date',
					align: 'start',
					value: 'appointment_date',
					sortable: false
				},
				{ text: 'Time From', value: 'appointment_from_time', sortable: false },
				{ text: 'Time To', value: 'appointment_to_time', sortable: false }
			],
		}
	},
	props: {
		deal: {
			type: Object, // String, Number, Boolean, Function, Object, Array
			required: true,
			default: null
		}
	},

	created() {
		this.dealTab = 'payment'
	},
}

</script>
<style lang="css" scoped>
</style>
