<template>
	<div>
		<div class="fill-height slider-cont">
			<div class="modal-inside pb-0 tab-brd">
				<div class="form-title mb-24">Edit Client</div>
				<v-tabs v-model="navigationTab" class="cust-tabs">
					<template v-for="(item, index) in navigation">
						<v-tab :href="`#${item.slug}`" @change="refreshPoints(item.slug)">
							{{item.name}}
							<v-chip class="ml-1 count-chip" v-if="item.name === 'Activity'">
								<img src="/images/bolt-grey.svg" class="mr-1">
								<!-- {{commentCount.comment}} -->
								{{client.activity_count}}
							</v-chip>
						</v-tab>
					</template>
				</v-tabs>
			</div>
			<div class="modal-inside pt-0 right-pane">
				<v-tabs-items v-model="navigationTab">
					<v-tab-item value="personal" :eager="true">
						<v-form ref="form">
							<div class="tab-content-wrapper">
								<v-row dense class="e-row">
									<v-col cols="6" md="2" class="e-col">
										<div>
											<label>
												<span class="label-txt">Title</span>
												<v-select autocomplete="off" :items="titles" v-model="client.title" solo outlined flat class="solo-cust" hide-details="auto">
													<template slot="append">
														<v-icon>mdi-chevron-down</v-icon>
													</template>
												</v-select>
											</label>
										</div>
									</v-col>
									<v-col cols="6" md="10" class="e-col">
										<div>
											<label>
												<span class="label-txt">
													Name
												</span>
												<v-text-field autocomplete="off" solo outlined v-model="client.name" flat hide-details="auto" class="solo-cust"></v-text-field>
											</label>
										</div>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Social Handle
											</span>
										</label>
										<v-text-field autocomplete="off" solo outlined v-model="client.social_handle" flat hide-details="auto" class="solo-cust social-handle-input">
											<template slot="append-outer">
												<v-select autocomplete="off" v-model="client.source_id" :items="socialMedia" item-text="name" item-value="id" solo outlined flat class="este-autocomplete-input" hide-details="auto">
													<template v-slot:selection="data">
														<img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
													</template>
													<template v-slot:item="data">
														<img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
													</template>
													<template slot="append">
														<v-icon>mdi-chevron-down</v-icon>
													</template>
												</v-select>
											</template>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Phone No.
											</span>
											<v-text-field autocomplete="off" solo outlined v-model="client.contact_no" flat hide-details="auto" class="solo-cust" append-icon="mdil-phone"></v-text-field>
										</label>
									</v-col>
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Email Address
											</span>
											<v-text-field autocomplete="off" v-model="client.email" solo outlined flat hide-details="auto" class="solo-cust" append-icon="mdil-email"></v-text-field>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Address
											</span>
											<v-text-field autocomplete="off" solo outlined flat v-model="client.address_one" hide-details="auto" class="solo-cust mb-2"></v-text-field>
											<v-text-field autocomplete="off" solo outlined flat v-model="client.address_two" hide-details="auto" class="solo-cust mar-8"></v-text-field>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Town / City
											</span>
											<v-text-field autocomplete="off" solo outlined flat v-model="client.city" hide-details="auto" class="solo-cust"></v-text-field>
										</label>
									</v-col>
									<v-col cols="12" md="6" class="e-col">
										<v-row no-gutters>
											<v-col cols="6">
												<label>
													<span class="label-txt">
														Post Code
													</span>
													<v-text-field autocomplete="off" solo outlined flat v-model="client.postcode" hide-details="auto" class="solo-cust"></v-text-field>
												</label>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Preferred Branch:
											</span>
											<v-select autocomplete="off" :items="branches" :rules="rules.branch_id" item-value="id" item-text="branch" v-model="client.branch_id" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<p class="sub-title mb-4">
											Contact Preferences
										</p>
										<label>
											<span class="label-txt">
												Receive SMS
											</span>
										</label>
										<v-radio-group class="mt-2" hide-details="auto" mandatory v-model="client.is_receive_sms" row>
											<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Yes" :value="1"></v-radio>
											<v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="No" :value="0"></v-radio>
										</v-radio-group>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</v-tab-item>
					<v-tab-item value="client" :eager="true">
						<v-form ref="form">
							<div class="tab-content-wrapper">
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Symptoms / Condition:
											</span>
										</label>
										<v-autocomplete v-model="client.symptoms" :items="symptoms" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input" @change="symptomChange">
											<template v-slot:selection="data">
												<v-chip color="" class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
													<span class="pr-2">
														{{ data.item.name }}
													</span>
													<v-icon color="primary" small @click.stop="removeSymptoms(data.item)">
														close
													</v-icon>
												</v-chip>
											</template>
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="6" class="e-col">
										<label>
											<span class="label-txt">
												Suggested Treatments:
											</span>
										</label>
									</v-col>
									<v-col cols="12" md="12" class="e-col">
										<template v-for="treat in suggestedTreatments">
											<v-chip small class="este-chip status-chip mb-2" text-color="black" @click="suggTreat(treat)">
												{{ treat.name | capitalize }}
											</v-chip>
										</template>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Service Recommended:
											</span>
										</label>
										<v-autocomplete v-model="client.treatments" :items="treatments" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
											<template v-slot:selection="data">
												<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
													<span class="pr-2">
														{{ data.item.name }}
													</span>
													<v-icon color="primary" small @click.stop="removeService(data.item)">
														close
													</v-icon>
												</v-chip>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="4" class="e-col">
										<label>
											<span class="label-txt">
												Follow Up Date
											</span>
											<v-menu v-model="followUpMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
												<template v-slot:activator="{ on }">
													<v-text-field @click:clear="clearFollowUpDate" clearable clear-icon="mdi-close-circle-outline" autocomplete="off" :value="dateUkFormat(client.client_details.follow_up_date)" solo outlined v-on="on" flat hide-details="auto" class="solo-cust" prepend-inner-icon="mdil-calendar"></v-text-field>
												</template>
												<v-date-picker v-model="client.client_details.follow_up_date" no-title @input="followUpMenu = false"></v-date-picker>
											</v-menu>
										</label>
									</v-col>
									<v-col cols="12" md="4" class="e-col">
										<label>
											<span class="label-txt">Follow Up Time:</span>
											<v-menu ref="followUpTimeMenu" v-model="followUpTimeMenu" :close-on-content-click="false" :return-value.sync="client.client_details.follow_up_time" transition="scale-transition" offset-y max-width="290px" min-width="290px">
												<template v-slot:activator="{ on, attrs }">
													<v-text-field @click:clear="clearFollowUpTime" clearable clear-icon="mdi-close-circle-outline" outlined flat hide-details="auto" solo class="solo-cust" v-model="client.client_details.follow_up_time" prepend-inner-icon="mdil-clock" readonly v-bind="attrs" v-on="on"></v-text-field>
												</template>
												<v-time-picker :allowed-minutes="allowedStep" no-title ampm-in-title format="24hr" v-if="followUpTimeMenu" v-model="client.client_details.follow_up_time" full-width @click:minute="$refs.followUpTimeMenu.save(client.client_details.follow_up_time)"></v-time-picker>
											</v-menu>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Responsible
											</span>
											<v-autocomplete :items="staff" v-model="client.client_details.responsible_id" item-text="name" item-value="id" chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
												<template v-slot:selection="data">
													<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
														<span class="pr-2">
															{{ data.item.name }}
														</span>
														<v-icon color="primary" small @click.stop="removeResponsible(data.item)">
															mdi-close
														</v-icon>
													</v-chip>
												</template>
											</v-autocomplete>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Status
											</span>
											<v-select autocomplete="off" :item-disabled="statusDisable" :items="SortAtoZ(statusTags.filter(function(el) {return el.slug.includes('client')}))" item-value="id" item-text="name" v-model="client.status" solo outlined flat class="solo-cust" hide-details="auto">
												<template slot="append">
													<v-icon>mdi-chevron-down</v-icon>
												</template>
											</v-select>
										</label>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Campaigns:
											</span>
										</label>
										<v-autocomplete v-model="client.campaigns" :items="campaigns" item-text="name" item-value="id" multiple chips autocomplete="off" solo outlined flat hide-details="auto" class="este-autocomplete-input">
											<template v-slot:selection="data">
												<v-chip class="este-chip tag-chip px-3" v-bind="data.attrs" :input-value="data.selected">
													<span class="pr-2">
														{{ data.item.name }}
													</span>
													<v-icon color="primary" small @click.stop="removeCampaign(data.item)">
														mdi-close
													</v-icon>
												</v-chip>
											</template>
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="e-col">
										<label>
											<span class="label-txt">
												Source:
											</span>
										</label>
										<v-select autocomplete="off" :items="source" v-model="client.source_id" item-text="name" item-value="id" solo outlined flat class="solo-cust" hide-details="auto">
											<template slot="append">
												<v-icon>mdi-chevron-down</v-icon>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row class="e-row">
									<v-col cols="12" md="12" class="">
										<label>
											<span class="label-txt">
												Client Satisfaction:
											</span>
										</label>
									</v-col>
									<v-col cols="12" md="12" class="e-col">
										<template v-for="(item,index) in satisfaction">
											<div v-model="client.client_satisfaction" :class="['emoji-wrap', `${lowerCase(item.name)}-emoji` , { 'active': activeIndex === index  }, { 'active': client.client_satisfaction === item.slug  }]" @click="setActive(item, index)" :key="item.name">
												<div class="icon">
													<img :src="`${item.image}`">
												</div>
												<div class="text">{{item.name}}</div>
											</div>
										</template>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</v-tab-item>
					<v-tab-item value="appointment" :eager="true">
						<tab-appointment :itemData="client" :userId="client.id" :keyId="client.id"></tab-appointment>
					</v-tab-item>
					<v-tab-item value="callback" :eager="true">
						<tab-callback :itemData="client" :userId="client.id" :keyId="client.id"></tab-callback>
					</v-tab-item>
					<v-tab-item value="deal" :eager="true">
						<tab-deal :itemData="client" :userId="client.id" :keyId="client.id"></tab-deal>
					</v-tab-item>
					<v-tab-item value="insight" :eager="true">
						<tab-insight :itemData="client" :userId="client.id" :keyId="client.id"></tab-insight>
					</v-tab-item>
					<v-tab-item value="activity" :eager="true">
						<tab-activity :itemData="client" :userId="client.id" :keyId="client.id"></tab-activity>
					</v-tab-item>
					<v-tab-item value="points" :eager="true">
						<tab-point :itemData="client" :userId="client.id" :keyId="client.id"></tab-point>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<div class="form-btn-wrapper">
				<v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined @click="close" x-large>
					<template v-if="navigationTab !==  'deal'">Cancel</template>
					<template v-if="navigationTab ===  'deal'">Close</template>
				</v-btn>
				<v-btn v-if="navigationTab !==  'deal'" color="secondary" class="b-blue" depressed :loading="loading" rounded x-large @click="updateClient">
					Save Changes
				</v-btn>
			</div>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { USER, CREATE_CUSTOMER, GET_SERVICES } from '../../../store/action-types.js';
import globalFn from '../../../mixins/globalFn.js';
import sliderFn from '../../../mixins/sliderFn.js';
import status from '../../../mixins/status.js';
import TabActivity from '../../../components/TabItems/TabActivity';
import TabInsight from '../../../components/TabItems/TabInsight';
import TabDeal from '../../../components/TabItems/TabDeal';
import TabCallback from '../../../components/TabItems/TabCallback';
import TabAppointment from '../../../components/TabItems/TabAppointment';
import TabPoint from '../../../components/TabItems/TabPoint';
import edit from './edit.js';
export default {
	mixins: [globalFn, status, sliderFn, edit],
	components: {
		TabActivity,
		TabInsight,
		TabDeal,
		TabCallback,
		TabAppointment,
		TabPoint
	},
};

</script>
