<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-sheet class="pt-12" height="80vh">
              <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
              </v-overlay>
              <v-calendar :weekdays="weekday" :event-overlap-mode="mode" :first-interval="firstInterval" :event-overlap-threshold="30" :interval-count="intervalCount" class="custom-calender" ref="calendar" v-model="focus" :events="events" :event-more="true" category-show-all :categories="categories" :event-color="getEventColor" :type="type" @click:event="viewEvent" @click:more="viewDay" @click:date="viewDay" @change="updateRange">
                <template v-slot:category="{ category }">
                  <div class="category-wrap">
                    <div class="category-name">
                      {{category.categoryName}}
                    </div>
                  </div>
                </template>
                <template v-slot:event="{event}">
                  <template v-if="screen === 'appointments'">
                    <template v-if="event.data_type === 'pabau'">
                      <!--   <div class="job-card">
                        <span class="title-slot">{{event.customer_name | capitalize}}</span>
                        <span class="time-slot">{{event.start_time}} - {{event.end_time}}</span>
                      </div> -->
                    </template>
                    <template v-else>
                      <!-- <div class="job-card">
                        <span class="title-slot">{{event.user.name | capitalize}}</span>
                        <span class="time-slot">{{event.appointment_from_time}} - {{event.appointment_to_time}}</span>
                      </div> -->
                    </template>
                  </template>
                  <template v-else-if="screen === 'call-back'">
                    <div class="job-card">
                      <span class="title-slot">{{event.name | capitalize}}</span>
                      <span class="time-slot">{{event.callback_time}}</span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="job-card">
                      <span class="title-slot">{{event.name | capitalize}}</span>
                      <span class="time-slot">{{event.lead_details.follow_up_time}}</span>
                    </div>
                  </template>
                </template>
              </v-calendar>
            </v-sheet>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import eventBus from '../../../eventBus.js';
import sliderFn from '../../../mixins/sliderFn';
import globalFn from '../../../mixins/globalFn';
export default {
  mixins: [sliderFn, globalFn],
  data: () => ({
    user: JSON.parse(localStorage.getItem('user')),
    focus: '',
    intervalCount: 19,
    firstInterval: 6,
    screen: '',
    mode: 'column',
    type: 'week',
    overlay: false,
    weekday: [1, 2, 3, 4, 5, 6, 0],
    events: [],
    filters: {
      attributes: 'user,treatments,consultant',
      start_date: null,
      end_date: null,
      branches: [],
      consultants: [],
      treatments: []
    },
    showAppointmentParams: '?attributes=user,treatments,consultations,consultant,branch',
    showLeadParams: '?attributes=staff,branch,leadDetails,reasons,symptoms,treatments,source',
    showCallbackParams: '?attributes=user,branch,treatments,consultant,documents,comments',
    consultants: [],
    staff: [],
    categories: [],
    appData: [],
    PabauData: []
  }),
  mounted() {
    this.$refs.calendar.checkChange()
    eventBus.$on("PrevAction", this.PrevAction);
    eventBus.$on("NextAction", this.NextAction);
    eventBus.$on("updateCalendarView", this.updateCalendarView);
    eventBus.$emit("setCalendarViewOnLoad", this.type);
    this.screen = this.$router.currentRoute.params.screen
    eventBus.$on("loadDataForDateRange", this.updateRange);
    eventBus.$on('searchCalendarAppointments', this.filterCalendarAppointments)
    eventBus.$on('setBranchId', this.setBranchId)
  },
  created() {
    window.Echo.channel("new-appointment").listen(".appointment-created", e => {
      let res = []
      res.type = 'success'
      res.error = {}
      res.error.details = []
      res.error.details = e.data.treatments
      let name = (e.data.name) ? e.data.name : `@ ${e.data.social_handle}`
      res.message = (e.data.treatments.length > 0) ? `New Appointment Added: ${name} for` : `New Appointment Added: ${name}`
      let from = new Date(this.filters.start_date);
      let to = new Date(this.filters.end_date);
      let check = new Date(e.data.callback_date);
      if (this.user.user_type_name === 'Admin') {
        this.$store.dispatch('snackbar/SHOW', res)
        this.appointmentsData()
      } else if ((check >= from && check <= to) && (this.user.permission_branches.some(el => el.id === e.data.branch_id) || this.user.branch_id === e.data.branch_id)) {
        this.$store.dispatch('snackbar/SHOW', res)
        this.appointmentsData()
      }
    });
    window.Echo.channel("new-callback").listen(".callback-created", e => {
      let res = []
      res.type = 'success'
      res.treatments = []
      res.treatments = e.data.treatments
      let name = (e.data.name) ? e.data.name : `@ ${e.data.social_handle}`
      res.message = (e.data.treatments.length > 0) ? `New Callback Added: ${name} for` : `New Callback Added: ${name}`
      let from = new Date(this.filters.start_date);
      let to = new Date(this.filters.end_date);
      let check = new Date(e.data.callback_date);
      if (this.user.user_type_name === 'Admin') {
        this.$store.dispatch('snackbar/SHOW', res)
        this.loadDataForCallbacks()
      } else if ((check >= from && check <= to) && (this.user.permission_branches.some(el => el.id === e.data.branch_id) || this.user.branch_id === e.data.branch_id)) {
        this.$store.dispatch('snackbar/SHOW', res)
        this.loadDataForCallbacks()
      }
    });
  },
  methods: {
    viewDay({ date }) {
      this.focus = date
      this.type = this.$router.currentRoute.params.screen === 'appointments' ? 'category' : 'day'
    },
    getEventColor(event) {
      return event.color
    },
    PrevAction() {
      this.$refs.calendar.prev()
    },
    NextAction() {
      this.$refs.calendar.next()
    },
    updateCalendarView(type) {
      this.type = type
    },
    async filterCalendarAppointments(data) {
      if (data) {
        this.filters.branches = data.branches
        this.filters.treatments = data.treatments
        this.filters.consultants = data.consultants
        await this.appointmentsData()
      } else {
        this.filters.branches = []
        this.filters.treatments = []
        this.filters.consultants = []
        await this.appointmentsData()
      }
    },
    setBranchId(data) {
      this.filters.branches.push(data)
    },
    async updateData(data) {
      const dates = data.split('-')
      let start_date = this.changeDateFormat(dates[0])
      let end_date = this.changeDateFormat(dates[1])
      this.filters.start_date = start_date
      this.filters.end_date = end_date
      await this.loadData(start_date, end_date)
    },
    async updateRange({ start, end }) {
      let dates = [start.date, end.date]
      eventBus.$emit("updateDaterange", dates);
      this.filters.start_date = start.date
      this.filters.end_date = (this.type !== 'category') ? end.date : null;
      await this.loadData(start.date, end.date)
    },
    async loadData(start, end) {
      const screen = this.$router.currentRoute.params.screen
      if (screen === 'leads') {
        await this.loadDataForLeads(start, end)
        this.events = this.appData
      } else if (screen === 'call-back') {
        await this.loadDataForCallbacks()
        this.events = this.appData
      } else {
        await this.appointmentsData()
      }
    },
    async appointmentsData() {
      await this.getAppointmentsData()
      await this.getPabauData()

      if (this.appData) {
        this.staff = [...new Set(this.appData.map(el => (el.consultant !== null) ? el.consultant.name : ''))]
      }
      if (this.PabauData) {
        this.consultants = [...new Set(this.PabauData.map(a => a.staff_name))]
      }
      let final = [...this.appData, ...this.PabauData]
      this.events = final
      this.categories = [...this.staff, ...this.consultants]
      this.categories.sort()
    },
    async loadDataForLeads(start_date, end_date) {
      this.overlay = true
      this.appData = []
      let params = `?start_date=${start_date}&end_date=${end_date}&attributes=lead`
      await this.$store.dispatch('leads/GET_CALENDAR', { data: '', params: params }).then(res => {
        if (res.result === 'success' && res.data) {
          res.data = res.data.map(
            function(el) {
              let item = Object.assign({}, el);
              item.color = '#DFB447'
              item.start = (el.lead_details.follow_up_date !== null && el.lead_details.follow_up_time !== null) ? el.lead_details.follow_up_date + ' ' + el.lead_details.follow_up_time : el.lead_details.follow_up_date;
              return item;
            }
          );
          this.appData = res.data
          this.overlay = false
        } else if (res.result === 'fail') {
          this.loader = false
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        console.log(err)
        this.loader = false
      })
    },
    async loadDataForCallbacks() {
      this.overlay = true
      this.filters.branches = []
      this.appData = []
      await this.$store.dispatch('callback/GET_CALENDAR', { data: this.filters, params: '' }).then(res => {
        if (res.result === 'success' && res.data) {
          res.data = res.data.map(
            function(el) {
              let item = Object.assign({}, el);
              item.color = '#DFB447'
              item.start = el.callback_date + ' ' + el.callback_time
              item.end = el.callback_date + ' ' + el.callback_time
              return item;
            }
          );
          this.appData = res.data
          this.overlay = false
        } else if (res.result === 'fail') {
          this.loader = false
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        console.log(err)
        this.loader = false
      })
    },
    async getAppointmentsData() {
      this.overlay = true
      this.appData = []
      await this.$store.dispatch('appointments/GET_CALENDAR', { data: this.filters, params: '' }).then(res => {
        if (res.result === 'success') {
          res.data = res.data.map(
            function(el) {
              let item = Object.assign({}, el);
              item.data_type = 'appointment'
              item.name = (el.user !== null) ? el.user.name : ''
              item.color = '#DFB447'
              item.category = (el.consultant !== null) ? el.consultant.name : ''
              item.start = (el.appointment_from_time !== null) ? el.appointment_date + ' ' + el.appointment_from_time : el.appointment_date;
              item.end = (el.appointment_to_time !== null) ? el.appointment_date + ' ' + el.appointment_to_time : el.appointment_date;
              return item;
            }
          );
          if (res.data) {
            res.data.forEach((item) => {
              this.appData.push(item)
            })
          }
        } else if (res.result === 'fail') {
          this.loader = false
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        console.log(err)
        this.loader = false
      })
    },
    async getPabauData() {
      let self = this
      this.PabauData = []
      await this.$store.dispatch('appointments/GET_PABAU', { data: this.filters, params: '' }).then(res => {
        if (res.result === 'success') {
          res.data = res.data.map(
            function(el) {
              let item = Object.assign({}, el);
              item.data_type = 'pabau'
              item.name = el.customer_name
              item.category = el.staff_name
              item.color = 'rgba(34, 31, 32, 0.5)'
              item.start = (el.start_time !== null) ? el.start_date + ' ' + el.start_time : el.start_date;
              item.end = (el.end_time !== null) ? el.end_date + ' ' + el.end_time : el.end_date;
              return item;
            }
          );
          if (res.data) {
            res.data.forEach((item) => {
              // item.color = self.colors[self.rnd(0, self.colors.length - 1)]
              this.PabauData.push(item)
            })
          }
          this.overlay = false
        } else if (res.result === 'fail') {
          this.loader = false
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        console.log(err)
        this.loader = false
      })
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    getEventColor(event) {
      return event.color
    },
    async viewEvent(event) {
      let result = []
      if (this.screen === 'leads') {
        await this.$store.dispatch('leads/SHOW_LEAD', { id: event.event.id, param: this.showLeadParams }).then(res => {
          if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
            this.openDrawer("add", "1100", "leads/editPane");
          } else {
            this.openDrawer("add", "750", "leads/editTab");
          }
        })
      } else if (this.screen === 'call-back') {
        await this.$store.dispatch('callback/SHOW_CALLBACK', { id: event.event.id, param: this.showCallbackParams }).then(res => {
          if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
            this.openDrawer("add", "1100", "callback/editPane");
          } else {
            this.openDrawer("add", "750", "callback/editTab");
          }
        })
      } else {
        if (event.event.data_type === 'pabau') {
          if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
            this.openDrawer("add", "1100", "appointments/pabauPane");
          } else {
            this.openDrawer("add", "750", "appointments/pabauTab");
          }
          setTimeout(function() {
            eventBus.$emit("loadPabauDetails", event.event);
          }, 500);

        } else {
          await this.$store.dispatch('appointments/SHOW_APPOINTMENT', { id: event.event.id, param: this.showAppointmentParams }).then(res => {
            if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
              this.openDrawer("add", "1100", "appointments/editPane");
            } else {
              this.openDrawer("add", "750", "appointments/editTab");
            }
            result = res.data
          })
          this.$root.$emit('loadLeadDetails', result)
        }
      }
    },
  },
}

</script>
