<template>
  <div>
    <div class="fill-height slider-cont">
      <div class="modal-inside pb-0 no-tab-right-pane">
        <div class="form-title mb-24">Update User</div>
        <div class="tab-content-wrapper">
          <v-row class="e-row">
            <v-col cols="12" md="12" class="e-col">
              <label>
                <span class="label-txt">
                  Update client info
                </span>
              </label>
              <v-radio-group hide-details="auto" mandatory v-model="existing" row @change="loadCreateDetails">
                <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Choose from existing list" :value="1"></v-radio>
                <v-radio :ripple="false" off-icon="mdi-checkbox-blank-circle-outline" on-icon="mdi-check-circle" hide-details="auto" label="Create New" :value="0"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <template v-if="existing">
            <search-contact @catchSearchContact="getSelectedValue" :data="sale.user"></search-contact>
          </template>
          <template v-else>
            <v-form ref="form">
              <v-row dense class="e-row">
                <v-col cols="6" md="2" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt">Title</span>
                      <v-select autocomplete="off" :items="titles" v-model="form.title" solo outlined flat class="solo-cust" hide-details="auto">
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </label>
                  </div>
                </v-col>
                <v-col cols="6" md="10" class="e-col">
                  <div>
                    <label>
                      <span class="label-txt">
                        Name
                      </span>
                      <v-text-field autocomplete="off" solo outlined :rules="rules.name" v-model="form.name" flat hide-details="auto" class="solo-cust"></v-text-field>
                    </label>
                  </div>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Social Handle
                    </span>
                  </label>
                  <v-text-field autocomplete="off" solo outlined v-model="form.social_handle" flat hide-details="auto" class="solo-cust social-handle-input">
                    <template slot="append-outer">
                      <v-select autocomplete="off" v-model="form.source_id" :items="socialMedia" item-text="name" item-value="id" solo outlined flat class="este-autocomplete-input" hide-details="auto">
                        <template v-slot:selection="data">
                          <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
                        </template>
                        <template v-slot:item="data">
                          <img :src="`images/${trimLowerCase(data.item.name)}.svg`" height="20px">
                        </template>
                        <template slot="append">
                          <v-icon>mdi-chevron-down</v-icon>
                        </template>
                      </v-select>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Phone No.
                    </span>
                    <v-text-field autocomplete="off" solo outlined v-model="form.contact_no" flat hide-details="auto" class="solo-cust" append-icon="mdil-phone"></v-text-field>
                  </label>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Email Address
                    </span>
                    <v-text-field autocomplete="off" v-model="form.email" solo outlined flat hide-details="auto" class="solo-cust" append-icon="mdil-email"></v-text-field>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Address
                    </span>
                    <v-text-field autocomplete="off" solo outlined flat v-model="form.address_one" hide-details="auto" class="solo-cust mb-2"></v-text-field>
                    <v-text-field autocomplete="off" solo outlined flat v-model="form.address_two" hide-details="auto" class="solo-cust mar-8"></v-text-field>
                  </label>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="6" class="e-col">
                  <label>
                    <span class="label-txt">
                      Town / City
                    </span>
                    <v-text-field autocomplete="off" solo outlined flat v-model="form.city" hide-details="auto" class="solo-cust"></v-text-field>
                  </label>
                </v-col>
                <v-col cols="12" md="6" class="e-col">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <label>
                        <span class="label-txt">
                          Post Code
                        </span>
                        <v-text-field autocomplete="off" solo outlined flat v-model="form.postcode" hide-details="auto" class="solo-cust"></v-text-field>
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="e-row">
                <v-col cols="12" md="12" class="e-col">
                  <label>
                    <span class="label-txt">
                      Preferred Branch:
                    </span>
                    <v-select autocomplete="off" :rules="rules.branch_id" :items="branches" item-value="id" item-text="branch" v-model="form.branch_id" solo outlined flat class="solo-cust" hide-details="auto">
                      <template slot="append">
                        <v-icon>mdi-chevron-down</v-icon>
                      </template>
                    </v-select>
                  </label>
                </v-col>
              </v-row>
            </v-form>
          </template>
        </div>
      </div>
      <div class="form-btn-wrapper">
        <v-btn class="cancel-btn btn-gap px-15" depressed rounded outlined x-large @click="close">
          Cancel
        </v-btn>
        <v-btn color="secondary" class="b-blue" depressed :disabled="loading" rounded x-large @click="create">
          Save Changes
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../store/action-types.js';
import status from '../../../mixins/status.js';
import globalFn from '../../../mixins/globalFn.js';
import SearchContact from '../../../components/contacts/SearchContact'
export default {
  mixins: [status, globalFn],
  components: {
    SearchContact
  },
  data: () => ({
    loading: false,
    user: JSON.parse(localStorage.getItem('user')),
    tab: null,
    existing: 1,
    user_id: 0,
    form: {
      title: '',
      name: '',
      address_one: '',
      address_two: '',
      city: '',
      postcode: '',
      contact_no: '',
      branch_id: '',
      email: '',
      status: 1,
      social_handle: ''
    },
    rules: {
      name: [
        v => !!v || 'Please enter name'
      ],
      email: [
        v => !!v || "Please enter email address",
        v => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com"
      ],
      branch_id: [
        v => !!v || 'Please select branch'
      ]
    },
    contacts: []
  }),
  computed: {
    sale() {
      if (this.$store.state.sales.item) {
        if (!this.$store.state.sales.item.user) {
          this.$store.state.sales.item.user = {}
        }
        return this.$store.state.sales.item
      }
    },
    branches() {
      if (this.$store.state.branches.allList) {
        return this.$store.state.branches.allList
      }
    },
    source() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list
      }
    },
    socialMedia() {
      if (this.$store.state.source.list) {
        return this.$store.state.source.list.filter(item => ['Facebook', 'Instagram', 'TikTok', 'Snapchat'].includes(item.name));
      }
    }
  },
  created() {
    this.$store.dispatch('branches/GET_ALL_BRANCH', '')
    this.$store.dispatch('source/GET_SOURCE', '')
  },
  methods: {
    getSelectedValue(data) {
      if (data) {
        this.user_id = data.id
      }
    },
    loadCreateDetails() {
      if (this.existing === 0) {
        this.form.name = this.sale.client_name
        this.form.branch_id = this.sale.branch_id
        this.form.contact_no = this.sale.mobile
        this.form.email = this.sale.email
      }
    },
    close() {
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    loadData() {
      this.$root.$emit("salesTableUpdate", "");
      this.$store.commit("rightSlider/RESET_SLIDER");
    },
    async create() {
      if (this.existing === 1) {
        await this.updateSale()
      } else {
        await this.createClient()
      }
    },
    async createClient() {
      let formData = {}
      formData.title = this.form.title
      formData.name = this.form.name
      formData.address_one = this.form.address_one
      formData.address_two = this.form.address_two
      formData.city = this.form.city
      formData.postcode = this.form.postcode
      formData.branch_id = this.form.branch_id
      formData.social_handle = this.form.social_handle
      if (this.form.email) {
        formData.email = this.form.email
      }
      if (this.form.contact_no) {
        formData.contact_no = this.form.contact_no
      }
      if (this.form.source_id) {
        formData.source_id = this.form.source_id
      }

      formData.status = this.form.status

      this.loading = true
      await this.$store.dispatch('clients/CREATE_CLIENT', formData).then(response => {
        if (response.result === 'success') {
          this.loading = false
          this.user_id = response.data.id
          this.updateSale()
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.loading = false
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        this.loading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },
    async updateSale() {
      let formData = {}
      formData.id = this.sale.id
      formData.user_id = this.user_id
      this.loading = true
      await this.$store.dispatch('sales/UPDATE', formData).then(response => {
        if (response.result === 'success') {
          this.loading = false
          this.loadData()
        } else if (response.result === 'fail') {
          response.type = 'error'
          this.loading = false
          this.$store.dispatch('snackbar/SHOW', response)
        }
      }).catch(err => {
        this.loading = false
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    }
  }
};

</script>
