<template>
	<div class="content-wrapper">
		<v-layout>
			<v-flex>
				<v-card flat class="pa-0">
					<v-card-text class="pa-0">
						<v-sheet class="pt-12" height="80vh">
							<v-overlay :value="overlay">
								<v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
							</v-overlay>
							<v-calendar :weekdays="weekday" :event-overlap-mode="mode" :first-interval="firstInterval" :event-overlap-threshold="30" :interval-count="intervalCount" class="custom-calender" ref="calendar" v-model="focus" :events="events" :event-more="true" category-show-all :categories="categories" :event-color="getEventColor" :type="type" @click:event="viewEvent" @change="updateRange">
								<template v-slot:category="{ category }">
									<div class="category-wrap">
										<div class="category-name">
											{{category.categoryName}}
										</div>
									</div>
								</template>
								<template v-slot:event="{event}">
								</template>
							</v-calendar>
						</v-sheet>
					</v-card-text>
				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
import eventBus from '../../../eventBus.js';
import sliderFn from '../../../mixins/sliderFn';
import globalFn from '../../../mixins/globalFn';
export default {
	mixins: [sliderFn, globalFn],
	data: () => ({
		focus: '',
		user: JSON.parse(localStorage.getItem('user')),
		intervalCount: 19,
		firstInterval: 6,
		screen: '',
		mode: 'column',
		type: 'category',
		overlay: false,
		weekday: [1, 2, 3, 4, 5, 6, 0],
		events: [],
		filters: {
			attributes: 'user,treatments,consultant',
			start_date: null,
			end_date: null,
			branches: [],
			consultants: [],
			treatments: []
		},
		showAppointmentParams: '?attributes=user,treatments,consultations,consultant,branch',
		consultants: [],
		staff: [],
		categories: [],
		appData: [],
		PabauData: []
	}),
	mounted() {
		this.$refs.calendar.checkChange()
		eventBus.$on("PrevAction", this.PrevAction);
		eventBus.$on("NextAction", this.NextAction);
		this.screen = this.$router.currentRoute.params.screen
		eventBus.$on('searchCalendarAppointments', this.filterCalendarAppointments)
	},
	created() {
		window.Echo.channel("new-appointment").listen(".appointment-created", e => {
			let res = []
			res.type = 'success'
			res.error = {}
			res.error.details = []
			res.error.details = e.data.treatments
			let name = (e.data.name) ? e.data.name : `@ ${e.data.social_handle}`
			res.message = (e.data.treatments.length > 0) ? `New Appointment Added: ${name} for` : `New Appointment Added: ${name}`
			let from = new Date(this.filters.start_date);
			let to = new Date(this.filters.end_date);
			let check = new Date(e.data.callback_date);
			if (this.user.user_type_name === 'Admin') {
				this.$store.dispatch('snackbar/SHOW', res)
				this.appointmentsData()
			} else if ((check >= from && check <= to) && (this.user.permission_branches.some(el => el.id === e.data.branch_id) || this.user.branch_id === e.data.branch_id)) {
				this.$store.dispatch('snackbar/SHOW', res)
				this.appointmentsData()
			}
		});
	},
	methods: {
		viewDay({ date }) {
			this.focus = date
			this.type = 'category'
		},
		getEventColor(event) {
			return event.color
		},
		PrevAction() {
			this.$refs.calendar.prev()
		},
		NextAction() {
			this.$refs.calendar.next()
		},
		async filterCalendarAppointments(data) {
			if (data) {
				this.filters.branches = data.branches
				this.filters.treatments = data.treatments
				this.filters.consultants = data.consultants
				await this.appointmentsData()
			} else {
				this.filters.branches = []
				this.filters.treatments = []
				this.filters.consultants = []
				await this.appointmentsData()
			}
		},
		async updateRange({ start, end }) {
			let dates = [start.date, end.date]
			this.filters.start_date = start.date
			this.filters.end_date = (this.type !== 'category') ? end.date : null;
			await this.setBranchId()
			await this.loadData(start.date, end.date)
		},
		async setBranchId() {
			this.filters.branches.push(this.user.branch_id)
		},
		async loadData(start, end) {
			await this.appointmentsData()
		},
		async appointmentsData() {
			await this.getAppointmentsData()
			await this.getPabauData()

			if (this.appData) {
				this.staff = [...new Set(this.appData.map(el => (el.consultant !== null) ? el.consultant.name : ''))]
			}
			if (this.PabauData) {
				this.consultants = [...new Set(this.PabauData.map(a => a.staff_name))]
			}
			let final = [...this.appData, ...this.PabauData]
			this.events = final
			this.categories = [...this.staff, ...this.consultants]
			this.categories.sort()
		},
		async getAppointmentsData() {
			this.overlay = true
			this.appData = []
			await this.$store.dispatch('appointments/CALENDAR_DASHBOARD', { data: this.filters, params: '' }).then(res => {
				if (res.result === 'success') {
					res.data = res.data.map(
						function(el) {
							let item = Object.assign({}, el);
							item.data_type = 'appointment'
							item.name = (el.user !== null) ? el.user.name : ''
							item.color = '#DFB447'
							item.category = (el.consultant !== null) ? el.consultant.name : ''
							item.start = (el.appointment_from_time !== null) ? el.appointment_date + ' ' + el.appointment_from_time : el.appointment_date;
							item.end = (el.appointment_to_time !== null) ? el.appointment_date + ' ' + el.appointment_to_time : el.appointment_date;
							return item;
						}
					);
					if (res.data) {
						res.data.forEach((item) => {
							this.appData.push(item)
						})
					}
				} else if (res.result === 'fail') {
					this.loader = false
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				console.log(err)
				this.loader = false
			})
		},
		async getPabauData() {
			let self = this
			this.PabauData = []
			await this.$store.dispatch('appointments/GET_PABAU', { data: this.filters, params: '' }).then(res => {
				if (res.result === 'success') {
					res.data = res.data.map(
						function(el) {
							let item = Object.assign({}, el);
							item.data_type = 'pabau'
							item.name = el.customer_name
							item.category = el.staff_name
							item.color = 'rgba(34, 31, 32, 0.5)'
							item.start = (el.start_time !== null) ? el.start_date + ' ' + el.start_time : el.start_date;
							item.end = (el.end_time !== null) ? el.end_date + ' ' + el.end_time : el.end_date;
							return item;
						}
					);
					if (res.data) {
						res.data.forEach((item) => {
							this.PabauData.push(item)
						})
					}
					this.overlay = false
				} else if (res.result === 'fail') {
					this.loader = false
					res.type = 'error'
					this.$store.dispatch('snackbar/SHOW', res)
				}
			}).catch(err => {
				console.log(err)
				this.loader = false
			})
		},
		rnd(a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a
		},
		getEventColor(event) {
			return event.color
		},
		async viewEvent(event) {
			let result = []
			if (event.event.data_type === 'pabau') {
				if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
					this.openDrawer("add", "1100", "appointments/pabauPane");
				} else {
					this.openDrawer("add", "750", "appointments/pabauTab");
				}
				setTimeout(function() {
					eventBus.$emit("loadPabauDetails", event.event);
				}, 500);

			} else {
				await this.$store.dispatch('appointments/SHOW_APPOINTMENT', { id: event.event.id, param: this.showAppointmentParams }).then(res => {
					if (this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'xl') {
						this.openDrawer("add", "1100", "appointments/editPane");
					} else {
						this.openDrawer("add", "750", "appointments/editTab");
					}
					result = res.data
				})
				this.$root.$emit('loadLeadDetails', result)
			}
		},
	},
}

</script>
