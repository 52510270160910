import collection from '../../api/collections/Opportunities'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    assignedTo: [],
    item: {},
    pagination: {},
    reminders: []
  },
  mutations: {
    SET(state, data) {
      state.list = data
    },
    SET_ASSIGNED_TO(state, data) {
      state.assignedTo = data
    },
    SET_LIST(state, data) {
      state.all = data
    },
    SET_ITEM(state, data) {
      state.item = data
    },
    RESET(state, data) {
      state.item = {}
    },
    SET_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload)
        commit('SET', data.data)
        return data
      }, commit, dispatch, 'GET', true)
    },
    async CREATE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE', true)
      return data
    },
    async SHOW({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.show(payload)
        commit('SET_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW', false)
      return data
    },
    async UPDATE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload)
        return data
      }, commit, dispatch, 'UPDATE', true)

      return data
    },
    async DELETE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.delete(payload)
        return data
      }, commit, dispatch, 'DELETE', true)
      return data
    },

    async ADD_COMMENT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.comment(payload)
        return data
      }, commit, dispatch, 'ADD_COMMENT', true)
      return data
    },

    async STATUS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.status(payload)
        return data
      }, commit, dispatch, 'STATUS', true)
      return data
    },

    async WIDGET_COUNT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.count(payload)
        return data
      }, commit, dispatch, 'WIDGET_COUNT', true)
      return data
    },

    async ASSIGNED_TO({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.assignedTo(payload)
        commit('SET_ASSIGNED_TO', data.data)
        return data
      }, commit, dispatch, 'ASSIGNED_TO', true)
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.upload(payload)
        return data
      }, commit, dispatch, 'UPLOAD_DOCS', true)
      return data
    },

    async REMINDER({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.reminder(payload)
        return data
      }, commit, dispatch, 'REMINDER', true)
      return data
    },
  }
}
