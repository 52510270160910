import collection from '../../../api/collections/PurchaseInvoices'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    item: {},
    pagination: {}
  },
  mutations: {
    SET_PURCHASE_INVOICE(state, data) {
      state.list = data
    },
    SET_PURCHASE_INVOICE_ITEM(state, data) {
      state.item = data
    },
    RESET_PURCHASE_INVOICE(state, data) {
      state.item = {}
    },
    SET_PURCHASE_INVOICE_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_PURCHASE_INVOICE({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_PURCHASE_INVOICE', data.data)
        return data
      }, commit, dispatch, 'GET_PURCHASE_INVOICE', true)
    },
    async CREATE_PURCHASE_INVOICE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_PURCHASE_INVOICE', true)
      return data
    },
    async SHOW_PURCHASE_INVOICE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_PURCHASE_INVOICE_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW_PURCHASE_INVOICE', false)
      return data
    },
    async UPDATE_PURCHASE_INVOICE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_PURCHASE_INVOICE', true)

      return data
    },
    async DELETE_PURCHASE_INVOICE({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.delete(payload)
        return data
      }, commit, dispatch, 'DELETE_PURCHASE_INVOICE', true)
      return data
    },
  }
}
