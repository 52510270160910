import collection from '../../../api/collections/Symptoms'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    treatments: [],
    allList: [],
    item: {},
    pagination: {}
  },
  mutations: {
    SET_SYMPTOM(state, data) {
      state.list = data
    },
    SET_SUGGESTED_TREATMENTS(state, data) {
      state.treatments = data
    },
    SET_ALL_SYMPTOM(state, data) {
      state.allList = data
    },
    SET_SYMPTOM_ITEM(state, data) {
      state.item = data
    },
    RESET_SYMPTOM(state, data) {
      state.item = {}
    },
    SET_SYMPTOM_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_SYMPTOM({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload)
        commit('SET_SYMPTOM', data.data)
        return data
      }, commit, dispatch, 'GET_SYMPTOM', true)
    },
    async GET_ALL_SYMPTOM({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.getList(payload)
        commit('SET_ALL_SYMPTOM', data.data)
        return data
      }, commit, dispatch, 'GET_ALL_SYMPTOM', true)
    },

    async GET_SUGGESTED_TREATMENTS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.suggestedTreatments(payload)
        commit('SET_SUGGESTED_TREATMENTS', data.data)
        return data
      }, commit, dispatch, 'GET_SUGGESTED_TREATMENTS', true)
    },
    async CREATE_SYMPTOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_SYMPTOM', true)
      return data
    },
    async SHOW_SYMPTOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_SYMPTOM_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW_SYMPTOM', false)
      return data
    },
    async UPDATE_SYMPTOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_SYMPTOM', true)

      return data
    },
    async DELETE_SYMPTOM({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.delete(payload)
        return data
      }, commit, dispatch, 'DELETE_SYMPTOM', true)
      return data
    },
  }
}
