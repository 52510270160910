import eventBus from "../../../eventBus.js"
export default {
	data: () => ({
		loading: false,
		followUpTimeMenu: false,
		enquiryMenu: false,
		followUpMenu: false,
		followupDateChange: false,
		user: JSON.parse(localStorage.getItem('user')),
		titles: ["Mr", "Mrs", "Dr", "Miss"],
		reasonSearch: null,
		sourceSearch: null,
		suggestedTreatments: [],
		rules: {
			email: [
				v => !!v || "Please enter email address",
				v => /.+@.+\..+/.test(v) || "Please enter a valid email address e.g. example@example.com"
			],
			branch_id: [
				v => !!v || 'Please select branch'
			],
			status: [],
			source: [
				v => !!v || 'Please select source'
			],
		},
		staff: [],
		staffParams: {
			branch: '',
			type: 'lead'
		},
		contactChange: false,
		emailChange: false,
		socailHandle: false,

		navigationTab: null,
		navigation: [{
			name: 'Overview',
			icon: 'mdil-star',
			slug: 'lead',
			module: 'lead',
			form: true
		}, {
			name: 'Activity',
			icon: 'mdi-history',
			slug: 'activity',
			module: 'activity',
			form: false
		}],
	}),
	watch: {
		'lead.social_handle': _.debounce(function() {
			if (this.opportunity.social_handle) {
				this.autoSelectSocialIcon();
			}
			this.socailHandle = true
		}, 1000),
		'lead.contact_no'(val) {
			this.contactChange = true
		},
		'lead.email'(val) {
			this.emailChange = true
		},
		'lead.social_handle'(val) {
			this.socailHandle = true
		},
		'lead.follow_up_date': function(newVal, oldVal) {

			this.IsFollowUpDateChanged(newVal, oldVal);

		}
	},
	computed: {
		opportunity() {
			if (this.$store.state.opportunities.item) {
				return this.$store.state.opportunities.item
			}
		},
		treatments() {
			if (this.$store.state.treatments.allList) {
				return this.$store.state.treatments.allList
			}
		},
		branches() {
			if (this.$store.state.branches.allList) {
				return this.$store.state.branches.allList
			}
		},
		reasons() {
			if (this.$store.state.reasons.allList) {
				return this.$store.state.reasons.allList
			}
		},
		symptoms() {
			if (this.$store.state.symptoms.allList) {
				return this.$store.state.symptoms.allList
			}
		},
		source() {
			if (this.$store.state.source.list) {
				return this.$store.state.source.list
			}
		},
		socialMedia() {
			if (this.$store.state.source.list) {
				return this.$store.state.source.list.filter(item => ['Facebook', 'Instagram', 'TikTok', 'Snapchat'].includes(item.name));
			}
		},
		commentCount() {
			if (this.$store.state.statistics.comments) {
				return this.$store.state.statistics.comments;
			}
		},
		campaigns() {
			if (this.$store.state.campaign.all) {
				return this.$store.state.campaign.all
			}
		},
	},
	mounted() {
		this.symptomChange()
		this.staffByBranch()
		eventBus.$on(`loadCommentsCount${this.opportunity.id}`, this.loadCommentStats)
	},
	created() {
		this.$store.dispatch('treatments/GET_ALL_TREATMENT', '')
		this.$store.dispatch('branches/GET_ALL_BRANCH', '')
		this.$store.dispatch('symptoms/GET_ALL_SYMPTOM', '')
		this.$store.dispatch('reasons/GET_ALL_REASON', '')
		this.$store.dispatch('source/GET_SOURCE', '')
		this.$store.dispatch('campaign/LIST', '')
	},
	methods: {
		IsFollowUpDateChanged(newVal, oldVal) {
			if (this.opportunity.status !== 23 && newVal) {
				var d1 = new Date(oldVal);
				var d2 = new Date(newVal);
				if (d1.getTime() !== d2.getTime() && (this.opportunity.status !== 26 && this.opportunity.status !== 19)) {
					this.opportunity.status = 15
				}
			}
		},
		clearFollowUpDate() {
			this.opportunity.follow_up_date = null
		},
		clearFollowUpTime() {
			this.opportunity.follow_up_time = null
		},
		async loadCommentStats() {
			await this.$store.dispatch('statistics/GET_COMMENT_COUNT', `?type=lead&id=${this.opportunity.id}`)
		},
		close() {
			this.$store.commit("rightSlider/RESET_SLIDER");
		},
		async autoSelectSocialIcon() {
			let isValid = this.isValidUrl(this.opportunity.social_handle)
			if (isValid) {
				let url = new URL(this.opportunity.social_handle);
				this.opportunity.source = this.getSourceId(url.origin)
			}
		},
		isValidUrl(_string) {
			const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
			return matchPattern.test(_string);
		},
		getSourceId(url) {
			let id = 0
			let self = this
			this.socialMedia.some(function(el) {
				if (url.indexOf(el.name.toLowerCase()) != -1) {
					id = el.id
				}
			});
			return id
		},
		loadData() {
			eventBus.$emit('StatsRefresh')
			this.$store.commit("rightSlider/RESET_SLIDER");

			if (this.$router.currentRoute.meta.slug === 'opportunities') {
				this.$root.$emit("opportunitiesTable", "");
			} else if (this.$router.currentRoute.meta.slug === 'view-customer') {
				this.$root.$emit("refreshOppsWidget", "");
			} else if (this.$router.currentRoute.meta.slug === 'lead-activity-report') {
				this.$root.$emit("leadActivityTable", "");
			}

		},
		async removeService(item) {
			const index = this.opportunity.treatments.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.opportunity.treatments.splice(index, 1)
			} else {
				const i = this.opportunity.treatments.findIndex(x => x === item.id)
				if (i >= 0) this.opportunity.treatments.splice(i, 1)
			}
		},
		async removeSymptoms(item) {
			const index = this.opportunity.symptoms.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.opportunity.symptoms.splice(index, 1)
			} else {
				const i = this.opportunity.symptoms.findIndex(x => x === item.id)
				if (i >= 0) this.opportunity.symptoms.splice(i, 1)
			}
			await this.symptomChange()
		},
		async removeCampaign(item) {
			const index = this.opportunity.campaigns.findIndex(x => x.id === item.id)
			if (index >= 0) {
				this.opportunity.campaigns.splice(index, 1)
			} else {
				const i = this.opportunity.campaigns.findIndex(x => x === item.id)
				if (i >= 0) this.opportunity.campaigns.splice(i, 1)
			}
		},
		async removeReason(item) {
			if (item.id) {
				const index = this.opportunity.reasons.findIndex(x => x.id === item.id)
				if (index >= 0) {
					this.opportunity.reasons.splice(index, 1)
				} else {
					const i = this.opportunity.reasons.findIndex(x => x === item.id)
					if (i >= 0) this.opportunity.reasons.splice(i, 1)
				}
			} else {
				const index = this.opportunity.reasons.findIndex(x => x.id === item)
				if (index >= 0) {
					this.opportunity.reasons.splice(index, 1)
				} else {
					const i = this.opportunity.reasons.findIndex(x => x === item)
					if (i >= 0) this.opportunity.reasons.splice(i, 1)
				}
			}
		},

		removeResponsible(item) {
			this.opportunity.responsible_id = null
		},
		async symptomChange() {

			const ids = []
			this.opportunity.symptoms.forEach(value => {
				if (value.id) {
					ids.push({ id: value.id });
				} else {
					ids.push(value);
				}
			});
			await this.$store.dispatch('symptoms/GET_SUGGESTED_TREATMENTS', { 'symptoms': ids }).then(response => {
				if (response.result === 'success') {
					this.suggestedTreatments = response.data
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
		async suggTreat(item) {
			let exists = this.opportunity.treatments.some(el => el.id === item.id) ? true : this.opportunity.treatments.some(el => el === item.id) ? true : false;
			if (!exists) {
				this.opportunity.treatments.push(item.id)
			} else {
				const index = this.opportunity.treatments.findIndex(x => x.id === item.id)
				if (index >= 0) {
					this.opportunity.treatments.splice(index, 1)
				} else {
					const i = this.opportunity.treatments.findIndex(x => x === item.id)
					if (i >= 0) this.opportunity.treatments.splice(i, 1)
				}
			}
		},
		async staffByBranch() {
			this.staff = []
			this.staffParams.branch = this.opportunity.branch_id
			await this.$store.dispatch('staff/GET_STAFF_LIST', { data: this.staffParams, params: '' }).then(response => {
				if (response.result === 'success') {
					this.staff = response.data
				} else if (response.result === 'fail') {
					response.type = 'error'
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},
		async updateLead() {
			let formData = {}
			formData.id = this.opportunity.id
			formData.title = this.opportunity.title
			formData.name = this.opportunity.name
			formData.address_one = this.opportunity.address_one
			formData.address_two = this.opportunity.address_two
			formData.city = this.opportunity.city
			formData.postcode = this.opportunity.postcode
			if (this.contactChange) {
				formData.contact_no = this.opportunity.contact_no
			}
			formData.branch_id = this.opportunity.branch_id
			if (this.socailHandle) {
				formData.social_handle = this.opportunity.social_handle
			}
			if (this.opportunity.email && this.emailChange) {
				formData.email = this.opportunity.email
			}
			formData.status = this.opportunity.status
			formData.responsible_id = this.opportunity.responsible_id

			formData.source_id = this.opportunity.source_id

			formData.is_receive_sms = this.opportunity.is_receive_sms
			formData.reasons = []
			this.opportunity.reasons.forEach(value => {
				if (value.id) {
					formData.reasons.push({ id: value.id });
				} else {
					formData.reasons.push(value);
				}
			});

			formData.service_recommended = []
			this.opportunity.treatments.forEach(value => {
				if (value.id) {
					formData.service_recommended.push(value.id);
				} else {
					formData.service_recommended.push(value);
				}
			});

			formData.symptoms = []
			this.opportunity.symptoms.forEach(value => {
				if (value.id) {
					formData.symptoms.push(value.id);
				} else {
					formData.symptoms.push(value);
				}
			});

			formData.campaign_ids = []
			this.opportunity.campaigns.forEach(value => {
				if (value.id) {
					formData.campaign_ids.push(value.id);
				} else {
					formData.campaign_ids.push(value);
				}
			});



			formData.enquiry_date = this.opportunity.enquiry_date
			formData.follow_up_date = this.opportunity.follow_up_date
			formData.follow_up_time = this.opportunity.follow_up_time
			this.loading = true
			await this.$store.dispatch('opportunities/UPDATE', formData).then(response => {
				if (response.result === 'success') {
					this.loading = false
					this.loadData()
				} else if (response.result === 'fail') {
					this.loading = false
					response.type = 'error'
					this.$store.dispatch('snackbar/SHOW', response)
				}
			}).catch(err => {
				this.loading = false
				err.type = 'error'
				this.$store.dispatch('snackbar/SHOW', err)
			});
		},


		gotoContact(item) {
			let route = this.$router.resolve({ path: `/view-contact/${item.id}` });
			window.open(route.href);
		},

		disableStatusOption(item) {
			return [21,28].includes(item.id);
    	 // if(this.opportunity.oldStatus === 21 && item.id === 21){
    	 // 	return true
    	 // }else if(this.opportunity.oldStatus === 28 && item.id === 28){
    	 // 	return true
    	 // }
		}


	}
}
