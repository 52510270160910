import collection from '../../api/collections/Sales'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    item: {},
    packageItem: {},
    list: [],
    package: []
  },
  mutations: {
    SET_PACKAGE(state, data) {
      state.package = data
    },
    SET_SHOW_PACKAGE(state, data) {
      state.packageItem = data
    },
    SET_SALES(state, data) {
      state.list = data
    },
    SET_SALE_ITEM(state, data) {
      state.item = data
    },
  },
  actions: {
    async GET({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_SALES', data.data)
        return data
      }, commit, dispatch, 'GET', true)
    },

    async UPDATE({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.update(payload)
        return data
      }, commit, dispatch, 'UPDATE', true)
    },

    async SHOW({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_SALE_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW', false)
      return data
    },

    async GET_PACKAGE({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.package(payload)
        commit('SET_PACKAGE', data.data)
        return data
      }, commit, dispatch, 'GET_PACKAGE', true)
    },

    async SHOW_PACKAGE({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.showPackage(payload)
        commit('SET_SHOW_PACKAGE', data.data)
        return data
      }, commit, dispatch, 'SHOW_PACKAGE', true)
    },

    async UPDATE_STATUS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.status(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_STATUS', true)

      return data
    },
  }
}
