<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="dashboard-card">
          <v-row>
            <v-col cols="12">
              <h4 class="graph-title pl-0">Opportunites Generated 30 Days</h4>
              <div class="graph-wrap">
                <line-chart class="chart" :width="600" :height="400" :options="options" :chartData="leadsGenerated"></line-chart>
              </div>
            </v-col>
            <!--  <v-col cols="12">
              <h4 class="graph-title pl-0">Appointments Completed 30 Days </h4>
              <div class="graph-wrap">
                <line-chart class="chart" :width="600" :options="options" :chartData="appointmentsGenerated"></line-chart>
              </div>
            </v-col>
            <v-col cols="12">
              <h4 class="graph-title pl-0">Deal Value Last 30 Days </h4>
              <div class="graph-wrap">
                <line-chart class="chart" :width="600" :options="dealOptions" :chartData="dealsBranchGenerated"></line-chart>
              </div>
            </v-col>
            <v-col cols="12">
              <h4 class="graph-title pl-0">Channel Source Deal Value Last 30 Days </h4>
              <div class="graph-wrap">
                <line-chart class="chart" :width="600" :options="dealOptions" :chartData="dealsChannelGenerated"></line-chart>
              </div>
            </v-col> -->
          </v-row>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import LineChart from "../../../plugins/LineChart.js";
import status from "../../../mixins/status.js";
export default {
  components: {
    LineChart
  },
  mixins: [status],
  data() {
    var _this = this;
    return {
      leadsGenerated: [],
      appointmentsGenerated: [],
      dealsBranchGenerated: [],
      dealsChannelGenerated: [],
      channelSourceOppMonth: [],
      deal: null,
      options: {
        plugins: {
          datalabels: {
            display: false
          }
        },
        layout:{
          margin:{
            bottom: 200
          },
        },
        tooltips: {
          usePointStyle: true,
          mode: 'index',
          fontColor: '#221F20',
          fontFamily: "'Inter', sans-serif",
          intersect: false,
          callbacks: {
            title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
            footer: (tooltipItems, data) => {
              var sum = 0;
              tooltipItems.forEach(function(tooltipItem) {
                sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              });
              return 'Total: ' + sum;
            }
          }
        },
        legend: { 
          position: 'bottom',
          display: true,
          labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontColor: '#221F20',
            fontFamily: "'Inter', sans-serif",
            fontSize: 14,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#221F20',
              fontFamily: "'Inter', sans-serif",
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              fontColor: '#221F20',
              fontFamily: "'Inter', sans-serifs",
              beginAtZero: true,
              callback: function(value) {
                if (value % 1 === 0) {
                  return value
                }
              }
            }
          }]
        }
      },
      dealOptions: {
        plugins: {
          datalabels: {
            display: false
          }
        },
        tooltips: {
          usePointStyle: true,
          mode: 'index',
          fontColor: '#221F20',
          fontFamily: "'Inter', sans-serif",
          intersect: false,
          callbacks: {
            title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
            footer: (tooltipItems, data) => {
              var sum = 0;
              tooltipItems.forEach(function(tooltipItem) {
                sum += data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              });
              return 'Total: ' + sum;
            },
          }
        },
        legend: {
          position: 'bottom',
          display: true,
          labels: {
            usePointStyle: true,
            boxWidth: 6,
            fontColor: '#221F20',
            fontFamily: "'Inter', sans-serif",
            fontSize: 14,
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#221F20',
              fontFamily: "'Inter', sans-serif",
            }
          }],
          yAxes: [{
            gridLines: {
              display: false,
            },
            stacked: true,
            ticks: {
              fontColor: '#221F20',
              fontFamily: "'Inter', sans-serif",
              beginAtZero: true,
              callback: function(value) {
                if (value % 1 === 0) {
                  return `£${value}`
                }
              }
            }
          }]
        }
      },
    }
  },
  computed: {
    source() {
      let _this = this
      if (this.$store.state.source.list) {
        this.$store.state.source.list = this.$store.state.source.list.map(
          function(el, index) {
            let item = Object.assign({}, el);
            item.slug = el.name.replace(/\s+/g, '_').toLowerCase();
            item.color = _this.statsColors[index]
            return item;
          }
        );
        return this.$store.state.source.list
      }
    },
    branches() {
      let _this = this
      if (this.$store.state.branches.allList) {
        this.$store.state.branches.allList = this.$store.state.branches.allList.map(
          function(el, index) {
            let item = Object.assign({}, el);
            item.slug = el.branch.replace(/\s+/g, '_').toLowerCase();
            item.color = _this.statsColors[index]
            return item;
          }
        );
        return this.$store.state.branches.allList
      }
    },
  },
  created() {
    this.$store.dispatch('branches/GET_ALL_BRANCH', '')
    this.$store.dispatch('source/GET_SOURCE', '')
    this.loadPulseData()
  },
  methods: {
    async loadPulseData() {
      await this.leads()
    },
    getBranchDataset(objArray) {
      console.log(objArray)
      let result = []
      this.branches.forEach((item) => {
        result.push({
          label: item.branch,
          fill: true,
          borderWidth: 1,
          borderColor: item.color,
          backgroundColor: item.color,
          data: objArray.map(function(el) { return el[item.slug]; })
        })
      })
      return result
    },

    getValues(objArray, slug) {
      let ids = []
      objArray.forEach((el) => {
        el.branch.reduce((ids, thing) => {
          if (thing.name === slug && thing.lead) {
            ids.push(thing.lead);
          } else {
            ids.push(0)
          }
          return ids;
        }, []);
      })

      return ids;
    },
    getSourceDataset(objArray) {
      let result = []
      this.source.forEach((item) => {
        result.push({
          label: item.name,
          fill: true,
          borderWidth: 1,
          borderColor: item.color,
          backgroundColor: item.color,
          data: objArray.map(function(el) { return el[item.slug]; })
        })
      })
      return result
    },
    async leads() {
      let data = []
      await this.$store.dispatch('pulse/LEADS_GENERATED').then(res => {
        data = res.data
      })

      this.leadsGenerated = {
        labels: data.map(function(el) { return el.date; }),
        datasets: this.getBranchDataset(data)
      }

    },
    async appointments() {
      let data = []
      await this.$store.dispatch('pulse/APPOINTMENTS_GENERATED').then(res => {
        data = res.data
      })
      this.appointmentsGenerated = {
        labels: data.map(function(el) { return el.date; }),
        datasets: this.getBranchDataset(data)
      }
    },
    async dealsBranch() {
      this.deal = true
      let data = []
      await this.$store.dispatch('pulse/DEALS_BRANCH_GENERATED').then(res => {
        data = res.data
      })
      this.dealsBranchGenerated = {
        labels: data.map(function(el) { return el.date; }),
        datasets: this.getBranchDataset(data)
      }
    },
    async dealsChannel() {
      this.deal = true
      let data = []
      await this.$store.dispatch('pulse/DEALS_CHANNEL_GENERATED').then(res => {
        data = res.data
      })
      this.dealsChannelGenerated = {
        labels: data.map(function(el) { return el.date; }),
        datasets: this.getSourceDataset(data)
      }
    }
  }
}

</script>
