<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat class="pa-0">
          <v-card-text class="pa-0">
            <v-data-table class="results-table" item-key="id" :headers="headers" :items="items" :loading="loading" :options.sync="options" sort-by="updated_at" :items-per-page="itemsPerPage" :footer-props="footerProps" :server-items-length="totalCount">
              <template v-slot:item.name="{ item }">
                <p class="user-name">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.treatments="{ item }">
                <template v-if="item.treatments.length > 0">
                  <template v-for="tag in item.treatments.slice(0,3)">
                    <v-chip small class="este-chip tag-chip" text-color="black">
                      {{ tag.name | capitalize }}
                    </v-chip>
                  </template>
                </template>
                <span class="badge-label" v-if="item.treatments.length > 3">+{{item.treatments.length -3}}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu max-width="200" :nudge-width="200" offset-y left content-class="user-action">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="rgba(0, 0, 0, 0.6)" v-on="on">
                      <v-icon large>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <div class="logout-block">
                    <ul>
                      <li><a @click="editItem(item)">Edit</a></li>
                      <li><a @click="deleteItem(item)">Delete</a></li>
                    </ul>
                  </div>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="modalDialog" max-width="490">
      <v-card class="delete-alert">
        <v-card-title>
          Confirm
          <a class="float-right pa-6" @click="modalDialog = false"><img src="/images/close.svg" alt="Close" /></a>
        </v-card-title>
        <v-card-text>
          {{message}}
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center">
          <div class="delete-wrapper">
            <v-btn class="cancel-btn btn-gap" depressed rounded outlined @click="modalDialog = false" color="dark" x-large>
              Cancel
            </v-btn>
            <v-btn class="delete-btn" :color="(actionText === 'Delete') ? 'red' : 'secondary'" depressed @click="handleFunction(action)" rounded x-large>
              {{actionText}}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { USER } from '../../../../store/action-types.js';
import globalFn from '../../../../mixins/globalFn';
import sliderFn from '../../../../mixins/sliderFn';
import status from '../../../../mixins/status';
export default {
  name: "Clients",
  mixins: [globalFn, sliderFn, status],
  data() {
    return {
      loading: false,
      modalDialog: false,
      headers: [{ text: 'Name', align: 'start', value: 'name', align: 'left', sortable: false },
        { text: 'Treatments', value: 'treatments', sortable: false, align: 'left' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ],
      search: "",
      isTyping: false,
      page: 1,
      totalCount: 0,
      itemsPerPage: 10,
      options: {},
      sortOrder: 'desc',
      sortBy: 'updated_at',
      items: [],
      footerProps: {
        itemsPerPageOptions: [10, 15, 20, 25, 50],
        showFirstLastPage: true,
        showCurrentPage: true,
      },
      selectedItem: '',
      params: '?attributes=treatments',
      message: '',
      action: '',
      actionText: '',
    }
  },
  watch: {
    options: {
      handler($event) {
        const sortOrder = $event.sortDesc[0] ? "ASC" : "DESC";
        if ($event.sortBy[0]) {
          this.sortBy = $event.sortBy[0];
          this.sortOrder = sortOrder;
          this.paginate($event);
        }
      },
      deep: true
    },
  },
  computed: {

  },
  mounted() {
    this.$root.$on('machineTableUpdate', this.readTheDataFromApi)
    this.$root.$on('searchMachines', this.getSearchMachines)
  },
  created() {

  },
  methods: {
    async getSearchMachines(data) {
      this.options.page = 1;
      this.items = []
      if (data) {
        this.search = `&search=${data.search}`
        await this.readTheDataFromApi()
      } else {
        this.search = ''
        await this.readTheDataFromApi()
      }
    },
    async paginate(e) {
      this.itemsPerPage = e.itemsPerPage;
      this.page = e.page;
      await this.readTheDataFromApi()
    },
    async readTheDataFromApi() {
      this.loading = true;
      await this.$store.dispatch('machines/GET_MACHINE', `${this.params}&page=${this.page}&per_page=${this.itemsPerPage}&${this.search}`).then(res => {
        this.items = res.data.data
        this.totalCount = res.data.total
      })
      this.loading = false;
    },
    async editItem(item) {
      await this.$store.dispatch('machines/SHOW_MACHINE', { id: item.id, param: this.params }).then(res => {
        this.openDrawer("add", "750", "settings/machines/edit");
      })
    },
    handleFunction(funName) {
      this[funName]()
    },
    async deleteItem(item) {
      this.selectedItem = item
      this.modalDialog = true
      this.message = " Please confirm you want to delete?"
      this.actionText = "Delete"
      this.action = "deleteMachine"
    },
    async deleteMachine() {
      await this.$store.dispatch('machines/DELETE_MACHINE', this.selectedItem.id).then(res => {
        if (res.result === 'success') {
          this.modalDialog = false
          this.selectedItem = null
          this.readTheDataFromApi()
        } else if (res.result === 'fail') {
          res.type = 'error'
          this.$store.dispatch('snackbar/SHOW', res)
        }
      }).catch(err => {
        err.type = 'error'
        this.$store.dispatch('snackbar/SHOW', err)
      });
    },
  }
};

</script>
