var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fill-height slider-cont"},[_c('div',{staticClass:"modal-inside pb-0 no-tab-right-pane"},[_c('div',{staticClass:"form-title mb-24"},[_vm._v("Stock History for "+_vm._s(_vm.product.name))]),_vm._v(" "),_c('div',{staticClass:"tab-content-wrapper"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [(item.quantity !== null)?_c('span',[_vm._v(_vm._s(item.quantity))]):_vm._e()]}},{key:"item.movement",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.movement === 1) ? 'In' : 'Out'))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user && item.user !== null)?_c('span',[_vm._v(_vm._s(item.user.name))]):_vm._e()]}},{key:"item.movement_date",fn:function(ref){
var item = ref.item;
return [(item.movement_date !== null)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.movement_date,"DD/MM/YY @ H:mm")))]):_vm._e()]}}])})],1)]),_vm._v(" "),_c('div',{staticClass:"form-btn-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap px-15",attrs:{"depressed":"","rounded":"","outlined":"","x-large":""},on:{"click":_vm.close}},[_vm._v("\n        Close\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }