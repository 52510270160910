var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"results-table",attrs:{"headers":_vm.headers,"items":_vm.configuration},scopedSlots:_vm._u([{key:"item.brand_logo_url",fn:function(ref){
var item = ref.item;
return [(item.brand_logo)?_c('img',{attrs:{"height":"100px","src":item.brand_logo_url}}):_vm._e()]}},{key:"item.brand_mini_logo_url",fn:function(ref){
var item = ref.item;
return [(item.brand_mini_logo)?_c('img',{attrs:{"height":"100px","src":item.brand_mini_logo_url}}):_vm._e()]}},{key:"item.email_logo_url",fn:function(ref){
var item = ref.item;
return [(item.email_logo)?_c('img',{attrs:{"height":"100px","src":item.email_logo_url}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("Edit")])])])])])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }