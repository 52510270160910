import http from '../http/common'

const resource = '/campaign'

export default {

	create: (payload) => http.post(resource, payload),

	list: (payload) => http.get(`${resource}/get-all${payload}`),

	get: (payload) => http.get(`${resource}${payload}`),

	show: (id, params) => http.get(`${resource}/${id}${params}`),

	update: (id, payload) => http.put(`${resource}/${id}`, payload),

	delete: (id) => http.delete(`${resource}/${id}`),

	search: (params) => http.get(`${resource}/search${params}`),
}
