var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"results-table",attrs:{"single-expand":true,"expanded":_vm.expanded,"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.deal_info",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"name-column"},[_c('div',{staticClass:"user-name"},[_vm._v("\n                  "+_vm._s(item.deal_info)+"\n                ")]),_vm._v(" "),(item.client)?[_c('div',{staticClass:"name-column"},[(item.client.name)?_c('div',{staticClass:"user-name"},[_vm._v("\n                      "+_vm._s(item.client.name)+"\n                    ")]):_vm._e(),_vm._v(" "),(!item.client.name && item.client.social_handle)?_c('div',{staticClass:"d-flex user-name"},[(item.client.source_name !== null)?_c('img',{staticClass:"mr-2",attrs:{"src":("/images/" + (item.client.source_name.toLowerCase()) + ".svg")}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n                        @"+_vm._s(item.client.social_handle)+"\n                      ")])]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"user-location"},[_vm._v("\n                      "+_vm._s(item.client.branch_name)+"\n                    ")])])]:_vm._e()],2)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v("\n              "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YY"))+"\n            ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"total mb-2"},[_vm._v(_vm._s(_vm._f("currency")(item.total,'£')))]),_vm._v(" "),(item.latest_payment !== null)?_c('p',{staticClass:"total red--text"},[_vm._v("B: "+_vm._s(_vm._f("currency")(item.latest_payment.amount,'£')))]):_c('p',{staticClass:"total green--text"},[_vm._v("B: £0.00")])]}},{key:"item.points_earned",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"total mb-2 green--text"},[_c('span',{staticClass:"tiny-text"},[_vm._v("Points: ")]),_vm._v(_vm._s(item.points_earned.points_balance))]),_vm._v(" "),_c('p',{staticClass:"total mb-2 green--text"},[_c('span',{staticClass:"tiny-text"},[_vm._v("£: ")]),_vm._v(_vm._s(_vm.getPoundsValue(item.points_earned.points_balance)))])]}},{key:"item.treatments",fn:function(ref){
var item = ref.item;
return [(item.deal_treatments.length > 0)?[_vm._l((item.deal_treatments.slice(0,2)),function(tag){return [_c('v-chip',{staticClass:"este-chip tag-chip",attrs:{"small":"","text-color":"black"}},[_vm._v("\n                    "+_vm._s(_vm._f("capitalize")(tag.treatment_name))+"\n                  ")])]})]:_vm._e(),_vm._v(" "),(item.deal_treatments.length > 2)?_c('span',{staticClass:"badge-label"},[_vm._v("+"+_vm._s(item.deal_treatments.length -2))]):_vm._e()]}},{key:"item.sold_by",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"profile-icon",attrs:{"alt":"avatar"}},[(item.sold_by && item.sold_by.name)?_c('p',[_vm._v(_vm._s(_vm.getInitials(item.sold_by.name))+" ")]):_vm._e()])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(!_vm.expanded.includes(item))?_c('v-chip',{staticClass:"este-chip tag-chip",attrs:{"small":""},on:{"click":function($event){return _vm.toggleRow(item)}}},[_vm._v(" View")]):_vm._e(),_vm._v(" "),(_vm.expanded.includes(item))?_c('v-chip',{staticClass:"este-chip tag-chip",attrs:{"small":""},on:{"click":function($event){return _vm.toggleRow(item)}}},[_vm._v(" Hide")]):_vm._e(),_vm._v(" "),_c('v-menu',{attrs:{"max-width":"200","nudge-width":200,"offset-y":"","left":"","content-class":"user-action"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"rgba(0, 0, 0, 0.6)"}},on),[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"logout-block"},[_c('ul',[_c('li',[(!_vm.expanded.includes(item))?_c('a',{on:{"click":function($event){return _vm.toggleRow(item)}}},[_vm._v("View")]):_vm._e()]),_vm._v(" "),_c('li',[(_vm.expanded.includes(item))?_c('a',{on:{"click":function($event){return _vm.toggleRow(item)}}},[_vm._v("Hide")]):_vm._e()]),_vm._v(" "),_c('li',[_c('a',{on:{"click":function($event){return _vm.archiveItem(item)}}},[_vm._v("Archive Deal")])])])])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expanded-table pa-0",attrs:{"colspan":headers.length}},[_c('v-row',{staticClass:"child",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"has-border",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('label',{staticClass:"login-label"},[_vm._v("Payments:")]),_vm._v(" "),_c('payments-history',{staticClass:"mt-6",attrs:{"dealId":item.id,"userId":item.user_id}})],1),_vm._v(" "),_c('v-col',{staticClass:"no-border",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('label',{staticClass:"login-label"},[_vm._v("Appointments:")]),_vm._v(" "),_c('appointments-history',{staticClass:"mt-6",attrs:{"dealId":item.id,"userId":item.user_id}})],1)],1)],1)]}}])})],1)],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"490"},model:{value:(_vm.modalDialog),callback:function ($$v) {_vm.modalDialog=$$v},expression:"modalDialog"}},[_c('v-card',{staticClass:"delete-alert"},[_c('v-card-title',[_vm._v("\n        Confirm\n        "),_c('a',{staticClass:"float-right pa-6",on:{"click":function($event){_vm.modalDialog = false}}},[_c('img',{attrs:{"src":"/images/close.svg","alt":"Close"}})])]),_vm._v(" "),_c('v-card-text',[_vm._v("\n        "+_vm._s(_vm.message)+"\n      ")]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"delete-wrapper"},[_c('v-btn',{staticClass:"cancel-btn btn-gap",attrs:{"depressed":"","rounded":"","outlined":"","color":"dark","x-large":""},on:{"click":function($event){_vm.modalDialog = false}}},[_vm._v("\n            Cancel\n          ")]),_vm._v(" "),_c('v-btn',{staticClass:"delete-btn",attrs:{"color":(_vm.actionText === 'Archive') ? 'red' : 'secondary',"depressed":"","rounded":"","x-large":""},on:{"click":function($event){return _vm.handleFunction(_vm.action)}}},[_vm._v("\n            "+_vm._s(_vm.actionText)+"\n          ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }