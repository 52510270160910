import collection from '../../../api/collections/Insights'
import asyncMiddleware from '../../../utils/action-handler'

export default {
  namespaced: true,
  state: {
    list: [],
    all: [],
    item: {},
    pagination: {}
  },
  mutations: {
    SET_INSIGHT(state, data) {
      state.list = data
    },
    SET_INSIGHTS_LIST(state, data) {
      state.all = data
    },
    SET_INSIGHT_ITEM(state, data) {
      state.item = data
    },
    RESET_INSIGHT(state, data) {
      state.item = {}
    },
    SET_INSIGHT_TABLE_PAGINATION(state, data) {
      state.pagination = data
    }
  },
  actions: {
    async GET_INSIGHT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.get(payload.data, payload.params)
        commit('SET_INSIGHT', data.data.data)
        return data
      }, commit, dispatch, 'GET_INSIGHT', true)
    },
    async GET_INSIGHTS_LIST({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.list(payload)
        commit('SET_INSIGHTS_LIST', data.data)
        return data
      }, commit, dispatch, 'GET_INSIGHTS_LIST', true)
    },
    async CREATE_INSIGHT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.create(payload)
        return data
      }, commit, dispatch, 'CREATE_INSIGHT', true)
      return data
    },
    async SHOW_INSIGHT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        if (!payload.param) {
          payload.param = ''
        }
        const { data } = await collection.show(payload.id, payload.param)
        commit('SET_INSIGHT_ITEM', data.data)
        return data
      }, commit, dispatch, 'SHOW_INSIGHT', false)
      return data
    },
    async UPDATE_INSIGHT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.update(payload.id, payload)
        return data
      }, commit, dispatch, 'UPDATE_INSIGHT', true)

      return data
    },
    async ARCHIVE_INSIGHT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.archive(payload.id, payload)
        return data
      }, commit, dispatch, 'ARCHIVE_INSIGHT', true)

      return data
    },
    async ADD_COMMENT({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.comment(payload)
        return data
      }, commit, dispatch, 'ADD_COMMENT', true)
    },

    async UPLOAD_DOCS({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.upload(payload)
        return data
      }, commit, dispatch, 'UPLOAD_DOCS', true)
      return data
    },

    async PUSHBACK_INSIGHT({ commit, dispatch }, payload) {
      const data = await asyncMiddleware(async function() {
        const { data } = await collection.pushback(payload)
        return data
      }, commit, dispatch, 'PUSHBACK_INSIGHT', true)
      return data
    },
  }
}
