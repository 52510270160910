var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-wrapper"},[_c('v-layout',[_c('v-flex',[_c('v-card',{staticClass:"pa-0",attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pt-3 pa-0"},[_c('v-data-table',{staticClass:"results-table",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"updated_at","items-per-page":_vm.itemsPerPage,"footer-props":_vm.footerProps,"server-items-length":_vm.totalCount,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._l((item.audit),function(val,index){return [_vm._l((val.new_values),function(value,key){return [(key !== 'category')?_c('div',{staticClass:"my-6 height-60"},[(item.name)?_c('div',{staticClass:"font-14 line-h-17 font-weight--bold text-black-100 mb-1"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.name)+"\n\t\t\t\t\t\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(!item.name && item.social_handle)?_c('div',{staticClass:"d-flex font-14 line-h-17 font-weight--bold text-black-100 mb-1"},[(item.source_name !== null)?_c('img',{staticClass:"mr-1",attrs:{"src":("/images/" + (item.source_name.toLowerCase()) + ".svg")}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t@"+_vm._s(item.social_handle)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.branch_name)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',[_c('span',{staticClass:"badge--chip font-10 line-h-12 text-black-300 font-weight--semibold mr-2",class:item.user_type_name === 'Lead' ? 'bg-blue-100' : 'bg-gold-200'},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.user_type_name)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])])]):_vm._e()]})]})]}},{key:"item.consultant",fn:function(ref){
var item = ref.item;
return [_vm._l((item.audit),function(item,index){return [_vm._l((item.new_values),function(value,key){return [(key !== 'category')?_c('div',{staticClass:"my-6 height-60"},[_c('div',{staticClass:"font-14 line-h-17 font-weight--bold text-black-100"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(item.owner_name))+"\n\t\t\t\t\t\t\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"mt-1"},[(item.branch_name)?_c('span',{staticClass:"font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.branch_name)+"\n\t\t\t\t\t\t\t\t\t\t\t\t")]):_vm._e()])]):_vm._e()]})]})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._l((item.audit),function(item){return [_vm._l((item.new_values),function(value,key){return [(key !== 'category')?_c('p',{staticClass:"my-6 height-60"},[_c('span',{staticClass:"font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"},[(key === 'is_urgent')?[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\tFlag\n\t\t\t\t\t\t\t\t\t\t\t\t")]:[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(key.replace(/_/g, " ")))+"\n\t\t\t\t\t\t\t\t\t\t\t\t")]],2)]):_vm._e()]})]})]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._l((item.audit),function(item){return [_vm._l((item.new_values),function(value,key){return [(key !== 'category')?_c('div',{staticClass:"my-6 font-13 line-h-16 font-weight--semibold text-black-300 height-60"},[(key === 'status')?[_c('span',{staticClass:"font-10 line-h-12 text-black-300 font-weight--semibold badge--chip border"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm._f("capitalize")(value))+"\n\t\t\t\t\t\t\t\t\t\t\t\t")])]:(key === 'comment')?[_c('ReadMore',{attrs:{"data":value}})]:(key === 'is_urgent')?[_c('span',{staticClass:"font-13 line-h-16 font-weight--semibold text-black-300"},[_vm._v(_vm._s((value === 1) ? 'True' : 'False'))])]:[_c('span',{staticClass:"font-13 line-h-16 font-weight--semibold text-black-300"},[_vm._v(_vm._s(value))])]],2):_vm._e()]})]})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._l((item.audit),function(item,index){return [_vm._l((item.new_values),function(value,key){return [(key !== 'category')?_c('p',{staticClass:"font-13 line-h-16 font-weight--semibold text-black-300 my-6 height-60"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t\t"+_vm._s(item.created_at)+"\n\t\t\t\t\t\t\t\t\t\t")]):_vm._e()]})]})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._l((item.audit),function(item,index){return [_vm._l((item.new_values),function(value,key){return [(key !== 'category')?_c('div',{staticClass:"my-6 height-60"},[_c('v-btn',{staticClass:"bg-gray-100 btn-action",attrs:{"elevation":0},on:{"click":function($event){return _vm.editItem(item)}}},[_c('inline-svg',{attrs:{"height":"16","src":"/images/icons/duotune/general/gen055.svg"}})],1)],1):_vm._e()]})]})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }