<template>
  <v-app>
    <TheHeader :globalSearch="globalSearch"></TheHeader>
    <TheSidebar></TheSidebar>
    <transition name="fade">
      <div class="fill-height main-cont-wrapper">
        <v-main :class="globalSearch ? 'pt-0' : ''">
          <v-container fluid :class="globalSearch ? 'pa-0' : ''">
            <router-view v-if="!globalSearch"></router-view>
            <TheGlobalSearch v-if="globalSearch"></TheGlobalSearch>
          </v-container>
          <v-dialog persistent v-model="reminderCheckModal" max-width="550" class="rounded-6">
            <v-card class="rounded-6 advance-sec">
              <v-card-title class="font-18 line-h-22 text-black-100 font-weight--bold">
                Reminder
              </v-card-title>
              <v-divider class="my-5"></v-divider>
              <v-form ref="form">
                <v-card-text class="pt-0 d-flex flex-column align-center justify-center">
                  <v-img src="/images/bell.gif" lazy-src="/images/bell.gif" width="100" height="100"></v-img>
                  <p class="font-18 line-h-20 font-weight--semibold text-black-300 mb-0 text-center">
                    {{reminderNotes}}
                  </p>
                </v-card-text>
                <v-divider class="my-5"></v-divider>
                <v-card-actions class="d-flex justify-end align-center px-4 pb-4">
                  <div class="d-flex align-center">
                    <v-btn class="height-35 border rounded-6 text-capitalize px-3 py-2 letter-s-0 mr-2 font-12 line-h-15 font-weight--regular" depressed outlined x-large @click="closeReminderModal">
                      Close
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </v-main>
      </div>
    </transition>
  </v-app>
</template>
<script>
import TheGlobalSearch from '../../components/TheGlobalSearch.vue'
import TheSidebar from '../../components/TheSidebar.vue'
import TheHeader from '../../components/TheHeader.vue'
import { mapActions } from "vuex";
import { USER } from "../../store/action-types";
export default {
  name: 'AdminDashboard',
  components: {
    TheSidebar,
    TheHeader,
    TheGlobalSearch
  },
  data: () => ({
    user: '',
    playerID: null,
    globalSearch: false,
    reminderCheckModal: false,
    reminderCount: 0,
    reminderNotes: null
  }),
  beforeCreate() {
    this.$OneSignal.init({ appId: process.env.MIX_ONESIGNAL_APP_ID });
  },
  watch: {
    playerID: {
      handler: function(val, oldVal) {
        if (val) {
          this.loadPlayerId()
        }
      },
      deep: true
    }
  },
  created() {
    this.CheckIfSubscribed();
    this.loadPlayerId();
    this.loadUserObject();

    /*Commented user feedback snippet code as client is not using this*/
    // window.Userback = window.Userback || {};
    // Userback.access_token = '32703|60095|UtAxkSPxYRio1onnYebTlywlj';
    // (function(d) {
    //   var s = d.createElement('script');
    //   s.async = true;
    //   s.src = 'https://static.userback.io/widget/v1.js';
    //   (d.head || d.body).appendChild(s);
    // })(document);
    /*Commented user feedback snippet code as client is not using this*/

    // this.$intercom.shutdown();
    // this.$intercom.once('ready', this.bootIntercom);

    window.Echo.channel("reminder-followup").listen(".followup-due-today", e => {
      console.log(e)
      if (this.user.id === e.data.user_id) {
        this.reminderCheckModal = true
        this.reminderNotes = e.data.body
      }
    });
  },
  mounted() {
    this.$root.$on("showGlobalSearch", this.showGlobalScreen)
    this.$root.$on("hideGlobalScreen", this.hideGlobalScreen)
  },
  methods: {
    async reminderCheck() {

      let check = localStorage.getItem('reminderFollowup')
      await this.$store.dispatch('opportunities/REMINDER', {}).then(res => {
        if (res.result === 'success') {
          if (check && res.data.length > 0) {

            this.reminderCheckModal = true
            this.reminderCount = res.data.length
          }else{
            this.reminderCheckModal = false
            localStorage.removeItem('reminderFollowup')
          }
        } else if (res.result === 'fail') {
          console.log(res)
        }
      }).catch(err => {
        console.log(err)
      });
    },

    closeReminderModal() {
      this.reminderCheckModal = false
      localStorage.removeItem('reminderFollowup')
    },
    // bootIntercom() {
    //   console.log('intercom trigger')
    //   this.$intercom.boot({
    //     user_id: this.user.id,
    //     name: this.user.name,
    //     email: this.user.email
    //   });
    //   this.$intercom.show();
    // },
    showGlobalScreen() {
      this.globalSearch = true
    },
    hideGlobalScreen() {
      this.globalSearch = false
      this.$root.$emit("showHeader")
    },
    ...mapActions({
      USER
    }),
    async CheckIfSubscribed() {
      let _this = this
      OneSignal.push(function() {
        OneSignal.isPushNotificationsEnabled(function(isEnabled) {
          if (isEnabled) {
            console.log("Push notifications are enabled!");
          } else {
            OneSignal.push(function() {
              console.log("Push notifications are not enabled yet.");
              OneSignal.showSlidedownPrompt()
            });
          }
        });
        OneSignal.on('subscriptionChange', function(isSubscribed) {
          OneSignal.push(function() {
            OneSignal.getUserId(function(userId) {
              _this.playerID = userId
            });
          });
        });
      });
    },
    async loadUserObject() {
      await this.USER().then(res => {
        this.user = res.data
      })
    },
    async loadPlayerId() {
      this.playerID = await this.$OneSignal.getUserId()
      if (this.playerID && !this.user.og_player_id) {
        console.log('API triggered')
        try {
          console.log(this.user)
          let formData = {}
          formData.user_id = (this.user) ? this.user.id : null
          formData.og_player_id = this.playerID
          await this.$store.dispatch('staff/UPDATE_PLAYERID', formData).then(res => {
            if (res.result === 'success') {
              console.log(res)
            } else if (res.result === 'fail') {
              console.log(res)
            }
          }).catch(err => {
            console.log(err)
          });
        } catch (e) {
          console.log("error: ", e);
        }
      }
    },
  }
}

</script>
<style>
</style>
